import { AfterViewChecked, AfterViewInit, Directive, ElementRef, HostListener, OnInit, Optional, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appShadowCheck]'
})
export class ShadowCheckDirective implements OnInit, AfterViewInit, AfterViewChecked {

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    @Optional() private ngControl: NgControl
  ) { }

  ngOnInit() {
    if (document.readyState === 'complete') {
      this.checkInputValue();
    } else {
      document.addEventListener('DOMContentLoaded', () => {
        this.checkInputValue();
      });
    }
  }

  ngAfterViewInit() {
    this.checkInputValue();
  }

  ngAfterViewChecked() {
  }

  @HostListener('input', ['$event.target.value'])
  @HostListener('change', ['$event.target.value'])
  onInput(value: string) {
    this.checkInputValue();
  }

  checkInputValue() {
    const element = this.el.nativeElement as HTMLInputElement | HTMLSelectElement;
    const value = element.value.trim();
    let addDropShadowClass = false;
    // if (element.type === 'number') {
    //   const numericValue = parseFloat(value);
    //   addDropShadowClass = !isNaN(numericValue) && numericValue > 0;
    // }
    // // else if (element.type === 'text') {
    // //   addDropShadowClass = value !== '';
    // // }
    // else {
      addDropShadowClass = value !== '';
    // }
  
    if (addDropShadowClass) {
      this.renderer.addClass(this.el.nativeElement, 'drop-shadow');
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'drop-shadow');
    }
  }
}

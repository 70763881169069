<ng-template #createCATprojectPopup>
  <div class="modal-header">
    <h4 class="modal-title float-start">
      {{lang.getText('create_cat_project')}}
    </h4>
    <div *ngIf="loading" style="height:0px">
      <div class="lds-ellipsis" style="left: 100px;">
        <div style="top: 15px;"></div>
        <div style="top: 15px;"></div>
        <div style="top: 15px;"></div>
        <div style="top: 15px;"></div>
      </div>
    </div>
    <button type="button" class="close float-end" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="text-center text-danger" *ngIf="no_api_access">
      {{lang.getText(no_api_access_msg)}}
    </div> 
    <div class="row my-4" style="top: 15px;">
      <div class="col-12">
      <!-- <p>{{lang.getText('select_one_of_the_available_connectors')}}:</p> -->
      <select class="form-select form-control" [(ngModel)]="currentConnector" (change)="onChangeConnector()">
        <option value="0">{{lang.getText('select_one_of_the_available_connectors')}}</option>
        <option *ngFor="let connect of connectors;" [disabled]="connect.disabled" value="{{connect.id}}">{{connect.name}}</option>
      </select>
      </div>
    </div>

    <!-- Trados Controls -->
    <ng-container *ngIf="currentConnector == 1">
      <div class="row my-4" style="top: 15px;">
        <div class="col-12">
        <!-- <p>{{lang.getText('location')}}:</p>   -->
        <select class="form-select form-control" (change)="onChangeLocation($event.target.value)" [(ngModel)]="selectedLocation">
          <option value="" disabled selected>{{lang.getText('location')}}</option>
          <option [value]="tradosFolder.id" *ngFor="let tradosFolder of tradosFolders;">{{tradosFolder.name}}</option>
        </select>
        </div>
      </div>

      <div class="row my-4">
        <div class="col-12">
          <!-- <p>{{lang.getText('project_templates')}}:</p>   -->
          <select class="form-select form-control" (change)="onChangeTemplate($event.target.value)" [(ngModel)]="selectedTemplate">
            <option value="" disabled selected>{{lang.getText('project_templates')}}</option>
            <option [value]="tradosTemplate.id" *ngFor="let tradosTemplate of tradosTemplates;">{{tradosTemplate.name}}</option>
          </select>
        </div>
        <a class="col-12 mt-2" style="cursor: pointer; color: #56bfea;" (click)="toggleExtraOptionsShow()">{{lang.getText('not_defined_a_template')}}</a>
      </div>  

      <div class="row my-4" *ngIf="isShown">
        <div class="col-12">
          <!-- <p>{{lang.getText('file_processing_configuration')}}:</p> -->
          <select class="form-select form-control" [(ngModel)]="selectedFileType">
            <option value="" disabled selected>{{lang.getText('file_processing_configuration')}}</option>
            <option [value]="tradosFileProcessingConfig.id" *ngFor="let tradosFileProcessingConfig of tradosFileProcessingConfigs['items'];">{{tradosFileProcessingConfig.name}}</option>
          </select>
        </div>
      </div> 

      <div class="row my-4" *ngIf="isShown">
        <div class="col-12">
        <!-- <p>{{lang.getText('translation_engine')}}:</p> -->
        <select class="form-select form-control" [(ngModel)]="selectedTranslationEngine">
          <option value="" disabled selected>{{lang.getText('translation_engine')}}</option>
          <option [value]="tradosTranslationEngine.id" *ngFor="let tradosTranslationEngine of tradosTranslationEngines['items'];">{{tradosTranslationEngine.name}}</option>
        </select>
        </div>
      </div> 

      <div class="row my-4" *ngIf="isShown">
        <div class="col-12">
          <!-- <p>{{lang.getText('workflow')}}:</p> -->
          <select class="form-select form-control" [(ngModel)]="selectedWorkflow">
            <option value="" disabled selected>{{lang.getText('workflow')}}</option>
            <option [value]="tradosWorkflow.id" *ngFor="let tradosWorkflow of tradosWorkflows['items'];">{{tradosWorkflow.name}}</option>
          </select>
        </div>
      </div>
    </ng-container>

    <!-- XTM Controls -->
    <ng-container *ngIf="currentConnector == 2">
      <div class="row my-4">
        <div class="col-6">
          <p>{{lang.getText('workflow')}}:</p>
          <select class="form-select form-control" [(ngModel)]="xtmWorkflow">
            <option value="" *ngIf="xtmWorkflows.length > 0">{{ lang.getText('please_select_workflow') }}</option>
            <option value="" *ngIf="xtmWorkflows.length == 0">{{lang.getText('no_results')}}</option>
            <option [value]="workflow.id" *ngFor="let workflow of xtmWorkflows;">{{workflow.name}}</option>
          </select>
        </div>
        <div class="col-6">
          <p>{{ lang.getText('segment_locking_rule') }}:</p>
          <select class="form-select form-control" [(ngModel)]="xtmSegmentLockingRule">
            <option value="">{{ lang.getText('please_select_segment_locking_rule') }}</option>
            <option [value]="segmentLockingRule" *ngFor="let segmentLockingRule of segmentLockingRules;">{{segmentLockingRule}}</option>
          </select>
        </div>
      </div> 
    </ng-container>

    <div class="row my-4">
      <div class="col-md-4 row">
        <p>{{lang.getText('due_date')}}:</p>
        <input [(ngModel)]="selectedDueDate" class="form-control" type="text" bsDatepicker autocomplete="off" [bsConfig]="bsConfig" name="selectedDueDate" />
        <span class="input-group-addon"> <em class="icon-calendar"></em> </span>
      </div>
    </div> 

    <div class="language-combination-sec">
      <br>
      <div class="col-12 language-sec">
        <strong>{{lang.getText('select_the_language_combinations')}}:</strong>
      </div>
      <br>
      <div class="row row-secondary p-2">
        <div class="col-4"><label>{{lang.getText('source2')}}</label></div>
        <div class="col-4"><label>{{lang.getText('target2')}}</label></div>
        <div class="col-4">
          <input type="checkbox" id="vehicle1" name="vehicle1" value="Select All" 
          [(ngModel)]="masterSelected"
          (change)="checkUncheckAll($event)">
          <label for="vehicle1" class="ms-2">{{lang.getText('select_all')}}</label>
        </div>
      </div>
      <br>
      <div class="row row-primary p-2">
        <div class="col-4">
          <p *ngFor="let source of sources;">{{source.name}}</p>
        </div>
        <div class="col-4">
          <p *ngFor="let target of targets;">{{target.name}}</p>
        </div>
        <div class="col-4">
          <div *ngFor="let item of checklist; let ti = index;"> 
            <input type="checkbox" id="tran-{{ti}}" name="tran-{{ti}}" value="{{item.id}}" 
            [(ngModel)]="item.isSelected"
            (change)="currentConnector == 2 ? setXTMSourceLanguage($event, item, ti) : isAllSelected()"
            >
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="select-files-sec">
      <br>
      <div class="row row-secondary p-2">
        <div class="col-8"><label>{{lang.getText('select_files_to_include')}}:</label></div>
        <div class="col-4">
          <input type="checkbox" id="vehicle1" name="vehicle1" value="Select All" 
          [(ngModel)]="filesSelected"
          (change)="checkUncheckAllFiles()">
          <label for="vehicle1" class="ms-2">{{lang.getText('select_all')}}</label>
        </div>
      </div>
      <br>
      <div class="row row-primary p-2" *ngFor="let item of checklistFiles; let ti = index;">
        <div class="col-8">
          <p style="word-wrap: break-word;">{{getFileName(item.path)}}</p>
        </div>
        <div class="col-4">
          <div class="file-select-div"> 
            <input type="checkbox" id="file-{{ti}}" name="file-{{ti}}" value="{{item.id}}" 
            [(ngModel)]="item.isSelected"
            (change)="isAllSelectedFiles()"
            >
            <label class="ms-2">{{lang.getText('select')}}</label>
            <select class="form-select form-control role-select" [(ngModel)]="item.role">
              <option value="translatable">{{'translatable'}}</option>
              <option value="reference">{{'reference'}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <br>
    <br>
    <div class="col-12 float-start">
      <button [attr.disabled]="no_api_access" (click)="onCreateCat()" class="btn btn-primary">{{lang.getText('create_cat_project')}}</button>
      <button [attr.disabled]="no_api_access" (click)="onCreateCat(true)" class="btn btn-primary ms-3">{{lang.getText('create_start_cat_project')}}</button>
    </div>
  </div>
</ng-template>


<ng-template #sendAdditionalFilesPopup>
  <div class="modal-header">
    <h4 class="modal-title float-start">
      {{lang.getText('send_additional_files')}}
    </h4>
    <div *ngIf="loading" style="height:0px">
      <div class="lds-ellipsis" style="left: 100px;">
        <div style="top: 15px;"></div>
        <div style="top: 15px;"></div>
        <div style="top: 15px;"></div>
        <div style="top: 15px;"></div>
      </div>
    </div>
    <button type="button" class="close float-end" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <br>
    <div class="select-files-sec">
      <br>
      <div class="row">
        <div class="col-8"><strong>{{lang.getText('select_files_to_include')}}:</strong></div>
        <div class="col-4">
          <input type="checkbox" id="vehicle1" name="vehicle1" value="Select All" 
          [(ngModel)]="filesSelected"
          (change)="checkUncheckAllFiles()">
          <label for="vehicle1" class="ms-2">{{lang.getText('select_all')}}</label>
        </div>
      </div>
      <br>
      <div class="row" *ngFor="let item of checklistFiles; let ti = index;">
        <div class="col-8">
          <p style="word-wrap: break-word;">{{getFileName(item.path)}}</p>
        </div>
        <div class="col-4">
          <div class="file-select-div"> 
            <input type="checkbox" id="file-{{ti}}" name="file-{{ti}}" value="{{item.id}}" 
            [(ngModel)]="item.isSelected"
            (change)="isAllSelectedFiles()"
            >
            <select class="form-select form-control role-select" [(ngModel)]="item.role">
              <option value="translatable">{{'translatable'}}</option>
              <option value="reference">{{'reference'}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <br>
    <br>
    <div class="float-start">
      <button (click)="startProject()" class="btn btn-primary text-capitalize">{{lang.getText('start') + ' ' + lang.getText('project')}}</button>
    </div>
    <div class="float-end">
      <button (click)="sendAdditionalFiles()" class="btn btn-primary">{{lang.getText('send_additional_files')}}</button>
    </div>
  </div>
</ng-template>

<div class="backdrop" [ngStyle]="{'display':display}"></div>
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display}" style="z-index: 9999;"> 
  <div class="modal-dialog" role="document">
    <div class="modal-content" [ngClass]="status">
      <div class="modal-header text-center;" style="white-space: pre-line;">
        <em class="fa fa-check" style="padding-top: 4px;"></em> &nbsp;
        {{message}}
        <button type="button" class="close" aria-label="Close" (click)="closeModalDialog()"><span
            aria-hidden="true">&times;</span></button>
      </div>
    </div>
  </div>
</div>


import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FileComponent } from './pages/file/file.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { ProjectsComponent } from './pages/projects/projects.component';

const routes: Routes = [
  { path: 'vendor-files/:hash/:projectId/:vendorId', component: FileComponent },//redirect to "vendor/project-files" route
  {
    path: 'vendor',
      children: [
        { path: 'privacy-policy', component: PrivacyPolicyComponent },
        { path: 'dashboard', component: ProjectsComponent },
        { path: 'project-files/:projectId/:vendorId', component: FileComponent },
      ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VendorPortalRoutingModule { }

<!-- Dashboard Settings popup -->
<ng-template #dashboardSettingsPopup>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{lang.getText('dashboard_settings')}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div *ngIf="settingsFg" class="modal-body">
    <form [formGroup]='settingsFg'>
      <div class="row">
        <div class="col-md-12 mb-2">
          <p class="dashboard_settings_info_line">{{ lang.getText('dashboard_settings_info_line') }}</p>
        </div>
        <!-- <div formArrayName="settingsFa" class="col-md-12" *ngFor="let setting of settingsFa.controls; let i = index;">
          <div [formGroupName]="i" class="row">
            <div class="col-md-1 text-center col-sm-2">
              <input formControlName="id" type="hidden" [value]="setting.value.id">
              <input formControlName="show" type="checkbox" [value]="setting.value.show">
            </div>
            <div class="col-md-8 col-sm-6 nospace">
              <p>{{ lang.getText("hide_" + setting.value.label) }}</p>
            </div>
          </div>
        </div> -->
        <div formArrayName="settingsFa" class="col-md-4 mb-2" *ngFor="let setting of settingsFa.controls; let i = index;">
          <div [formGroupName]="i">
            <div class="form-check form-switch">
              <input formControlName="id" type="hidden" [value]="setting.value.id">
              <input class="form-check-input" type="checkbox" id="{{ setting.value.label }}" formControlName="show" [value]="setting.value.show" (change)="checkAllSettings()"> 
              <label class="form-check-label label-font-styles ms-2" for="{{ setting.value.label }}">
                {{ lang.getText("hide_" + setting.value.label) }}
              </label>
            </div>
          </div>
        </div>
        <hr class="mt-2">
        <div class="col-md-12">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="enable_all" [checked]="allSettingsEnabled"
            (change)="checkUncheckAllSettings()">
            <label class="form-check-label label-font-styles ms-2" for="enable_all">
              {{ lang.getText(allSettingsLabel) }}
            </label>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-12">
          <button type="button" class="btn btn-primary mt-2"
            (click)="saveDashboardSettings()">{{lang.getText('save')}} {{lang.getText('settings')}}</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

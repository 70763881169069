<div class="app-login-main-container">
<div class="login-header">
    <div class="col-sm-12">
      <img alt="Brand logo" class="brand-logo" src="{{publicURL}}logo/brand-logo.webp">
    </div>
  </div>
  <div class="container-fluid">
    <div class=" justify-content-md-center">
      <div class="design-main-container col-lg-6 col-md-6 col-sm-8">
      <div class="login-layout">
        <div class="inner-login-layout">
        <form   autocomplete="off">
          <div class="form-group">
            <input type="text" class="form-control"  placeholder="Old Password">
          </div>
          <div class="form-group">
            <input type="text" class="form-control"  placeholder="New Password">
          </div>
          <div class="form-group">
            <input type="text" class="form-control"  placeholder="Confirm Password">
          </div>
         <div class="d-flex justify-content-center login-btn-container"> 
          <button type="submit" class="btn btn-primary login-btn">Set Password</button>
        </div>
        </form>
      </div>
      <div class="graphic-design-container">
        <div class="left-img">
          <img src="../../assets/img/teacup-flow-dezk.png" alt="Teacup Image">
        </div>
        <div class="right-img">
          <img src="../../assets/img/plant-flow-dezk.png" alt="Plant Image">
        </div>
      </div>
    </div>
      </div>
    </div>
  </div>
</div>
  
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HelperService } from 'src/app/services/helper.service';
import { JwtauthService } from 'src/app/services/jwtauth.service';
import { LanguageService } from 'src/app/services/language.service';
import { TokenService } from 'src/app/services/token.service';
import { environment as env } from 'src/environments/environment';

@Component({
  selector: 'app-po-history',
  templateUrl: './po-history.component.html',
  styleUrls: ['./po-history.component.scss']
})
export class PoHistoryComponent implements OnInit {
  apiURL = env['API_URL'];
  project;
  poListForm: UntypedFormGroup;
  poFA: UntypedFormArray = this.fb.array([]);
  bsConfig = {
    dateInputFormat: 'DD/MM/Y',
    rangeInputFormat: 'DD/MM/Y'
  };

  constructor(
    private http: HttpClient,
    private token: TokenService,
    private Jwtwis: JwtauthService,
    private modalService: BsModalService,
    public helpSer: HelperService,
    public lang: LanguageService,
    private fb: UntypedFormBuilder,
  ) { }

  ngOnInit() {
  }

  @ViewChild('modalTemp') modalTemp;
  modalRef: BsModalRef;
  openModal(project) {
    this.project = project;
    this.getPOHistory();
    this.modalRef = this.modalService.show(
      this.modalTemp,
      { class: 'modal-xl xlModal' }
    );
  }

  closeModal() {
    this.modalRef.hide();
  }

  list = null;
  getPOHistory() {
  this.list = null;
  let formData = {
      token:            this.token.get(),
      projectId:        this.project.id,
      projectVendorId:  this.project.pv_id
    }
    this.http.post(`${this.apiURL}vendor/po/history`, formData).subscribe(
      data => {
        this.list = [];
        Object(data).forEach((po, pi) => {
          this.poListForm = this.fb.group({
            pos: this.fb.array([])
          });
          this.poFA.push(this.fb.group({
            id: po.id,
            project_id: po.project_id,
            received_at: po.vendor_invoice_details != null && po.vendor_invoice_details.received_at != null ? this.helpSer.splitDateAndTime(po.vendor_invoice_details.received_at).date : null,
            paid_at: po.vendor_invoice_details != null && po.vendor_invoice_details.paid_at != null ? this.helpSer.splitDateAndTime(po.vendor_invoice_details.paid_at).date : null,
          }));
          this.poListForm.setControl('pos', this.poFA);
          this.poFA.valueChanges.subscribe(values => {
            setTimeout(() => { this.getChangedProjects() });
          });

          po.filename = `PO-#${this.project.orderNo}-${pi+1}.pdf`;
          this.list.push(po);
        });
      },
      error => this.handleError(error)
    );
  }

  changedProjects = [];
  isDirtyprojectsForm = false;
  getChangedProjects() {
    this.changedProjects = [];
    this.changedProjects = this.poListForm.value.pos.filter(po => {
      return (po.paid_at != null && po.paid_at != '') || (po.received_at != null && po.received_at != '');
    });
    this.isDirtyprojectsForm = false;
    if (this.changedProjects.length > 0) {
      this.isDirtyprojectsForm = true;
    }
  }

  historyPDF(pF, process){
    let formData = {
      token:            this.token.get(),
      projectId:        this.project.id,
      projectVendorId:  this.project.pv_id,
      projectFinanceId: pF.id
    }
    this.http.post(`${this.apiURL}vendor/po/history/pdf`, formData).subscribe(
      (data: any) => {
        console.log('data',data);
        let blob = this.helpSer.base64ToBlob(data.pdf);
        if (process == 'down') {
          this.helpSer.downloadFile(blob, pF.filename);
        } else if ( process == 'view' ) {
          window.open(window.URL.createObjectURL(blob));
        }  
      },
      error => {
        this.handleError(error);
      }
    );
  }

  handleError(error) {
    this.Jwtwis.handleError(error);
  }

  noInvoiceMsg = 'You haven\'t uploaded an invoice yet.';
  getInvoice(project, method, po, index) {
    let formData:FormData = new FormData();
    formData.append('token', this.token.get());
    formData.append('projectId', project.id);
    formData.append('projectVendorId', project.pv_id);
    formData.append('projectFinanceId', po.id);
    formData.append('method', method);
    this.http.post(`${this.apiURL}vendor/invoice/get`, formData)
      .subscribe(
        data => {
          let pdf = data['pdf']
          if (method == 'name') {
            this.list[index] = data;
            return;
          }
          if ( pdf == null && (method == 'view' || method == 'down') ) {
            this.helpSer.alertIt(this.noInvoiceMsg);
            return;
          }
          if ( pdf == null ) {
            return;
          }
          let b64PDF = pdf['b64'];
          if (method == 'view') {
            this.helpSer.viewBase64File(b64PDF);
          } else {
            this.helpSer.downloadBase64File(b64PDF, pdf['filename']);
          }
        }
      );
  }

  deleteInvoice(project, po, index) {
    if ( project.invoiced_at == null ) {
      this.helpSer.alertIt(this.noInvoiceMsg);
      return;
    }
    if ( !confirm('Are you sure you want to delete this invoice?') ) {
      return;
    }
    let formData:FormData = new FormData();
    formData.append('token', this.token.get());
    formData.append('projectId', project.id);
    formData.append('projectVendorId', project.pv_id);
    formData.append('projectFinanceId', po.id);
    this.http.post(`${this.apiURL}vendor/invoice/delete`, formData)
      .subscribe(
        data => {
          project.invoiced_at = null;
          this.getInvoice(project, 'name', po, index);
        }
      );
  }

  @ViewChild('invoiceUploadIpt', {static: true}) invoiceUploadIpt: ElementRef<HTMLElement>;
  selectedInvoiceProj = null;
  selectedPO = null;
  selectedIndex = null;
  triggerInvoiceUpload(project, po, index) {
    this.selectedInvoiceProj = project;
    this.selectedPO = po;
    this.selectedIndex = index;
    let el: HTMLElement = this.invoiceUploadIpt.nativeElement;
    el.click();
  }

  uploadInvoice(event) {
    let files = event.target.files;
    if ( files.length < 1 ) return;
    let file: File = files[0];
    (this.invoiceUploadIpt.nativeElement as HTMLInputElement).value = "";
    let formData:FormData = new FormData();
    formData.append('token', this.token.get());
    formData.append('pdf', file);
    formData.append('projectId', this.selectedInvoiceProj.id);
    formData.append('projectVendorId', this.selectedInvoiceProj.pv_id);
    formData.append('projectFinanceId', this.selectedPO.id);
    this.http.post(`${this.apiURL}vendor/invoice/upload`, formData)
      .subscribe(
        data => {
          if ( data == 'Done' ) {
            this.getInvoice(this.selectedInvoiceProj, 'name', this.selectedPO, this.selectedIndex);
          }
        }
      );
  }

  checkInvoiceStatus(po) { 
    if (
      po.vendor_invoice_details == null ||
      (po.vendor_invoice_details.filename == null && po.vendor_invoice_details.path == null)
    ) {
      return true;
    }
    return null;
  }

  savePos() {
    let postData = this.token.getAPITokenData();
    postData['changedProjects'] = this.changedProjects;
    this.http.post(`${this.apiURL}vendor/invoice/save-invoice-dates`, postData)
      .subscribe(
        data => this.getPOHistory()
      );
  }
}

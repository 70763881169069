import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FileUploadBuilderComponent } from './file-upload-builder.component';
import { SapDetailsComponent } from './sap-details/sap-details.component';
import { SapFieldComponent } from './sap-details/sap-field/sap-field.component';
import { DisplayBatchesCbComponent } from './components/display-batches-cb/display-batches-cb.component';
import { VendorCatPercentageChangeComponent } from './components/vendor-cat-percentage-change/vendor-cat-percentage-change.component';
import { AddVendorComponent } from './components/add-vendor/add-vendor.component';
import { VendorRatesComponent } from './components/vendor-rates/vendor-rates.component';
import { CreateCatComponent } from './components/create-cat/create-cat.component';
// import { GenerateInvoiceComponent } from './components/generate-invoice/generate-invoice.component';
// import { VendorInvoiceComponent } from '../modules/shared/components/vendor-invoice/vendor-invoice.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxSummernoteModule } from 'ngx-summernote';
import { ProjectRelationListComponent } from './components/project-relation-list/project-relation-list.component';
import { ProjectRelationActionComponent } from './components/project-relation-action/project-relation-action.component';
import { RouterModule } from '@angular/router';
import { ProjectClientLanguageTranslationsComponent } from './components/project-client-language-translations/project-client-language-translations.component';
import { ProjectVendorLanguageTranslationsComponent } from './components/project-vendor-language-translations/project-vendor-language-translations.component';
import { CheckboxesComponent } from '../components/checkboxes/checkboxes.component';
import { GenerateQuoteComponent } from './components/generate-quote/generate-quote.component';
import { FinanceBreakdownComponent } from './components/finance-breakdown/finance-breakdown.component';
import { VendorFinanceBreakdownComponent } from './components/vendor-finance-breakdown/vendor-finance-breakdown.component';
import { DeliveryBatchesComponent } from './components/delivery-batches/delivery-batches.component';
import { SharedModule } from '../modules/shared/shared.module';
import { TotalDisplayComponent } from './components/total-display/total-display.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
@NgModule({
    imports: [
        RouterModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BsDatepickerModule.forRoot(),
        NgxSummernoteModule,
        SharedModule,
        TimepickerModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
    ],
    declarations: [
        FileUploadBuilderComponent,
        SapDetailsComponent,
        SapFieldComponent,
        DisplayBatchesCbComponent,
        VendorCatPercentageChangeComponent,
        AddVendorComponent,
        VendorRatesComponent,
        CreateCatComponent,
        // GenerateInvoiceComponent,
        GenerateQuoteComponent,
        // VendorInvoiceComponent,
        ProjectRelationListComponent,
        ProjectRelationActionComponent,
        ProjectClientLanguageTranslationsComponent,
        ProjectVendorLanguageTranslationsComponent,
        CheckboxesComponent,
        FinanceBreakdownComponent,
        VendorFinanceBreakdownComponent,
        DeliveryBatchesComponent,
        TotalDisplayComponent
    ],
    exports: [
        FileUploadBuilderComponent,
        SapDetailsComponent,
        DisplayBatchesCbComponent,
        VendorCatPercentageChangeComponent,
        AddVendorComponent,
        VendorRatesComponent,
        CreateCatComponent,
        // GenerateInvoiceComponent,
        GenerateQuoteComponent,
        // VendorInvoiceComponent,
        ProjectRelationListComponent,
        ProjectRelationActionComponent,
        ProjectClientLanguageTranslationsComponent,
        ProjectVendorLanguageTranslationsComponent,
        FinanceBreakdownComponent,
        VendorFinanceBreakdownComponent,
        DeliveryBatchesComponent,
        CheckboxesComponent,
        TotalDisplayComponent
    ],
    providers: []
})
  
export class ProjectEditModule { }

import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { HelperService } from 'src/app/services/helper.service';
import { ProjectService } from 'src/app/services/project.service';
import { environment } from 'src/environments/environment';
import { TranslatePipe } from '../../pipes/translate.pipe';

@Component({
  selector: 'app-client-translation-rates',
  templateUrl: './client-translation-rates.component.html',
  styleUrls: ['./client-translation-rates.component.scss']
})
export class ClientTranslationRatesComponent implements OnInit {
  @Input() is_project_page: Boolean = false;
  @Input() client = [];
  @Input() currency_id = null;
  @Input() selected_tr_for_rate = null;
  @Output() selected_tr_rate = new EventEmitter();

  apiURL;
  t_rates_fa: FormArray = new FormArray([]);
  edit_form = new FormGroup({
    t_rates: this.t_rates_fa
  });
  languages = [];
  currencies = [];

  constructor(
    private project_ser: ProjectService,
    private fb: UntypedFormBuilder,
    private http_client: HttpClient,
    public translate_pipe: TranslatePipe,
    public help_ser: HelperService
    ) {
      this.apiURL = environment['API_URL'];
  }

  ngOnInit(): void {
    this.get_languages();
    this.get_currencies();
    this.get_rates();
  }

  rates = null;
  get_rates() {
    let source_lang_id = this.is_project_page ? this.selected_tr_for_rate.get('source_lang_id').value : '';
    let target_lang_id = this.is_project_page ? this.selected_tr_for_rate.get('target_lang_id').value : '';
    let filters = {
      client_id: this.client,
      currency_id: this.currency_id,
      source_lang_id: source_lang_id,
      target_lang_id: target_lang_id
    };
    this.project_ser.client_translation_rates(filters).subscribe(
      data => {
        this.rates = data;
        Object.keys(data).forEach(key => {
          let t_rate = data[key];
          this.t_rates_fa.push(this.fb.group(t_rate));
        });
        this.t_rates_fa.disable();
      }
    )
  }

  validation_errors;
  update_t_rate(t_rate) {
    this.http_client.post(`${this.apiURL}client-translation-rates/update`, t_rate.value).subscribe(
      data => {
        t_rate.patchValue(data['data'])
        t_rate.disable();
      }, error => {
        if ( error.error ) {
          this.validation_errors = error.error.error
          console.log("this.validation_errors", this.validation_errors);
          console.log("error.error", error.error);
        }
      }
    )
  }

  delete(ri) {
    if ( ! confirm(this.translate_pipe.transform('are_you_sure')) ) {
      return;
    }
    let t_rate = this.t_rates_fa.at(ri);
    if ( ! t_rate.value.id ) {
      this.t_rates_fa.removeAt(ri);
      return;
    }
    this.http_client.delete(`${this.apiURL}client-translation-rates/${t_rate.value.id}/delete`).subscribe(
      data => {
        this.t_rates_fa.removeAt(ri);
      }
    )
  }

  select_t_rate(t_rate) {
    this.selected_tr_rate.emit(t_rate.value)
  }

  add_t_rate() {
    this.t_rates_fa.push(this.fb.group({
      id: '',
      source_lang_id: '',
      target_lang_id: '',
      client_id: this.client,
      description: '',
      currency_id: '',
      word_rate: 0
    }));
  }

  edit_t_rate(t_rate) {
    this.t_rates_fa.disable();
    t_rate.enable();
  }

  get_languages() {
    this.project_ser.get_languages().subscribe(
      data => {
        this.languages = data['result'];
      }
    )
  }

  get_currencies() {
    this.project_ser.get_currencies().subscribe(
      data => {
        this.currencies = data['result'];
      }
    )
  }
}

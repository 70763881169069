<div *ngIf="total_pages > 1" class="pages row {{theme}}">
    <button
        type="button"
        [disabled]="prev_page == null"
        (click)="get_page(prev_page)"
        class="page btn btn-arrow page btn-round prev">
    </button>
    <button *ngFor="let page of pages;"
        type="button"
        (click)="get_page(page.label)"
        [ngClass]="{'active': page.active}"
        class="page btn btn-round">
        {{page.label}}
    </button>
    <button
        type="button"
        [disabled]="next_page == null"
        (click)="get_page(next_page)"
        class="page btn btn-arrow page btn-round next">
    </button>
</div>

<ng-template #invoiceHistoryModal>
  <div class="new-redesign-style">
    <div class="modal-header">
      <h4 class="modal-title pull-left">
        {{lang.getText('invoice_history')}}
      </h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <!-- <div class="row new-redesign-style d-flex align-item-center">
      <div class="col-md-10 mb-2">
        <p> {{lang.getText('invoice_history')}}</p>
      </div>  
        <div class="col-md-2 mb-2">
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>  -->
  <!-- <div class="modal-header">
    <h4 class="modal-title pull-left">
        {{lang.getText('invoice_history')}}
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> -->
  <div *ngIf="projectFinances" class="modal-body">
    <div class="row row-secondary p-2">
      <div class="row font-weight-bold">
        <div [ngClass]="view == 'financially_closed' ? 'col-sm-4' : 'col-sm-2'">{{lang.getText('filename')}}</div>
        <div class="col-sm-1">{{lang.getText('amount2')}}</div>
        <div [ngClass]="!financial_dashboard ? 'col-sm-1' : 'col-sm-2'">{{lang.getText('created')}}</div>
        <div class="col-sm-2">{{lang.getText('sent')}}</div>
        <div class="col-sm-2" *ngIf="(financial_dashboard && (view == 'payable_by_client' || view == 'financially_closed')) || !financial_dashboard">{{lang.getText('paid_without_colun')}}</div>
        <div [ngClass]="!financial_dashboard ? 'col-sm-3' : view == 'billable_to_client' ? 'col-sm-4' : 'col-sm-2'" *ngIf="view != 'financially_closed'">{{lang.getText('action')}}</div>
      </div>
    </div>
    <div [formGroup]="invoiceListForm" >
      <div formArrayName="invoices" *ngFor="let pfInvoice of projectFinances['invoice'];let i = index" class="row row-primary mt-3">
        <div class="row mt-1" [formGroupName]="i">
          <div [ngClass]="view == 'financially_closed' ? 'col-sm-4' : 'col-sm-2'">{{pfInvoice.filename}}</div>
          <div class="col-md-1">{{pfInvoice.amount}}</div>
          <div [ngClass]="!financial_dashboard ? 'col-sm-1' : 'col-sm-2'">{{splitDateAndTime(pfInvoice.created_at).date}}</div>
          <div class="col-md-2">
            <input formControlName="sent_at" class="smallin w-100 form-control" type="text" [attr.disabled]="view == 'payable_by_client' ? true : null"
                  placeholder="&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp; /" bsDatepicker
                  [bsConfig]="bsConfig" />
          </div>
          <div class="col-md-2" *ngIf="(financial_dashboard && (view == 'payable_by_client' || view == 'financially_closed')) || !financial_dashboard">
            <input formControlName="paid_at" class="smallin w-100 form-control" type="text"
            placeholder="&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp; /" bsDatepicker
            [bsConfig]="bsConfig" />
          </div>
          <div class="btns-col" [ngClass]="!financial_dashboard ? 'col-sm-3' : view == 'billable_to_client' ? 'col-sm-4' : 'col-sm-2'" *ngIf="view != 'financially_closed' || !financial_dashboard">
            <ng-container *ngIf="view == 'billable_to_client' || !financial_dashboard">
              <button (click)="pfInvoiceDo(pfInvoice,'view')" class="btn btn-circle-sm btn-circle"> <i class="fa fa-eye color-primary" aria-hidden="true"></i></button>
              <button (click)="pfInvoiceDo(pfInvoice,'down')" class="btn btn-sm btn-primary ms-2">{{lang.getText('download')}}</button>
              <button [disabled]="pfInvoice.sent_at" (click)="pfInvoiceDo(pfInvoice, 'send')" class="btn btn-circle-sm btn-circle ms-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 512 512"><path d="M48 448l416-192L48 64v149.333L346 256 48 298.667z" fill="#18347b"/></svg>
              </button>
            </ng-container>
            <ng-container *ngIf="view == 'payable_by_client'">
              <ng-container *ngIf="pfInvoice.client_invoice_reminder_count == 0">
                <button (click)="pfInvoiceDo(pfInvoice, 'send_reminder')" class="btn btn-sm btn-primary ms-1">{{lang.getTextWDSOrdinal('send_reminder', [pfInvoice.client_invoice_reminder_count+1], false)}}</button>
              </ng-container>
              <ng-container *ngIf="pfInvoice.client_invoice_reminder_count == 1">
                <button (click)="pfInvoiceDo(pfInvoice, 'send_reminder')" class="btn btn-sm btn-primary ms-1">{{lang.getTextWDSOrdinal('send_reminder', [pfInvoice.client_invoice_reminder_count+1])}}</button>
              </ng-container>
              <ng-container *ngIf="pfInvoice.client_invoice_reminder_count == 2">
                <button (click)="pfInvoiceDo(pfInvoice, 'send_reminder')" class="btn btn-sm btn-primary ms-1">{{lang.getTextWDSOrdinal('send_reminder', [pfInvoice.client_invoice_reminder_count+1])}}</button>
              </ng-container>
              <ng-container *ngIf="pfInvoice.client_invoice_reminder_count == 3">
                <button (click)="false" [disabled]="true" class="btn btn-sm btn-primary ms-1">{{lang.getTextWDSOrdinal('send_reminder', [pfInvoice.client_invoice_reminder_count])}}</button>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="projectFinances['invoice'].length == 0 && projectFinances != null" class="col-md-12">
      <div class="col-sm-12 text-center">
        <p>{{lang.getText('no_results')}}</p>
      </div>
    </div>
  </div>
  <div class="modal-footer-style">
    <button type="button" class="btn new-btn-primary ps-4 pe-4" (click)="saveInvoices()">{{lang.getText('save_changes')}}</button>
    <button type="button" class="btn new-btn-danger ps-4 pe-4" (click)="closeModal()">{{lang.getText('cancel')}}</button>
  </div>
</div>
</ng-template>


<!-- <ng-template #invoiceHistoryModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
        {{lang.getText('invoice_history')}}
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div *ngIf="projectFinances" class="modal-body">
    <div class="col-md-12">
      <div class="row font-weight-bold">
        <div class="col-sm-1">#</div>
        <div [ngClass]="view == 'financially_closed' ? 'col-sm-4' : 'col-sm-2'">{{lang.getText('filename')}}</div>
        <div class="col-sm-1">{{lang.getText('amount2')}}</div>
        <div [ngClass]="!financial_dashboard ? 'col-sm-1' : 'col-sm-2'">{{lang.getText('created')}}</div>
        <div class="col-sm-2">{{lang.getText('sent')}}</div>
        <div class="col-sm-2" *ngIf="(financial_dashboard && (view == 'payable_by_client' || view == 'financially_closed')) || !financial_dashboard">{{lang.getText('paid_without_colun')}}</div>
        <div [ngClass]="!financial_dashboard ? 'col-sm-3' : view == 'billable_to_client' ? 'col-sm-4' : 'col-sm-2'" *ngIf="view != 'financially_closed'">{{lang.getText('action')}}</div>
      </div>
    </div>
    <hr>
    <div [formGroup]="invoiceListForm">
      <div formArrayName="invoices" *ngFor="let pfInvoice of projectFinances['invoice'];let i = index" class="col-md-12">
        <div class="row mt-1" [formGroupName]="i">
          <div class="col-md-1">{{i+1}}</div>
          <div [ngClass]="view == 'financially_closed' ? 'col-sm-4' : 'col-sm-2'">{{pfInvoice.filename}}</div>
          <div class="col-md-1">{{pfInvoice.amount}}</div>
          <div [ngClass]="!financial_dashboard ? 'col-sm-1' : 'col-sm-2'">{{splitDateAndTime(pfInvoice.created_at).date}}</div>
          <div class="col-md-2">
            <input formControlName="sent_at" class="smallin w-100" type="text" [attr.disabled]="view == 'payable_by_client' ? true : null"
                  placeholder="&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp; /" bsDatepicker
                  [bsConfig]="bsConfig" />
          </div>
          <div class="col-md-2" *ngIf="(financial_dashboard && (view == 'payable_by_client' || view == 'financially_closed')) || !financial_dashboard">
            <input formControlName="paid_at" class="smallin w-100" type="text"
            placeholder="&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp; /" bsDatepicker
            [bsConfig]="bsConfig" />
          </div>
          <div class="btns-col" [ngClass]="!financial_dashboard ? 'col-sm-3' : view == 'billable_to_client' ? 'col-sm-4' : 'col-sm-2'" *ngIf="view != 'financially_closed' || !financial_dashboard">
            <ng-container *ngIf="view == 'billable_to_client' || !financial_dashboard">
              <button (click)="pfInvoiceDo(pfInvoice,'view')" class="btn btn-sm btn-primary">{{lang.getText('view')}}</button>
              <button (click)="pfInvoiceDo(pfInvoice,'down')" class="btn btn-sm btn-primary ms-2">{{lang.getText('download')}}</button>
              <button [disabled]="pfInvoice.sent_at" (click)="pfInvoiceDo(pfInvoice, 'send')" class="btn btn-sm btn-primary ms-2">{{lang.getText('send')}}</button>
            </ng-container>
            <ng-container *ngIf="view == 'payable_by_client'">
              <ng-container *ngIf="pfInvoice.client_invoice_reminder_count == 0">
                <button (click)="pfInvoiceDo(pfInvoice, 'send_reminder')" class="btn btn-sm btn-primary ms-1">{{lang.getTextWDSOrdinal('send_reminder', [pfInvoice.client_invoice_reminder_count+1], false)}}</button>
              </ng-container>
              <ng-container *ngIf="pfInvoice.client_invoice_reminder_count == 1">
                <button (click)="pfInvoiceDo(pfInvoice, 'send_reminder')" class="btn btn-sm btn-primary ms-1">{{lang.getTextWDSOrdinal('send_reminder', [pfInvoice.client_invoice_reminder_count+1])}}</button>
              </ng-container>
              <ng-container *ngIf="pfInvoice.client_invoice_reminder_count == 2">
                <button (click)="pfInvoiceDo(pfInvoice, 'send_reminder')" class="btn btn-sm btn-primary ms-1">{{lang.getTextWDSOrdinal('send_reminder', [pfInvoice.client_invoice_reminder_count+1])}}</button>
              </ng-container>
              <ng-container *ngIf="pfInvoice.client_invoice_reminder_count == 3">
                <button (click)="false" [disabled]="true" class="btn btn-sm btn-primary ms-1">{{lang.getTextWDSOrdinal('send_reminder', [pfInvoice.client_invoice_reminder_count])}}</button>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="projectFinances['invoice'].length == 0 && projectFinances != null" class="col-md-12">
      <div class="col-sm-12 text-center">
        <p>{{lang.getText('no_results')}}</p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModal()">{{lang.getText('cancel')}}</button>
    <button type="button" class="btn btn-primary" (click)="saveInvoices()">{{lang.getText('save_changes')}}</button>
  </div>
</ng-template> -->

export class SummerNoteConfig {
    config:object = {
        placeholder: '',
        tabsize: 2,
        height: '350px',
        toolbar: [
            ['style', ['bold', 'italic', 'underline']],
            ['fontsize', ['fontname', 'fontsize', 'color']],
        ],
        fontSizes: ['8', '9', '10', '11', '12', '14','16', '18', '24', '26', '28', '30', '32', '34', '36'],
        fontNames: ['Roboto', 'Arial'],
        fontNamesIgnoreCheck: ['Roboto', 'Arial'],
        addDefaultFonts: false
        // fontNames: ['Arial', 'Times New Roman', 'Calibri', 'Comic Sans MS']
    }

    configPDF:object = {
        placeholder: '',
        tabsize: 2,
        height: '350px',
        toolbar: [
            ['fontsize', ['fontsize']],
            ['pagebreak',['pagebreak']],
        ],
        fontSizes: ['8', '9', '10', '11', '12', '14','16', '18', '20', '22', '24', '26', '28', '30', '32', '34', '36'],
    }

    allConfig:object = {
        placeholder: '',
        tabsize: 2,
        height: '200px',
        uploadImagePath: '/api/upload',
        toolbar: [
            ['misc', ['codeview', 'undo', 'redo']],
            ['style', ['bold', 'italic', 'underline', 'clear']],
            ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
            ['fontsize', ['fontname', 'fontsize', 'color']],
            ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
            ['insert', ['table', 'picture', 'link', 'video', 'hr']],
            ['pagebreak',['pagebreak']], // The Button
        ],
        fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
    }
}

<div>
  <div *ngIf="detailsTFA !== null" class="layout_2fa">
    <div class="row auth">
      <div class="col-md-5 no-padding">
        <label class="col-auto col-form-label"
          >{{lang.getText('two_factor_authentication')}}</label
        >
      </div>
      <div class="col-md-4">
        <button
          *ngIf="!detailsTFA"
          (click)="generate2Fa(true)"
          class="btn btn-primary "
        >
          {{lang.getText('enable')}}
        </button>
        <button
          *ngIf="detailsTFA"
          (click)="generate2Fa(false)"
          class="btn btn-danger "
        >
          {{lang.getText('disable')}}
        </button>
      </div>
    </div>
    <br />
    <form *ngIf="authCodeURL" autocomplete="off">
      <div class="form-group text-center">
        <label>{{lang.getText('scan_qr_code_with_google_autheticator')}} </label>
        <div class="col-12 no-padding">
          <img [src]="authCodeURL" alt="authentication"/>
        </div>
      </div>
      <div class="form-group">
        <label for="verify_code">{{lang.getText('verification_code')}}</label>
        <input
          type="text"
          name="verify_code"
          class="form-control"
          [(ngModel)]="verificationCode"
        />
      </div>
      <div *ngIf="verifyCodeInval" class="alert alert-danger" role="alert">
        {{lang.getText('invalid_code')}}
      </div>
      <div class="form-group">
        <button (click)="submitVerificationCode()" class="btn btn-primary">
          {{lang.getText('verify')}}
        </button>
      </div>
    </form>
  </div>
</div>

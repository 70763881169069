<ng-template #sendModalTemp>
  <div class="modal-header">
    <h1 class="modal-title">
      {{lang.getText('po')}}
    </h1>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
  <div [hidden]="invoiceSendStep !== 1">
    <h4> {{lang.getText('preview')}}</h4>
    <div *ngIf="translationCount > 50" class="alert alert-danger">
      {{lang.getText('project_vendor_langu_combi_max_50_breakdown_alert')}}
    </div>
    <div *ngIf="poHTML" class="pdf-editor form-group">
      <textarea [(ngModel)]="poHTML" [ngxSummernote]="summernoteConfig.configPDF"></textarea>
    </div>
    <div class="form-group">
      <button (click)="generatePOPDF()" class="btn btn-light me-2">{{lang.getText('preview_pdf')}}</button>
      <button [hidden]="invoiceSendStep !== 1" type="button" class="btn btn-primary" (click)="invoiceSendStep = 2">
        <span aria-hidden="true">{{lang.getText('generate_po')}}</span>
      </button>
    </div>
    <div *ngIf="poPDF" class="form-group">
      <iframe *ngIf="poPDF" class="pdf-object" [src]="poPDF" type="application/pdf" title="poPDF"></iframe>
    </div>
  </div>
  <div [hidden]="invoiceSendStep !== 2">
    <h4>{{lang.getText('send_to')}}</h4>
    <div class="col-md-12">
      <div class="col-md-12 no-padding">
        <div>
          <span *ngFor="let email of invoiceEmails; let ei = index; let eLast = last;">
            <span>
              {{ email }} &nbsp;
              <span class="fa fa-trash fa-lg" title="Remove" (click)="invoiceEmails.splice(ei, 1)"></span>{{eLast ? '' : ', '}}
            </span>
          </span>
        </div>
        <div class="form-group row">
          <div class="col-md-8">
            <input class="form-control" type="text" [(ngModel)]="invoiceEmailIpt">
          </div>
          <div class="col-md-4">
            <input class="btn btn-secondary" type="button" value="Add" (click)="helpSer.pushEmail(invoiceEmails, invoiceEmailIpt)">
          </div>
        </div>
      </div>
    </div>
  </div>
    <div [hidden]="invoiceSendStep !== 3" class="alert alert-success">
      <strong>{{lang.getText('sending_po')}}</strong>
    </div>
    <div [hidden]="invoiceSendStep !== 4" class="alert alert-success">
      <strong>{{ 'done' | translate }}</strong>
    </div>
    <div class="col-md-12 no-padding">
      <button [hidden]="invoiceSendStep !== 2" type="button" class="btn btn-primary" (click)="sendPO()">
        <span aria-hidden="true">{{lang.getText('send')}}</span>
      </button>
    </div>
  </div>
</ng-template>

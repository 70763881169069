import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, OnInit, EventEmitter, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HelperService } from 'src/app/services/helper.service';
import {LanguageService} from '../../../services/language.service';
import { ProjectRelationActionComponent } from '../project-relation-action/project-relation-action.component';
import { JwtauthService } from 'src/app/services/jwtauth.service';
import { TokenService } from 'src/app/services/token.service';
import { environment as env } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-project-relation-list',
  templateUrl: './project-relation-list.component.html',
  styleUrls: ['./project-relation-list.component.scss']
})
export class ProjectRelationListComponent implements OnInit {
  apiURL: String = env['API_URL'];
  lang;
  comment;
  parent_project_id;
  project_relation_count;
  constructor(
    private jwtAuth: JwtauthService,
    private token: TokenService,
    private http: HttpClient,
    private modalService: BsModalService,
    public helperSer: HelperService,
    public language: LanguageService,
    private router: Router,
  ) {
    this.lang = language;
  }

  ngOnInit() {
    this.project_relation_count = 0;
  }


  @ViewChild('projectRelationListModal') projectRelationListModal;
  modalRef: BsModalRef;
  projectRelations: any[] = [];
  related_projects_list: any[] = [];
  openModal() {
    this.modalRef = this.modalService.show(
      this.projectRelationListModal,
      { class: 'modal-xl', backdrop: 'static' }
    );
  }

  closeModal() {
    this.modalRef.hide();
    this.projectRelations = [];
  }

  @ViewChild('openProjectRelatationActionCom') openProjectRelatationActionCom: ProjectRelationActionComponent;
  openProjectRelatationActionModal() {
    this.openProjectRelatationActionCom.openModal(this.parent_project_id, this.projectRelations);
  }

  editProjectRelation(id, index) {
    this.comment = this.projectRelations[index]['comment'];
    this.projectRelations[index]['isEditMode'] = true;
  }
  unlinkProjectRelation(id, index) {
    if (!confirm(this.lang.getText('unlink_project_warning'))) return;
    let formData = {
      token: this.token.get(),
      project_id: this.projectRelations[index]['project_id'],
      related_project_id: this.projectRelations[index]['related_project_id'],
    }
    this.http.post(`${this.apiURL}project-relation/unlink`, formData).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    );
  }
  updateProjectRelation(id, index) {
    this.projectRelations[index]['isEditMode'] = false;
    this.projectRelations[index]['comment'] = this.comment;
    let formData = {
      token: this.token.get(),
      project_id: this.projectRelations[index]['project_id'],
      related_project_id: this.projectRelations[index]['related_project_id'],
      comment: this.projectRelations[index]['comment'],
    }
    this.http.post(`${this.apiURL}project-relation/update`, formData).subscribe(
      data => {
      },
      error => this.handleError(error)
    );
  }

  getProjectStatus(project_status_id, canceled_at) {
    let data = {
      project_status: '',
      staus_class: ''
    };
    if (canceled_at !== null) {
      data.project_status = 'Archived';
      data.staus_class = 'danger';
    } else if (project_status_id == 0) {
      data.project_status = 'Unclaimed';
      data.staus_class = 'primary';
    } else { 
      data.project_status = 'Active';
      data.staus_class = 'success';
    }

    return data;
  }

  getProjectRelations(parent_project_id, openProjectRelationsModal = true) {
    this.parent_project_id = parent_project_id;
    let formData = {
      token: this.token.get(),
      project_id: this.parent_project_id
    }
    this.http.post(`${this.apiURL}project-relation/list`, formData).subscribe(
      data => {
        this.handleResponse(data);
        if (openProjectRelationsModal) { 
          this.openModal();
        }
      },
      error => this.handleError(error)
    );
  }

  @Output("updateProjectRelationsCount") updateProjectRelationsCount = new EventEmitter();
  handleResponse(data) {
    this.projectRelations = [];
    this.related_projects_list = data['projects'];
    this.related_projects_list.forEach(project => {
      let projectData = [];
      projectData['project_id'] = project.project_id;
      projectData['related_project_id'] = project.related_project_id;
      projectData['comment'] = project.comment;
      projectData['isEditMode'] = false;
      
      if (project.project_relation_details) {
        projectData['name'] = project.project_relation_details.name;
        let status_data = this.getProjectStatus(project.project_relation_details.project_status_id, project.project_relation_details.canceled_at);
        projectData['status'] = status_data.project_status;
        projectData['class'] = status_data.staus_class;
      } else { 
        projectData['name'] = project.project_reverse_relation_details.name;
        let status_data = this.getProjectStatus(project.project_reverse_relation_details.project_status_id, project.project_reverse_relation_details.canceled_at);
        projectData['status'] = status_data.project_status;
        projectData['class'] = status_data.staus_class;
      }
      this.projectRelations.push(projectData);
    });
    this.updateProjectRelationsCount.emit({ project_relation_count: this.projectRelations.length });
  }

  handleError(error) {
    this.jwtAuth.handleError(error);
  }

  projectLinkedSuccess($event) {
    this.getProjectRelations($event.parent_project_id, false);
  }

  navigateToRelatedProject(parent_project_id, project_id, related_project_id) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    let navigate_project_id = parent_project_id == related_project_id ? project_id : related_project_id;
    this.closeModal();
    this.router.navigate(['/project/edit/', navigate_project_id]);
  }
}

<div [formGroup]="projectEditForm" class="delivery-section">
  <div class="main_head">
    <p>{{lang.getText('delivery_date_info')}}</p>
  </div>

  <div class="container delivery-date-container pt-2 pb-2">
  <div class="row">
    <!-- <div class="col-md-6">
    </div>  -->
    <!-- <div class="row nospace">
        <span>{{lang.getText('rough_lead_time_indication')}}</span>
    </div> -->
    <div class="col-md-3">
      <p class="col-md-12 inner-label my-2">{{lang.getText('rough_lead_time_indication')}}</p>
      <input formControlName="batchesLeadTime" class="form-control my-2" type="text" />
    </div>
  </div>
  <div class="row">
    <div class="col-xl-6 col-lg-6 col-sm-12 col-md-12">
      <p class="small_size_text text-start col-md-12 f-w-500 my-2">{{lang.getText('project_delivery_date')}}</p> 
      <div formGroupName="project_delivery" class="row align-items-end">
        <div class="col-xl-6 col-lg-6 col-md-6 ps-0">
          <label class="inner-label">{{lang.getText('date')}}</label>
          <div class="input-group">
            <label class="input-group-btn" for="deliveryDate" style="position: absolute;z-index: 10;">
              <span class="btn btn-default" style="border: none;">
                  <span class="calendar_img"></span>
              </span>
            </label>
            <input id="deliveryDate" style="padding-left: 40px;" appShadowCheck  formControlName="date" class="form-control f-w-500" type="text" bsDatepicker [bsConfig]="bsConfig" />
          </div>
        </div>
        <div class="col-xl-6 col-lg-4  col-md-4 nospace">
          <div class="row col-md-12 col-lg-12 nospace">
            <div class="row col-md-6 col-lg-6 nospace">
              <label class="inner-label">{{lang.getText('hours')}}</label>
              <select formControlName="hour" class="in_small_other diff f-w-500">
                <option *ngFor="let hour of hours;" value="{{hour}}">{{hour}}</option>
              </select>
            </div>
            <div class="row col-md-6 col-lg-6 nospace">
              <label class="inner-label">{{lang.getText('minutes')}}</label>
              <select formControlName="minute" class="in_small_other diff f-w-500">
                <option *ngFor="let minute of minutes;" value="{{minute}}">{{minute}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-6 col-lg-6 col-sm-12 col-md-12 nospace row align-items-end mb-2">
      <!-- <p class="small_size_text text-start col-md-12 my-2">&nbsp;</p><br> -->
      <div class="select_wrap" style="margin-top: 0px;">
        <button class="btn btn-primary px-4" [disabled]="!this.projectEditForm.get('project_delivery').get('date').value" (click)="openCalendarsList()" style="border-radius: 42px;">{{lang.getText('add_date_to_calendar')}}</button>
        <ul [hidden]="!calendarInfo.openList" class="calendars_list ps-0" style="list-style-type: none;">
          <li (click)="openCalendarsConfirmPopup(0, calendarConfirm)">
            <button class="btn  px-2 mt-2">{{lang.getText('add_to_local_outlook_calendar')}}</button>
          </li>
          <li (click)="openCalendarsConfirmPopup(2, calendarConfirmGoogle)">
            <button class="btn  px-2 mt-2">{{lang.getText('add_to_online_google_calendar')}}</button>
          </li>
        </ul>        
      </div>
    </div>
  </div>

  
  <div *ngIf="project['deliveryBatchesAPI'] && project['deliveryBatchesAPI'].length > 0" class="row sap-delivery-batch ms-4">
    <div class="col-12">
      <h4>{{lang.getText('sap_delivery_dates')}}</h4>
    </div>
    <div *ngFor="let batchDelivery of project['deliveryBatchesAPI']; let i = index" class="col-12">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <p>{{lang.getText('delivery_date_batch')}} {{i+1}} &nbsp;&nbsp;&nbsp;
          {{helperSer.formatDateTime(batchDelivery.requested_at)}}</p>
      </div>
    </div>
  </div>
  <div *ngIf="deliveryBatchesFA.controls.length" class="mt-2 batch ms-4">
    <div formArrayName="deliveryBatches" *ngFor="let batchDelivery of projectEditForm.get('deliveryBatches')['controls']; let i = index"  class="row">
      <div [formGroupName]="i"
      *ngIf="batchDelivery.getRawValue().delete != 'yes'" class="col-12">
        <div class="row">
          <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 text-center nospace">
            <p class="btn-primary badge-empty">
              {{lang.getText('batch')}} {{((current_page-1) * per_page) + i + 1}} {{'-'}} {{lang.getText('delivery_date')}}
              <!-- {{lang.getText('delivery_date_batch')}}  -->
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 nospace">
            <div class="row" formGroupName="requested_at">
              <!-- <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <p>{{lang.getText('delivery_date_batch')}} {{((current_page-1) * per_page) + i + 1}}</p>
              </div> -->
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 nospace">
                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-6 ps-0">
                    <label class="inner-label">{{lang.getText('date')}}</label>
                    <div class="input-group">
                      <label class="input-group-btn" for="batch-{{i}}" style="position: absolute;z-index: 10;">
                        <span class="btn btn-default" style="border: none;">
                            <span class="calendar_img"></span>
                        </span>
                      </label>
                      <input id="batch-{{i}}" style="padding-left: 40px;" appShadowCheck formControlName="date" class="form-control" type="text" bsDatepicker [bsConfig]="bsConfig" [attr.disabled]="checkXTMData ? '' : null">
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-4  col-md-4 nospace">
                    <div class="row col-md-12 col-lg-12 nospace">
                      <div class="row col-md-6 col-lg-6 nospace">
                        <label class="inner-label">{{lang.getText('hours')}}</label>
                        <select appShadowCheck formControlName="hour" class="in_small_other diff" [attr.disabled]="checkXTMData ? '' : null">
                          <option *ngFor="let hour of hours;" value="{{hour}}">{{hour}}</option>
                        </select>
                      </div>
                      <div class="row col-md-6 col-lg-6 nospace">
                        <label class="inner-label">{{lang.getText('minutes')}}</label>
                        <select appShadowCheck formControlName="minute" class="in_small_other diff" [attr.disabled]="checkXTMData ? '' : null">
                          <option *ngFor="let minute of minutes;" value="{{minute}}">{{minute}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-sm-12 col-md-12 nospace">
            <!-- <div class="row" style="height: 1.9rem;">
            </div> -->
            <div class="row">
              <!-- <div class="col-xl-4 col-lg-4 col-sm-12 col-md-12">
                <p>{{lang.getText('description')}} {{i+1}}</p>
              </div> -->
              <div class="col-xl-7 col-lg-7 col-sm-6 col-md-7 nospace">
                <div class="row">
                  <div class="col-xl-12 col-lg-6 col-md-6">
                    <label class="inner-label">{{lang.getText('description')}}</label>
                    <input formControlName="description" class="form-control" type="text" *ngIf="!batchDelivery.value.service_type_to"/> 
                    <div class="input-sec col-md-12 pe-0 ps-0">
                      <textarea  type="text" *ngIf="batchDelivery.value.service_type_to" disabled="disabled" class="input-value-box" [value]="batchDelivery.value.related_description"></textarea>
                    </div> 
                    <input type="hidden" formControlName="uuid">
                    <!-- <button
                      *ngIf="!batchDelivery.value.service_type_to"
                      class="btn btn-primary link_btn"
                      [disabled]="!batchDelivery.get('requested_at').get('date').value"
                      (click)="openLinkToPopup(setServiceLanguagePopup, batchDelivery)">
                      {{lang.getText('link_deadline_to_specific_language_combo_or_service')}}
                    </button> -->
                    <button
                      *ngIf="batchDelivery.value.service_type_to"
                      class="btn btn-secondary link_btn cancel-this-link"
                      [disabled]="checkXTMData ? '' : null"
                      (click)="!checkXTMData ? removeLinked(batchDelivery) : false">
                      {{lang.getText('cancel_this_link')}}
                    </button>
                  </div>
                </div>
              </div>  
              <div class="col-xl-5 col-lg-5 col-md-5 col-sm-6 align-content-end pb-2">
                <span class="btn btn-circle color-primary" *ngIf="!batchDelivery.value.service_type_to"
                  [attr.disabled]="checkXTMData ? '' : null"
                  (click)="openLinkToPopup(setServiceLanguagePopup, batchDelivery)" title="{{lang.getText('link_deadline_to_specific_language_combo_or_service')}}">
                  <em aria-hidden="true" class="fa fa-link" [class.disabled]="checkXTMData"></em>
                  <!-- <i class="fa fa-link" aria-hidden="true" [class.disabled]="checkXTMData"></i> -->
                </span>
                <span class="btn btn-circle color-secondary"  *ngIf="!projectEditDis"
                [attr.disabled]="checkXTMData ? '' : null"
                (click)="!checkXTMData && checkClaimed ? deletedeliverydate(batchDelivery, i) : false" >
                <em aria-hidden="true" class="fa fa-trash" [class.disabled]="checkXTMData"></em>
                <!-- <i class="fa fa-trash delivery-batch-icon" aria-hidden="true" [class.disabled]="checkXTMData"></i> -->
                </span>
              </div>                         
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-pagination
  theme = 'center-align-btn'
  (go_to_page) = "getBatches($event,'')"
  style="position: relative;
  top: 80px;"
  >
  </app-pagination>
  <div class="row mb-3 ms-4 ps-3">
    <div class="col-md-12 my-3 ps-0" *ngIf="deliveryBatchesFA.controls.length">
      <app-display-batches-cb [projectClaimed]="projectClaimed"></app-display-batches-cb>
    </div>
    <div class="col-md-12 text-center ps-0">
      <a *ngIf="!projectEditDis" (click)="checkClaimed ? addDeliveryBatches() : false" class="btn btn-success me-2 ps-4 pe-4 float-start">{{lang.getText('add_batch')}} 
        <i class="fa fa-plus fa-sm" aria-hidden="true"></i>
      </a>
      <!-- <button class="btn btn-primary me-2"
          [disabled]="( batchReq && ! batchReq.isStopped) || page_no == 1"
          (click)="getBatches('prev')">{{lang.getText('previous')}}
      </button>
      <button class="btn btn-primary ps-4 pe-4"
          [disabled]="( batchReq && ! batchReq.isStopped) || page_no == total_page"
          (click)="getBatches('next')">{{lang.getText('next')}}
      </button> -->
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-xl-6 col-lg-6 col-sm-12 col-md-12 ">
      <p class="small_size_text text-start col-md-12 mb-2 f-w-500 mt-2 text-nowrap">{{lang.getText('long_text4')}}
      </p>
      <!-- <label class="col-md-12 col-sm-12 nospace mb-2">
        {{lang.getText('long_text4')}}
      </label> -->
      <div formGroupName="commit_delivery_only_at" class="row pt-0">
        <div class="col-xl-6 col-lg-6 col-md-6 ps-0">
          <label class="inner-label">{{lang.getText('date')}}</label>
          <div class="input-group">
            <label class="input-group-btn" for="commitedDeliveryDate" style="position: absolute;z-index: 10;">
              <span class="btn btn-default" style="border: none;">
                  <span class="calendar_img"></span>
              </span>
            </label>
            <input id="commitedDeliveryDate" style="padding-left: 40px;" appShadowCheck formControlName="date" class="form-control" type="text" bsDatepicker [bsConfig]="bsConfig" />
          </div>
        </div>
        <div class="col-xl-6 col-lg-4  col-md-4 nospace">
          <div class="row col-md-12 col-lg-12 nospace">
            <div class="row col-md-6 col-lg-6 nospace">
              <label class="inner-label">{{lang.getText('hours')}}</label>
              <select formControlName="hour" class="in_small_other diff">
                <option *ngFor="let hour of hours;" value="{{hour}}">{{hour}}</option>
              </select>
            </div>
            <div class="row col-md-6 col-lg-6 nospace">
              <label class="inner-label">{{lang.getText('minutes')}}</label>
              <select formControlName="minute" class="in_small_other diff">
                <option *ngFor="let minute of minutes;" value="{{minute}}">{{minute}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-6 col-sm-12 col-md-12 nospace">
    </div>
  </div>
</div>
</div>
<ng-template #setServiceLanguagePopup>
  <div class="modal-header">
    <h4 class="modal-title float-start">{{lang.getText('link_deadline_to_specific_language_combo_or_service')}}</h4> 
    <button type="button" class="close float-end" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <p class="alert alert-danger" *ngIf="!allowLinkingBathch">{{lang.getText('please_save_your_changes_before_you_attempt_to_link_a_batch_delivery_date_to_a_language_combination_or_service')}}</p>
  <div class="modal-body mx-3">
    <div class="col-md-12 body-input no-padding">
      <div class="col-md-12">
        <div class="form-group">
          <!-- <label>{{lang.getText('select_service_language')}}</label> -->
          <div class="row">
            <div class="col-md-12">
              <select class="form-control" [(ngModel)]="selectedServiceTypeModel">
                <option selected value="">{{lang.getText('select_service_language')}}</option>
                <optgroup label="{{lang.getText('services')}}">
                  <option value="service::{{service.id}}" *ngFor="let service of project.additionalServices">{{service.comment}}</option>
                </optgroup>
                <optgroup label="{{lang.getText('translations')}}">
                  <option value="translation::{{translation.id}}" *ngFor="let translation of project.translations">
                    {{getLanguageById(translation.source_lang_id)['name']}} - {{getLanguageById(translation.target_lang_id)['name']}}
                    <span *ngIf="translation.comment">({{translation.comment}})</span>
                  </option>
                </optgroup>
              </select>
            </div>
          </div>
        </div>
        <div class="form-group mt-3 pt-3">
          <button (click)=linkBatch() [disabled]="!allowLinkingBathch" class="btn new-btn-primary ps-4 pe-4" style="margin-right:10px;text-transform: capitalize;">{{lang.getText('link')}}</button>
          <button (click)=closeModal() class="btn new-btn-danger ps-4 pe-4">{{lang.getText('cancel')}}</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #calendarConfirm>
  <div class="new-redesign-style" >
  <div class="modal-header">
    <h4 class="modal-title float-start">
      {{lang.getText('add_date_to_calendar')}}
    </h4>
    <button type="button" class="close float-end" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body mx-4 px-2">
    <p>{{lang.getText('calendar_confirmation')}}</p>
    <div class="float-right mt-3 pt-3">
      <button class="btn new-btn-primary ps-4 pe-4" (click)="addEventToCalendar()" style="margin-right:10px;">{{lang.getText('continue')}}</button>
      <button class="btn new-btn-danger ps-4 pe-4" (click)="closeModal()" >{{lang.getText('cancel')}}</button>
    </div>
  </div>
</div>
</ng-template>
<ng-template #calendarConfirmGoogle>
  <div class="new-redesign-style">
  <div class="modal-header">
    <h4 class="modal-title float-start">
      {{lang.getText('add_date_to_calendar')}}
    </h4>
    <button type="button" class="close float-end" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body mx-4 px-2">
    <p>{{lang.getText('calendar_confirmation_google')}}</p>
    <div class="float-right mt-3 pt-3">
      <button class="btn new-btn-primary ps-4 pe-4" (click)="addEventToCalendar()" style="margin-right:10px;">{{lang.getText('continue')}}</button>
      <button class="btn new-btn-danger ps-4 pe-4" (click)="closeModal()">{{lang.getText('cancel')}}</button>
    </div>
  </div>
</div>
</ng-template>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from './token.service';
import { Injector } from '@angular/core';
import {JwtauthService} from './jwtauth.service';
import { AppConfig } from '../configs/app.config';

@Injectable()
export class LanguageService {
  private langData;
  private langEnData;
  private statusesData;
  private lang = 'en';
  private apiURL = '';
  appConfig: AppConfig = new AppConfig();

  constructor(
    private injector: Injector,
    private token: TokenService,
    private jwtAuth: JwtauthService,
    private http: HttpClient
  ) {
    this.apiURL = this.injector.get('API_URL');
    this.lang = this.getMyLang();
    this.getJson();
    this.getEnJson();
    // this.getStatusesJson();
  }
  
  public getCode() {
    return this.getMyLang();
  }

  private getMyLang() {
    let lang = this.langSelected();
    return lang ? lang : 'en';
  }

  //return selected language or false
  private langSelected() {
    const userData = this.jwtAuth.getMyDetails();
    if ( ! userData ) {
      return this.jwtAuth.logout();
    }
    let lang = userData.lang;
    if(this.jwtAuth.isVenodor()) {
      lang = userData.vendor_lang;
    }
    let currentUserLang = localStorage.getItem('currentUserLang');
    if ( currentUserLang ) {
      lang = currentUserLang;
    }
    if( lang == 'null' || lang == undefined ) {
      return false;
    }
    return lang;
  }

  public changeLanguage(lang, user_type){
    localStorage.setItem('currentUserLang', lang);
    this.getJson();
    // this.getStatusesJson();
    let postData = {
      token: this.token.get(),
      lang: lang,
      user_type: user_type
    };
    return this.http.post(this.apiURL + "change_user_lang", postData);
  }

  getJson(){
    this.http.get('../assets/lang/' + this.getMyLang() + '.json?version=' + (this.appConfig.releaseNoteVersion)).subscribe(data =>{
      this.langData = data;
    });
  }

  is_data_loaded() {
    return (typeof this.langData != 'undefined');
  }

  getEnJson(){
    this.http.get('../assets/lang/en.json?version=' + (this.appConfig.releaseNoteVersion)).subscribe(data =>{
      this.langEnData = data;
    });
  }
/*
  getStatusesJson(){
    this.http.get('../assets/lang/statuses/' + this.getMyLang() + '.json').subscribe(data =>{
      this.statusesData = data;
    });
  }
*/
  getText(text){
    return ( 
      this.langData ?
        ( typeof this.langData[text] === 'undefined' ?
          ( typeof this.langEnData[text] === 'undefined' ? this.nonExistString(text) : this.langEnData[text] ) :
          ( this.langData ? this.langData[text] : this.nonExistString(text) ) ):
        this.nonExistString(text)
    );
  }

  //With Dynamic Words
  getTextWDS(text, words){
    let desc = this.getText(text);
    words.forEach((word, i) => {
      desc = desc.replaceAll(`{W${i}}`, word);
    });
    return desc;
  }

  getTextEmail(text){
    return this.getText('email_'+text);
  }

  getProjectStatus(projectCode){
    let project_code_temp = 'project_status_' + projectCode;
    return typeof this.langData[project_code_temp] !== 'undefined' ? this.langData[project_code_temp] : this.nonExistString(projectCode);
  }

  getCompanySetting(text) {
    return this.getText('company_setting_' + this.stringToLangKey(text));
  }

  stringToLangKey(svalue) {
    return svalue.replaceAll(' ', '_').toLowerCase();
  }

  nonExistString(keyVal) {
    return keyVal ? keyVal.replaceAll('-', ' ').replaceAll('_', ' ') : '';
  }

  //With Dynamic Ordinal Words
  getTextWDSOrdinal(text, words, showDescriptive = true){
    let desc = this.getText(text);
    words.forEach((word, i) => {
      let text = 'th';
      if (showDescriptive) {
        if (word.toString().slice(-1) == 1) {
          text = 'st';
        } else if (word.toString().slice(-1) == 2) {
          text = 'nd';
        } else if (word.toString().slice(-1) == 3) {
          text = 'rd';
        }
        desc = desc.replaceAll(`{W${i}}`, word + "" + text);
      } else {
        desc = desc.replaceAll(`{W${i}}`, '');
      }
    });
    return desc;
  }
}

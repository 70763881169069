<div [hidden]="!isLoading" class="container-fluid loader">
  <div class="body">
    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
  </div>
</div>
<header class="main-menu">  
  <app-header></app-header>
</header>
<div *ngIf="lang_ser.is_data_loaded()" class="container-fluid redesign-bg" [ngClass]="{'new-redesign-style': newDesign}">
  <router-outlet></router-outlet>
  
</div>

<notifier-container></notifier-container>
<ng-template #notificationTemp let-notificationData="notification">
    <p type="notificationData.type"> {{ notificationData.message | translate }} </p>
</ng-template>

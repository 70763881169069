import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'myCurrency'
})
export class MyCurrencyPipe implements PipeTransform {

  constructor(private currencyPipe: CurrencyPipe) { }
  transform(value: any, currencyCode?: string, display: string | boolean = 'symbol', digitsInfo: string = '1.2-2', locale?: string): string {
      let result;
      if (value != null){
        result = this.currencyPipe.transform(value, currencyCode, display, digitsInfo, locale);  
        let firstDigit = result.match(/\d/);
        let symbol = result.slice(0, firstDigit.index);
        let amount = result.slice(firstDigit.index);   
        return symbol + " " + amount;
      }
      return this.currencyPipe.transform(0, currencyCode, display, locale).split('0.00')[0];
  }
}

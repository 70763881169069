import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../services/language.service';

@Component({
  selector: 'app-company-resource-management',
  templateUrl: './company-resource-management.component.html',
  styleUrls: ['./company-resource-management.component.scss']
})
export class CompanyResourceManagementComponent implements OnInit {
  lang;
  constructor(
    public language: LanguageService
  ) {
    this.lang = language;
  }

  ngOnInit() {
  }

}

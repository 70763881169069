<ng-template #modalTemp>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{lang.getText('po_history')}}
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div *ngIf="list" class="modal-body">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-6 text-center font-weight-bold">
                  {{ lang.getText('purchase_orders') }}
                </div>
                <div class="col-md-6 text-center font-weight-bold">
                  {{ lang.getText('invoices') }}
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-6 text-center font-weight-bold">
                  <div class="header-border">&nbsp;</div>
                </div>
                <div class="col-md-6 text-center font-weight-bold">
                  <div class="header-border">&nbsp;</div>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-6">
                  <div class="row font-weight-bold">
                    <div class="col-sm-1">#</div>
                    <div class="col-sm-3">{{lang.getText('file_name')}}</div>
                    <div class="col-sm-2">{{lang.getText('amount2')}}</div>
                    <div class="col-sm-3">{{lang.getText('created')}}</div>
                    <div class="col-sm-3">{{lang.getText('action')}}</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row font-weight-bold">
                    <div class="col-sm-3">{{lang.getText('file_name')}}</div>
                    <div class="col-sm-2">{{ lang.getText('uploaded_without_colun') }}</div>
                    <div class="col-sm-2">{{ lang.getText('paid_without_colun') }}</div>
                    <div class="col-sm-5">{{lang.getText('action')}}</div>
                  </div>
                </div>
            </div>
        </div>
        <hr>
        <div *ngIf="list.length > 0">
          <div [formGroup]="poListForm">
            <div formArrayName="pos" *ngFor="let po of list;let i = index" class="col-md-12">
                <div class="row" [formGroupName]="i">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-1">{{i+1}}</div>
                      <div class="col-md-3">PO-#{{project.orderNo}}-{{i+1}}.pdf</div>
                      <div class="col-md-2">{{po.amount}}</div>
                      <div class="col-md-3">{{helpSer.splitDateAndTime(po.created_at).date}}</div>
                      <div class="col-md-3 btns-col">
                        <button (click)="historyPDF(po, 'view')" class="btn btn-sm btn-primary">{{lang.getText('view')}}</button>
                        <button (click)="historyPDF(po, 'down')" class="btn btn-sm btn-primary">{{lang.getText('download')}}</button>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-3">{{ po.vendor_invoice_details != null ? po.vendor_invoice_details.filename : '' }}</div>
                      <div class="col-md-2">
                        <input class="smallin w-100" type="text" placeholder="&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp; /" 
                        bsDatepicker [bsConfig]="bsConfig"
                        formControlName="received_at"
                        value="{{ po.vendor_invoice_details != null && po.vendor_invoice_details.received_at != null ? helpSer.splitDateAndTime(po.vendor_invoice_details.received_at).date : '' }}"/>
                      </div>
                      <div class="col-md-2">
                        <input class="smallin w-100" type="text" placeholder="&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp; /" 
                        bsDatepicker [bsConfig]="bsConfig" 
                        formControlName="paid_at"
                        [attr.disabled]="true"
                        value="{{ po.vendor_invoice_details != null && po.vendor_invoice_details.paid_at != null ? helpSer.splitDateAndTime(po.vendor_invoice_details.paid_at).date : '' }}"/>
                      </div>
                      <div class="col-md-5 btns-col">
                        <button class="btn btn-sm btn-primary" (click)="triggerInvoiceUpload(project, po, i)">{{lang.getText('upload')}}</button>
                        <button class="btn btn-sm btn-primary" (click)="getInvoice(project, 'view', po, i)" [attr.disabled]="checkInvoiceStatus(po)">{{lang.getText('view')}}</button>
                        <button class="btn btn-sm btn-primary" (click)="getInvoice(project, 'down', po, i)" [attr.disabled]="checkInvoiceStatus(po)">{{lang.getText('download')}}</button>
                        <button class="btn btn-sm btn-primary" (click)="deleteInvoice(project, po, i)" [attr.disabled]="checkInvoiceStatus(po)">{{lang.getText('delete')}}</button>
                      </div>
                    </div>
    
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div *ngIf="list.length == 0" class="col-md-12">
            <div class="col-sm-12 text-center">
                <p>{{lang.getText('no_results')}}</p>
            </div>
        </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="closeModal()">{{lang.getText('cancel')}}</button>
      <button type="button" class="btn btn-primary" (click)="savePos()">{{lang.getText('save_changes')}}</button>
    </div>
</ng-template>
<input #invoiceUploadIpt 
  type="file"
  class="hidden"
  accept="application/pdf"
  (change)="uploadInvoice($event)" />

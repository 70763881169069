import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HelperService } from 'src/app/services/helper.service';
import { JwtauthService } from 'src/app/services/jwtauth.service';
import { LanguageService } from 'src/app/services/language.service';
import { SuggestImprovementComponent } from '../suggest-improvement/suggest-improvement.component';

@Component({
  selector: 'app-help-modal',
  templateUrl: './help-modal.component.html',
  styleUrls: ['./help-modal.component.scss']
})
export class HelpModalComponent implements OnInit {
  @ViewChild("helpModal") helpModal;
  modalRef: BsModalRef;

  constructor(
    public jwtAuth: JwtauthService,
    private modalService: BsModalService,
    private helpSer: HelperService,
    public lang: LanguageService
  ) { }

  ngOnInit() {
  }

  openHelpModal() {
    document.body.classList.add('help-modal-opened');
    this.modalRef = this.modalService.show(this.helpModal, { 
      class: 'modal-lg helpModal', 
      ignoreBackdropClick: true,
      keyboard: false, 
    });
  }

  @ViewChild('suggestImprovement') suggestImprovement: SuggestImprovementComponent;
  openSuggestImprovement() {
    this.suggestImprovement.openModal()
  }

  downUserManual() {
    let user_role = this.jwtAuth.get_role();
    if ( confirm(this.lang.getText('would_you_like_to_download_the_user_manual')) ) {
      this.helpSer.downUserManual(user_role)
    }
  }
  closeModal() {
    document.body.classList.remove('help-modal-opened');
    this.modalRef.hide();
  }
}

import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { JwtauthService } from './jwtauth.service';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  constructor(
    private router: Router,
    private token_ser: TokenService,
    private auth_ser: JwtauthService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      if ( ! this.token_ser.isValid() ) {
        this.auth_ser.logout();
        return false;
      }
      return true;
  }
}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return inject(PermissionsService).canActivate(next, state);
}

import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {

  constructor(
    private lang: LanguageService
  ) {
    //
  }

  transform(value: any, ...args: any[]): any {
    return this.lang.getText(value);
  }

}

import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import {UntypedFormBuilder, UntypedFormGroup, FormArray, FormControl} from '@angular/forms';

import { TokenService } from '../services/token.service';
import { JwtauthService } from '../services/jwtauth.service';
import { DataService } from '../services/data.service';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LanguageService } from '../services/language.service';

@Component({
  selector: 'app-user-create-page',
  templateUrl: './user-create-page.component.html',
  styleUrls: ['./user-create-page.component.scss']
})
export class UserCreatePageComponent implements OnInit {
  userCreateForm: UntypedFormGroup;
  private apiURL = '';
  private userId;
  response;
  formData;
  roles;
  errors;
  descriptions;
  modalRef: BsModalRef;
  lang;

  constructor(
    private injector: Injector,
    private http: HttpClient,
    private Token: TokenService,
    private Jwtwis: JwtauthService,
    private dataSer: DataService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private modalService: BsModalService,
    public language: LanguageService
    ) {
    this.descriptions = dataSer.descriptions;
    this.apiURL = this.injector.get('API_URL');
    this.lang = language;
    this.initForm();
  }

  initForm() {
    this.http.post(`${this.apiURL}user/roles`, this.Token.getAPITokenData()).subscribe(
      data => {
        this.roles = data['result'];
        this.initUserCreateForm();
      },
      error => this.handleError(error)
    );
  }

  ngOnInit() {
    this.getUsers()
  }

  allUsers
  getUsers() {
    this.http.get(`${this.apiURL}users`).subscribe(
      data => {this.allUsers = data['result']},
      error => this.handleError(error)
    );
  }

  initUserCreateForm(){
    //const controls = this.roles.map(role => new FormControl(false));
    this.userCreateForm = this.fb.group({
      name: '',
      email: '',
      //password: '',
      //roles: new FormArray(controls),
      role_id: '',
    });
  }

  createUserConfirmed = false;
  onSubmitUserCreateForm(template) {
    if (this.allUsers.length < 3 || this.createUserConfirmed) {
      this.createUser();
      return;
    }
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'modal-lg' })
    );
  }

  closeModal() {
    this.modalRef.hide();
  }

  createUser() {
  this.createUserConfirmed = true;
  if (this.modalRef != null) {
    this.closeModal();
  }
    this.formData = this.userCreateForm.value;
    this.formData['roles'] = [ this.userCreateForm.value.role_id ];
    //console.log(this.formData);
    this.http.post(`${this.apiURL}user/create`, this.formData).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    );
  }

  handleResponse(data) {
    this.createUserConfirmed = false;
    this.router.navigateByUrl('/users-list');
  }

  handleError(error) {
    this.Jwtwis.handleError(error);
    this.errors = error.error.error;
  }
}

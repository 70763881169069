import { Component } from '@angular/core';
import {Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

import { JwtauthService } from 'src/app/services/jwtauth.service';
import { TokenService } from 'src/app/services/token.service';
import { environment as env } from 'src/environments/environment';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

  forgotPasswordForm: UntypedFormGroup;
  public error = null;
  public success = null;
  public group = 'user';
  public publicURL = env['PUBLIC_URL'];
  private token: string|undefined;
  constructor(
    private router: Router,
    private jwtAuth: JwtauthService,
    private Token: TokenService,
    private fb: UntypedFormBuilder,
  ) {
    if ( (this.router.url).includes('vendor') ) {
      this.group = 'vendor';
    }
    this.token = '';
    this.initForm();
  }

  initForm() {
    this.forgotPasswordForm = this.fb.group({
      email: '',
      group: this.group,
    })
  }

  onSubmitForgotPasswordForm() {
    this.success = '';
    this.error = '';
    let formData = this.forgotPasswordForm.value;
    formData['g_captcha_response'] = this.token;
    if (this.token == '') {
      alert('Google captcha field is required.');
      return;
    }
    this.jwtAuth.forgotPassword(formData).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    );
  }

  authCode: boolean = false;
  handleResponse(data) {
    this.success = data.success;
  }

  handleError(error) {
    this.jwtAuth.handleError(error);
    this.error = error.error.error;
  }

  resolved(captchaResponse: string) {
    this.token = captchaResponse;
  }

  errored(event) {
    console.warn(`reCAPTCHA error encountered`);
  }

}

<div [hidden]="breadcrumb_menus.length == 0" class="container-fluid bc">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li *ngFor="let menu of breadcrumb_menus;" class="breadcrumb-item">
                <a *ngIf="menu.href && menu.href != href" [routerLink]="menu.href">{{ menu.label_key | translate }}</a>
                <a *ngIf="menu.href == href">{{ menu.label_key | translate }}</a>
                <a *ngIf="!menu.href">{{ menu.label_key | translate }}</a>
            </li>
        </ol>
    </nav>
</div>
<div [hidden]="breadcrumb_menus.length > 0" class="container-fluid dummy">
</div>

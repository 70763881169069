<!-- <div
  [class]="'label '+field.cpl"> -->
  <div
  [class]="'label mb-2 color-black f-w-500 f-s-14 mt-3'">
  <span>{{lang.getText(field.valKey)}}</span>
</div>
<div
  [class]="'fd-form-con '+field.cpr">
  <input *ngIf="field.type == 'text'" [value]=getFieldValue(field) type="text" [readonly]=sapData.readOnly class="form-control">
  <textarea *ngIf="field.type == 'tear'" [value]=getFieldValue(field) [readonly]=sapData.readOnly rows="3" class="form-control"></textarea>
  <div *ngIf="field.type == 'thtm'">
    <div [innerHTML]=getFieldValue(field) class="form-control html-instructions"></div>
  </div>
</div>

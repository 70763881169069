import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { JwtauthService } from 'src/app/services/jwtauth.service';
import { TokenService } from 'src/app/services/token.service';
import { HelperService } from 'src/app/services/helper.service';
import { environment as env} from 'src/environments/environment';
import { SapConfig } from 'src/app/configs/sap.config';
import { SapFieldComponent } from './sap-field/sap-field.component';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { LanguageService } from '../../services/language.service';


@Component({
  selector: 'app-sap-details',
  templateUrl: './sap-details.component.html',
  styleUrls: ['./sap-details.component.scss']
})
export class SapDetailsComponent implements OnInit {
  apiURL = env['API_URL'];
  sapCon:SapConfig = new SapConfig();
  fields:any;
  fieldsGroups:any;
  @Input() projectId;
  @Input() sapData;
  @ViewChild(SapFieldComponent, { static: true }) sapFieldCom: SapFieldComponent;
  sapForm;
  lang;

  constructor(
    private http: HttpClient,
    private Token: TokenService,
    private jwtwis: JwtauthService,
    public language: LanguageService,
    private helpSer: HelperService
  ) {
    this.getDispProjMetas();
    this.lang = language;
  }

  ngOnInit() {
  }

  initForm(controls) {
    let fa = new UntypedFormArray(controls);
    fa.valueChanges.subscribe(values => { this.dashDisChange(); });
    this.sapForm = new UntypedFormGroup({
      sapkeys: fa,
    });
  }

  getFieldGroups() {
    let fieldsGroups = [];
    let groups = [];
    this.fields.forEach((field, i) => {
      console.log('i',i);
      if (i > 2) { return; }
      field['cp']  = 'col-lg-4';
      field['cpl'] = 'col-lg-4';
      field['cpr'] = 'col-lg-9';
      groups.push(field);
    });
    fieldsGroups.push(groups);

    groups = [];
    this.fields.forEach((field, i) => {
      if (i < 3 || i > 5) { return; }
      field['cp']  = 'col-lg-4';
      field['cpl'] = 'col-lg-4';
      field['cpr'] = 'col-lg-9';
      groups.push(field);
    });
    fieldsGroups.push(groups);

    groups = [];
    this.fields.forEach((field, i) => {
      if (i < 6 || i > 7) { return; }
      field['cp'] = 'col-lg-6';
      field['cpl'] = 'col-lg-6 col-c6c6';
      field['cpr'] = 'col-lg-9 col-c6c6';
      groups.push(field);
    });
    fieldsGroups.push(groups);

    groups = [];
    this.fields.forEach((field, i) => {
      if (i != 8) { return; }
      field['cp'] = 'col-lg-12';
      field['cpl'] = 'col-lg-2';
      field['cpr'] = 'col-lg-9';
      groups.push(field);
    });
    fieldsGroups.push(groups);

    groups = [];
    this.fields.forEach((field, i) => {
      if (i != 9) { return; }
      field['cp'] = 'col-lg-12';
      field['cpl'] = 'col-lg-2';
      field['cpr'] = 'col-lg-9';
      groups.push(field);
    });
    fieldsGroups.push(groups);

    groups = [];
    this.fields.forEach((field, i) => {
      if (i != 10) { return; }
      field['cp'] = 'col-lg-12';
      field['cpl'] = 'col-lg-2';
      field['cpr'] = 'col-lg-9';
      groups.push(field);
    });
    fieldsGroups.push(groups);

    groups = [];
    this.fields.forEach((field, i) => {
      if (i != 11) { return; }
      field['cp'] = 'col-lg-12';
      field['cpl'] = 'col-lg-2';
      field['cpr'] = 'col-lg-9';
      groups.push(field);
    });
    fieldsGroups.push(groups);

    groups = [];
    this.fields.forEach((field, i) => {
      if (i != 12) { return; }
      field['cp'] = 'col-lg-12';
      field['cpl'] = 'col-lg-2';
      field['cpr'] = 'col-lg-9';
      groups.push(field);
    });
    fieldsGroups.push(groups);

    groups = [];
    this.fields.forEach((field, i) => {
      if (i < 13 || i > 15) { return; }
      field['cp'] = 'col-lg-4';
      field['cpl'] = 'col-lg-4';
      field['cpr'] = 'col-lg-9';
      groups.push(field);
    });
    fieldsGroups.push(groups);

    return fieldsGroups;
  }

  fieldsValue;
  generateFields(values) {
    this.fieldsValue = values;
    this.fields = this.sapCon.fields;
    this.fieldsGroups = this.getFieldGroups();
    const controls = this.fields.map(field => new UntypedFormControl(this.dispProjMetas.includes(field.valKey)));
    this.initForm(controls);
  }

  dashDisChange() {
    let formData = this.Token.getAPITokenData();
    formData['metas'] = this.sapForm.getRawValue().sapkeys
      .map((v, i) => v ? this.fields[i].valKey : null )
      .filter(v => v !== null);
    this.http.post(`${this.apiURL}user-disp-project-meta-update`, formData).subscribe(
      data => {
        // console.log(data);
      },
      error => this.handleError(error)
    );
  }

  dispProjMetas;
  getDispProjMetas() {
    let formData = this.Token.getAPITokenData();
    this.http.post(`${this.apiURL}user-disp-project-meta-get`, formData).subscribe(
      data => {
        this.dispProjMetas = Object.values(data);
        this.getDetails();
      },
      error => this.handleError(error)
    );
  }

  serverHealth = null;
  getDetails() {
    let formData = this.Token.getAPITokenData();
    this.http.post(`${this.apiURL}project/sap-details/`+this.projectId, formData).subscribe(
      data => {
        this.serverHealth = data['server_health'];
        this.generateFields(data['values']);
      },
      error => this.handleError(error)
    );
  }

  getInstructions() {
    let label = 'SAP Instructions';
    let formData = this.Token.getAPITokenData();
    this.http.post(`${this.apiURL}project/sap-instructions/`+this.projectId, formData).subscribe(
      data => {
        let blob = new Blob([this.helpSer.getFileBuffer(data['file'])], { type: data['type'] });
        this.helpSer.downloadFile(blob, data['fileName']+' SAP '+label+'.'+data['extension']);
      }
    );
  }

  uploadFile() {
    let formData:FormData = new FormData();
    formData.append('projectId', this.projectId);
    formData.append('token', this.Token.get());
    this.http.post(`${this.apiURL}project/copy-sap-instructions-vendors`, formData, {reportProgress: true, observe: 'events'})
      .subscribe(
        data => {
          console.log(data);
        },
      );
  }

  handleError(error) {
    this.jwtwis.handleError(error);
  }
}

<ng-template #helpModal>
  <div class="modal-header">
    <img alt="Close Image" src="../../../assets/img/close_icon.png" class="close_icon" (click)="closeModal()">
  </div>
  <div class="modal-body border-layout" *ngIf="!jwtAuth.isVenodor()">
    <div class="row">
      <div class="col-md-12">
        <div class="text-center">
          <img style="height: 100px;" alt="Help Image" src="../../assets/img/help_icon.webp" />
        </div>
        <h2 class="text-center">{{ lang.getText("flowdezk_assistant") }}</h2>
        <div class="text-center col-8 m-auto">{{description}}</div>
        <div class="mb-2 chat_section" #chatContainer [scrollTop]="chatContainer.scrollHeight">
          <div  *ngFor="let ar of assistantResponse; let i=index">
            <div class="question">{{ar.question}}</div>
            <div class="response" [innerText]="ar.response"></div>
          </div>
          <div class="question" *ngIf="tempQuestion != ''">{{tempQuestion}}</div>
          <div class="loader container-fluid text-center" *ngIf="loading">
            <div class="body">
              <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
          </div>
        </div>
        <div class="input-group mb-3">
          <input class="form-control" [(ngModel)]="question" type="text" placeholder="{{lang.getText('type_your_question')}}">
          <div class="input-group-append">
            <span class="" id="basic-addon2"><button type="button" [class.disabled]="loading"  class="send-btn rounded-circle btn btn-secondary m-1" (click)="askChatGpt()"><em class="fa fa-arrow-up"></em></button></span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-body" *ngIf="jwtAuth.isVenodor()">
    <div class="row">
      <div class="col-md-12"></div>
      <div class="col-md-12">
        <div class="image-container">
          <div class="image-card" (click)="downUserManual()">
            <img 
              src="../../../assets/img/download-manual.svg"
              alt="Download Icon"
            />
            <p>{{ lang.getText("download_manual") }}</p>
          </div>
          <div class="image-card" (click)="openSuggestImprovement()">
            <img 
              src="../../../assets/img/suggest-improvement.svg"
              alt="suggest Icon"
            />
            <p>{{ lang.getText("suggest_improvement") }}</p>
          </div>
          <div class="image-card">
            <a href="mailto:support@flowdezk.com">
              <img (click)="closeModal()" src="../../../assets/img/create-support-ticket.svg" alt="Ticket Icon" />
            
              <p>{{ lang.getText("create_support_ticket") }}</p>
            </a>
          </div>
        </div>
        <img 
          src="../../../assets/img/help_avatar.png"
          class="help_avatar"
          alt="help_avatar"
        />
      </div>
    </div>
  </div>
  
  </ng-template>
  <app-suggest-improvement #suggestImprovement ></app-suggest-improvement>
  <ng-template #agreeModal>
    <div class="modal-header">
      <img alt="Close Image" src="../../../assets/img/close_icon.png" class="close_icon" (click)="closeAgreeModal()">
    </div>
    <div class="modal-body border-layout">
      <div class="row">
        <div class="col-md-12">
          <div class="text-center">
            <p>{{ lang.getText("flowdezk_assistant") }}</p>
          </div>
        </div>
      </div>
      <div class="text-center">
        <button class="btn btn-primary" (click)="closeAgreeModal()">{{ lang.getText("i_dont_agree") }}</button>
        <button class="btn btn-primary" (click)="openHelpModal()">{{ lang.getText("i_agree") }}</button>
      </div>
    </div>
  </ng-template>
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Output, ViewChild, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormGroup, UntypedFormBuilder,FormArray,FormControl } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { JwtauthService } from 'src/app/services/jwtauth.service';
import { TokenService } from 'src/app/services/token.service';
import { environment as env } from 'src/environments/environment';
import { LanguageService } from '../../../services/language.service';
import { mergeMap } from 'rxjs/operators';
import { HelperService } from 'src/app/services/helper.service';


@Component({
  selector: 'app-create-cat',
  templateUrl: './create-cat.component.html',
  styleUrls: [
    './create-cat.component.scss',
    '../../project-main-tab-page.component.scss',
    '../../project-create-page.component.scss' 
  ]
})
export class CreateCatComponent implements OnInit{
  apiURL = env['API_URL'];
  lang;
  languages = [];
  projectData = [];
  projectID;
  orderID;

  connectors = [
    { id: 1, name: "Trados Team/Enterprise", disabled:false },
    { id: 2, name: "XTM", disabled:false },
    { id: 3, name: "memoQ", disabled:true },
    { id: 4, name: "MemSource", disabled:true }
  ];
  sources = [];
  targets = [];

  tradosFolders = [];
  tradosFileProcessingConfigs = [];
  tradosTranslationEngines = [];
  tradosWorkflows = [];
  tradosTemplates = [];
  selectedTemplate: string = '';
  selectedLocation: string = '';
  selectedFileType: string = '';
  selectedTranslationEngine: string = '';
  selectedWorkflow: string = '';
  selectedDueDate = '';
  xtmSegmentLockingRule: string = '';
  xtmWorkflow;
  tag: string = 'quote-files';
  // selectedRole: string = 'translatable';
  bsConfig = {
    dateInputFormat: 'DD/MM/Y',
    rangeInputFormat: 'DD/MM/Y',
  };

  connectionId = null;
  xtmWorkflows: any = [];
  
  constructor(
    private modalService: BsModalService,
    private http: HttpClient,
    private token: TokenService,
    private jwtAuth: JwtauthService,
    public language: LanguageService,
    private fb: UntypedFormBuilder,
    private helperSer: HelperService
  ) {
    this.lang = language;
  }

  masterSelected:boolean;
  checklist:any;
  checkedList:any;
  currentConnector = 0;
  segmentLockingRules = [
    "DISABLED",
    "ANY_100_MATCH",
    "APPROVED_100_MATCH",
    "XLIFFDOC_TRANSLATED",
    "XLIFFDOC_PROOFED",
    "XLIFFDOC_VALIDATED"
  ];
  clientSelected;
  @Output() showLoader: EventEmitter<Object> = new EventEmitter();
  
  ngOnInit() {
    // return false;
  }

  getTradosTemplates(location_id) {
    let data = {
      token : this.token.get(),
    };
    this.http.post(`${this.apiURL}trados/list-templates/${location_id}`,data).subscribe(
      data => {
        let templates = data['result']['items'];
        this.tradosTemplates = templates;
      },
      error => {
        console.log('error',error);  
      }
    );
  }

  no_api_access = false;
  no_api_access_msg = "";
  getTradosFolders() {
    let data = {
      token : this.token.get(),
    };
    this.http.post(`${this.apiURL}trados/list-folders`,data).subscribe(
      data => {
        if(data['lang_key'] != undefined && data['lang_key'] == "no_active_subscription_for_the_TRADOS"){
          this.no_api_access = true;
          this.no_api_access_msg = data['lang_key'];
          return;
        }
        let folders = data['result']['items'];
        let options = [];
        folders.forEach((folder)=>{
          if(!folder.hasParent){
            options.push({
              id: folder.id,
              name: "Root",
              depth: 1
            })
          }
          else{
            options.push({
              id: folder.id,
              name: this.extractPath(folder.path) + folder.name,
              depth: folder.path.length
            })
          }
        });
        options.sort((a, b) => a.depth - b.depth)
        this.tradosFolders = options;
      },
      error => {
        console.log('error',error);  
        // this.handleError(error)
      }
    );
  }

  loading = false;
  onChangeTemplate(template_id){
    this.loading = true;
    // this.tradosFileProcessingConfigs = [];
    let data = {
      token : this.token.get(),
    };
    this.http.post(`${this.apiURL}trados/list-template-related-data/${template_id}`,data).subscribe(
      data => {
        this.loading = false;
        this.tradosFolders['items'] = [data['result']['location']];
        this.tradosFileProcessingConfigs['items'] = [data['result']['fileProcessingConfiguration']];
        this.tradosTranslationEngines['items'] = [data['result']['translationEngine']];
        this.tradosWorkflows['items'] = [data['result']['workflow']];
        this.selectedLocation = data['result']['location']['id'];
        this.selectedFileType = data['result']['fileProcessingConfiguration']['id'];
        this.selectedTranslationEngine = data['result']['translationEngine']['id'];
        this.selectedWorkflow = data['result']['workflow']['id'];
      },
      error => {
        this.loading = false;
        console.log('error',error);  
        // this.handleError(error)
      }
    );
    // this.selectedFileType = '';
    // this.selectedTranslationEngine = '';
    // this.selectedWorkflow = '';
    // this.getTradosFileProcessingConfigs(location_id);
    // this.getTradosTranslationEngine(location_id);
    // this.getTradosWorkflow(location_id);
  }

  onChangeLocation(location_id){
    this.selectedFileType = '';
    this.selectedTranslationEngine = '';
    this.selectedWorkflow = '';
    this.getTradosTemplates(location_id);
    this.getTradosFileProcessingConfigs(location_id);
    this.getTradosTranslationEngine(location_id);
    this.getTradosWorkflow(location_id);
  }

  getTradosFileProcessingConfigs(location_id) {
    this.loading = true;
    this.tradosFileProcessingConfigs = [];
    let data = {
      token : this.token.get(),
    };
    this.http.post(`${this.apiURL}trados/list-file-processing-config/${location_id}`,data).subscribe(
      data => {
        this.loading = false;
        this.tradosFileProcessingConfigs = data['result'];
      },
      error => {
        this.loading = false;
        console.log('error',error);  
        // this.handleError(error)
      }
    );
  }

  getTradosTranslationEngine(location_id) {
    this.loading = true;
    this.tradosTranslationEngines = [];
    let data = {
      token : this.token.get(),
    };
    this.http.post(`${this.apiURL}trados/list-translation-engine/${location_id}`,data).subscribe(
      data => {
        this.loading = false;
        this.tradosTranslationEngines = data['result'];
      },
      error => {
        this.loading = false;
        console.log('error',error);  
        // this.handleError(error)
      }
    );
  }

  getTradosWorkflow(location_id) {
    this.loading = true;
    this.tradosWorkflows = [];
    let data = {
      token : this.token.get(),
    };
    this.http.post(`${this.apiURL}trados/list-workflow/${location_id}`,data).subscribe(
      data => {
        this.loading = false;
        this.tradosWorkflows = data['result'];
      },
      error => {
        this.loading = false;
        console.log('error',error);  
        // this.handleError(error)
      }
    );
  }

  @ViewChild('createCATprojectPopup') createCATprojectPopup;
  @ViewChild('sendAdditionalFilesPopup') sendAdditionalFilesPopup;
  modalRef: BsModalRef;
  projectTranslations;
  openModal(projectID,projectData,languages,orderID, clientSelected) {
    this.projectID = projectID;
    this.projectData = projectData;
    this.languages = languages;
    this.orderID = orderID;
    this.projectTranslations = projectData.translations;
    this.clientSelected = clientSelected;
    // this.setData();
    // this.getTradosTemplates();
    this.getTradosFolders();
    this.getFiles();
    this.modalRef = this.modalService.show(
      this.createCATprojectPopup,
      { class: 'new-redesign-style modal-xl' }
    );
  }

  openModalFilesOnly(projectID,projectData,languages,orderID, clientSelected) {
    this.projectID = projectID;
    this.projectData = projectData;
    this.languages = languages;
    this.orderID = orderID;
    if(projectData.trados_data?.trados_project_id?.meta_value) {
      this.currentConnector = 1;
    } else if(projectData.xtm_data?.xtm_project_id) {
      this.currentConnector = 2;
    }
    this.clientSelected = clientSelected;
    if(this.currentConnector == 2){
      this.setConnectionId();
      this.setData();
    }
    
    // this.getTradosTemplates();
    // this.getTradosFolders();
    this.getFiles();
    this.modalRef = this.modalService.show(
      this.sendAdditionalFilesPopup,
      { class: 'new-redesign-style modal-lg' }
    );
  }
  closeModal() {
    this.checklist = [];
    this.checkedList = [];
    this.checklistFiles = [];
    this.checkedListFiles = [];
    // this.currentConnector = 0; // this value needed for start project function
    this.xtmWorkflow = '';
    this.selectedDueDate = '';
    this.xtmSegmentLockingRule = '';
    this.modalRef.hide();
  }

  async setData(){
    console.log('projectData',this.projectData);
    let source, target = null;
    let sourceData = [];
    let targetData = [];
    let translateData = [];
    this.projectTranslations.forEach(row => {
      source = this.getLanguageById(row['source_lang_id']);
      target = this.getLanguageById(row['target_lang_id']);
      sourceData.push({
        id: source.id,
        name: this.getCatLanguageCode(source)
      });
      targetData.push({
        id: target.id,
        name: this.getCatLanguageCode(target)
      });
      translateData.push({
        id: row['project_translate_id'],
        isSelected: false,
        source: this.getCatLanguageCode(source),
        target: this.getCatLanguageCode(target)
      });
      
    });
    this.sources = sourceData;
    this.targets = targetData;
    this.masterSelected = false;
    this.checklist = translateData;
    this.getCheckedItemList();
  }

  getCatLanguageCode(lang) {
    if (this.currentConnector == 1) {
      return lang.trados_code;
    } else if (this.currentConnector == 2) {
      return lang.xtm_code;
    }
  }

  getCheckedItemList(){
    this.checkedList = [];
    for (var i = 0; i < this.checklist.length; i++) {
      if(this.checklist[i].isSelected)
      this.checkedList.push(this.checklist[i]);
    }
    // this.checkedList = JSON.stringify(this.checkedList);
  }

  isAllSelected() {
    this.masterSelected = this.checklist.every(function(item:any) {
        return item.isSelected == true;
      })
    this.getCheckedItemList();
  }

  xtmSourceLanguage = '';
  setXTMSourceLanguage(event, item, ti) {
    event.preventDefault();
    event.stopPropagation();
    if (event.target.checked) {
      let isPushAllowed = true;
      if (this.xtmSourceLanguage == '') {
        this.xtmSourceLanguage = item.source;
      } else {
        if (this.xtmSourceLanguage != item.source) {
          isPushAllowed = false;
          event.srcElement.checked = false;
          this.checklist[ti].isSelected = false;
          this.openModalDialog('failed',this.lang.getText('please_select_language_combination')+'!');
        }
      }

      if (isPushAllowed) {
        this.checklist[ti].isSelected = true;
        this.checkedList.push(this.checklist[ti]);
      }
    } else {
      event.srcElement.checked = false;
      this.checkedList = this.checkedList.filter(checkedItem => {
        return checkedItem.id != item.id;
      });
    }

    this.isAllSelected();
  }

  checkUncheckAllSourceXTM(event) {
    for (var i = 0; i < this.checklist.length; i++) {
      let item = this.checklist[i];
      let isPushAllowed = true;
      if (this.xtmSourceLanguage == '') {
        this.xtmSourceLanguage = item.source;
      } else {
        if (this.xtmSourceLanguage != item.source) {
          isPushAllowed = false;
          event.srcElement.checked = false;
          item.isSelected = false;
          let checkboxElement = document.getElementById("tran-" + i) as HTMLInputElement;
          checkboxElement.checked = false;
        }
      }
      
      if (isPushAllowed && this.masterSelected) {
        this.checkedList.push(item);
        item.isSelected = this.masterSelected;
      } else if(!this.masterSelected) {
        item.isSelected = this.masterSelected;
      }
    }
  }

  checkUncheckAll(event) {
    if (this.currentConnector == 2) {
      this.checkedList = [];
      this.checkUncheckAllSourceXTM(event);
    } else {
      for (var i = 0; i < this.checklist.length; i++) {
        this.checklist[i].isSelected = this.masterSelected;
      }
      this.getCheckedItemList();
    }
  }

  filesSelected:boolean;
  checklistFiles:any;
  checkedListFiles = [];
  getCheckedItemListFiles(){
    this.checkedListFiles = [];
    for (var i = 0; i < this.checklistFiles.length; i++) {
      if(this.checklistFiles[i].isSelected)
      this.checkedListFiles.push(this.checklistFiles[i]);
    }
    // this.checkedList = JSON.stringify(this.checkedList);
  }

  isAllSelectedFiles() {
    this.filesSelected = this.checklistFiles.every(function(item:any) {
        return item.isSelected == true;
      })
    this.getCheckedItemListFiles();
  }

  checkUncheckAllFiles() {
    for (var i = 0; i < this.checklistFiles.length; i++) {
      this.checklistFiles[i].isSelected = this.filesSelected;
    }
    this.getCheckedItemListFiles();
  }
  
  getLanguageById(langId) {
    let language = [];
    this.languages.forEach(lang => {
      if ( lang.id == langId )
        language = lang;
    });
    return language;
  }

  @Output() tradosCreateResponse: EventEmitter<Object> = new EventEmitter();
  @Output() xtmCreateResponse: EventEmitter<Object> = new EventEmitter();
  
  onCreateCat(start_project = false){
    if(this.selectedDueDate == ''){
      this.openModalDialog('failed',this.lang.getText('please_select_due_date'));
      return false;
    }

    if (this.checkedList.length == 0) {
      this.openModalDialog('failed',this.lang.getText('please_select_language_combination')+'!');
      return false;
    }

    // Trados specific errors
    if (this.currentConnector == 1) {
      if(this.selectedLocation == ''){
        this.openModalDialog('failed',this.lang.getText('please_select_location')+'!');
        return false;
      }
      if(this.selectedFileType == ''){
        this.openModalDialog('failed',this.lang.getText('please_select_file_processing_configuration')+'!');
        return false;
      }
      if(this.selectedTranslationEngine == ''){
        this.openModalDialog('failed',this.lang.getText('please_select_translation_engine')+'!');
        return false;
      }
      if(this.selectedWorkflow == ''){
        this.openModalDialog('failed',this.lang.getText('please_select_workflow')+'!');
        return false;
      }
      if ( ! this.checkedListFiles.length ) {
        if ( ! confirm(this.lang.getText('are_you_sure_want_continue_without_any_file')) ) {
          return false;
        }
      }
    }

    // XTM specific errors
    if (this.currentConnector == 2) {
      if (this.checkedListFiles.length == 0) {
        this.openModalDialog('failed',this.lang.getText('please_select_files_xtm')+'!');
        return false;
      }

      if (this.xtmWorkflow == '') {
        this.openModalDialog('failed', this.lang.getText('please_select_workflow') + '!');
        return false;
      }

      if (this.xtmSegmentLockingRule == '') {
        this.openModalDialog('failed', this.lang.getText('please_select_segment_locking_rule') + '!');
        return false;
      }
    }
    let length_error = false;
    this.checkedListFiles.forEach(row => {
        if(row.path.length > 200){
          length_error = true;
        }
    });
    if(length_error){
      this.openModalDialog('failed',this.lang.getText('path_file_name_length_error')+'!');
      return false;
    }
    
    if (this.currentConnector == 1) {
      this.createTradosProject(start_project);
    } else if (this.currentConnector == 2) {
      this.createXTMProject(start_project);
    }
    // formData['token'] = this.token.get();
  }

  createTradosProject(start_project) {
    let data = {
      checkedList : this.checkedList,
      token : this.token.get(),
      projectData : this.projectData,
      orderID: this.orderID,
      templateID: this.selectedTemplate,
      locationID: this.selectedLocation,
      fileProcessingConfigID: this.selectedFileType,
      translationEngineID: this.selectedTranslationEngine,
      dueDate: this.helperSer.formateDPDate(this.selectedDueDate),
      workflowID: this.selectedWorkflow
    };
    this.http.post(`${this.apiURL}trados/create-cat/${this.projectID}`, data).subscribe(
      (data:any) => {
        // this.tradosCreateResponse.emit({ status: 'Created' });
        this.openModalDialog('success',this.lang.getText('project_created_in_trados'));
        // If files selected to upload
        if (this.checkedListFiles.length > 0) {
          this.addFilesToTrados(start_project);
        }
        // If no files selected to upload and 'create and start' button click
        else if (start_project && this.checkedListFiles.length == 0) {
          this.startProject(true);
        }
        else{
          this.tradosCreateResponse.emit({ status: 'Created' });
          setTimeout(() => {
            this.closeModalDialog();
          }, 2 * 1000);
          this.closeModal(); 
        }
      },
      error => {
        this.tradosCreateResponse.emit({ status: 'Error' });
        if(error.status === 400){
          this.openModalDialog('failed',this.prepareErrorMessage(error.error.error));  
          //this.openModalDialog('failed',this.lang.getText('api_error_bad_request'));
        }
        else {  
          this.openModalDialog('failed',error.error.error);
        }  
        // setTimeout(() => {
        //   this.closeModalDialog();
        // }, 2 * 2000);
        this.handleError(error)
        this.closeModal();
      }
    );
  }

  createXTMProject(start_project) {
    if (this.connectionId == null) {
      this.openModalDialog('failed',this.lang.getText('cannot_find_xtm_connection_for_this_client')+'!');
      return false;
    }
    let data = {
      checkedList : this.checkedList,
      projectData : this.projectData,
      referenceID: this.orderID,
      sourceLanguage: this.sources,
      targetLanguages: this.targets,
      workflowId: this.xtmWorkflow,
      dueDate: this.helperSer.formateDPDate(this.selectedDueDate),
      checkedListFiles: this.checkedListFiles,
      segmentLockingType: this.xtmSegmentLockingRule,
      connectionId: this.connectionId,
      selectedClientId: this.clientSelected.id
    }
    this.showLoader.emit(true);
    this.http.post(`${this.apiURL}xtm/create-cat/${this.projectID}`, data).subscribe(
      (data:any) => {
        // this.xtmCreateResponse.emit({ status: 'Created' });
        this.showLoader.emit(false);
        this.openModalDialog('success', this.lang.getText('project_created_in_xtm'));
        setTimeout(() => {
          this.closeModalDialog();
        }, 2 * 1000);
        // If files selected to upload
        if (this.checkedListFiles.length > 0) {
          this.addFilesToXTM(start_project);
        }
        // If no files selected to upload and 'create and start' button click
        else if (start_project && this.checkedListFiles.length == 0) {
          this.startProject(true);
        }
        else{
          this.xtmCreateResponse.emit({ status: 'Created' });
          setTimeout(() => {
            this.closeModalDialog();
          }, 2 * 1000);
          this.closeModal(); 
        }
      },
      error => {
        this.showLoader.emit(false);
        this.xtmCreateResponse.emit({ status: 'Error' });
        if(error.status === 400){
          this.openModalDialog('failed',this.prepareErrorMessage(error.error.error));
        }
        else {  
          this.openModalDialog('failed',error.error.error);
        }  
        // setTimeout(() => {
        //   this.closeModalDialog();
        // }, 2 * 2000);
        this.handleError(error)
        this.closeModal();
      }
    );
  }

  prepareErrorMessage(error){
    let errorDetail = "";
    let errorStr = "";
    if(error['incorrectParameters'] && error['reason']) {
      errorDetail += error['reason'] + " - " + error['incorrectParameters']
    } else {
      error['details'].forEach(error => {
        errorDetail += error.name + " - " + error.value
      });
    }
    if(error.message) {
      errorStr += error.message
    }
    return errorStr + " Detail: " + errorDetail;
  }

  handleError(error) {
    this.jwtAuth.handleError(error);
  }

  display = 'none';
  status = '';
  message = '';
  openModalDialog(status,message){
    this.display='block';
    this.status = status;
    this.message = message;
  }
  closeModalDialog(){
    this.display='none';
  }

  // files;
  allFilesReq;
  async getFiles() {
    let formData = this.token.getAPITokenData();
    console.log('this.projectID;',this.projectID)
    formData['projectId'] = this.projectID;
    formData['tag'] = this.tag;

    this.allFilesReq = this.http.post(`${this.apiURL}project/file/get`,formData).subscribe(
      data => {
        let filesData = [];
        data['result']['files'].forEach(file => {
          if (file['upload_by'] == 0){
            // this.files.push(file);
            filesData.push({
              id:  file.id,
              isSelected:false,
              path: file.path,
              role: 'translatable'
            });
          }
          // if (file['upload_by'] == 1)
          //   this.vendorUploadedFiles.push(file);
        });
        this.filesSelected = false;
        this.checklistFiles = filesData;  
      },
      error => this.handleError(error)
    );
  }
  getFileName(link) {
    return link.substring(link.lastIndexOf('/')+1);
  }

  sendAdditionalFiles(){
    if (this.checkedListFiles.length == 0) {
      this.openModalDialog('failed',this.lang.getText('please_select_files_xtm')+'!');
      return false;
    }
    let length_error = false;
    this.checkedListFiles.forEach(row => {
        if(row.path.length > 200){
          length_error = true;
        }
    });
    if(length_error){
      this.openModalDialog('failed',this.lang.getText('path_file_name_length_error')+'!');
      return false;
    }
    if (this.currentConnector == 1) {
      this.addFilesToTrados();
    } else if (this.currentConnector == 2) {
      this.addFilesToXTM();
    }
  }

  addFilesToTrados(start_project = false){
    console.log('this.checkedList',this.checkedList);
    console.log('this.checkedListFiles',this.checkedListFiles);
    // console.log('selectedRole',this.selectedRole);
    let data = {
      checkedList : this.checkedList,
      checkedListFiles : this.checkedListFiles,
      token : this.token.get(),
      projectData : this.projectData
    };
    // setTimeout(() => {
    //   this.openModalDialog('success',this.lang.getText('adding_files_to_trados'));
    // }, 2 * 1000);
    this.loading = true;
    this.http.post(`${this.apiURL}trados/add-files/${this.projectID}`, data).subscribe(
      res => {
        this.loading = false;
        if(res['error'].length > 0){
          let errors = "";
          res['error'].forEach(error => {
            if(error.message != "undefined")
            errors += error.message + '\n';
          });
          console.log('errors',errors);
          this.tradosCreateResponse.emit({ status: 'Error' });
          this.openModalDialog('failed',errors);  
          //return;
        }
        else{
          this.tradosCreateResponse.emit({ status: 'Created' });
          this.closeModalDialog();
          this.openModalDialog('success',this.lang.getText('project_files_added_to_trados'));
          setTimeout(() => {
            this.closeModalDialog();
          }, 2 * 1000);
          this.closeModal();
        }
        if(start_project){
          this.startProject();
        }
    },
    error => {
        this.tradosCreateResponse.emit({ status: 'Error' });
        if(error.status === 400)  
          this.openModalDialog('failed',this.prepareErrorMessage(error.error.error));  
        else {  
          this.openModalDialog('failed',error.error.error);
        }  
        this.handleError(error)
        this.closeModal();
    });
  }

  addFilesToXTM(start_project = false){
    let data = {
      checkedList : this.checkedList,
      checkedListFiles : this.checkedListFiles,
      token : this.token.get(),
      projectData : this.projectData,
      connectionId: this.connectionId
    };
    this.loading = true;
    this.showLoader.emit(true);
    this.http.post(`${this.apiURL}xtm/add-files/${this.projectID}`, data).subscribe(
      res => {
        this.loading = false;
        this.showLoader.emit(false);
        if(res['error'] && res['error'].length > 0){
          let errors = "";
          res['error'].forEach(error => {
            if(error.message != "undefined")
            errors += error.message + '\n';
          });
          console.log('errors',errors);
          this.xtmCreateResponse.emit({ status: 'Error' });
          this.openModalDialog('failed',errors);
        } else{
          this.closeModalDialog();
          this.openModalDialog('success',this.lang.getText('project_files_added_to_xtm'));
          setTimeout(() => {
            this.closeModalDialog();
          }, 2 * 1000);
          if(start_project){
            this.startProject();
          } else {
            this.xtmCreateResponse.emit({ status: 'Created' });
            this.closeModal();
          }
        }
    },
    error => {
      this.showLoader.emit(false);
      this.xtmCreateResponse.emit({ status: 'Error' });
      if(error.status === 400)  
        this.openModalDialog('failed',this.prepareErrorMessage(error.error.error));  
      else {  
        this.openModalDialog('failed',error.error.error);
      }  
      this.handleError(error)
      this.closeModal();
    });
  }

  startProject(startWithoutFiles = false){
    if(this.currentConnector == 1) {
      this.startTradosProject(startWithoutFiles);
    } else if (this.currentConnector == 2) {
      this.startXTMProject(startWithoutFiles);
    }
  }

  startTradosProject(startWithoutFiles) {
    // setTimeout(() => {
    //   this.openModalDialog('success',this.lang.getText('starting_the_trados_project'));
    // }, 2 * 1000);
    let data = {
      token : this.token.get()
    };
    this.http.post(`${this.apiURL}trados/start-project/${this.projectID}`, data).subscribe(
      res => {
          this.tradosCreateResponse.emit({ status: 'Created' });
          this.closeModalDialog();
          this.openModalDialog('success',this.lang.getText('project_started_in_trados'));
          setTimeout(() => {
            this.closeModalDialog();
          }, 2 * 1000);
          this.closeModal();
      },
      error => {
          this.closeModalDialog();
          if(startWithoutFiles){
            this.tradosCreateResponse.emit({ status: 'startWithoutFiles' });  
          }
          this.tradosCreateResponse.emit({ status: 'Error' });
          if(error.status === 400)  
            this.openModalDialog('failed',this.prepareErrorMessage(error.error.error));  
          else {  
            this.openModalDialog('failed',error.error.error);
          }  
          this.handleError(error)
          this.closeModal();
      });
  }

  startXTMProject(startWithoutFiles){
    if(!startWithoutFiles) {
      setTimeout(() => {}, 10 * 1000);
    }
    let data = {
      token : this.token.get(),
      connectionId: this.connectionId
    };
    this.showLoader.emit(true);
    this.http.post(`${this.apiURL}xtm/start-project/${this.projectID}`, data).subscribe(
      res => {
        this.showLoader.emit(false);
        this.xtmCreateResponse.emit({ status: 'Created' });
        this.closeModalDialog();
        this.openModalDialog('success',this.lang.getText('project_started_in_xtm'));
        setTimeout(() => {
          this.closeModalDialog();
        }, 2 * 1000);
        this.closeModal();
      },
      error => {
        this.showLoader.emit(false);
        if(error.error.error.reason && error.error.error.reason == "Project is under analysis. Please wait for analysis end.") {
          this.startXTMProject(startWithoutFiles);
        } else {
          this.closeModalDialog();
          if(startWithoutFiles){
            this.xtmCreateResponse.emit({ status: 'startWithoutFiles' });  
          }
          this.xtmCreateResponse.emit({ status: 'Error' });
          if(error.status === 400)  
            this.openModalDialog('failed',this.prepareErrorMessage(error.error.error));  
          else {  
            this.openModalDialog('failed',error.error.error);
          }  
          this.handleError(error)
          this.closeModal();
        }
      });
  }

  isShown: boolean = false ; // hidden by default
  toggleExtraOptionsShow() {
    this.isShown = ! this.isShown;
  }

  checkLanguageCode() {
    let source, target = null;
    let error = false;
    this.projectTranslations.forEach(row => {
      source = this.getLanguageById(row['source_lang_id']);
      target = this.getLanguageById(row['target_lang_id']);
      if(this.currentConnector == 1) {
        if(source && (source.trados_code == '' || source.trados_code == null)){
          this.openModalDialog('failed',this.lang.getText('please_map_trados_code_into_language')+" "+source.name);
          error = true;
          return false;
        }
        if(target && (target.trados_code == '' || target.trados_code == null)){
          this.openModalDialog('failed',this.lang.getText('please_map_trados_code_into_language')+" "+target.name);
          error = true;
          return false;
        }
      } else if(this.currentConnector == 2) {
        if(source && (source.xtm_code == '' || source.xtm_code == null)){
          this.openModalDialog('failed',this.lang.getText('please_map_xtm_code_into_language')+" "+source.name);
          error = true;
          return false;
        }
        if(target && (target.xtm_code == '' || target.xtm_code == null)){
          this.openModalDialog('failed',this.lang.getText('please_map_xtm_code_into_language')+" "+target.name);
          error = true;
          return false;
        }
      }
    });

    return error;
  }

  async onChangeConnector() {
    if(this.checkLanguageCode()) {
      this.closeModal();
    };
    this.xtmSourceLanguage = '';
    this.checkedList = [];
    await this.setData();
    this.masterSelected = false;
    this.checkedListFiles = [];
    await this.getFiles();
    this.filesSelected = false;
    if(this.currentConnector == 1){
      this.getTradosFolders();
    }
    if (this.currentConnector == 2) {
      this.bsConfig["minDate"] = new Date(new Date().setDate(new Date().getDate() + 1));
      let result = this.setConnectionId();
      if (result && this.xtmWorkflows.length == 0) {
        this.getXTMWorkflows();
      }
    }
  }

  setConnectionId() {
    if (this.clientSelected.xtm_connection_details == null) {
      this.openModalDialog('failed',this.lang.getTextWDS('please_add_the_connection_first_for', [this.connectors[this.currentConnector-1].name])+" "+this.clientSelected.name);
      this.closeModal();
    } else if(this.clientSelected.xtm_connection_details && (this.clientSelected.xtm_connection_details.xtm_url == null || this.clientSelected.xtm_connection_details.xtm_url == '')) {
      this.openModalDialog('failed',this.lang.getTextWDS('please_add_the_connection_url_first_for', [this.connectors[this.currentConnector-1].name])+" "+this.clientSelected.name);
      this.closeModal();
    } else {
      this.connectionId = this.clientSelected.xtm_connection_details.id;
      return true;
    }
    return false;
  }

  getXTMWorkflows() {
    this.http.get(`${this.apiURL}xtm/get-workflows/${this.connectionId}`).subscribe(
      workflows => {
        this.xtmWorkflows = workflows;
      },
      error => this.handleError(error)
    );
  }

  extractPath(paths){
    let full_path_name = "";
    paths.slice().reverse().forEach((path)=>{
      full_path_name += (!path.hasParent ? "Root" : path.name) + ' / ';
    });
    return full_path_name;
  }
}

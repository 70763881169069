<div class="btn-group" dropdown >
  <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
          aria-controls="dropdown-basic">
    {{lang.getText('euro')}} <span class="caret"></span>
  </button>
  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu"
      role="menu" aria-labelledby="button-basic">
    <li role="menuitem"><a class="dropdown-item" href="#">{{lang.getText('usd')}}</a></li>
    <li role="menuitem"><a class="dropdown-item" href="#">{{lang.getText('gbp')}}</a></li>
    <li role="menuitem"><a class="dropdown-item" href="#">{{lang.getText('aud')}}</a></li>
    <li class="divider dropdown-divider"></li>
    <li role="menuitem"><a class="dropdown-item" href="#">{{lang.getText('inr')}}</a>
    </li>
  </ul>
</div>

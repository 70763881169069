<ng-template #vendorCATChangModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      {{lang.getText('modify_cat_breakdown')}}
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" id="vendorCATChangTemp">
    <div class="col-md-12 body-input">
      <div class="col-md-12 no-padding">
        <div class="form-group">
          <label>{{lang.getText('long_text12')}}</label>
        </div>
        <div *ngIf="translation && wordMatches" class="form-group">
          <div class="single_part_main cat_analysis">
            <div
              [formGroup]="translation"
              class="single_part_inner">
                <div class="row">
                  <div class="col-lg-6 col-md-12">
                    <div formArrayName="catAnalysisGroA" class="" *ngFor="let wordMatch of wordMatches.wordMatchesA; let i = index">
                      <div [formGroupName]="i" class="row">
                        <input formControlName="word_match_id" type="hidden" value="{{wordMatch.id}}">
                        <div class="col-md-5">
                          <p>{{wordMatch.name}}</p>
                        </div>
                        <div class="col-md-4">
                          <input formControlName="percentage" type="number" min="0" max="100" class="form-control">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <div formArrayName="catAnalysisGroB" class="" *ngFor="let wordMatch of wordMatches.wordMatchesB; let i = index">
                      <div [formGroupName]="i" class="row">
                        <input formControlName="word_match_id" type="hidden" value="{{wordMatch.id}}">
                        <div class="col-md-5">
                          <p>{{wordMatch.name}}</p>
                        </div>
                        <div class="col-md-4">
                          <input formControlName="percentage" type="number" min="0" max="100" class="form-control">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <button
            (click)="applyPercentage()"
            class="btn btn-primary">
            {{lang.getText('save')}}</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

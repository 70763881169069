import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from './token.service';
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
	private apiURL = env['API_URL'];
  constructor(
    private token: TokenService,
    private http: HttpClient
  ) {
		//
  }
  
  duplicate(postData) {
    postData['token'] = this.token.get();
    return this.http.post(`${this.apiURL}project/duplicate`, postData);
  }

  deleteTranslation(projectId, project_translate_id) {
    let formData = this.token.getAPITokenData();
    formData['projectId'] = projectId;
    formData['project_translate_id'] = project_translate_id;
    return this.http.post(`${this.apiURL}project/delete-client-translation`, formData);
  }

  get_translations(id, page, per_page) {
    let formData = {
      token: this.token.get(),
      projectId: id,
      page: page,
      per_page: per_page
    };
    return this.http.post(`${this.apiURL}project/quoting-translations`, formData);
  }

  get_vendor_translations(project_id, vendor_id, page, per_page) {
    let formData = {
      projectId: project_id,
      vendor_id: vendor_id,
      page: page,
      per_page: per_page,
    }
    return this.http.post(`${this.apiURL}project/res-translations`, formData)
  }

  getPrevNextProjects($project_id){
    let formData = JSON.parse(localStorage.getItem('projects_list_filters'));
    formData['filters']['prevNext'] = $project_id;
    return this.http.post(`${this.apiURL}projects`, formData);
  }

  save_favorites(data, default_favorite) {
    let formData = {
      default_favorite: default_favorite,
      favorites: data
    };
    return this.http.post(`${this.apiURL}user-projects-filter-favorites/update`, formData);
  }
  
  get_languages() {
    return this.http.post(`${this.apiURL}languages`, {});
  }

  get_currencies() {
    return this.http.post(`${this.apiURL}currencies`, {});
  }

  client_translation_rates(filters) {
    return this.http.post(`${this.apiURL}client-translation-rates/get`, filters);
  }
}

export class StatusesConfig {
    public vendor:any = [
        {id: 0, ui: false, name: 'Rejected', label: 'rejected'},
        {id: 1, ui: true,  name: 'Received', label: 'received'},
        {id: 2, ui: true,  name: 'Accepted', label: 'accepted'},
        {id: 3, ui: true,  name: 'Confirmed', label: 'confirmed'},
        {id: 4, ui: true,  name: 'Delivered', label: 'delivered'},
        {id: 5, ui: true,  name: 'Invoiced', label: 'invoiced'},
        {id: 6, ui: true,  name: 'Paid', label: 'paid_without_colun'}
    ]
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VendorPortalRoutingModule } from './vendor-portal-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { PoHistoryComponent } from './components/po-history/po-history.component';
import { FileComponent } from './pages/file/file.component';

@NgModule({
  declarations: [
    PrivacyPolicyComponent,
    ProjectsComponent,
    PoHistoryComponent,
    FileComponent
  ],
  imports: [
    CommonModule,
    VendorPortalRoutingModule,
    BsDatepickerModule.forRoot(),
    ReactiveFormsModule,
  ]
})
export class VendorPortalModule { }

import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  private http_backend: HttpClient;

  constructor(
    private backend_handler: HttpBackend,
  ) {
    this.http_backend = new HttpClient(backend_handler);
  }

  upload(url, data, options) {
    return this.http_backend.put(url, data, options);
  }
}

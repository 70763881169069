import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UntypedFormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { JwtauthService } from 'src/app/services/jwtauth.service';
import { LanguageService } from 'src/app/services/language.service';
import { TokenService } from 'src/app/services/token.service';
import { environment as env } from 'src/environments/environment';
import { AppConfig } from 'src/app/configs/app.config';
import { ProjectService } from 'src/app/services/project.service';

@Component({
  selector: 'app-filter-favorites',
  templateUrl: './filter-favorites.component.html',
  styleUrls: ['./filter-favorites.component.scss']
})
export class FilterFavoritesComponent implements OnInit {
  apiURL;
  favFilterRadioIpt = null
  favorites: any[] = [];
  projectFilterForm: UntypedFormGroup;
  modalRef: BsModalRef;
  transVal;
  clients;
  owners;
  statuses;
  localStorageFilterDataKey;
  @ViewChild('favoriteModal') favoriteModal;
  appConfig: AppConfig = new AppConfig();

  constructor(
    private http: HttpClient,
    private modalService: BsModalService,
    private Token: TokenService,
    private Jwtwis: JwtauthService,
    private project_ser: ProjectService,
    public lang: LanguageService
    ) {
      this.apiURL = env['API_URL'];
    }

  ngOnInit() {
    this.getFavorites();
  }

  openModal(filterForm, clients, owners, statuses, localStorageFilterDataKey) {
    this.projectFilterForm = filterForm;
    this.getFavorites();
    this.clients = clients;
    this.owners = owners;
    this.statuses = statuses;
    this.localStorageFilterDataKey = localStorageFilterDataKey;
    this.modalRef = this.modalService.show(
      this.favoriteModal,
      Object.assign({}, { class: 'modal-lg' })
    );
  }

  closeModal() {
    this.modalRef.hide();
  }

  addFavorites() {
    if (this.favorites.length > 2) {
      this.favorites.splice(0, 1);
    }
    this.favorites.push(this.projectFilterForm.value);
    this.updateFavorites();
  }
  
  @Output() resetForm: EventEmitter<Object> = new EventEmitter();
  resetProjectsFilterForm() {
    this.favFilterRadioIpt = null;
    this.resetForm.emit();
    this.closeModal();
  }

  @Output() applyFav: EventEmitter<Object> = new EventEmitter();
  applyFavorites() {
    localStorage.setItem(this.localStorageFilterDataKey, JSON.stringify(this.favorites.slice().reverse()[this.favFilterRadioIpt]));
    this.applyFav.emit();
    this.closeModal();
  }

  canAddToFav() {
    let values = this.projectFilterForm.value;
    return values.unclaimed || values.archived || values.fromDate || values.toDate || values.deliveryDate || values.owner || values.client || values.status || values.text;
  }

  getFavorites() {
    this.http.get(`${this.apiURL}user-projects-filter-favorites/get`).subscribe(
      data => {
        if (data['favorites']) {
          this.favorites = JSON.parse(data['favorites']);
        }
      }
    );
  }

  updateFavorites() {
    let data = JSON.stringify(this.favorites);
    this.project_ser.save_favorites(data, false).subscribe(
      data => {  }
    );
  }
}

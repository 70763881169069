import { Component, OnInit, Injector, ViewChild, HostListener, TemplateRef, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from "@angular/router";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { timeout } from 'rxjs/operators';

import { TokenService } from 'src/app/services/token.service';
import { JwtauthService } from 'src/app/services/jwtauth.service';
import { LanguageService } from 'src/app/services/language.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-po-list',
  templateUrl: './po-list.component.html',
  styleUrls: ['./po-list.component.scss']
})
export class PoListComponent implements OnInit {
  apiURL;
  lang;
  modalRef;
  orderId;
  projectFinances;
  view;
  poListForm: UntypedFormGroup;
  poFA: UntypedFormArray = this.fb.array([]);
  bsConfig = {
    dateInputFormat: 'DD/MM/Y',
    rangeInputFormat: 'DD/MM/Y',
  }

  @Input() financial_dashboard;;

  constructor(
    private injector: Injector,
    private http: HttpClient,
    private Token: TokenService,
    private Jwtwis: JwtauthService,
    private router: Router,
    private fb: UntypedFormBuilder,
    public helperService: HelperService,
    private modalService: BsModalService,
    public language: LanguageService
  ) {
    this.apiURL = this.injector.get('API_URL');
    this.lang = language;
  }

  ngOnInit() {
  }

  splitDateAndTime(dateTime) {
    return this.helperService.splitDateAndTime(dateTime);
  }

  handleError(error) {
    this.Jwtwis.handleError(error);
  }

  @ViewChild('poHistoryModal', {static: true}) poHistoryModal:TemplateRef<any>;
  openModal(projectId, projectVendorId, view = null) {
    this.view = view;
    this.getPOHistory(projectId, projectVendorId);
    this.modalRef = this.modalService.show(
      this.poHistoryModal,
      { class: 'modal-xl xlModal', backdrop: 'static' }
    );
  }

  closeModal() {
    this.modalRef.hide();
    this.clearPoData();
  }

  pfInvoiceDo(finance, method){
    let formData = {
      token: this.Token.get(),
      projectFinanceID: finance.id
    };
    this.http.post(`${this.apiURL}project-finance/get`, formData)
    .subscribe(
      (data: any) => {
        let blob = this.helperService.base64ToBlob(data.pdf);
        if (method == 'down') {
          this.helperService.downloadFile(blob, finance.filename);
        } else if ( method == 'view' ) {
          window.open(window.URL.createObjectURL(blob));
        }  
      },
      error => {
        this.handleError(error);
      }
    );
  }

  vendorPOList = [];
  getPOHistory(projectId, pVId) {
    this.vendorPOList = null;
    let formData = {
      token: this.Token.get(),
      projectId:  projectId,
      pVId: pVId,
      financial_dashboard: this.financial_dashboard,
    }
    this.http.post(`${this.apiURL}project-finance/getPOs`, formData).subscribe(
      pos => {
        this.poListForm = this.fb.group({
          pos: this.fb.array([])
        });

        this.vendorPOList = Object(pos);
        this.vendorPOList.map((po) => {
          this.poFA.push(this.fb.group({
            id: po.id,
            project_id: po.project_id,
            sent_at: this.splitDateAndTime(po.sent_at).date,
            paid_at: po.paid_at != null ? this.splitDateAndTime(po.paid_at).date : '',
            vendor_invoice_details: po.vendor_invoice_details,
            invoice_received_at: po.vendor_invoice_details != null && po.vendor_invoice_details.received_at != null ? this.splitDateAndTime(po.vendor_invoice_details.received_at).date : null,
            invoice_paid_at: po.vendor_invoice_details != null && po.vendor_invoice_details.paid_at != null ? this.splitDateAndTime(po.vendor_invoice_details.paid_at).date : null,
          }));
        })

        this.poFA.valueChanges.subscribe(values => {
          setTimeout(() => { this.getChangedProjects() });
        });

        this.poListForm.setControl('pos', this.poFA);
      },
      error => this.handleError(error)
    );
  }

  changedProjects = [];
  isDirtyprojectsForm = false;
  getChangedProjects() {
    // let changedProjects = [];
    switch (this.view) {
      case 'billable_by_vendor':
        this.changedProjects = this.poListForm.value.pos.filter(po => {
          return (po.sent_at != null && po.sent_at != '') || (po.invoice_received_at != null && po.invoice_received_at != '');
        });
        break;
      case 'payable_to_vendor':
        this.changedProjects = this.poListForm.value.pos.filter(po => {
          return (po.paid_at != null && po.paid_at != '') || (po.invoice_paid_at != null && po.invoice_paid_at != '');
        });
        break;
      default:
        this.changedProjects = this.poListForm.value.pos.filter(po => {
          return (po.paid_at != null && po.paid_at != '') || (po.sent_at != null && po.sent_at != '') ||
                (po.invoice_received_at != null && po.invoice_received_at != '') || (po.invoice_paid_at != null && po.invoice_paid_at != '');
        });
        break;
    }

    this.isDirtyprojectsForm = false;
    if (this.changedProjects.length > 0) {
      this.isDirtyprojectsForm = true;
    }
  }

  @Output() saveEvent: EventEmitter<any> = new EventEmitter();
  savePos() {
    let postData = this.Token.getAPITokenData();
    postData['view'] = this.view;
    postData['changedProjects'] = this.changedProjects;
    postData['financial_dashboard'] = this.financial_dashboard;
    let proGerReq = this.http.post(`${this.apiURL}financial-dashboard/projects/update`, postData)
    .pipe(
      timeout(60 * 1000)
    )
    .subscribe(
      data => {
        this.clearPoData();
        this.closeModal();
        this.saveEvent.emit();
      },
      error => {
        this.handleError(error);
      }
    );
  }

  clearPoData() {
    this.isDirtyprojectsForm = false;
    this.changedProjects = [];
    this.poFA.clear();
  }

  getInvoice(po, method) {
    let formData:FormData = new FormData();
    formData.append('token', this.Token.get());
    formData.append('projectId', po.project_id);
    formData.append('projectVendorId', po.project_vendor_id);
    formData.append('projectFinanceId', po.id);
    formData.append('method', method);
    this.http.post(`${this.apiURL}vendor/invoice/get`, formData)
      .subscribe(
        data => {
          let pdf = data['pdf']
          if ( pdf == null ) {
            return;
          }
          let b64PDF = pdf['b64'];
          if (method == 'view') {
            this.helperService.viewBase64File(b64PDF);
          } else {
            this.helperService.downloadBase64File(b64PDF, pdf['filename']);
          }
        }
      );
  }

  checkInvoiceStatus(po) { 
    if (
      po.vendor_invoice_details == null ||
      (po.vendor_invoice_details.filename == null && po.vendor_invoice_details.path == null)
    ) {
      return true;
    }
    return null;
  }

}

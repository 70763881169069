import { Component, OnInit, Injector } from '@angular/core';
import { HttpClient, HttpResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { UntypedFormBuilder, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { LanguageService } from 'src/app/services/language.service';
import { TokenService } from 'src/app/services/token.service';
import { JwtauthService } from 'src/app/services/jwtauth.service';
import { FileUploadService } from 'src/app/modules/shared/services/file-upload.service';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss']
})
export class FileComponent implements OnInit {
  projectId;
  vendorId;
  apiURL;
  formData;
  lang;
  commentsForm;
  allow_user: boolean = false;

  constructor(
    private injector: Injector,
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    public language: LanguageService,
    private tokenSer: TokenService,
    private file_upload_Ser: FileUploadService,
    private router: Router,
    private authSer: JwtauthService
    ) {
    this.projectId  = this.route.snapshot.paramMap.get('projectId');
    this.vendorId   = this.route.snapshot.paramMap.get('vendorId');
    let hash        = this.route.snapshot.paramMap.get('hash');
    if ( hash != null ) {
      this.router.navigate(['vendor', 'project-files', this.projectId, this.vendorId]);
      return;
    }
    if ( tokenSer.isValid() == null || ! authSer.isVenodor() ) {
      authSer.logout();
      return;
    }
    this.allow_user = true;
    this.apiURL = this.injector.get('API_URL');
    this.lang = language;
    this.formData = {
      projectId:  this.projectId,
      vendorId:   this.vendorId
    }
   }

  ngOnInit() {
    this.getFiles();
    this.getComment();
  }

  form
  files
  initForm(){
    const controls = this.files.map(file => new UntypedFormControl(false));
    this.form = this.fb.group({
      files: new UntypedFormArray(controls),
    });
  }

  uploadFilesEnable
  enableUploadFile() {
    this.uploadFilesEnable = this.uploadFilesEnable ? false : true;
    return false;
  }

  getFiles() {
    this.formData['token'] = this.tokenSer.get();
    this.http.post(`${this.apiURL}vendor/file/index`, this.formData ).subscribe(
      data => {
        this.files = data['result']['files'];
        this.initForm();
      },
      error => this.handleError(error)
    );
  }

  selectedFiles
  updateSelectedFile() {
    this.selectedFiles = this.form.value.files
      .map((v, i) => v ? this.files[i].id : null )
      .filter(v => v !== null);
  }

  getFileName(link) {
    return link.substring(link.lastIndexOf('/')+1);
  }

  viewFile(id){
    this.formData['token'] = this.tokenSer.get();
    this.formData['fileId'] = id;
    this.http.post(`${this.apiURL}vendor/file/view`, this.formData).subscribe(
      data => {
        window.open(data['url'], "_blank");
      },
      error => this.handleError(error)
    );
  }

  readOnly
  downloadReq;
  downloadFiles(){
    this.updateSelectedFile();
    switch (this.selectedFiles.length) {
      case 0:
        return;
      case 1:
        this.viewFile(this.selectedFiles.at(0));
        return;
    }
    this.formData['token'] = this.tokenSer.get();
    this.formData['fileIds'] = this.selectedFiles;
    this.downloadReq = this.http.post(`${this.apiURL}vendor/file/download`, this.formData).subscribe(
      data => {
        window.open(data['url'], "_blank");
        // let blob = new Blob([this.getFileBuffer(data['file'])], { type: data['type'] });
        // window.open(window.URL.createObjectURL(blob), '_blank');
      },
      error => {
        alert(this.lang.getText('your_download_file_size_exceeds_alert'));
        this.handleError(error)
      }
    );
  }

  getFileBuffer(fileData){
    const byteString = atob(fileData);
    const fileBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(fileBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    return fileBuffer;
  }

  uploadFileChange(event) {
    let files: FileList = event.target.files;
    for (var i = 0; i < files.length; i++) {
      let file: File = files[i];
      this.uploadFile(file)
    }
    event.value = '';
  }

  uploadFile(file) {
    if ( (file.size / Math.pow(1024, 3)) > 2.001 ) {
      alert('Maximum allowed file size is 2GB');
      return;
    } else {
      this.getUploadURL(file);
    }
  }

  uploads = [];
  uploadToS3(file, linkObj) {
    let ui = this.uploads.length;
    this.uploads.push({
      req: null,
      uploading: true,
      progress: 0,
      done: false
    });

    const headers = new HttpHeaders({ 'Content-Type': file.type });
    this.file_upload_Ser.upload(linkObj.url, file, { headers: headers, reportProgress: true, observe: 'events'})
      .subscribe(
        event => {
          if (event['type'] === HttpEventType.UploadProgress) {
            this.uploadFilePercentDone(event, ui);
          } else if (event instanceof HttpResponse) {
            this.fineshUpload(ui, linkObj);
          }
        }
      );
  }

  getUploadURL(file) {
    let formData:FormData = new FormData();
    formData.append('token', this.tokenSer.get());
    formData.append('fileType', file.type);
    formData.append('projectId', this.projectId);
    formData.append('fileName', file.name);
    formData.append('vendorId', this.vendorId);
    
    this.http.post(`${this.apiURL}vendor/file/upload-link`, formData).subscribe(
      data => {
        this.uploadToS3(file, data)
      },
      error => {
        if (error.error['error'] == 'Storage full' ) {
          alert('Your storage is full, please ask your admin to purchase new storage.');
        }
        this.handleError(error)
      }
    );
  }

  fineshUpload(ui, data) {
    let formData:FormData = new FormData();
    formData.append('token', this.tokenSer.get());
    formData.append('id', data.id);
    formData.append('projectId', this.projectId);
    formData.append('vendorId', this.vendorId);
    
    this.http.post(`${this.apiURL}vendor/file/finesh-upload`, formData).subscribe(
      data => {
        this.handleResponse(ui);
      },
      error => this.handleError(error)
    );
  }

  uploadFilePercentDone(event, ui) {
    this.uploads[ui].progress = Math.round(100 * event.loaded / event.total);
    if (this.uploads[ui].progress == 100) {
      setTimeout(() => {
        this.uploads[ui].done = true;
      }, 500);
    }
  }

  handleResponse(ui) {
    this.getFiles();
    setTimeout(() => {
      this.uploads[ui].uploading = false;
    }, 2 * 1000);
  }

  handleError(error) {
    this.authSer.handleError(error);
  }

  saveComment(){
    let formData = {
      project_id: this.formData.projectId,
      token : this.tokenSer.get(),
      vendor_id: this.formData.vendorId,
      comments: this.commentsForm.value['comment']
    };
    this.http.post(`${this.apiURL}vendor/file/update-comments`, formData)
      .subscribe(
        data => {
          alert(this.lang.getText('success_comment_saved'));
        },
        data => {
          alert(this.lang.getText('error_comment_not_saved'));
        },
      );
  }
  
  getComment(){
    let formData = {
      project_id: this.formData.projectId,
      token : this.tokenSer.get(),
      vendor_id: this.formData.vendorId
    };
    this.commentsForm = this.fb.group({
      comment: ''
    });
    this.http.post(`${this.apiURL}vendor/file/get-comments`, formData)
      .subscribe(
        data => {
          this.commentsForm.patchValue({
            comment : data['result']['comments'] == null ? '' : data['result']['comments']
          });
        }
      );
  }
}

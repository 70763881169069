import { Component, OnInit, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from 'src/app/services/token.service';
import { JwtauthService } from 'src/app/services/jwtauth.service';
import { environment as env } from 'src/environments/environment'
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  apiURL = env['API_URL'];
  pdfURL = `${this.apiURL}subscription/get`;
  pdfDown = this.pdfURL+'?down=yes';
  lang;

  constructor(
    private router: Router,
    private http: HttpClient,
    private token: TokenService,
    public jwtwis: JwtauthService,
    private elRef : ElementRef,
    public language: LanguageService
    ) {
    this.lang = language;
  }

  ngOnInit() {
    const el = this.elRef.nativeElement;
    var d1 = el.querySelector('.content');
    d1.innerHTML = '<embed src="'+this.pdfURL+'#toolbar=0&navpanes=0&scrollbar=0" style="width:80%; height:70vh;" frameborder="0">';
  }

  agree() {
    let myDetails = this.jwtwis.getMyDetails();
    myDetails.privacy_policy = 'agreed';
    this.jwtwis.setMyDetails(myDetails);

    let postData = {
      token:    this.token.get(),
      vendorId: null,
    };
    this.http.post(`${this.apiURL}vendor/privacy-policy`, postData).subscribe(
      data => {
        this.router.navigateByUrl('vendor/dashboard');
      },
      error => this.handleError(error)
    );
  }

  reject() {
    let myDetails = this.jwtwis.getMyDetails();
    myDetails.privacy_policy = 'agreed';
    this.jwtwis.setMyDetails(myDetails);

    let postData = {
      token:    this.token.get(),
      vendorId: null,
    };
    this.http.post(`${this.apiURL}vendor/privacy-policy-reject`, postData).subscribe(
      data => {
        // this.router.navigateByUrl('vendor/dashboard');
        this.jwtwis.logout();
      },
      error => this.handleError(error)
    );
  }

  handleError(error) {
    this.jwtwis.handleError(error);
  }

}

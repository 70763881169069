import {Component, Input, OnInit, Injector, TemplateRef, ViewChild, Output, EventEmitter} from '@angular/core';
import { HttpClient, HttpResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import {UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl} from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { TokenService } from '../services/token.service';
import { JwtauthService } from '../services/jwtauth.service';
import { HelperService } from '../services/helper.service';
import { LanguageService } from '../services/language.service';
import { FileUploadService } from '../modules/shared/services/file-upload.service';
import { PaginationComponent } from 'src/app/modules/shared/components/pagination/pagination.component';

@Component({
  selector: 'file-upload',
  inputs: ['projectId', 'tag'],
  templateUrl: './file-upload-builder.component.html',
  styleUrls: [
    './file-upload-builder.component.scss',
  ]
})
export class FileUploadBuilderComponent implements OnInit {
  @Input() projectId;
  @Input() tag;
  @Input() readOnly;
  @Input() delivery;
  @Input() inputDeliverEmails;
  @Input() uploadFilesEnable;
  @Input() isCheckClaimed = false;
  @Output() isClaimProject = new EventEmitter<boolean>();
  @Input() isVendor = false;
  @Input() deliveryComment = '';
  @Input() projectClaimed;
  @Input() input_files;
  @Input() tabName;
  @Input() type;
  @Output() deliverFilesEvent: EventEmitter<any> = new EventEmitter();
  @Output() undoDeliverFilesEvent: EventEmitter<any> = new EventEmitter();
  @Input() fileIndex;
  @Input() translation_files_delivered;
  @Output() deliverFilesSerEvent: EventEmitter<any> = new EventEmitter();
  @Output() undoDeliverFilesSerEvent: EventEmitter<any> = new EventEmitter();
  @Input() fileIndexSer;
  @Input() services_delivered;
  

  apiURL;
  formData; form;
  selectedFiles;
  token;
  modalRef: BsModalRef;
  allFiles;
  copyForm;
  lang;
  interval;
  claimedPopupDisplayed = false;
  isModalOpen = true;
  external_storage_url: string = '';
  @ViewChild('claimedPopup') claimedPopup: TemplateRef<any>;

  constructor(
    private injector: Injector,
    private http: HttpClient,
    private Token: TokenService,
    private Jwtwis: JwtauthService,
    private fb: UntypedFormBuilder,
    private modalService: BsModalService,
    public helpSer: HelperService,
    private file_upload_Ser: FileUploadService,
    public language: LanguageService
    ) {
    this.apiURL = this.injector.get('API_URL');
    this.lang = language;
   }

  ngOnInit() {
    this.interval = setInterval(() => this.updateFuncText(this.lang),500);
    if (this.delivery == 'ON') {
      this.displayDelivered = true;
      if (this.inputDeliverEmails != null) {
        this.deliverEmails.push(this.inputDeliverEmails.slice());
        this.allDeliverEmails.push(this.inputDeliverEmails.slice());
      }
    }
  }

  initForm(){
    const controls = this.files.map(file => new UntypedFormControl(false));
    const controls2 = this.files.map(file => new UntypedFormControl(false));
    this.form = this.fb.group({
      files: new UntypedFormArray(controls),
      delivered: new UntypedFormArray(controls2),
    });
    this.copyForm = this.fb.group({
      checkedFiles: new UntypedFormArray([]),
    });
    if(this.masterSelected){
      this.form.get('files').controls.map((control,i)=>{
        control.patchValue(true);
      });
    }
  }

  openModal(template: TemplateRef<any>) {
    this.isModalOpen = false;
    this.getAllFiles();
    this.modalRef = this.modalService.show(template, { class: 'modal-xl new-redesign-style' });
    return false;
  }

  closeModal() {
    this.isModalOpen = true;
    this.modalRef.hide();
  }

  enableUploadFile() {
    this.uploadFilesEnable = this.uploadFilesEnable ? false : true;
    return false;
  }

  copyFiles() {
    let copySelectedFiles = this.copyForm.value.checkedFiles
      .map((v, i) => v ? this.allFiles[i].id : null )
      .filter(v => v !== null);
    let formData = this.Token.getAPITokenData();
    formData['fileIds'] = copySelectedFiles;
    formData['tag'] = this.tag;
    this.http.post(`${this.apiURL}project/copy-files`, formData).subscribe(
      data => this.getFiles() ,
      error => this.handleError(error)
    );
    this.closeModal();
  }

  displayDelivered;
  getAllFiles() {
    let formData = this.Token.getAPITokenData();
    formData['projectId'] = this.projectId;
    formData['tag'] = '';
    formData['orderUser'] = true;
    this.http.post(`${this.apiURL}project/file/get`, formData).subscribe(
      data => {
        this.handle_files(data);
      },
      error => this.handleError(error)
    );
  }

  handle_files(data) {
    let fa = this.copyForm.get('checkedFiles') as UntypedFormArray;
    this.allFiles = [];
    let uniqueFiles = [];
    let fileIds = [];
    data['result']['files'].forEach(values => {
      let fileVal = values.path+' '+values.upload_by;
      values['fileExist'] = !uniqueFiles.includes(fileVal);
      uniqueFiles.push(fileVal);
      fa.push(new UntypedFormControl(false));
      if (values['tag'].includes('vendor-files.vendor_id,') && values['upload_by'] == 1) {
        fileIds.push(values['id']);
      }
      this.allFiles.push(values)
      //this.tags.indexOf(tag) === -1 ? this.tags.push(tag) : console.log("This item already exists");
    });
    this.getFileDetails(fileIds);
    this.isModalOpen = true;
  }

  cFDisVendors = [];
  cFDisVendorsAd = [];
  copyFilesDisplayVendorLab(file) {
    let fileId = file['id'], fileUser = file['user_id'];
    if ( typeof this.fileDetails[fileId] == "undefined" )
      return false;
    if ( this.cFDisVendorsAd.includes(fileId) )
      return true;
    if ( this.cFDisVendors.includes(fileUser) )
      return false;
    this.cFDisVendors.push(fileUser);
    this.cFDisVendorsAd.push(fileId);
    return true;
  }

  fileDetails = [];
  getFileDetails(fileIds) {
    let formData = this.Token.getAPITokenData();
    formData['fileIds'] = fileIds;
    this.http.post(`${this.apiURL}project/file-details`, formData).subscribe(
      data => {
        Object.values(data).forEach(file => {
          this.fileDetails[file.file_id] = file;
        });
      },
      error => this.handleError(error)
    );
  }

  tags = [
    { tag: 0, name: "PM Upload" },
    { tag: 1, name: "Vendor Upload" },
    { tag: 2, name: "CAT Download" },
    //{ tag: "client-files", name: "Client Upload" },
  ];
  copyActiveTag = 0;
  // diaplayTagFilesOld(tag) {
  //   this.copyActiveTag = tag;
  //   this.isSelectedAll = true;
  //   console.log("this.isSelectedAll", this.isSelectedAll);
  //   const filesForTag = this.allFiles.filter(file => file.upload_by === this.copyActiveTag);
  //   const checkedFilesControls = this.copyForm.get('checkedFiles').controls;
  //   const controlsForTag = filesForTag.map(file => {
  //     const index = this.allFiles.findIndex(f => f.id === file.id);
  //     return checkedFilesControls[index];
  //   });
  //   const allSelected = controlsForTag.every(control => control && control.value === true);
  //   this.isSelectedAll = allSelected;
  // }

  diaplayTagFiles(tag) {
    this.copyActiveTag = tag;  
    const filesForTag = this.allFiles.filter(file => file.upload_by === this.copyActiveTag);
    if (filesForTag.length === 0) {
      this.isSelectedAll = false;
      return;
    }
    const checkedFilesControls = this.copyForm.get('checkedFiles').controls;
    const controlsForTag = filesForTag.map(file => {
      const index = this.allFiles.findIndex(f => f.id === file.id);
      return checkedFilesControls[index];
    });
    const allSelected = controlsForTag.every(control => control && control.value === true);
    this.isSelectedAll = allSelected;
  }
  
  
   
  vendorUploadedFiles = [];
  files;
  allFilesReq = null;
  getFiles(page_no = 1) {
    this.formData = {
      projectId: this.projectId,
      tag: this.tag,
      per_page: 5,
      page_no: page_no
    }
    this.allFilesReq = this.http.post(`${this.apiURL}project/file/get`, this.formData).subscribe(
      data => {
        this.handle_exact_files(data);
      },
      error => this.handleError(error)
    );
  }

  @ViewChild(PaginationComponent) pagination_com: PaginationComponent;
  handle_exact_files(data) {
    this.files = [];
    this.vendorUploadedFiles = [];
    data['data'].forEach(file => {
      if ( file.tag != this.tag ) {
        return;
      }
      if (file['upload_by'] == 0)
        this.files.push(file);
      if (file['upload_by'] == 1)
        this.vendorUploadedFiles.push(file);
    });
    this.pagination_com.generate(data['total_pages'], data['page_no']);
    this.initForm();
  }

  updateSelectedFile() {
    this.selectedFiles = this.form.value.files
      .map((v, i) => v ? this.files[i].id : null )
      .filter(v => v !== null);
    }

  getFileName(link) {
    return link.substring(link.lastIndexOf('/')+1);
  }

  viewFile(id){
    let formData = this.Token.getAPITokenData();
    formData['fileId'] = id;

    this.http.post(`${this.apiURL}project/file/view`, formData).subscribe(
      data => {
        window.open(data['url'], "_blank");
      },
      error => this.handleError(error)
    );
  }

  deleteFile(id){
    this.selectedFiles = this.files.filter(function(item) { return item.id == id; })
      .map(function(obj) { return obj.id; });
    this.deleteFiles(true);
  }

  deleteFiles(single_delete = false){
    if ( this.readOnly ) return;
    if(!single_delete)
      this.updateSelectedFile();
    if (this.selectedFiles.length < 1) return;

    let que = this.lang.getText('are_you_sure_you_want_to_delete_these_file');
    if (this.selectedFiles.length > 1)
      que += '';
    que += 's?';
    if ( !confirm(que) )
      return;

    if(this.masterSelected){
      this.deleteAllFiles();
      return;
    }
    this.formData = this.Token.getAPITokenData();
    this.formData['fileIds'] = this.selectedFiles;
    this.http.post(`${this.apiURL}project/delete-files`, this.formData).subscribe(
      data => {
        this.getFiles();
      },
      error => this.handleError(error)
    );
  }

  deliverFile(id){
    this.selectedFiles = this.files.filter(function(item) { return item.id == id; })
      .map(function(obj) { return obj.id; });
    this.deliverFiles(true);
  }
  
  deliverFiles(single_deliver = false){
    if ( this.readOnly ) return;
    this.displayDelivered = true;
    if(!single_deliver)
      this.updateSelectedFile();
    if (this.selectedFiles.length < 1) return;
    this.getDeliverEmails();
  }

  deliverReqActive = false;
  submitDeliver() {
    this.formData = this.Token.getAPITokenData();
    this.formData['projectId'] = this.projectId;
    this.formData['fileIds'] = this.selectedFiles;
    this.formData['tag'] = this.tag;
    this.formData['emails'] = this.deliverEmails;
    this.formData['subject'] = this.deliveForm.getRawValue().subject;

    //this.form.patchValue({delivered: this.form.value.files})
    this.deliverReqActive = true;
    this.http.post(`${this.apiURL}project/deliver-files`, this.formData).subscribe(
      data => {
        //this.handleResponse(data);
        this.displayDelivered = true;
        this.getFiles();
        this.closeModal();
        this.deliverReqActive = false;
    },
      error => this.handleError(error)
    );
  }

  downloadReq;
  downloadFiles(id = false){
    this.updateSelectedFile();
    let fileIds;
    if(id){
      fileIds = [id];
    }
    else if(this.masterSelected){
      this.downloadAllFiles();
      return;
    }
    else if (this.selectedFiles.length){
      fileIds = this.selectedFiles;
    }
      
    this.downloadReq = this.http.post(`${this.apiURL}project/file/download`, {fileIds: fileIds}).subscribe(
      data => {
        window.open(data['url'], "_blank");
        // this.saveOrOpenBlob(data['url'], data['name']);
      },
      error => {
        alert(this.lang.getText('your_download_file_size_exceeds_alert'));
        this.handleError(error)
      }
    );
  }

  downloadAllFiles(){
    let formData:FormData = new FormData();
    formData.append('projectId', this.projectId);
    formData.append('tag', this.tag);
    this.downloadReq = this.http.post(`${this.apiURL}project/file/download-all-files`,formData).subscribe(
      data => {
        window.open(data['url'], "_blank");
        // this.saveOrOpenBlob(data['url'], data['name']);
      },
      error => {
        alert(this.lang.getText('your_download_file_size_exceeds_alert'));
        this.handleError(error)
      }
    );
  }

  deleteAllFiles(){
    let formData:FormData = new FormData();
    formData.append('projectId', this.projectId);
    formData.append('tag', this.tag);
    this.http.post(`${this.apiURL}project/file/delete-all-files`, formData).subscribe(
      data => {
        this.getFiles();
      },
      error => this.handleError(error)
    );
  }

  saveOrOpenBlob(url, blobName) {
    var blob;
    var xmlHTTP = new XMLHttpRequest();
    xmlHTTP.open('GET', url, true);
    xmlHTTP.responseType = 'arraybuffer';
    xmlHTTP.onload = function(e) {
        blob = new Blob([this.response]);   
    };
    xmlHTTP.onprogress = function(pr) {
        //pr.loaded - current state
        //pr.total  - max
    };
    xmlHTTP.onloadend = function(e){
        var fileName = blobName;
        var tempEl = document.createElement("a");
        document.body.appendChild(tempEl);
        // tempEl.style = "display: none";
        url = window.URL.createObjectURL(blob);
        tempEl.href = url;
        tempEl.download = fileName;
        tempEl.click();
        window.URL.revokeObjectURL(url);
    }
    xmlHTTP.send();
}

  uploadFileChange(event) {
    let files: FileList = event.target.files;
    for (var i = 0; i < files.length; i++) {
      let file: File = files[i];
      this.uploadFile(file)
    }
    event.value = '';
  }

  uploads = [];
  uploadFile(file) {
    if ( (file.size / Math.pow(1024, 3)) > 2.001 ) {
      this.helpSer.alertIt('Maximum allowed file size is 2GB');
      return;
    } else {
      this.getUploadURL(file);
    }
  }

  uploadToS3(file, linkObj) {
    let ui = this.uploads.length;
    this.uploads.push({
      req: null,
      uploading: true,
      progress: 0,
      done: false
    });

    const headers = new HttpHeaders({ 'Content-Type': file.type });
    this.uploads[ui].req = this.file_upload_Ser.upload(linkObj.url, file, { headers: headers, reportProgress: true, observe: 'events'})
      .subscribe(
        event => {
          if (event['type'] === HttpEventType.UploadProgress) {
            this.uploadFilePercentDone(event, ui);
          } else if (event instanceof HttpResponse) {
            this.fineshUpload(ui, linkObj); // Only need at successful upload
          }
        }
      );
  }

  getUploadURL(file) {
    let formData:FormData = new FormData();
    formData.append('token', this.Token.get());
    formData.append('fileType', file.type);
    formData.append('projectId', this.projectId);
    formData.append('tag', this.tag);
    formData.append('fileName', file.name);
    formData.append('fileSize', file.size);
    
    this.http.post(`${this.apiURL}project/file/get-upload-link`, formData).subscribe(
      data => {
        this.uploadToS3(file, data)
      },
      error => {
        if (error.error['error'] == 'Storage full' ) {
          this.helpSer.alertIt(this.lang.getText('your_storage_is_full'));
        }
        this.handleError(error);
      }
    );
  }

  fineshUpload(ui, data) {
    let formData:FormData = new FormData();
    formData.append('token', this.Token.get());
    formData.append('id', data.id);
    
    this.http.post(`${this.apiURL}project/file/finesh-upload`, formData).subscribe(
      data => {
        this.handleResponse(ui);
      },
      error => this.handleError(error)
    );
  }

  uploadFilePercentDone(event, ui) {
    this.uploads[ui].progress = Math.round(100 * event.loaded / event.total);
    if (this.uploads[ui].progress == 100) {
      setTimeout(() => {
        this.uploads[ui].done = true;
      }, 500);
    }
  }

  @ViewChild('deliverEmailsTemp') jobDeliverEmailsTemp: TemplateRef<any>;
  deliverEmails = [];
  allDeliverEmails = [];
  deliveForm: UntypedFormGroup = this.fb.group({
    email: '',
    subject: '',
  })
  getDeliverEmails() {
    this.deliveForm.patchValue({subject: this.lang.getText('delivery_files')});
    this.modalRef = this.modalService.show(
      this.jobDeliverEmailsTemp,
      { class: 'modal-lg', backdrop : 'static' }
    );
  }

  addDeliverSubject() {
    let subCon = this.deliveForm.get('subject');
    subCon.enabled ? subCon.disable() : subCon.enable();
  }

  addDeliveryEmail() {
    let email = this.deliveForm.value.email;
    if ( this.helpSer.validEmail(email) ) {
      this.allDeliverEmails.push(email);
    }
    email = this.pushEmail(this.deliverEmails, email);
  }

  pushEmail(emails, ipt) {
    return this.helpSer.pushEmail(emails, ipt)
  }

  handleResponse(ui) {
    this.getFiles();
    setTimeout(() => {
      this.uploads[ui].uploading = false;
    }, 2 * 1000);
  }

  handleError(error) {
    this.isModalOpen = true;
    this.deliverReqActive = false;
    this.Jwtwis.handleError(error);
    //this.errors = error.error.error;
  }
  updateFuncText(lang){
    if(lang != undefined){
      this.tags = [
        { tag: 0, name: this.lang.getText('pm_upload') },
        { tag: 1, name: this.lang.getText('vendor_upload') },
        { tag: 2, name: this.lang.getText('cat_download') },
        //{ tag: "client-files", name: "Client Upload" },
      ];
      clearInterval(this.interval);
    }
  }
  
  checkClaimed(show=true){
    if(this.isCheckClaimed&&!this.projectClaimed){
      if(!this.claimedPopupDisplayed&&show){
        this.modalRef = this.modalService.show(this.claimedPopup);
        this.claimedPopupDisplayed = true;
      }
      return false;
    }
    return true;
  }
  
  claimProject(claim:any){
    this.isClaimProject.emit(claim);
    this.closeModal();
  }

  deliverFilesParent(ti){
    this.deliverFilesEvent.emit({
      index:ti
    });
  }
  undoDeliverFilesParent(ti){
    this.undoDeliverFilesEvent.emit({
      index:ti
    });
  }

  deliverFilesSerParent(ti){
    this.deliverFilesSerEvent.emit({
      index:ti
    });
  }
  undoDeliverFilesSerParent(ti){
    this.undoDeliverFilesSerEvent.emit({
      index:ti
    });
  }

  masterSelected:boolean = false;
  checkUncheckAllFiles(evt) {
    this.form.get('files').controls.map((control,i)=>{
      control.patchValue(evt.target.checked);
    });
    this.masterSelected = evt.target.checked;
  }
  isSelectedAll :boolean = false;
  checkUncheckAll(event) {

    const filesForTag = this.allFiles.filter(file => file.upload_by === this.copyActiveTag);
    console.log("filesForTag---", filesForTag);
    this.allFiles.forEach((element, index) => {
      if (this.copyActiveTag == element.upload_by) {
        this.copyForm.get('checkedFiles').controls[index].patchValue(event.target.checked);
        this.isSelectedAll = event.target.checked;
      }
    });
  }
  
  isAllSelected(evt, index) {
    this.form.value.files[index] = evt.target.checked
    this.masterSelected = this.form.value.files.every((item) => item == true);
  }
  // checkCheckBoxvalueOld(event, fi){
  //   const allSelected = this.copyForm.get('checkedFiles').controls.every(control => control.value === true);
  //   this.isSelectedAll = allSelected;
  // }

  checkCheckBoxvalue(event, fi) {
    const filesForTag = this.allFiles.filter(file => file.upload_by === this.copyActiveTag);
    const checkedFilesControls = this.copyForm.get('checkedFiles').controls;
    const controlsForTag = filesForTag.map(file => {
      const index = this.allFiles.findIndex(f => f.id === file.id);
      return checkedFilesControls[index];
    });
    const allSelected = controlsForTag.every(control => control && control.value === true);
    this.isSelectedAll = allSelected;
  }
}

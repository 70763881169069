<ng-template #poHistoryModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
        {{lang.getText('po_history')}}
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div *ngIf="vendorPOList" class="modal-body">
    <div class="col-md-12">
      <div class="row font-weight-bold">
        <div class="col-sm-3">&nbsp;</div>
        <div class="col-sm-4 text-center">{{ lang.getText('purchase_orders') }}</div>
        <div class="col-sm-5 text-center">{{ lang.getText('vendor_invoices') }}</div>
      </div>
      <div class="row font-weight-bold">
        <div class="col-sm-3">&nbsp;</div>
        <div class="col-sm-4"><div class="header-border">&nbsp;</div></div>
        <div class="col-sm-5"><div class="header-border">&nbsp;</div></div>
      </div>
      <div class="row font-weight-bold">
        <div class="col-sm-1">#</div>
        <div [ngClass]="(financial_dashboard && (view == 'billable_by_vendor' || view == 'payable_to_vendor')) || !financial_dashboard ? 'col-sm-2' : 'col-sm-4'">{{lang.getText('filename')}}</div>
        <div class="col-sm-4">
          <div class="row">
            <div class="col-sm-2">{{lang.getText('amount2')}}</div>
            <div class="col-sm-5">{{lang.getText('sent')}}</div>
            <div class="col-sm-5">{{lang.getText('action')}}</div>
          </div>
        </div>
        <div class="col-sm-5">
          <div class="row">
            <div [ngClass]="(financial_dashboard && view == 'payable_to_vendor') || !financial_dashboard ? 'col-md-4' : 'col-md-6'">{{ lang.getText('received') }}</div>
            <div class="col-sm-4" *ngIf="(financial_dashboard && view == 'payable_to_vendor') || !financial_dashboard">{{lang.getText('paid_without_colun')}}</div>
            <div [ngClass]="(financial_dashboard && view == 'payable_to_vendor') || !financial_dashboard ? 'col-md-4' : 'col-md-6'">{{lang.getText('action')}}</div>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div [formGroup]="poListForm">
      <div formArrayName="pos" *ngFor="let pfPo of vendorPOList;let i = index;" class="col-md-12">
        <div class="row mt-1" [formGroupName]="i">
          <div class="col-md-1">{{i+1}}</div>
          <div [ngClass]="(financial_dashboard && (view == 'billable_by_vendor' || view == 'payable_to_vendor')) || !financial_dashboard ? 'col-sm-2' : 'col-sm-4'">{{pfPo.filename}}</div>
          <div class="col-sm-4">
            <div class="row">
              <div class="col-md-2">{{pfPo.amount}}</div>
              <div class="col-md-5">
                <input formControlName="sent_at" class="smallin w-100" type="text" [attr.disabled]="view == 'payable_to_vendor' ? true : null"
                      placeholder="&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp; /" bsDatepicker
                      [bsConfig]="bsConfig" />
              </div>
              <div class="col-md-5 btns-col">
                <button (click)="pfInvoiceDo(pfPo, 'view')" class="btn btn-sm btn-primary">{{lang.getText('view')}}</button>
                <button (click)="pfInvoiceDo(pfPo, 'down')" class="btn btn-sm btn-primary ms-2">{{lang.getText('download')}}</button>
              </div>
            </div>
          </div>
          <div class="col-sm-5" *ngIf="(financial_dashboard && (view == 'billable_by_vendor' || view == 'payable_to_vendor')) || !financial_dashboard">
            <div class="row">
              <div [ngClass]="(financial_dashboard && view == 'payable_to_vendor') || !financial_dashboard ? 'col-md-4' : 'col-md-6'">
                <input formControlName="invoice_received_at" class="smallin w-100" type="text"
                placeholder="&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp; /" bsDatepicker
                [attr.disabled]="(financial_dashboard && view == 'payable_to_vendor') ? true : null"
                [bsConfig]="bsConfig" />
                <span *ngIf="pfPo.vendor_invoice_details != null"
                  class="btn btn-link btn-sm file-name">{{ pfPo.vendor_invoice_details.filename }}</span>
              </div>
              <div class="col-md-4" *ngIf="(financial_dashboard && view == 'payable_to_vendor') || !financial_dashboard">
                <input formControlName="invoice_paid_at" class="smallin w-100" type="text"
                [attr.disabled]="pfPo.vendor_invoice_details == null ? true : null"
                placeholder="&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp; /" bsDatepicker
                [bsConfig]="bsConfig" />
              </div>
              <div [ngClass]="(financial_dashboard && view == 'payable_to_vendor') || !financial_dashboard ? 'col-md-4' : 'col-md-6'">
                <button class="btn btn-sm btn-primary" (click)="getInvoice(pfPo, 'view')" [attr.disabled]="checkInvoiceStatus(pfPo)">{{lang.getText('view')}}</button>
                <button class="btn btn-sm btn-primary ms-2" (click)="getInvoice(pfPo, 'down')" [attr.disabled]="checkInvoiceStatus(pfPo)">{{lang.getText('download')}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="vendorPOList.length == 0" class="col-md-12">
      <div class="col-sm-12 text-center">
        <p>{{lang.getText('no_results')}}</p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModal()">{{lang.getText('cancel')}}</button>
    <button type="button" class="btn btn-primary" (click)="savePos()">{{lang.getText('save_changes')}}</button>
  </div>
</ng-template>

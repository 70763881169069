<ng-template #suggestImprovement>
    <div class="modal-header">
        <div class="app-header">
            <h4>{{lang.getText('suggestion_header')}}   <button type="button" class="close pull-right close-button" aria-label="Close" (click)="closeModal()">
              <span aria-hidden="true">&times;</span> 
           </button>  </h4>
        </div>
    </div>
    <div class="modal-body">
      <form [hidden]="suggestionSubmited" [formGroup]="suggestionFg" class="row">
        <div class="mb-3">
          <p>{{lang.getText('suggestion_body_title')}}</p>
        </div>
         
        <div class="mb-3">
          <textarea formControlName="suggestion" class="form-control" style="height: 108px;"></textarea>
        </div>

        <div class="mb-3">
          <p>{{lang.getText('suggestion_text2')}}</p>
        </div>
        <div>
          <input formControlName="email" class="form-control"  type="text" >
        </div>
        <div>
          <p>{{lang.getText('suggestion_text3')}}</p>
        </div>
        <div class="mb-3">
        
        <p>
          <a target="_blank" rel="noopener" href="//assets-global.website-files.com/654cdc691d1adc4354c3beec/654cdc691d1adc4354c3c14e_Privacy-Statement.pdf">{{lang.getText('suggestion_privacy_statement')}}</a>
        </p> 
        </div>
        <div>
          <button (click)="submitSuggestion()" class="btn btn-primary">{{lang.getText('submit')}}</button>
          <button type="reset" (click)="closeModal()" class="btn btn-secondary ml-1">{{lang.getText('cancel')}}</button>
        </div>
      </form>
      <h4 *ngIf="suggestionSubmited" class="thanks-message">{{lang.getText('thank_you_for_your_suggestion')}}</h4>
    </div>
  </ng-template>
  
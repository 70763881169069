import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../../../services/token.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ConfigureService {
  private apiURL = environment['API_URL'];
  formData: FormData = new FormData();
  constructor(
    private token: TokenService,
    private http: HttpClient,
  ) {
    this.formData.append('token', this.token.get() );
  }
  
  storageDetails() {
    return this.http.post(`${this.apiURL}storage-space/details`, this.formData);
  }

  subscriptionDetails() {
    return this.http.post(`${this.apiURL}payment-method/get`, this.formData);
  }

  purchaseStorage(units) {
    this.formData.append('units', units);
    return this.http.post(`${this.apiURL}storage-space/purchase`, this.formData);
  }
}

<ng-template #editCommentDescription>
    <div  class="modal-header">
      <h4 class="modal-title pull-left">{{lang.getText('edit_projects')}}  </h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div  class="modal-body">
      <div class="row pb-3">
        <div class="col-md-2">
          <label>{{lang.getText('description')}}</label>
        </div>
        <div class="col-md-10">
          <input class="w-100 form-control"   type="text" value={{projectDetails.discription}} #description   />
        </div>
      </div>
      <div class="row">
        <div class="col-md-2">
          <label>{{lang.getText('comments2')}}</label>
        </div>
        <div class="col-md-10">
          <input class="w-100 form-control"  type="text" value={{getComments(projectDetails.comments_details)}}  #comment  />
        </div>
      </div>
      <div class="row pt-3">
        <div class="form-group col-auto ml-auto">
          <button (click)="updateDescription(description.value,comment.value,projectDetails.id)" class="btn btn-primary">{{lang.getText('update')}}</button>
          <button  (click)="closeModal()" class="btn btn-primary ml-3"   type="reset">{{lang.getText('cancel')}}</button>
        </div>
      </div>
      
      
      </div>
     
  
    
  </ng-template>
<ng-template #projectRelationListModal>
  <div class="new-redesign-style">
  <div class="modal-header">
    <h4 class="modal-title pull-left">
        {{lang.getText('related_projects')}}
    </h4>
    <button type="button" class="close pull-right ps-2 pe-2 mb-5" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <button type="button" class="btn new-btn-primary ps-4 pe-4" (click)="openProjectRelatationActionModal()">
      {{lang.getText('link_project')}}
    </button>
    <hr style="color: #6f6b6b;">
        <div class="row row-secondary mt-2 mb-2 p-2">
          <div class="col-md-5 text-start">{{ lang.getText('project') }}</div>
          <div class="col-md-3 text-start">  {{ lang.getText('comments2') }} </div>
          <div class="col-md-2 text-start">  {{ lang.getText('status') }}</div>
          <div class="col-md-2 text-start"> {{ lang.getText('actions') }}</div>
        </div>
        <ng-container *ngIf="this.projectRelations.length > 0">
          <div *ngFor="let project of this.projectRelations; let i=index" class="row content row-primary mb-2 p-1">
            <div class="col-md-5 col-sm-12 text-start">
              <a (click)="navigateToRelatedProject(this.parent_project_id, project.project_id, project.related_project_id)">{{ project.name }}</a>
            </div>
            <div class="col-md-3 col-sm-12 text-start">
              <span *ngIf="!project.isEditMode">{{ project.comment }}</span>
              <span *ngIf="project.isEditMode">
                <input type="text" class="form-control" [value]="project.comment" [(ngModel)]="comment" > 
              </span>
            </div>
            <div class="col-md-2 col-sm-12 text-start badge-empty mt-1 mb-1">
                  <span class="ms-3">{{ project.status }}<span [class]="project.status"></span></span>
            </div>
            <div class="col-md-2 col-sm-12 text-start">
              <ng-container *ngIf="!project.isEditMode">
                <a class="btn btn-circle btn-circle-sm me-2 ms-0" (click)="editProjectRelation(project.id, i)" title="{{ lang.getText('edit') }}">
                  <i class="fa fa-pencil  color-primary" aria-hidden="true"></i>
                </a>
                <a class="btn btn-circle btn-circle-sm me-2" (click)="editProjectRelation(project.id, i)" title="{{ lang.getText('edit') }}">
                  <i class="fa fa-chain-broken fa-rotate-90 color-secondary" aria-hidden="true"></i>
                </a>
                <!-- <a class="btn btn-circle btn-circle-sm me-2"  (click)="unlinkProjectRelation(project.id, i)" title="{{ lang.getText('unlink') }}">
                  <i class="fa fa-pencil fa-lg color-primary" aria-hidden="true"></i>
                </a>
                <button class="btn btn-outline-secondary" (click)="editProjectRelation(project.id, i)">{{ lang.getText('edit') }}</button> -->
                <!-- <button class="btn btn-outline-danger" (click)="unlinkProjectRelation(project.id, i)">{{ lang.getText('unlink') }}</button> -->
              </ng-container>
              <ng-container *ngIf="project.isEditMode">
                <button class="btn btn-outline-secondary" (click)="updateProjectRelation(project.id, i)">{{ lang.getText('save') }}</button>
                <button class="btn btn-outline-danger" (click)="project.isEditMode = false">{{ lang.getText('cancel') }}</button>
              </ng-container>
            </div>
          </div>
         </ng-container>
         <ng-container *ngIf="this.projectRelations.length == 0">
          <div class="row content row-primary mb-2 p-1">
            <div class="text-center">
              <p>{{ lang.getText('this_project_has') }} {{ project_relation_count }} {{ lang.getText('related_projects2') }}</p>
            </div>
          </div>
        </ng-container> 
    <!-- <table class="table" aria-label="Project Details">
      <thead role="rowgroup">
        <tr role="row">
          <th class="col-md-4" scope="project">{{ lang.getText('project') }}</th>
          <th class="col-md-4" scope="comments">{{ lang.getText('comments2') }}</th>
          <th class="col-md-1" scope="status">{{ lang.getText('status') }}</th>
          <th class="col-md-3" scope="actions">{{ lang.getText('actions') }}</th>
        </tr>
        <div class="row row-secondary mt-2 mb-2 p-2">
          <div class="col-3 text-start">{{ lang.getText('project') }}</div>
          <div class="col-3 text-start">  {{ lang.getText('comments2') }} </div>
          <div class="col-3 text-start">  {{ lang.getText('status') }}</div>
          <div class="col-3 text-start"> {{ lang.getText('actions') }}</div>
        </div>
      </thead>
      <tbody role="rowgroup">
        <ng-container *ngIf="this.projectRelations.length > 0">
          <tr role="row" *ngFor="let project of this.projectRelations; let i=index">
            <td class="col-md-4"><a (click)="navigateToRelatedProject(this.parent_project_id, project.project_id, project.related_project_id)">{{ project.name }}</a></td>
            <td class="col-md-4">
              <span *ngIf="!project.isEditMode">{{ project.comment }}</span>
              <span *ngIf="project.isEditMode">
                <input type="text" class="form-control" [value]="project.comment" [(ngModel)]="comment" > 
              </span>
            </td>
            <td class="col-md-1">
              <p class="text-{{ project.class }}">{{ project.status }}</p>
            </td>
            <td class="col-md-3 actions">
              <ng-container *ngIf="!project.isEditMode">
                <button class="btn btn-outline-secondary" (click)="editProjectRelation(project.id, i)">{{ lang.getText('edit') }}</button>
                <button class="btn btn-outline-danger" (click)="unlinkProjectRelation(project.id, i)">{{ lang.getText('unlink') }}</button>
              </ng-container>
              <ng-container *ngIf="project.isEditMode">
                <button class="btn btn-outline-secondary" (click)="updateProjectRelation(project.id, i)">{{ lang.getText('save') }}</button>
                <button class="btn btn-outline-danger" (click)="project.isEditMode = false">{{ lang.getText('cancel') }}</button>
              </ng-container>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="this.projectRelations.length == 0">
          <tr>
            <td colspan="4" class="text-center">
              <p>{{ lang.getText('this_project_has') }} {{ project_relation_count }} {{ lang.getText('related_projects2') }}</p>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table> -->
  </div>
</div>
</ng-template>
<app-project-relation-action #openProjectRelatationActionCom (projectLinkedSuccess)="projectLinkedSuccess($event)"></app-project-relation-action>

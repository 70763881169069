<div class="nospace col-12 text-center align-items-center {{type}}" [ngClass]="check_string_length ? string_length >= length_check ? '' : 'd-flex' : 'd-flex justify-content-center'" >
  <div class="nospace" [ngClass]="check_string_length ? string_length >= length_check ? '' : 'ps-2 text-start col-'+col_l : ''">
    <span *ngIf="title != ''" >{{title | translate}}&nbsp;{{':'}}&nbsp;</span>
  </div>
  <div class="nospace" [ngClass]="check_string_length ? string_length >= length_check ? '' : 'pe-2 text-start col-'+col_r : '' ">
    <span class="d-flex align-items-center">
      <span class="pe-1">{{ null | myCurrency:currencyCode }}</span>
      <input *ngIf="check_string_length" type="number" min="0" readonly="" class="form-control input-no-design nospace color-inherit" value="{{amount}}">
      <span *ngIf="!check_string_length" class="form-control input-no-design nospace color-inherit">{{amount}}</span>
    </span>
  </div>
</div>

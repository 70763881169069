<div *ngIf="loading_translations();" class="row" style="width: 100%;">
  <div class="row vend-res-trans-row">
    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
  </div>
</div>
<ng-container *ngIf="vendor !== null && ! loading_translations()">
  <div [formGroup]="vendor">
    <div
      formArrayName="translations"
      *ngFor="let translation of vendor.get('translations').controls; let ti = index"
      style="width: 100%;">
      <div
        [hidden]="!trasnlation_pagination_data.res_show_hide_financially_closed && translation.get('financially_closed').value"
        [formGroupName]="ti"
        [ngClass]="{ 'selected' : translation.getRawValue().display }"
        class="row vend-res-trans-row" 
        *ngIf="translation.getRawValue().delete != 'yes'"
        id="res_trans_{{ti+1}}">
        <input formControlName="project_translate_id" type="hidden" />
        <div class="col-lg-12 col-md-12 col-sm-12 nospace">
          <div class="row">
            <div class="col-md-3 col-sm-12"> 
              <label>
                <input 
                  formControlName="financially_closed" 
                  type="checkbox"
                  (change)="markTrasnlationFinanciallyClosed($event.target.checked, translation)"> 
                  {{ lang.getText('financially_closed') }}
              </label>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-8 col-md-8 nospace">
          <div class="row mt-2 mb-2">
            <div class="col-md-3 nospace">
              <div class="row">
                <div class="col-md-12">
                  <span>{{lang.getText('source')}} {{((trasnlation_pagination_data.res_page - 1) * trasnlation_pagination_data.res_per_page) + ti + 1}}</span>
                  <!-- <select formControlName="source_lang_id" class="form-control" 
                    (change)="checkClaimed ? getCopiableCATCombination(pvi, ti) : false">
                    <option *ngFor="let language of languages; let li = index" value="{{language.id}}"  >{{language.name}}</option>
                  </select> -->
                  <ng-select (change)="checkClaimed ? getCopiableCATCombination(pvi, ti) : false"
                    [items]="languages"
                    [clearable]="false"
                    bindLabel="name" 
                    bindValue="id"
                    notFoundText="{{ 'no_data_found' | translate }}" 
                    formControlName="source_lang_id">
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="col-md-3 nospace">
              <div class="row">
                <div class="col-md-12">
                  <span>{{lang.getText('target')}} {{((trasnlation_pagination_data.res_page - 1) * trasnlation_pagination_data.res_per_page) + ti + 1}}</span>
                  <!-- <select formControlName="target_lang_id" class="form-control" 
                    (change)="checkClaimed ? getCopiableCATCombination(pvi, ti) : false">
                    <option *ngFor="let language of languages; let li = index" value="{{language.id}}">{{language.name}}</option>
                  </select> -->
                  <ng-select (change)="checkClaimed ? getCopiableCATCombination(pvi, ti) : false"
                    [items]="languages" 
                    [clearable]="false"
                    bindLabel="name" 
                    bindValue="id"
                    notFoundText="{{ 'no_data_found' | translate }}" 
                    formControlName="target_lang_id">
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="col-md-3 nospace">
              <div class="row">
                <div class="col-md-12">
                  <span>{{lang.getText('words')}} {{((trasnlation_pagination_data.res_page - 1) * trasnlation_pagination_data.res_per_page) + ti + 1}}</span>
                      <input (keypress)="nonNumericValPressDecimal($event)" formControlName="word_rate" type="number" class="form-control"> 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-3 col-md-7 ">
          <div class="row md_rw sub-total-col">
            <div class="col-xl-5 col-lg-5 col-md-5 nospace">
              <span>{{lang.getText('subtotal_combo')}} {{((trasnlation_pagination_data.res_page - 1) * trasnlation_pagination_data.res_per_page) + ti + 1}}</span>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6">
              <input formControlName="amount" type="number" min="0" readonly class="form-control">
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="row md_rw delete-col">
            <div class="col-md-12 ps-0">
              <div class="col-lg-12 col-md-6 col-sm-12 pe-2">
                <span class="btn btn-border-secondary me-1" *ngIf="!projectEditDis" (click)="checkClaimed && !checkXTMData ? deleteVendorTran(translation, ti) : false" ><i class="fa fa-trash fa-lg" aria-hidden="true" [class.disabled]="checkXTMData"></i></span>
                <span class="btn btn-border-secondary"
                  *ngIf="!projectEditDis"
                  (click)="checkClaimed ? displayThisVendTran(pvi, ti) : false"
                >
                  <i class="fa fa-pencil fa-lg" aria-hidden="true"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 nospace" *ngIf="checkXTMData">
          <div class="row">
            <div class="trans-job col-md-3 col-sm-12">
              <p class="medium">{{lang.getText('sap_xtm_job_id')}}: {{ translation.getRawValue().sap_xtm_job_id }}</p>
            </div>
            <ng-container *ngFor="let metas of translation.getRawValue().client_translation_meta_values">
              <ng-container *ngIf="metas.meta_key === 'sap_xtm_job_file_name' && metas.meta_value != ''">
                <div class="trans-job col-md-6 col-sm-12">
                  <p class="medium">{{lang.getText(metas.meta_key)}}: {{metas.meta_value}}</p>
                </div>
              </ng-container>
              <div class="trans-job col-md-3 col-sm-12" *ngIf="metas.meta_key != 'sap_xtm_job_file_name'">
                <p class="medium">{{lang.getText(metas.meta_key)}}: {{metas.meta_value}}</p>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="single_part_main cat_analysis m-3">
          <div 
            *ngIf="translation.getRawValue().display && translation.getRawValue().delete != 'yes'"
            class="single_part_inner">
            <div class="main_head">
              <p>{{lang.getText('vendor')}} {{pvi+1}} {{lang.getText('cat_analysis_language_combination')}} {{((trasnlation_pagination_data.res_page - 1) * trasnlation_pagination_data.res_per_page) + ti + 1}}</p>
            </div>
            <div class="container no-padding">
              <div class="row">
                <div class="col-xl-12 col-lg-6 col-md-12">
                  <app-checkboxes
                    [items]=supplierServices
                    [formArray]="translation.get('services')"
                    [disabled]="translation.get('financially_closed').value" >
                  </app-checkboxes>
                  <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-12 nospace">
                      <div class="row">
                        <div class="col-md-3">
        
                        </div>
                        <div class="col-md-3 text-center">
                          <p>{{lang.getText('words')}}</p>
                        </div>
                        <div class="col-md-3 text-center">
                          <p>{{lang.getText('unit_rate')}}</p>
                        </div>
                        <div class="col-md-3 text-center">
                          <p>{{lang.getText('subtotal')}}</p>
                        </div>
                      </div>
                      <div formArrayName="catAnalysisGroA" class="" *ngFor="let wordMatch of wordMatchesA; let i = index">
                        <div [formGroupName]="i" class="row">
                          <input formControlName="word_match_id" type="hidden" value="{{wordMatch.id}}">
                          <div class="col-md-3 col-sm-3 nospace badge-secondary">
                            <p>{{wordMatch.name}}</p>
                          </div>
                          <div class="col-md-3 col-sm-3">
                            <input (keypress)="nonNumericValPress($event)" formControlName="word_count" type="number" min="0" class="form-control words">
                          </div>
                          <div class="col-md-3 col-sm-3">
                            <input formControlName="rate" type="number" min="0" readonly class="form-control unit_rate">
                          </div>
                          <div class="col-md-3 col-sm-3">
                            <input formControlName="amount" type="number" min="0" readonly class="form-control sub_total">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12 nospace">
                      <div class="row">
                        <div class="col-md-3">
        
                        </div>
                        <div class="col-md-3 text-center">
                          <p>{{lang.getText('words')}}</p>
                        </div>
                        <div class="col-md-3 text-center">
                          <p>{{lang.getText('unit_rate')}}</p>
                        </div>
                        <div class="col-md-3 text-center">
                          <p>{{lang.getText('subtotal')}}</p>
                        </div>
                      </div>
                      <div formArrayName="catAnalysisGroB" class="" *ngFor="let wordMatch of wordMatchesB; let i = index">
                        <div [formGroupName]="i" class="row">
                          <input formControlName="word_match_id" type="hidden" value="{{wordMatch.id}}">
                          <div class="col-md-3 col-sm-3 nospace badge-secondary">
                            <p>{{wordMatch.name}}</p>
                          </div>
                          <div class="col-md-3 col-sm-3">
                            <input (keypress)="translation.patchValue({is_cat_changed: true}); nonNumericValPress($event);" formControlName="word_count" type="number" min="0" class="form-control words">
                          </div>
                          <div class="col-md-3 col-sm-3">
                            <input formControlName="rate" type="number" min="0" readonly class="form-control unit_rate">
                          </div>
                          <div class="col-md-3 col-sm-3">
                            <input formControlName="amount" type="number" min="0" readonly class="form-control sub_total">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row col-xl-12 col-lg-6 col-md-6 total_count_section">
                  <div class="col-sm-8 p-0">
                    <button
                      (click)="!checkXTMData && !translation.get('financially_closed').value ? copyCATCombination(pvi, ti): false"
                      class="btn btn-primary btn-sm cat-import-btn  me-2 mb-2"
                      [disabled]="!copiableCATCombi.length || translation.get('financially_closed').value  || checkXTMData ">
                      {{lang.getText('copy_from_quote')}}
                    </button>
                    <button *ngIf="!projectEditDis"
                      [disabled]="checkXTMData || translation.get('financially_closed').value"
                      (click)='checkClaimed && !checkXTMData && !translation.get("financially_closed").value ? openImportCATModal(pvi, ti) : false'
                      class="btn btn-primary btn-sm cat-import-btn me-2 mb-2">
                      {{lang.getText('import_cat_analysis')}}
                    </button>
                    <button *ngIf="!projectEditDis"
                      [disabled]="checkXTMData || translation.get('financially_closed').value || translation.value.is_cat_changed"
                      (click)='checkClaimed && !checkXTMData && !translation.get("financially_closed").value ? updateCATtoAll(pvi, ti) : false'
                      class="btn btn-primary btn-sm cat-import-btn me-2 mb-2">
                      {{lang.getText('copy_and_save_analysis_to_all_language_combinations')}}
                    </button>
                    <button *ngIf="!projectEditDis"
                      (click)='checkClaimed && !translation.get("financially_closed").value ? openVendorCATChangModal(pvi, ti) : false'
                      [attr.disabled]="translation.get('financially_closed').value ? '' : null"
                      class="btn btn-primary btn-sm cat-import-btn me-2 mb-2">
                      {{lang.getText('change_cat_breakdown_for_vendor')}}
                    </button>
                    <button class="btn btn-warning btn-sm cat-import-btn"
                      type="button"
                      [attr.disabled]="translation.get('financially_closed').value ? '' : null"
                      (click)="translation.get('financially_closed').value ? false : updateCAT('vendor', translation, pvi, ti)"
                      >
                      {{ lang.getText('save_and_close') }}
                    </button>
                  </div>
                  <div class="col-sm-4 p-0">
                    <div class="text-center">
                      <div class="col-sm-10 row badge-primary mb-2">
                        <div class="text-center">
                          {{lang.getText('total_words')}}&nbsp;{{':'}}&nbsp;
                          {{translation.getRawValue().words_count}}
                          <input formControlName="words_count" readonly type="hidden" class="form-control">
                        </div>
                      </div>
                      <div class="col-sm-10 row badge-primary mb-2">
                        <div class="text-center">
                          {{lang.getText('weighed_words')}}&nbsp;{{':'}}&nbsp;
                          {{translation.getRawValue().weighted_count}}
                          <input formControlName="weighted_count" readonly type="hidden" class="form-control">
                        </div>
                      </div>
                      <div class="col-sm-10 row badge-primary mb-2">
                        <div class="text-center">
                          {{lang.getText('po_amount')}}&nbsp;{{':'}}&nbsp;
                          {{translation.getRawValue().sub_total}}
                          <input formControlName="sub_total" readonly type="hidden" class="form-control">
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <p>{{lang.getText('total_words')}}</p>
                  <input formControlName="words_count" readonly type="text" class="form-control">
                  <p>{{lang.getText('weighed_words')}}</p>
                  <input formControlName="weighted_count" readonly type="text" class="form-control">
                  <p>{{lang.getText('po_amount')}}</p>
                  <input formControlName="sub_total" readonly type="text" class="form-control"> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div formArrayName="batches" class="container">
          <div *ngFor="let batch of translation.get('batches').controls; let bi = index;">
            <div class="row" [formGroupName]="bi" *ngIf="batch.getRawValue().delete != 'yes'">
            <input formControlName="project_translate_id"  type="hidden" />
            <div class="col-xl-7 col-lg-8 col-md-12">
                <div class="row" formGroupName="delivery_at">
                  <div class="col-xl-4 col-lg-4 col-md-4 ps-0" style="margin-top: 30px;">
                    <p>{{lang.getText('delivery_date_batch')}} {{bi+1}}</p>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 ps-0">
                      <div class="row">
                          <label class="inner-label">{{lang.getText('date')}}</label>
                          <div class="row nospace col-xl-12 col-lg-12 col-md-12">
                            <input formControlName="date" class="form-control" type="text" bsDatepicker [bsConfig]="bsConfig">&nbsp;
                          </div>
                      </div>
                  </div>
    
                  <div class="col-xl-4 col-lg-4 col-md-4 ps-0">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="row pt-0">
                                <div class="col-xl-12 col-lg-12 col-md-12 d-flex nospace">
                                    <div class="row nospace col-6">
                                      <label class="inner-label">{{lang.getText('hours')}}</label>
                                      <select formControlName="hour" class="in_small_other diff">
                                        <option *ngFor="let hour of hours;" value="{{hour}}">{{hour}}</option>
                                      </select>
                                    </div>
                                    <div class="row nospace col-6">
                                      <label class="inner-label">{{lang.getText('minutes')}}</label>
                                      <select formControlName="minute" class="in_small_other diff">
                                        <option *ngFor="let minute of minutes;" value="{{minute}}">{{minute}}</option>
                                      </select>
                                    </div>
                                  </div>
                            </div>
                          </div>
                        </div>
                  </div>
                </div>
    
            </div>
            <div class="col-xl-5 col-lg-4 col-md-12">
                  <div class="row" style="margin-top:30px;">
                    <div class="col-xl-5 col-lg-5 col-md-5 nospace">
                      <p>{{lang.getText('description')}} </p>
                    </div>
                    <div class="col-xl-5 col-lg-5 col-md-5 ps-4 pe-0">
                          <input formControlName="description" type="text" class="form-control" />
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-2 d-flex align-items-center">
                        <div class="row">
                          <div class="col-md-12 pe-0 ps-0">
                            <div class="col-lg-4 col-md-6 col-sm-12 pe-0">
                              <span>
                                <i class="fa fa-trash fa-lg"
                                [class.disabled]="translation.get('financially_closed').value ? true : false"
                                (click)="checkClaimed && !translation.get('financially_closed').value ? deletedeliverybatch(batch) : false"  
                                aria-hidden="true"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
            </div>
          </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-md-12 nospace">
            <div class="row">
              <div class="col-md-12 ms-0 col-lg-12">
                <a *ngIf="!projectEditDis" 
                  (click)="checkClaimed  && !translation.get('financially_closed').value ? addVendorBatch(pvi, 'translations', ti) : false"
                  [class.disabled]="translation.get('financially_closed').value ? true : false"
                  class="btn btn-primary">
                  {{lang.getText('add_batch')}}
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-md-12">
            <div class="row md_rw">
              <div class="col-lg-8 col-md-6">
              </div>
              <div class="col-lg-4 col-md-6">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<app-vendor-cat-percentage-change #appVendorCATPercentageChange (translationOut)="vendorCATChangRes($event)"></app-vendor-cat-percentage-change>

<div class="container">
    <h2>{{lang.getText('privacy_policy')}}</h2>
    <div>
        <p>{{lang.getText('privacy_text')}}</p>
    </div>
    <div class="content">

    </div>
    <div><br /></div>
    <div class="agree-sec">
        <span (click)=agree() class="agreeBtn btn btn-primary" id="agree-btn">{{lang.getText('agree')}}</span>
        <span (click)=reject() class="agreeBtn btn btn-danger" id="reject-btn">{{lang.getText('reject')}}</span>
        <a [href]="pdfDown" class="btn btn-light btn-sm" target="_blank" id="down-btn">{{lang.getText('download_pdf')}}</a>
    </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-checkboxes',
  templateUrl: './checkboxes.component.html',
  styleUrls: ['./checkboxes.component.scss']
})
export class CheckboxesComponent implements OnInit {
  @Input() items;
  @Input() formArray: UntypedFormArray;
  @Input() disabled: Boolean;
  checkboxForm: UntypedFormGroup;
  constructor(
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit() {
    let cbsFa = this.fb.array([]);
    this.items.forEach(item => {
      let selectedFc = this.fb.control({ value: this.formArray.getRawValue().includes(item.id), disabled: this.disabled });
      selectedFc.valueChanges.subscribe(val => {
        this.checkboxChanged();
     })
     cbsFa.push(
        this.fb.group({
          id: this.fb.control(item.id),
          name: this.fb.control(item.name),
          selected: selectedFc,
        })
      );
    });
    this.checkboxForm = this.fb.group({
      checkboxes: cbsFa,
    });
  }

  checkboxChanged() {
    let selectedCbs = [];
    this.checkboxForm.getRawValue().checkboxes.forEach(item => {
      if (item.selected) {
        selectedCbs.push(item.id);
      }
    });
    this.updatecheckboxesFa(selectedCbs);
  }

  updatecheckboxesFa(selectedCbs) {
    while (this.formArray.length !== 0) {
      this.formArray.removeAt(0)
    }
    selectedCbs.forEach(val => {
      this.formArray.push(this.fb.control(val));
    });
  }
}

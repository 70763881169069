import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Router } from "@angular/router";
import { UntypedFormBuilder,UntypedFormArray,UntypedFormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { TokenService } from 'src/app//services/token.service';
import { JwtauthService } from 'src/app//services/jwtauth.service';
import { LanguageService } from 'src/app//services/language.service';
import {timeout} from 'rxjs/operators';
import { PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-vendor-list',
  templateUrl: './vendor-list.component.html',
  styleUrls: [
    './vendor-list.component.scss',
    '../../../../users-list-page/users-list-page.component.scss',
    '../../../../project-edit-page/project-edit-page.component.scss',
  ]
})
export class VendorListComponent implements OnInit {
  apiURL = '';
  vendors;
  vendor = [];
  vendorUploadInvoice: boolean = false;
  lang;
  label;
  errors;
  modalRef: BsModalRef;
  uploadVendorDataForm;
  VendorDataForm:UntypedFormGroup;
  showUploadBtn = false;
  fileToUpload: File | null = null;
  xtmConFgList = [];
  selectedConnection = 0; // Default to the first tab

  constructor(
    private injector: Injector,
    private http: HttpClient,
    private Token: TokenService,
    private Jwtwis: JwtauthService,
    private router: Router,
    public language: LanguageService,
    private modalService: BsModalService,
    private fb: UntypedFormBuilder
  ) {
    this.apiURL = this.injector.get('API_URL');
    this.lang = language;
    this.getVendorUploadInvoice();
  }

  ngOnInit() {
    this.getXTMConnections();
    this.getList();
    this.uploadVendorDataForm = this.fb.group({
      keep_duplicate: 'false',
      import_file: ''
    });  
    this.VendorDataForm = this.fb.group({

      vendor: this.fb.array([
        this.fb.group({
          id: '',
          name: '',
          email: '',
          xtm_data: this.fb.array([])
        })
      ])
    }); 
  }
  get vendorsFormArr(): UntypedFormArray {
    return this.VendorDataForm.get('vendor') as UntypedFormArray;
  }
  vendorsConectionFormArr(vendorXTMData, connectionId) {
    return vendorXTMData.filter(data => data.connection_id == connectionId);
  }
  getList(){
    this.http.post(`${this.apiURL}vendors`, this.Token.getAPITokenData()).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    );
  }
  delete(id){
    if ( ! window.confirm(this.lang.getText('are_you_sure_you_want_to_delete')) ) {
        return;
    }
    let formData = this.Token.getAPITokenData();
    this.http.post(`${this.apiURL}vendor/delete/${id}`, formData).subscribe(
      data => this.ngOnInit(),
      error => this.handleError(error)
    );
  }

  getVendorUploadInvoice() {
    this.http.post(`${this.apiURL}vendor/can-upload-invoice`, this.Token.getAPITokenData()).subscribe(
      data => {
        if ( data ) {
          this.vendorUploadInvoice = true;
        }
      }
    );
  }

  setVendorUploadInvoice() {
    let formData = {
      token: this.Token.get(),
      name: 'Can vendor upload invoice',
      value: this.vendorUploadInvoice
    };
    this.http.post(`${this.apiURL}config/update/6`, formData).subscribe(
      data => this.handleResponse(data)
    );
  }
  setvendors(data) {
    this.VendorDataForm.setControl('vendor', new UntypedFormArray([]));
    data.forEach(vendor => {
      let vendorFg = this.fb.group({
        id: vendor.id,
        name: vendor.name,
        email: vendor.email,
        xtm_data: this.fb.array([])
      });
      let xtmDataArray = vendorFg.get('xtm_data') as FormArray;
      this.xtmConFgList.forEach(connection => {
        let xtmData = this.vendorsConectionFormArr(vendor.xtm_data, connection.id);
        let vendorXTMData = {
          id: 0,
          connection_id: connection.id,
          vendor_id: vendor.id,
          xtm_user_id: "",
          xtm_user_display_name: "",
        };
        if (xtmData.length > 0) {
          vendorXTMData = {
            id: xtmData[0]['id'],
            connection_id: xtmData[0]['connection_id'],
            vendor_id: xtmData[0]['vendor_id'],
            xtm_user_id: xtmData[0]['xtm_user_id'],
            xtm_user_display_name: xtmData[0]['xtm_user_display_name'],
          }
        }
        xtmDataArray.push(this.fb.group(vendorXTMData));
      });
      this.vendorsFormArr.push(vendorFg);
    });
  }
  handleResponse(data) {
    if(data['result'] instanceof Array) {
      this.vendors = data['result'];
      this.setvendors(this.vendors);
      // this.router.onSameUrlNavigation = 'reload';
      // this.router.navigateByUrl('vend');
    }
  }

  handleError(error) {
    this.Jwtwis.handleError(error);
    this.errors = error.error.error;
  }
  downloadImport(){
    window.open(`${this.apiURL}get-import-template/vendor`);
  }
  openUploadVendorDataModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  importFileChange(files: FileList){
    this.uploadVendorDataForm.value.import_file != '' ? this.showUploadBtn = true : this.showUploadBtn = false;
    this.fileToUpload = files.item(0);
  }
  closeModal() {
    this.modalRef.hide();
    this.uploadVendorDataForm = this.fb.group({
      keep_duplicate: 'false',
      import_file: ''
    });
    this.showUploadBtn = false;
  }
  uploadVendorData(){
    const postData = new FormData();
    let token = this.Token.getAPITokenData();
    postData.append('token', token.token );
    postData.append('keep_duplicate', this.uploadVendorDataForm.value.keep_duplicate);
    postData.append('import_file', this.fileToUpload);
    const apiUrl = 'import-vendors';
    this.http.post(`${this.apiURL}${apiUrl}`, postData)
      .subscribe(
        data => {
          this.getList()
          this.closeModal();
        },
        error => {
          console.log(error);
          if(error!=undefined&&error.error.msg!=undefined)
            alert(error.error.msg)
          else
            alert("Error");
          this.closeModal();
        }
      );
  }
  vendorSaveChanges(){
    this.errors = {};
    this.display='none';
    let formData = this.VendorDataForm.value;
    formData['token'] = this.Token.get();
    this.http.post(`${this.apiURL}vendor/updateAll`, formData).subscribe(
      data => {
        this.openModalDialog('success',data['result']);
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl('vend');
      },
      error => {
        this.Jwtwis.handleError(error);
        console.log('error.error.error',error.error.error);
        this.errors = error.error.error;
        this.openModalDialog('failed','Id Must be Number...!');
        
      }
    );
  }
  display = 'none';
  status = '';
  message = '';
  openModalDialog(status,message){
    this.display='block';
    this.status = status;
    this.message = message;
  }
  closeModalDialog(){
    this.display='none';
  }

  getXTMConnections() {
    let postData = {
      token: this.Token.get()
    }
    this.http.post(`${this.apiURL}xtm-connection/get`, postData).subscribe(
      data => {
        data['result'].forEach((connection, index) => {
          let isSelected = false
          if (index == 0) {
            isSelected = true;
          }
          connection['isSelected'] = isSelected;
          this.xtmConFgList.push(connection);
        });
      },
      error => this.handleError(error)
    );
  }

  selectXTMConnection(index: number) {
    this.selectedConnection = index;
    this.xtmConFgList.forEach((connection, index) => {
      connection.isSelected = false;
    })
    this.xtmConFgList[index].isSelected = true;
  }

  @ViewChild(PerfectScrollbarComponent, { static: false }) componentRef?: PerfectScrollbarComponent;
  currentScrollposition = 0;
  scrollOffset = 50;
  public scrollToLeft(): void {
    this.currentScrollposition -= this.scrollOffset;
    if (this.currentScrollposition < 0) {
      this.currentScrollposition = 0;
      this.componentRef.directiveRef.scrollToLeft();
    } else {
      this.componentRef.directiveRef.scrollToX(this.currentScrollposition);
    }
  }

  public scrollToRight(): void {
    let maxScrollCointerWidth = this.componentRef.directiveRef.elementRef.nativeElement.clientWidth - this.scrollOffset;
    this.currentScrollposition += this.scrollOffset;
    if (this.currentScrollposition > maxScrollCointerWidth) {
      this.currentScrollposition = maxScrollCointerWidth;
      this.componentRef.directiveRef.scrollToRight()
    } else {
      this.componentRef.directiveRef.scrollToX(this.currentScrollposition);
    }
  }
}

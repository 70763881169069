import { HostListener, Component, OnInit, TemplateRef, ElementRef, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Router } from "@angular/router";
import {UntypedFormBuilder, UntypedFormArray, UntypedFormControl} from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { timeout } from 'rxjs/operators';
import { StatusesConfig } from 'src/app/configs/statuses.config';
import { ProjectLabelService } from 'src/app/services/project-label.service';
import { TokenService } from 'src/app/services/token.service';
import { JwtauthService } from 'src/app/services/jwtauth.service';
import { HelperService } from 'src/app/services/helper.service';
import { LanguageService } from 'src/app/services/language.service';
import { PoHistoryComponent } from '../../components/po-history/po-history.component';
import { clone } from 'lodash-es';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
projects = [];
  statuses;
  deliveryDates;
  apiURL;
  pageNum;
  pageNumPara;
  projectsPP;
  postData;
  projectCounts;
  projectFilterForm;
  clients;
  owners;
  modalRef: BsModalRef;
  projectsForm;
  selectedProjects;
  selectedModal = null;
  acceptSelected = 'acceptModal';
  rejectSelected = 'rejectModal';
  deliverSelected = 'deliverModal';
  archiveSelected = 'archiveModal';
  transferToOwner;
  userId;
  loading = false;
  noMoreResult = false;
  selectedProjectsFA = new UntypedFormArray([]);
  applyRedBGMClass;
  disSetSortByOptions;
  disSetDeliveryDateOptions;
  vendorStatusesSummaryBox = [];
  pmFileAccMsg = "";
  lang;
  interval;
  statusesConf: StatusesConfig = new StatusesConfig();
  vendorStatuses = this.statusesConf.vendor;
  myVendorId
  constructor(
    private injector: Injector,
    private http: HttpClient,
    private Token: TokenService,
    private Jwtwis: JwtauthService,
    public helperService: HelperService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private modalService: BsModalService,
    public language: LanguageService,
    private helpSer: HelperService,
    public labelSer: ProjectLabelService
    ) {
    helperService.vendorAuthCheck();
    this.apiURL = this.injector.get('API_URL');
    this.myVendorId = this.Jwtwis.venodorId();
    this.lang = language;
    this.projectFilterForm = this.fb.group({
      unclaimed: '',
      archived: '',
      fromDate: '',
      toDate: '',
      text: '',
      deliveryDate: '',
      order_direction: 'desc',
      disSetSortBy: 'orderNo',
      disSetDeliveryDate: '',
      batch_delivery_date: false,
      browserTime: - ( new Date().toLocaleString() ),
      delivered: '',
      headsup: '',
      confirmed: '',
      accepted: '',
      invoiced: '',
      paid: ''
    })

    this.projectFilterForm.get('disSetSortBy').valueChanges.subscribe(val => {
      setTimeout( () => this.loadProjectsFreshly() );

    })
    this.projectFilterForm.get('disSetDeliveryDate').valueChanges.subscribe(val => {
      setTimeout( () => this.loadProjectsFreshly() );
    })

    this.projectFilterForm.get('batch_delivery_date').valueChanges.subscribe(val => {
      setTimeout( () => this.loadProjectsFreshly() );
    })

    this.projectsForm = this.fb.group({
      selectedProjects: new UntypedFormArray([]),
    })

    this.selectedProjectsFA = this.projectsForm.get('selectedProjects') as UntypedFormArray;
  }

  ngOnInit() {
    this.userId = this.Jwtwis.getMyDetails().id;
    this.interval = setInterval(() => this.updateFuncText(this.lang),500);
    this.loadAllDatas();
    this.getInitialOrder();
    this.getLanguages();
    this.getCanVendorUploadInvoice();
    this.getLabels();
    

  }

  loadAllDatas() {
    if ( typeof(this.pageNumPara) == 'undefined' )
      this.pageNum = 1;
    else
      this.pageNum = parseInt(this.pageNumPara);

    this.loadProjectFilterForm();
    this.loadProjectsFreshly();
  }

  doProjects(projectIds, action) {
    let pData = {
      token: this.Token.get(),
      ids:   projectIds
    };
    this.http.post(`${this.apiURL}vendor/project/${action}`, pData).subscribe(
      data => {
        this.getProject(projectIds);
        if ( action == 'archive' ) {
          this.loadProjectsFreshly();
        }
        this.selectedProjectsFA.controls.forEach(selFa => {
          selFa.setValue(false);
        });
      },
      error => this.handleError(error)
    );
  }

  selectedProjectsIds = [];
  openModal(template: TemplateRef<any>, selected) {
    this.selectedModal = selected;
    this.selectedProjects = this.projectsForm.value.selectedProjects
      .map((v, i) => v ? this.projects[i] : null )
      .filter(v => v !== null);

    this.selectedProjectsIds = [];
    this.selectedProjects.forEach(proj => {
      this.selectedProjectsIds.push(proj.id);
    });
    this.modalRef = this.modalService.show(template);
  }

  openProjectPlanModal(template: TemplateRef<any>, projectId) {
    this.projectDetails = null;
    this.getProjectDetails(projectId)
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'modal-lg' })
    );
  }

  closeModal() {
    this.modalRef.hide();
  }

  acceptAll() {
    this.doProjects(this.selectedProjectsIds, 'accept');
    this.closeModal();
  }

  rejectAll(){
    this.doProjects(this.selectedProjectsIds, 'reject');
    this.closeModal();
  }

  deliverAll(){
    this.doProjects(this.selectedProjectsIds, 'deliver');
    this.closeModal();
  }

  archiveAll(){
    this.doProjects(this.selectedProjectsIds, 'archive');
    this.closeModal();
  }

  archiveProject(pId) {
    if ( confirm("Are you sure?") )
      this.doProjects([pId], 'archive');
  }

  setStatus(project, status, pi) {
    if ( ! confirm(`Are you sure you want to set the ${this.getStatusName(status)} status?`) )
      return;
    let postData = {
      token: this.Token.get(),
      projectId: project.id,
      pvId: project.pv_id,
      status: status
    };
    this.http.post(`${this.apiURL}vendor/project/set-status`, postData).subscribe(
      data => {
        this.projects[pi]['vendor_status'] = this.getStatusId(status);
      },
      error => this.handleError(error)
    );
  }

  getStatusId(label) {
    let id = null;
    this.vendorStatuses.forEach(status => {
      if (status.label == label)
        id = status.id
    });
    return id;
  }

  getStatusName(label) {
    let name = null;
    this.vendorStatuses.forEach(status => {
      if (status.label == label)
      name = status.name
    });
    return name;
  }

  getVenStatusName(proj) {
    let name = ''
    this.vendorStatuses.forEach(stat => {
      if (stat.id == proj['vendor_status'])
        name = stat.name
    });
    return name;
  }

  getVenStatusNameSummaryBox(proj) {
    let name = ''
    this.vendorStatusesSummaryBox.forEach(stat => {
      if (stat.id == proj['vendor_status'])
        name = stat.name
    });
    return name;
  }

  getStatuses() {
    this.http.post(`${this.apiURL}project/statuses`, this.Token.getAPITokenData()).subscribe(
      data => {
        this.statuses = data['result'];
      },
      error => this.handleError(error)
    );
  }

  getClients() {
    this.http.post(`${this.apiURL}clients`, this.Token.getAPITokenData()).subscribe(
      data => {
        this.clients = data['result'];


      },
      error => this.handleError(error)
    );
  }

  getOwners() {
    this.http.post(`${this.apiURL}owners`, this.Token.getAPITokenData()).subscribe(
      data => {
        this.owners = data['result'];
      },
      error => this.handleError(error)
    );
  }

  currencies = [];
  getCurrencies() {
    this.http.post(`${this.apiURL}currencies`, this.Token.getAPITokenData()).subscribe(
      data => {
        this.currencies = data['result'];
      },
      error => this.handleError(error)
    );
  }

  getClientName(clientId) {
    let clientName = ''
    if (typeof this.clients == 'undefined')
      return clientName;
    this.clients.forEach(client => {
      if ( client.id == clientId )
        clientName = client.name
    })
    return clientName;
  }

  initialOrderId=0;
  getInitialOrder() {
    this.http.post(`${this.apiURL}initial-order-id`, this.Token.getAPITokenData()).subscribe(
      data => {
        this.initialOrderId = parseInt(data['result']['value']);
      },
      error => this.handleError(error)
    );
  }

  lSFilterDataKey = 'vendorProjectListFilterData';
  resetForm() {
    this.projectFilterForm.reset({'order_direction': 'desc'});
    this.saveProjectFilterForm();
    this.loadProjectsFreshly()
  }

  loadProjectsFreshly(){
    this.projects = [];
    this.pageNum = 1;
    this.loading = false;
    this.totalProjects = 1;
    this.allResultsLoader = false;
    this.saveProjectFilterForm();
    this.getProjects();

  }

  saveProjectFilterForm() {
    this.applyRedBGMClass = '';
    let disSetDeliveryDate = this.projectFilterForm.getRawValue().disSetDeliveryDate;
    if ( disSetDeliveryDate != null && disSetDeliveryDate.includes('hours') ){
      this.applyRedBGMClass = 'applyRedBGM';
    }
    if(this.projectFilterForm.value['order_direction'] == null)
      this.projectFilterForm.patchValue({'order_direction': 'desc'},{emitEvent: true})
    if(this.projectFilterForm.value['disSetSortBy'] == null)
      this.projectFilterForm.patchValue({'disSetSortBy': 'orderNo'},{emitEvent: true})
    localStorage.setItem(this.lSFilterDataKey, JSON.stringify(this.projectFilterForm.getRawValue()));
  }

  loadProjectFilterForm() {
    let formValues = JSON.parse(localStorage.getItem(this.lSFilterDataKey));
    if (!formValues)
      return;
    formValues.fromDate     = this.stringToDate(formValues.fromDate);
    formValues.toDate       = this.stringToDate(formValues.toDate)
    formValues.deliveryDate = this.stringToDate(formValues.deliveryDate);
    formValues.owner        = formValues.owner == null ? '' : formValues.owner;
    formValues.client       = formValues.client == null ? '' : formValues.client;
    formValues.status       = formValues.status == null ? '' : formValues.status;
    formValues.disSetSortBy = formValues.disSetSortBy == null ? 'orderNo' : formValues.disSetSortBy;
    formValues.disSetDeliveryDate = formValues.disSetDeliveryDate == null ? '' : formValues.status;
    this.projectFilterForm.patchValue(formValues, {emitEvent: true});
  }

  stringToDate(dateTime) {
    var d = new Date(dateTime);
    if (
      dateTime == null ||
      isNaN(d.getTime()) ||
      isNaN(d.valueOf())
    )
      return null;
    return  new Date(dateTime);
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    let doc = document.documentElement;
    let top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
    let maxX = (document.body.offsetHeight - window.innerHeight);
    if ((2 * top) >= maxX && this.loadingNxtPage === false) {
      this.loadingNxtPage = true;
      this.pageNum++;
      this.getProjects();
    }
  }

  projectDetails;
  getProjectDetails(id) {
    let postData = this.Token.getAPITokenData();
    postData['projectId'] = id;
    this.http.post(`${this.apiURL}vendor/project/view`, postData).subscribe(
      data => {
        this.projectDetails = data;
      },
      error => this.handleError(error)
    );
  }

  getProjectVenStatus(proj) {
    let status = 1;
    if (proj.pv_heads_up === 1)
      status = 2;
    if (proj.pv_confirm === 1)
      status = 3;
    /*
    if (proj.pv_delivered_at != null && proj.pv_delivered_at.length > 12)
      status = 4;
    */
    if (proj.pv_vendor_deliver_at != null)
      status = 4;
    if (proj.pv_heads_up === 2)
      status = 0;
    if ( proj.invoiced_at != null )
      status = 5;
    if ( proj.payment_at != null )
      status = 6;
    if ( proj.manual_status != null )
      status = this.getStatusId(proj.manual_status);
    return status;
  }

  loadingNxtPage = false;
  getProject(ids) {
    let postData = {
      filters: clone (this.postData['filters'])
    }
    postData['filters']['byIds'] = ids;
    this.http.post(`${this.apiURL}vendor/projects`, postData).subscribe(
      data => {
        data['result']['projects'].forEach(vals => {
          this.projects = this.projects.map(projVals => {
            if (projVals.id === vals.id){
              vals['vendor_status'] = this.getProjectVenStatus(vals);
              return vals;
            }
            else
              return projVals;
          })
        });

      },
      error => this.handleError(error)
    );
  }

  allResultsLoader = false;
  proGerReq;
  getProjects() {
    if (this.projects.length >= this.totalProjects){ //all results loaded
      this.allResultsLoader = true;
      return;
    }
    if ( this.loading ) return;
    this.loading = true;
    if (this.proGerReq) this.proGerReq.unsubscribe();
    this.postData = this.Token.getAPITokenData();
    this.projectsPP = 30;
    this.postData['perPage'] = this.projectsPP;
    this.postData['pageNum'] = this.pageNum;
    this.postData['filters'] = this.projectFilterForm.value;
    this.postData['withReportingData'] = false;
    this.postData['filters']['byIds']  = null;
    if (this.postData['filters'].deliveryDate != '' && this.postData['filters'].deliveryDate != null){
      this.postData['filters'].deliveryDate = this.convertDate(this.postData['filters'].deliveryDate);
    }
    this.postData['filters'].browserTime  = ( new Date().toISOString() );
    this.proGerReq = this.http.post(`${this.apiURL}vendor/projects`, this.postData)
    .pipe(
      timeout(15000)
    )
    .subscribe(
      data => this.handleResponse(data),
      error => {
        this.handleError(error);
        this.loading = false;
      }


    );

  }

  downloadPO(project) {
    if (project.vendor_status < 3) return alert(this.lang.getText('this_link_has_not_been_made_available_by_your_project_manager'));
    let projVenId = project.pv_id;
    this.postData['token'] = this.Token.get();
    this.postData['projVenId'] = projVenId;
    this.http.post(`${this.apiURL}vendor/download-vendor-PO`, this.postData).subscribe(
      data => {
        let blob = new Blob([this.helperService.getFileBuffer(data['file'])], { type: data['type'] });
        this.helperService.downloadFile(blob, 'po.'+data['extension']);
      },
      error => {
        this.handleError(error);
      }
    );
  }

  downloadHandOff(projVenId) {
    let formData = {
      token: this.Token.get(),
      projVenId: projVenId
    }
    this.http.post(`${this.apiURL}vendor/download-hand-off`, formData).subscribe(
      data => {
        let blob = new Blob([this.helperService.getFileBuffer(data['file'])], { type: data['type'] });
        this.helperService.downloadFile(blob, 'Heads-up.'+data['extension']);
      },
      error => {
        this.handleError(error);
      }
    );
  }


  downloadFiles(project) {
    if ( (!project.pv_download_link) ) {
      this.helperService.alertIt(this.pmFileAccMsg);
    } else if ( project.heads_up_files_count == 0 ) {
      this.helperService.alertIt('You don\'t have any files to download.');
    } else {
      window.open(project.heads_up_files, '_blank');
    }
  }

  uploadDeliveryFiles(project) {
    if ( !project.pv_upload_link ) {
      this.helperService.alertIt(this.pmFileAccMsg);
    } else {
      window.open(project.upload_link, '_blank');
    }
  }

  getFinanceReport() {
    this.postData['token'] = this.Token.get();
    this.postData['pageNum'] = 1;
    this.postData['filters'] = this.projectFilterForm.value;
    this.postData['withReportingData'] = false;
    if (this.postData['filters'].deliveryDate != '' && this.postData['filters'].deliveryDate != null){
      this.postData['filters'].deliveryDate = this.convertDate(this.postData['filters'].deliveryDate);
    }
    this.proGerReq = this.http.post(`${this.apiURL}finance-report`, this.postData).subscribe(
      data => {
        let blob = new Blob([this.helperService.getFileBuffer(data['file'])], { type: data['type'] });
        this.helperService.downloadFile(blob, 'finance-report.'+data['extension']);
      },
      error => {
        this.handleError(error);
      }
    );
  }

  getVendorBatches(pIds){
    if ( pIds.length < 1 )
      return;
    let poData = this.Token.getAPITokenData();
    poData['pIds'] = pIds;
    this.http.post(`${this.apiURL}vendor-batches`, this.postData)
      .subscribe(
        data => {
          data['result'].forEach(vals => {
            this.projects = this.projects.map(projVals => {
              if (projVals.id === vals.project_id){
                let warn = false;
                vals.batches.forEach(batch => {
                  if ( warn == false &&
                      this.checkWarning(batch, projVals) ){
                    warn = true;
                  }
                });
                if (warn)
                  projVals.warn = warn;
              }
              return projVals;
            })
          });
        },
        error => {
          this.handleError(error);
        }
    );
  }

  convertDate(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  checkWarning(deliAt, proj){
    let deliveryAt = this.helperService.fromMysqlDateTime(deliAt);
    var tmwDate = new Date();
    tmwDate.setHours(tmwDate.getHours() + 24);
    return (deliveryAt < tmwDate && proj['project_status_id'] < 7) &&
      !(proj['project_status_id'] == 0 && proj['quote_canceled_at'] == null) ;
  }

  totalProjects = 1;
  handleResponse(data) {
    let getVendorBatchesFor = [];
    this.loadingNxtPage = false;
    data['result']['projects'].forEach(vals => {
      this.selectedProjectsFA.push(new UntypedFormControl(false));
      this.projects.push(vals);
      vals['vendor_status'] = this.getProjectVenStatus(vals);
      vals['warn'] = false;
      vals['project_delivery_date'] = '';
      let deliveryAt = this.helperService.fromMysqlDateTime(vals['deliver_date']);
      var tmwDate = new Date();
      tmwDate.setHours(tmwDate.getHours() + 24);
      let ignoreWarning = false;
      if (vals['ignore_vendor_warning'] != null) {
        if (new Date(vals['ignore_vendor_warning']) > new Date()) {
          ignoreWarning = true;
        }
      }
      if ( ignoreWarning == false && vals['recent_deadline'] ) {
        if ( this.helperService.fromMysqlDateTime(vals['recent_deadline']) < tmwDate ) {
          vals['warn'] = true;
        }
      }
      if ( ignoreWarning == false && vals['deliver_date'] != "1000-01-01" && deliveryAt < tmwDate ){
        vals['warn'] = true;
      }
      // this.getInvoice(vals, 'name');
    });
    this.getVendorBatches(getVendorBatchesFor)
    this.projectCounts = data['result']['total'];
    if (this.projects.length < 1) this.noMoreResult = true;
    else this.noMoreResult = false;
    if (this.projects.length >= data['result']['total'])
      this.loading = true;
    else
      this.totalProjects =  data['result']['total'];
      this.loading = false;
      if (this.projects.length >= this.totalProjects){ //all results loaded
        this.allResultsLoader = true;
      }
    }

  handleError(error) {
    this.Jwtwis.handleError(error);
  }

  languages
  getLanguages() {
    let postData = this.Token.getAPITokenData();
    this.http.post(`${this.apiURL}languages`, postData).subscribe(
      data => {
        this.languages = data['result'];
      },
      error => this.handleError(error)
    );
  }

  vendors
  getVendorsList() {
    let postData = this.Token.getAPITokenData();
    this.http.post(`${this.apiURL}vendors`, postData).subscribe(
      data => {
        this.vendors = data['result'];
      },
      error => this.handleError(error)
    );
  }

  getVendorName(vendorId) {
    return '';
  }

  getVendors(projDetails) {
    let vendors = [];
    let vendor = projDetails['vendor'];
    // projDetails.vendors.forEach(vendor => {
      //if (vendor.vendor_id != this.myVendorId) return;
      vendor['trans'] = [];
      vendor['details'] = this.getVendorName(vendor.vendor_id);
      projDetails.vendorTranslations.forEach(tran => {
        if (tran.vendor_id != vendor.vendor_id)
          return;

        let batches = [];
        projDetails.vendorTranBatches.forEach(batch => {
          if ( batch.project_tran_or_service_id == tran.id && batch.service == 0 ) {
            batches.push(batch);
          }
        });
        tran['batches'] = batches;
        vendor['trans'].push(tran);
      });
      vendor['services'] = [];
      projDetails.vendorAdditionalServices.forEach(addSer => {
        if (addSer.vendor_id != vendor.vendor_id)
          return;

        let batches = [];
        projDetails.vendorAddServiceBatches.forEach(batch => {
          if ( batch.project_tran_or_service_id == addSer.id && batch.service == 1 ) {
            batches.push(batch);
          }
        });
        addSer['batches'] = batches;
        vendor['services'].push(addSer);
      });
      vendors.push(vendor)
    // });
    return vendors;
  }

  hideAllReports = true;
  allProjectAmt = null;
  decimalRound = 2;
  getAllProjectAmt(currId) {
    this.hideAllReports = true;
    this.allProjectAmt = {
      invoice: 0,
      vendorPO: 0,
      invoiceCurr: [],
      invoiceAllSame: null,
      invoiceCurrCode: '',
      vendorPOCurr: [],
      vendorPOAllSame: null,
      vendorPOCurrCode: '',
      cvSameCurr: false,
    };
    this.projects.forEach(project => {
      if (project.currency_id == currId){
        this.allProjectAmt.invoice += this.getInvoiceAmt(project);
        this.allProjectAmt.vendorPO += this.getVendorsAmt(project);
        this.allProjectAmt.invoiceCurr.push(project.currency_id);
        this.allProjectAmt.vendorPOCurr.push(this.getVendorsCurr(project));
      }
    });
    this.allProjectAmt.invoiceAllSame = this.helperService.allArrValuesEqual(this.allProjectAmt.invoiceCurr);
    this.allProjectAmt.vendorPOAllSame = this.helperService.allArrValuesEqual(this.allProjectAmt.vendorPOCurr);
    if ( this.allProjectAmt.invoiceAllSame &&
          this.allProjectAmt.vendorPOAllSame &&
          ( this.allProjectAmt.invoiceCurr[0] == this.allProjectAmt.vendorPOCurr[0] ||
            this.allProjectAmt.vendorPOCurr[0] == 0 )
      ){
      this.allProjectAmt.cvSameCurr = true;
    }
    if ( this.allProjectAmt.invoiceAllSame ) {
      this.allProjectAmt.invoiceCurrCode = this.getCurrencyCode(this.allProjectAmt.invoiceCurr[0]);
    }
    if ( this.allProjectAmt.vendorPOAllSame ) {
      this.allProjectAmt.vendorPOCurrCode = this.getCurrencyCode(this.allProjectAmt.vendorPOCurr[0]);
    }
    if (this.allProjectAmt.invoiceCurrCode == this.allProjectAmt.vendorPOCurrCode) {
      this.allProjectAmt.marginCurrCode = this.allProjectAmt.vendorPOCurrCode;
    }
    this.allProjectAmt.invoice = this.helperService.roundOfToFraction(this.allProjectAmt.invoice, this.decimalRound);
    this.allProjectAmt.vendorPO = this.helperService.roundOfToFraction(this.allProjectAmt.vendorPO, this.decimalRound)
    this.allProjectAmt.margin = this.helperService.roundOfToFraction((this.allProjectAmt.invoice - this.allProjectAmt.vendorPO), 2);
    this.allProjectAmt.percentage = this.allProjectAmt.vendorPO == 0 ? '100' : this.helperService.roundOfToFraction(( (this.allProjectAmt.margin / this.allProjectAmt.invoice) * 100), this.decimalRound);
    this.allProjectAmt.percentage = this.allProjectAmt.invoice == 0 ? 0 : this.allProjectAmt.percentage;
    return this.allProjectAmt;
  }

  getInvoiceAmt(proj) {
    return proj.client_amount == null ? 0 : proj.client_amount;
  }

  getVendorsAmt(proj) {
    let amt = 0;
    proj.vendors.forEach(ven => {
      amt += ven.amount;
    });
    return this.helperService.roundOfToFraction(amt, this.decimalRound);
  }

  getVendorsCurr(proj) {
    let curr = 0;
    curr = proj.currency_id;
    proj.vendors.forEach(ven => {
      if (ven.currency_id != 0)
        curr = ven.currency_id;
    });
    return curr;
  }

  getMarginAmt(proj) {
    return this.helperService.roundOfToFraction(this.getInvoiceAmt(proj) - this.getVendorsAmt(proj), this.decimalRound);
  }

  getVendorsCurrencyCode(proj){
    let currId = 0;
    currId = proj.currency_id;
    proj.vendors.forEach(ven => {
      if (ven.currency_id != 0)
        currId = ven.currency_id;
    });
    return this.getCurrencyCode(currId);
  }

  getCurrencyCode(currencyId){
    let code = '';
    this.currencies.forEach(currency => {
      if (currency.id == currencyId){
        code = currency.name;
      }
    });
    return code;
  }

  cancelledProject(proj) {
    return ( proj.quote_canceled_at !== null || proj.pv_confirm === 0 );
  }

  updateFuncText(lang){
    if(lang != undefined){
      this.disSetSortByOptions = [
        { id: 'orderNo',  name: this.lang.getText('project_number')},
        { id: 'deadline', name: this.lang.getText('deadline2')},
      ];
      this.disSetDeliveryDateOptions = [
        { id: '', name: this.lang.getText('all2')},
        { id: '12hours', name: this.lang.getText('next_12_hours')},
        { id: '24hours', name: this.lang.getText('next_24_hours')},
        { id: '48hours', name: this.lang.getText('next_48_hours')},
      ];

      this.vendorStatusesSummaryBox = [
        {id: 0, name: this.lang.getText('heads_up_rejected')},
        {id: 1, name: this.lang.getText('heads_up_received')},
        {id: 2, name: this.lang.getText('project_accepted')},
        {id: 3, name: this.lang.getText('heads_up_confirmed')},
        {id: 4, name: this.lang.getText('project_delivered')},
      ];
      this.pmFileAccMsg = this.lang.getText('this_link_has_not_been_made_available_by_your_project_manager');
      clearInterval(this.interval);
    }
  }

  openReactivateModal(template: TemplateRef<any>){
    this.selectedProjects = this.projectsForm.value.selectedProjects
      .map((v, i) => v ? this.projects[i] : null )
      .filter(v => v !== null);
    this.modalRef = this.modalService.show(template);
  }

  reactivateAll(){
    let postData:any = this.Token.getAPITokenData();
    postData.ids = this.selectedProjects.map(proj => proj.id);
    this.selectedProjects.map(proj => this.removeProjectFromList(proj.id));
    this.http.post(`${this.apiURL}vendor/project/reactivate`, postData).subscribe(
      data => this.loadProjectsFreshly(),
      error => this.handleError(error)
    );
    this.closeModal();
  }
  
  removeProjectFromList(id){
    this.projects = this.projects.filter((vals, key) => {
      if ( vals.id === id ){
        this.selectedProjectsFA.removeAt(key);
        return false;
      }
      return true;
    })
    this.selectedProjects = this.selectedProjects.filter((vals) => {
      return vals.id !== id;
    })
  }
  
  changeOrder(){
    this.projectFilterForm.value['order_direction'] == 'desc'||this.projectFilterForm.value['order_direction'] == null ? this.projectFilterForm.patchValue({'order_direction': 'asc'},{emitEvent: true}) : this.projectFilterForm.patchValue({'order_direction': 'desc'},{emitEvent: true});
    setTimeout( () => this.loadProjectsFreshly() );
  }
  
  canVendorUploadInvoice: Boolean;
  getCanVendorUploadInvoice() {
    this.http.post(`${this.apiURL}vendor/can-upload-invoice`, this.Token.getAPITokenData()).subscribe(
      data => {
        if ( data ) {
          this.canVendorUploadInvoice = true;
        }
      }
    );
  }

  // noInvoiceMsg = 'You haven\'t uploaded an invoice yet.';
  // getInvoice(project, method) {
  //   let formData:FormData = new FormData();
  //   formData.append('token', this.Token.get());
  //   formData.append('projectId', project.id);
  //   formData.append('projectVendorId', project.pv_id);
  //   this.http.post(`${this.apiURL}vendor/invoice/get`, formData)
  //     .subscribe(
  //       data => {
  //         let pdf = data['pdf']
  //         if (method == 'name') {
  //           project['invoice']['name'] = pdf ? pdf['name'] : '';
  //           project.invoiced_at = pdf ? 'now' : '';
  //           return;
  //         }
  //         if ( pdf == null && (method == 'view' || method == 'down') ) {
  //           this.helperService.alertIt(this.noInvoiceMsg);
  //           return;
  //         }
  //         if ( pdf == null ) {
  //           return;
  //         }
  //         let b64PDF = pdf['b64'];
  //         if (method == 'view') {
  //           this.helpSer.viewBase64File(b64PDF);
  //         } else {
  //           this.helpSer.downloadBase64File(b64PDF, 'invoice.pdf');
  //         }
  //       }
  //     );
  // }

  getPO(project, method) {
    let formData:FormData = new FormData();
    formData.append('token', this.Token.get());
    formData.append('projectId', project.id);
    formData.append('projectVendorId', project.pv_id);
    this.http.post(`${this.apiURL}vendor/po/get`, formData)
      .subscribe(
        data => {
          let b64PDF = data['pdf']
          if ( b64PDF == null ) {
            /*
            if ( project.pv_confirm == null ) {
              this.helperService.alertIt('PO is not created yet.');
              return;
            }
            */
            this.helperService.alertIt('PO is not created yet.');
            return;
          }
          this.processPO(data, method);
        }
      );
  }

  processPO(data, method) {
    let b64PDF = data['pdf'];
    if (method == 'view') {
      this.helpSer.viewBase64File(b64PDF);
    } else if (method == 'down') {
      this.helpSer.downloadBase64File(b64PDF, 'Purchase order.pdf');
    }
  }

  // deleteInvoice(project) {
  //   if ( project.invoiced_at == null ) {
  //     this.helperService.alertIt(this.noInvoiceMsg);
  //     return;
  //   }
  //   if ( !confirm('Are you sure you want to delete this invoice?') ) {
  //     return;
  //   }
  //   let formData:FormData = new FormData();
  //   formData.append('token', this.Token.get());
  //   formData.append('projectId', project.id);
  //   formData.append('projectVendorId', project.pv_id);
  //   this.http.post(`${this.apiURL}vendor/invoice/delete`, formData)
  //     .subscribe(
  //       data => {
  //         project.invoiced_at = null;
  //         this.getInvoice(project, 'name');
  //       }
  //     );
  // }

  // uploadInvoice(event) {
  //   let files = event.target.files;
  //   if ( files.length < 1 ) return;
  //   let file: File = files[0];
  //   (this.invoiceUploadIpt.nativeElement as HTMLInputElement).value = "";
  //   let formData:FormData = new FormData();
  //   formData.append('token', this.Token.get());
  //   formData.append('pdf', file);
  //   formData.append('projectId', this.selectedInvoiceProj.id);
  //   formData.append('projectVendorId', this.selectedInvoiceProj.pv_id);
  //   this.http.post(`${this.apiURL}vendor/invoice/upload`, formData)
  //     .subscribe(
  //       data => {
  //         if ( data == 'Done' ) {
  //           this.getInvoice(this.selectedInvoiceProj, 'name');
  //         }
  //       }
  //     );
  // }

  // @ViewChild('invoiceUploadIpt', {static: true}) invoiceUploadIpt: ElementRef<HTMLElement>;
  // selectedInvoiceProj = null;
  // triggerInvoiceUpload(project) {
  //   this.selectedInvoiceProj = project;
  //   let el: HTMLElement = this.invoiceUploadIpt.nativeElement;
  //   el.click();
  // }

  labels;
  getLabels() {
    this.labelSer.get();
    this.labelSer.currentLabels.subscribe(labels => {
      this.labels = labels;
    })
  }

  @ViewChild('changeLabelTemp') changeLabelTemp: TemplateRef<any>;
  selectedProjectToSetLabel;
  openChangeLabelModal(project) {  
    this.selectedProjectToSetLabel = project;
    this.modalRef = this.modalService.show(this.changeLabelTemp, {class: "modal-sm change-label-temp"});
  }

  changeLabel(labelId) {
    let projectId = this.selectedProjectToSetLabel.id;
    let postData = {
      token: this.Token.get(),
      projectId: projectId,
      projectVendorId: this.selectedProjectToSetLabel.pv_id,
      labelId: labelId
    };
    this.http.post(`${this.apiURL}user-project-labels/set-vendor`, postData).subscribe(
      data => {
        this.modalRef.hide();
        this.getProject([projectId]);
    },
      error => this.handleError(error)
    );
  }

  ignoreWarn;
  openIgnoreWarningModal(template: TemplateRef<any>, id, pi) {
    this.ignoreWarn = {id: id, pi: pi};
    this.modalRef = this.modalService.show(template);
  }

  ignoreWarning(id, pi) {
    let poData = {
      token: this.Token.get(),
      id: id,
    };
    this.http.post(`${this.apiURL}vendor/project/ignore-vendor-warning`, poData)
      .subscribe(
        data => {
            this.projects[pi]['warn'] = false;
        },
        error => {
          this.handleError(error);
        }
    );
    this.closeModal();
  }

  @ViewChild('poHistoryCom') poHistoryCom: PoHistoryComponent;
  openPOHistoryModal(project) {
    project.orderNo = project.id+this.initialOrderId
    this.poHistoryCom.openModal(project);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from './token.service';
import { environment as env } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectLabelService {
	private apiURL = env['API_URL'];
  private labelsSource = new BehaviorSubject<Array<any>>([]);
  public currentLabels = this.labelsSource.asObservable();
  labels = [];

  constructor(
    private token: TokenService,
    private http: HttpClient
  ) {
		//
  }

  get() {
    let postData = {
      token: this.token.get()
    }
    this.http.post(`${this.apiURL}user-project-labels/get`, postData).subscribe(
      data => {
        this.labels = data['result']
        this.labelsSource.next(this.labels)
      }
    );
  }

  getColor(lab) {
    let color = null;
    if (lab == null) {return color; }
    this.labels.forEach(label => {
      if (label.id == lab.id) {
        color = label.color;
      }
    });
    return color;
  }

  getName(lab) {
    let name = '';
    if (lab == null) {return name; }
    this.labels.forEach(label => {
      if (label.id == lab.id) {
        name = label.name;
      }
    });
    return name;
  }
}

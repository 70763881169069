import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { HelperService } from 'src/app/services/helper.service';
import { JwtauthService } from 'src/app/services/jwtauth.service';
import { LanguageService } from 'src/app/services/language.service';
import { ProjectService } from 'src/app/services/project.service';
import { TokenService } from 'src/app/services/token.service';
import { environment as env } from 'src/environments/environment';
import { PaginationComponent } from 'src/app/modules/shared/components/pagination/pagination.component';

@Component({
  selector: 'app-delivery-batches',
  templateUrl: './delivery-batches.component.html',
  styleUrls: ['./delivery-batches.component.scss']
})
export class DeliveryBatchesComponent implements OnInit {
  @Input() project_id;
  @Input() project;
  @Input() projectEditForm: UntypedFormGroup;
  @Input() deliveryBatchesFA: UntypedFormArray;
  @Input() projectEditDis;
  @Input() checkXTMData;
  @Input() bsConfig;
  @Input() hours;
  @Input() minutes;
  @Input() languages;
  @Input() projectClaimed;
  @Input() checkClaimed;
  @Input() allowLinkingBathch;
  @Input() orderId;
  apiURL = env['API_URL'];
  
  constructor(
    private token: TokenService,
    private Jwtwis: JwtauthService,
    public helperSer: HelperService,
    public lang: LanguageService,
    private fb: UntypedFormBuilder,
    private projectSer: ProjectService,
    private modalService: BsModalService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    // 
  }

  ngAfterViewInit() {
    this.getBatches(1,'');
  }

  page_no = 1;
  total_page = 0;
  translations;
  batchReq;
  per_page = 5;
  current_page = 1;
  deliveryBatches;
  @ViewChild(PaginationComponent) pagination_com: PaginationComponent;
  getBatches(page_no = 1, action: String) {
    if ( ! action  ) {
      action = '';
    }
    if (action == 'prev') {
      this.page_no--;
    } else if(action == 'next') {
      this.page_no++;
    }

    let formData = {
      token: this.token.get(),
      projectId: this.project_id,
      page: page_no,
      per_page: this.per_page
    }
    this.batchReq = this.http.post(`${this.apiURL}project/batch-delivery-dates`, formData).subscribe(
      data => {
        this.pagination_com.generate(data['totalQuoteTotalBatchDeliveryPages'], page_no)
        this.deliveryBatchesFA.clear();
        this.deliveryBatches = data['deliveryBatches']
        this.deliveryBatches.forEach(batch => {          
          this.current_page = page_no;
          this.total_page = data['totalQuoteTotalBatchDeliveryPages'];
          let relatedType = null;
          let relatedTypeId = null;
          let allDateTime = null;
          if (batch.batch_link && batch.related_type_detail) {
            relatedType = batch.batch_link.service_type;
            relatedTypeId = batch.batch_link.trans_service_id;
          }
          allDateTime = this.prepareDeliveryBatchFormData(batch, relatedType, relatedTypeId);

          this.initDeliveryBatchesForm(allDateTime)
        });
      },
      error => {
        this.handleError(error)
      }
    );
  }

  initDeliveryBatchesForm(dateTime) {
    this.deliveryBatchesFA.push(
      this.fb.group({
        delete: '',
        requested_at: this.getDateTimeForm(dateTime.requested_at),
        description: this.fb.control(dateTime.description),
        uuid: this.fb.control(dateTime.uuid),
        service_type_to: this.fb.control(dateTime.service_type_to),
        service_type_id: this.fb.control(dateTime.service_type_id),
        related_description: this.fb.control(dateTime.related_description),
      })
    );
  }

  addDeliveryBatches() {
    const dateTime = {date: 0, hour: 10, minute: 0};
    const allDateTime = {
      requested_at: dateTime,
      description: '',
      id: null,
      service_type_to: '',
      service_type_id: '',
      related_description: ''
    };
    this.initDeliveryBatchesForm(allDateTime);
  }

  prepareDeliveryBatchFormData(batch, relatedType, relatedTypeId) {
    return {
      requested_at: this.helperSer.splitDateAndTime(batch.requested_at),
      description: batch.description,
      uuid: batch.uuid,
      service_type_to: relatedType,
      service_type_id: relatedTypeId,
      related_description: this.get_related_description(batch)
    };
  }

  getDateTimeForm(dateT) {
    return this.fb.group({
      date: new UntypedFormControl({value: dateT.date, disabled: this.projectEditDis}),
      hour: new UntypedFormControl({value: dateT.hour, disabled: this.projectEditDis}),
      minute: new UntypedFormControl({value: dateT.minute, disabled: this.projectEditDis}),
    })
  }

  get_related_description(batch){
    if ( batch.batch_link == null || batch.related_type_detail == null ) {
      return '';
    }
    let relatedType = batch.batch_link.service_type;
    var str=this.lang.getText('this_batch_delivery_date_has_been_linked_to');
    if ( relatedType == 'translation' ) {
      let tr = this.getTranslation(batch.related_type_detail);
      str += tr['source_lang']['name'] + ' - ' + tr['target_lang']['name'];
      if (tr.comment) {
        str += ' (' +  tr.comment + ')'
      }
    } else if ( relatedType == 'service') {
      str += batch.related_type_detail.comment;
    }
    return str;
  }

  getTranslation(detail) {
    let source, target = null;
    let transl;
    transl = detail;
    source = this.getLanguageById(detail['source_lang_id']);
    target = this.getLanguageById(detail['target_lang_id']);
    transl['source_lang'] = source;
    transl['target_lang'] = target;
    return transl;
  }

  //remove duplicate later
  getLanguageById(langId) {
    let language = [];
    this.languages.forEach(lang => {
      if ( lang.id == langId )
        language = lang;
    });
    return language;
  }

  removeLinked(batch) {
    if ( ! confirm(this.lang.getText('are_you_sure')) ) {
      return;
    }
    batch.patchValue({
      service_type_to: null,
      service_type_id: null,
      description: ''
    });
  }

  selectedBatch
  selectedServiceTypeModel = "";
  modalRef;
  openLinkToPopup(template: TemplateRef<any>, batch){
    this.selectedBatch = batch;
    let valus = batch.getRawValue();
    if ( valus.service_type_to ) {
      this.selectedServiceTypeModel = valus.service_type_to+'::'+valus.service_type_id;
    } else {
      this.resetBatchLinkField();
    }
    if ( this.project.translations.length < 1 ) {
      this.get_all_translations();
    }
    this.modalRef = this.modalService.show(template, { class: 'modal-xl new-redesign-style'});
  }

  linkBatch(){
    const val = this.selectedServiceTypeModel.split("::");
    let related_type = val[0];
    let related_type_id = val[1];
    let related_type_detail = null;
    let related_type_details = [];
    if ( related_type == 'service' ) {
      related_type_details = this.project.additionalServices;
    }
    if ( related_type == 'translation' ) {
      related_type_details = this.project.translations;
    }
    related_type_details.forEach(detail => {
      if ( detail.id == related_type_id ) {
        related_type_detail = detail;
      }
    });
    let batch = {
      batch_link: { service_type:  related_type },
      related_type_detail: related_type_detail
    }
    this.selectedBatch.patchValue({
      service_type_to: related_type,
      service_type_id: related_type_id,
      related_description: this.get_related_description(batch)
    });
    this.closeModal();
  }

  deletedeliverydate(batchDelivery, index) {
    if ( !confirm(this.lang.getText('are_you_sure_you_want_to_delete_this_item')) ) {
      return;
    }
    // batchDelivery.patchValue({delete: 'yes'});
    let deliveryBatchesDeleted = this.projectEditForm.get('deliveryBatchesDeleted') as UntypedFormArray;
    deliveryBatchesDeleted.push(this.fb.control(batchDelivery.get('uuid').value));
    this.deliveryBatchesFA.removeAt(index);
  }

  closeModal() {
    this.modalRef.hide();
  }

  get_all_translations() {
    this.projectSer.get_translations(this.project_id, 1, 100).subscribe(
      data => {
        this.project.translations = data['translations'];
      },
      error => {
        this.handleError(error)
      }
    );
  }

  resetBatchLinkField(){
    this.selectedServiceTypeModel = "";
  }
  
  calendarInfo={
    openList: false,
    calendar: 0
  }
  openCalendarsList(){
    this.calendarInfo.openList ? this.calendarInfo.openList=false : this.calendarInfo.openList=true;
  }
  
  openCalendarsConfirmPopup(calendar, template: TemplateRef<any>){
    this.calendarInfo.calendar = calendar;
    this.calendarInfo.openList = false;
    this.modalRef = this.modalService.show(
      template,
      { class: 'modal-xl', backdrop: 'static' }
    );
  }

  addEventToCalendar(){
    let date = this.projectEditForm.get('project_delivery').value;
    let minute = date.minute;
    let calendarType: string;
    if(this.calendarInfo.calendar == 2){
      calendarType = "html";
    }
    else{
      calendarType = "plaintext";
    }
    if( minute < 10)
      minute = '0' + minute;
    let event  = {
        "@context":"https://schema.org",
        "@type":"Event",
        "name":this.project.name,
        "description": this.generateDescriptionString(calendarType),
        "startDate":date.date,
        "endDate":date.date,
        "startTime": ("0"+date.hour).slice(-2) + ':' + ("0"+date.minute).slice(-2),
        "endTime": ("0"+date.hour).slice(-2) + ':' + ("0"+minute).slice(-2),
        "location":this.orderId
    };
    //alert(date.hour + ':' + minute);
    if(this.calendarInfo.calendar == 2)
      this.generateGoogle(event);
    else
      this.generateIcal(event);
    this.closeModal();
  }

  generateIcal(data) {
    let now:any = new Date();
    now = now.toISOString().replace(/\-/g, '').replace(/\:/g, '').replace(/\..../g, '');
    const formattedDate = this.generateTimeToCalendar(data, 'clean', 'ical');
    let timeslot = '';
    if (formattedDate['allday']) {
      timeslot = ';VALUE=DATE';
    }
    let ics_lines = [
      "BEGIN:VCALENDAR",
      "VERSION:2.0",
      "CALSCALE:GREGORIAN",
      "BEGIN:VEVENT",
      "DTSTAMP:" + formattedDate['start'],
      "DTSTART" + timeslot + ":" + formattedDate['start'],
      "DTEND" + timeslot + ":" + formattedDate['end'],
      "DESCRIPTION:" + data['description'].replace(/\n/g, '\\n'),
      "SUMMARY:" + data['name'],
      "LOCATION:" + data['location'],
      "STATUS:CONFIRMED",
      "LAST-MODIFIED:" + now,
      "SEQUENCE:0",
      "END:VEVENT",
      "END:VCALENDAR"
    ];
    let dlurl = 'data:text/calendar;base64,'+btoa(ics_lines.join('\r\n').replace(/[\u00A0-\u2666]/g, function(c) { return '&#' + c.charCodeAt(0) + ';'; })); // includes a fix to not throw an error with non-Latin1 characters. However, still needs improvement, since it shows up encoded in the iCal file.
    try {
        let save = document.createElement('a');
        save.href = dlurl;
        save.target = '_blank';
        save.download = data['iCalFileName'] || 'event-to-save-in-my-calendar';
        let evt = new MouseEvent('click', {
          'view': window,
          'bubbles': true,
          'cancelable': false
        });
        save.dispatchEvent(evt);
        (window.URL).revokeObjectURL(save.href);
    } catch(e) {
      console.log(e);
    }
  }
  
  datetoArray(date) {
    if ( typeof date != 'string' ) {
      date = this.helperSer.formateDPDate(date);
    }
    return date.split('/');
  }

  generateTimeToCalendar(data, style = 'delimiters', targetCal = 'general') {
    let startDate:any = this.datetoArray(data['startDate']);
    let endDate:any = this.datetoArray(data['endDate']);
    let start:any = '';
    let end:any = '';
    let allday = false;
    if (data['startTime'] != null && data['endTime'] != null) {
      // Adjust for timezone, if set (see https://en.wikipedia.org/wiki/List_of_tz_database_time_zones for either the TZ name or the offset)
      if (data['timeZoneOffset'] != null && data['timeZoneOffset'] != '') {
        // if we have a timezone offset given, consider it
        start = new Date( startDate[2] + '-' + startDate[1] + '-' + startDate[0] + 'T' + data['startTime'] + ':00.000' + data['timeZoneOffset'] );
        end = new Date( endDate[2] + '-' + endDate[1] + '-' + endDate[0] + 'T' + data['endTime'] + ':00.000' + data['timeZoneOffset'] );
        start = start.toISOString().replace('.000', '');
        end = end.toISOString().replace('.000', '');
        if (style == 'clean') {
          start = start.replace(/\-/g, '').replace(/\:/g, '');
          end = end.replace(/\-/g, '').replace(/\:/g, '');
        }
      } else {
        // if there is no offset, we prepare the time, assuming it is UTC formatted
        start = new Date( startDate[2] + '-' + startDate[1] + '-' + startDate[0] + 'T' + data['startTime'] + ':00.000+00:00' );
        end = new Date( endDate[2] + '-' + endDate[1] + '-' + endDate[0] + 'T' + data['endTime'] + ':00.000+00:00' );
        if (data['timeZone'] != null && data['timeZone'] != '') {
          // if a timezone is given, we adjust dynamically with the modern toLocaleString function
          let utcDate = new Date(start.toLocaleString('en-US', { timeZone: "UTC" }));
          if (data['timeZone'] == 'currentBrowser') {
            data['timeZone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
          }
          let tzDate = new Date(start.toLocaleString('en-US', { timeZone: data['timeZone'] }));
          let offset = utcDate.getTime() - tzDate.getTime();
          start.setTime( start.getTime() + offset );
          end.setTime( end.getTime() + offset );
        }
        start = start.toISOString().replace('.000', '').replace('Z','');
        end = end.toISOString().replace('.000', '').replace('Z','');
        if (style == 'clean') {
          start = start.replace(/\-/g, '').replace(/\:/g, '');
          end = end.replace(/\-/g, '').replace(/\:/g, '');
        }
      }
    } else { // would be an allday event then
      allday = true;
      start = new Date( startDate[2], startDate[0] - 1, startDate[1]);
      start.setDate(start.getDate() + 1); // increment the day by 1
      let breakStart = start.toISOString().split('T');
      end = new Date( endDate[2], endDate[0] - 1, endDate[1]);
      if (targetCal == 'google' || targetCal == 'microsoft' || targetCal == 'ical') {
        end.setDate(end.getDate() + 2); // increment the day by 2 for Google Calendar, iCal and Outlook
      } else {
        end.setDate(end.getDate() + 1); // otherwise by 1
      }
      let breakEnd = end.toISOString().split('T');
      if (style == 'clean') {
        breakStart[0] = breakStart[0].replace(/\-/g, '');
        breakEnd[0] = breakEnd[0].replace(/\-/g, '');
      }
      start = breakStart[0];
      end = breakEnd[0];
    }
    let returnObject = {'start':start, 'end':end, 'allday':allday};
    return returnObject;
  }

  generateDescriptionString(strType){
    let space = ' ';
    let projectDeliveryHeading = this.lang.getText('project_delivery_date');
    let sapDeliveryHeading = this.lang.getText('sap_delivery_dates');
    let manualDeliveryHeading = this.lang.getText('manual_delivery_dates');
    if ( strType == 'html' ) {
      space = '\xa0';
      projectDeliveryHeading = '<b>' + this.lang.getText('project_delivery_date') + '</b>';
      sapDeliveryHeading = '<b>' + this.lang.getText('sap_delivery_dates') + '</b>';
      manualDeliveryHeading = '<b>' + this.lang.getText('manual_delivery_dates') +'</b>';
    }
    let str = projectDeliveryHeading+' ' + space+space+space+space+space+space+space+space+space+space +this.helperSer.formatDateTime(this.project.project_delivery) + '\n';
  
    if(this.helperSer.nonEmptyArray(this.project.deliveryBatchesAPI)){
      str += ' \n \n' + '  ' +sapDeliveryHeading+' \n';
      this.project.deliveryBatchesAPI.forEach((batch,i)=>{
        str += space+space+space+space+space+space + this.lang.getText('delivery_date_batch') + ' ' + (i+1) + space+space+space+space+space+space+space+space+space+space ;
        str += this.helperSer.formatDateTime(batch.requested_at) + space+space+space+space+space+space+space+space+space+space + '\n';
      });
    }

    if(this.helperSer.nonEmptyArray(this.deliveryBatches)){
      str += ' \n \n' + '  ' +manualDeliveryHeading+'\n';
      this.deliveryBatches.forEach((batch,i)=>{
        str += space+space+space+space+space+space + this.lang.getText('delivery_date_batch') + '  ' + (i+1) +space+space+space+space+space+space+space+space+space+space;
        let description = this.get_related_description(batch);
        str += this.helperSer.formatDateTime(batch.requested_at) + space+space+space+space+space+space+space+space+space+space +description +'\n';
      });
    }
    return str;
  }

  generateGoogle(data) {
    // base url
    let url = 'https://calendar.google.com/calendar/render?action=TEMPLATE';
    // generate and add date
    let formattedDate = this.generateTimeToCalendar(data, 'clean', 'google');
    url += '&dates=' + formattedDate['start'] + '%2F' + formattedDate['end'];
    // add details (if set)
    if (data['description'] != null && data['description'] != '') {
      url += '&details=' + encodeURIComponent(data['description']);
    }
    if (data['location'] != null && data['location'] != '') {
      url += '&location=' + encodeURIComponent(data['location']);
    }
    if (data['name'] != null && data['name'] != '') {
      url += '&text=' + encodeURIComponent(data['name']);
    }
    window.open(url, '_blank').focus();
  }

  handleError(error) {
    this.Jwtwis.handleError(error);
  }
}

import { Injectable } from '@angular/core';

@Injectable()
export class DataService {
  public descriptions = {
    'sup-admin'   : 'This user gets access to everything including admin features',
    'pm'          : 'This user gets access to all projects that have been claimed by or assigned to this person',
    'acc-manager' : 'This user gets access to all projects of a specific client',
  }
  constructor() { }
}

import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LanguageService } from '../../../services/language.service';

@Component({
  selector: 'app-vendor-cat-percentage-change',
  templateUrl: './vendor-cat-percentage-change.component.html',
  styleUrls: ['./vendor-cat-percentage-change.component.scss']
})
export class VendorCatPercentageChangeComponent {
  lang;
  constructor(
    private modalService: BsModalService,
    private fb: UntypedFormBuilder,
    public language: LanguageService
  ) {
    this.lang = language;
  }

  @ViewChild('vendorCATChangModal') vendorCATChangModal;
  modalRef: BsModalRef;
  translation: UntypedFormGroup;
  wordMatches;
  openModel(tran, wordMatches) {
    this.translation = tran;
    this.wordMatches = wordMatches;
    this.modalRef = this.modalService.show(
      this.vendorCATChangModal,
      Object.assign({}, { class: 'modal-xl' })
    );
  }

  closeModal() {
    this.modalRef.hide();
  }

  @Output() translationOut: EventEmitter<UntypedFormGroup> = new EventEmitter();
  applyPercentage() {
    this.lockPercentage();
    this.translationOut.emit(this.translation);
    this.closeModal();
  }

  lockPercentage() {
    this.lockPercentageGroup('A')
    this.lockPercentageGroup('B')
  }

  lockPercentageGroup(group) {
    (this.translation.get('catAnalysisGro' + group) as UntypedFormArray).controls.forEach(fg => {
      fg.patchValue({lockPercentage: true})
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { JwtauthService } from 'src/app/services/jwtauth.service';
import { LanguageService } from 'src/app/services/language.service';
import { AccountService } from '../../services/account.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  constructor(
    private accountSer: AccountService,
    private jwtSer: JwtauthService,
    public lang: LanguageService
    ) {
    this.detailsTFA = this.jwtSer.myDetails.mfa;
    this.get2FaDetails();
  }
  
  ngOnInit() {
    
  }

  detailsTFA = null;
  get2FaDetails() {
    this.accountSer.get2FA().subscribe(
      data => {
        this.detailsTFA = data;
      }
    );
  }

  authCodeURL;
  generate2Fa(enable) {
    let conformText= enable?'do_you_want_to_setup_two_factor_authentication': 'do_you_want_to_disable_two_factor_authentication';
    if ( ! confirm(this.lang.getText(conformText)) ) {
      return;
    }
    this.accountSer.generate2FA().subscribe(
      data => {
        if ( ! enable ) {
          this.get2FaDetails();
          return;
        }
        this.verificationCode = '';
        this.verifyCodeInval = false;
        this.authCodeURL = 'data:image/svg+xml;base64,'+data['qr-base64'];
      }
    );
  }

  verificationCode;
  verifyCodeInval: boolean = false;
  submitVerificationCode() {
    this.verifyCodeInval = false;
    this.accountSer.verify2FA(this.verificationCode).subscribe(
      data => {
        this.authCodeURL = null;
        this.get2FaDetails();
      }, 
      error => {
        this.verifyCodeInval = true;
      }
    );
  }
}

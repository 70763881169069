import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import {UntypedFormBuilder, UntypedFormGroup, FormArray, UntypedFormControl} from '@angular/forms';

import { TokenService } from '../services/token.service';
import { JwtauthService } from '../services/jwtauth.service';
import { DataService } from '../services/data.service';
import { LanguageService } from '../services/language.service';

@Component({
  selector: 'app-user-edit-page',
  templateUrl: './user-edit-page.component.html',
  styleUrls: ['./user-edit-page.component.scss']
})
export class UserEditPageComponent implements OnInit {
  userEditForm: UntypedFormGroup;
  private apiURL = '';
  user;
  userId;
  response;
  formData;
  roles;
  errors;
  descriptions;
  lang;

  constructor(
    private injector: Injector,
    private http: HttpClient,
    private route: ActivatedRoute,
    private Token: TokenService,
    private Jwtwis: JwtauthService,
    private dataSer: DataService,
    private router: Router,
    private fb: UntypedFormBuilder,
    public language: LanguageService
    ) {
    this.descriptions = dataSer.descriptions;
    this.apiURL = this.injector.get('API_URL');
    this.lang = language;
    this.initForm();
  }

  initForm() {

    this.route.params.subscribe(params => {
      this.userId =  params['id']
    });

    this.http.post(`${this.apiURL}user/roles`, this.Token.getAPITokenData()).subscribe(
      data => {
        this.roles = data['result'];
        this.getUserDetails();
      },
      error => this.handleError(error)
    );
  }

  ngOnInit() {

  }

  getUserDetails(){
    this.formData = {
      userId: this.userId,
      token: this.Token.get(),
    };
    this.http.post(`${this.apiURL}user/view`, this.formData).subscribe(
      data => {
        this.user = data['result'];
        this.createUserEditForm()
      },
      error => this.handleError(error)
    );
  }

  createUserEditForm(){
    const controls = this.roles.map(role => new UntypedFormControl( this.user.roles.includes(role.id)));
    let roleId = '';
    this.roles.map(role => {
      if ( this.user.roles.includes(role.id) )
        roleId = role.id;
    });
    let mfa = this.user.mfa;
    this.userEditForm = this.fb.group({
      name: this.user.name,
      email: this.user.email,
      //roles: new FormArray(controls),
      mfa: [{ value: mfa, disabled: ! mfa }],
      role_id: roleId,
      send_reset_email: false,
    });
  }

  onSubmitUserEditForm() {
    this.formData = this.userEditForm.value;
    this.formData['token'] = this.Token.get()
    this.formData['roles'] = [ this.userEditForm.value.role_id ];
    // console.log(this.formData);
    this.http.post(`${this.apiURL}user/update/${this.userId}`, this.formData).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    );
  }

  handleResponse(data) {
    this.router.navigateByUrl('/users-list');
  }

  handleError(error) {
    this.Jwtwis.handleError(error);
    this.errors = error.error.error;
  }
}

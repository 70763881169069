import { Component } from '@angular/core';
import {Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

import { environment as env } from 'src/environments/environment';
import { JwtauthService } from 'src/app/services/jwtauth.service';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  loginForm: UntypedFormGroup;
  public error = null;
  public group = 'user';
  publicURL = env['PUBLIC_URL'];
  sessionExp: boolean = false;

  constructor(
    private router: Router,
    private jwtAuth: JwtauthService,
    private Token: TokenService,
    private fb: UntypedFormBuilder,
  ) {
    if ( (this.router.url).includes('vendor') ) {
      this.group = 'vendor';
    }
    this.initForm();
    if ( localStorage.getItem('session_expired') == 'yes' ) {
      this.sessionExp = true;
      localStorage.removeItem('session_expired');
    }
   }

  initForm() {
    this.loginForm = this.fb.group({
      email: '',
      password: '',
      code: '',
      group: this.group,
    })
  }

  onSubmitLoginForm() {
    this.jwtAuth.login(this.loginForm.value).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    );
  }

  authCode: boolean = false;
  handleResponse(data) {
    this.error = '';
    if ( data['result'] == 'code' ) {
      this.authCode = true;
      return;
    }
    if ( data['result'] == 'invalid code' ) {
      this.error = 'Please check your code.';
      return;
    }
    this.Token.handle(data.access_token);
    localStorage.setItem('userGroup', this.group);
    this.jwtAuth.updateMyDetails().subscribe(
      data => {
        let redirectUri = '/project';
        if (this.group == 'vendor') {
          redirectUri = 'vendor/dashboard';
          data['result']['vendor'] = true;
          if ( data['result']['privacy_policy'] != 'agreed' ) {
            redirectUri = 'vendor/privacy-policy';
          }
        }
        this.jwtAuth.setMyDetails(data['result']);
        this.jwtAuth.get_external_storage_url();
        this.router.navigateByUrl(redirectUri);
      },
      error => this.handleError(error)
    );
  }

  handleError(error) {
    this.jwtAuth.handleError(error);
    this.error = error.error.result;
  }
}

<div *ngIf="labels" class="label-container">
  <div>
    <h5>{{lang.getText('my_project_labels')}}</h5>
  </div>
  <div *ngIf="labelFg.disabled">
    <ul class="status-picker-colors-view grid_ui_Color_label row">
      <li
        class="mb-2 col-xl-6 col-lg-6 col-md-6"
        *ngFor="let label of labelFa.controls; let li=index"
      >
        <div class="position_hide_div">
          <div [hidden]="label.get('delete').value == 'yes'" class="status-picker-color-input-wrapper">
            <div class="color-picker-box">
              <span
                class="color-option-box color-box-0"
              ></span>
              <div class="label-background-color" [style.backgroundColor]="label.get('color').value">{{label.get('name').value}}</div>
            </div>
          </div>
        </div>
      </li>
      <li class="col-xl-12 col-lg-12 col-md-12">
        <button
          class="btn btn-primary"
          (click)="editLabel()"
          >
          {{lang.getText('edit_label')}}
        </button>
      </li>
    </ul>
  </div>
  <form *ngIf="labelFg.enabled" [formGroup]="labelFg">
    <div>
      <div formArrayName="labels">
        <div>
          <div class="label-picker-content">
            <ul class="label-picker-colors-view grid_ui_Color_label">
              <li
                class="mb-2"
                *ngFor="let label of getLabelsFa().controls; let li=index"
              >
                <div [formGroupName]="li" class="position_hide_div"  [ngClass]="labelFg.enabled ? 'bg-box' :'full-bg-box'">
                  <div [hidden]="label.get('delete').value == 'yes'" class="label-picker-color-input-wrapper">
                    <div class="color-picker-box">
                      <input 
                        #colorPicker
                        type="color"
                        value="#2b439d" list
                        formControlName="color"
                        class="color-option-box color-box-0"
                        />
                      <datalist id="reds">
                        <option>#cc0000</option>
                        <option>#990000</option>
                        <option>#660000</option>
                        <option>#330000</option>
                        <option>red</option> 
                        <option>#F00</option>
                      </datalist>
                    </div>
                    <input
                      #labelText
                      class="editable-input-wrapper color-option-input hover-class"
                      type="text"
                      formControlName="name"
                    />
                    <span (click)="deleteLabel(li)" class="fa fa-close label-delete"></span>
                  </div>
                </div>
              </li>

              <li class="mt-3">
                <button
                  [ngClass]="labelFg.enabled ? 'button_display' :'button_display_none'"
                  type="button"
                  class="btn btn-primary"
                  (click)="addLabel('', '', '#F1F1F1')"
                  [disabled]="labelFa.length > 9"
                  >
                  {{lang.getText('add_new_label')}}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <br />
      <div>
        <button
          *ngIf="labelFg.enabled"
          type="button"
          class="btn btn-primary"
          (click)="saveLabels()"
          >
          {{lang.getText('apply')}}
        </button>
      </div>
    </div>
  </form>
</div>


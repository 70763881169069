<div class="innerproject_details" [ngClass]="{reportingPage: reportingPage}">

  <form [formGroup]='projectFilterForm'>
  <div class="row action-layout filterFormUi">
    <div class="col ">
        <div
          class="redesign-expand-heading d-flex justify-content-between align-items-center"
        >
          <span class="text">{{ lang.getText("filters") }}</span>
        </div>
        <div class="redesign-box-shadow">
          <div #filterForm class="filter-layout filter_main ps-0 pe-0" [hidden]="is_filter_hidden()">
            <div
              class="form-row filter-sec-i fs-small d-flex fc first-row justify-content-between"
            >
              <div class="form-group col-auto d-flex checkbox_align">
                <div class="form-check form-switch">
                  <input formControlName="unclaimed" class="form-check-input" type="checkbox" role="switch" />
                  <label class="form-check-label" for="autoSizingCheck">{{lang.getText('unclaimed')}}</label>
                </div>
              </div>
           <div class="form-group col-auto d-flex checkbox_align">
              <div class="form-check form-switch">
                <input formControlName="archived" class="form-check-input" type="checkbox" role="switch" />
                <label class="form-check-label" for="autoSizingCheck">{{lang.getText('archived')}}</label>
              </div>
            </div>
            <div class="form-group col-auto">
              <select formControlName="order_creation_date" class="form-control order_creation_datewidth">
                <option value="">{{lang.getText('date_criteria')}}</option>
                <option value="order_creation_date">{{lang.getText('order_creation_date')}}</option>
                <option value="order_delivery_date">{{lang.getText('order_delivery_date')}}</option>
              </select>
            </div>
            <div class="form-group col-auto row dateInput_section">
              <label for="filter-date" class="col-auto col-form-label col-form-label-sm">{{lang.getText('from')}}</label>
              <span class="new_calnder"><input formControlName="fromDate" class="smallin" type="text"  bsDatepicker [bsConfig]="{ dateInputFormat: 'DD/MM/Y', customTodayClass: 'todaydate', rangeInputFormat: 'DD/MM/YYY' }"  />
               <span class="calendar_img"></span> </span>
            </div>
            <div class="form-group col-auto row dateInput_section">
              <label for="filter-date" class="col-auto col-form-label col-form-label-sm">{{lang.getText('to')}}</label>
              <span class="new_calnder"><input formControlName="toDate" class="smallin" type="text"  bsDatepicker [bsConfig]="{ dateInputFormat: 'DD/MM/Y', customTodayClass: 'todaydate', rangeInputFormat: 'DD/MM/YYY' }"  />  
              <span class="calendar_img"></span> </span>  
              </div>
            </div>
          
          <div class="form-row filter-sec-ii d-flex justify-content-between" [ngClass]="{'form-row': !reportingPage}">
            <div class="form-group col-md-4">
              <!-- <select formControlName="owner" class="form-control inputbox_width">
                <option value="">{{lang.getText('owner')}}</option>
                <option *ngFor="let owner of owners" [value]="owner.id">{{owner.name}}</option>
              </select> -->
              <!-- <ng-select class="form-control client inputbox_width w-100 p-0 h-auto"
                [items]="owners"
                [clearable]="false"
                bindLabel="name" 
                bindValue="id"
                notFoundText="{{ 'no_data_found' | translate }}"
                placeholder="{{ 'select' | translate }} {{ 'owner' | translate }}" 
                formControlName="owner">
              </ng-select> -->

              <ng-select class="form-control client inputbox_width w-100 p-0 h-auto"
                  [clearable]="false"
                  bindLabel="name" 
                  bindValue="id"
                  notFoundText="{{ 'no_data_found' | translate }}"
                  placeholder="{{ 'select' | translate }} {{ 'owner' | translate }}" 
                  formControlName="owner">
                  <ng-option [value]="''"> {{lang.getText('owner')}} </ng-option>
                  <ng-option *ngFor="let owner of owners" [value]="owner.id">
                    {{ owner.name }}
                  </ng-option>
            </ng-select>

           
            </div>
            <div class="form-group" [ngClass]="{'form-group col-md-4': !reportingPage}">
              <!-- <select formControlName="client" class="form-control client inputbox_width">
                <option value="">{{lang.getText('client')}}</option>
                <option *ngFor="let client of clients" [value]="client.id">{{client.name}}</option>
              </select> -->
              <ng-select class="form-control client inputbox_width w-100 p-0 h-auto"
                [clearable]="false"
                bindLabel="name" 
                bindValue="id"
                notFoundText="{{ 'no_data_found' | translate }}"
                placeholder="{{ 'select' | translate }} {{ 'client' | translate }}" 
                formControlName="client"
                >
                  <ng-option [value]="''"> {{lang.getText('client')}} </ng-option>
                  <ng-option *ngFor="let client of clients" [value]="client.id">
                    {{ client.name }}
                  </ng-option>
            </ng-select>

              <!-- <ng-select class="form-control client inputbox_width w-100 p-0 h-auto"
                [items]="clients"
                [clearable]="false"
                bindLabel="name" 
                bindValue="id"
                notFoundText="{{ 'no_data_found' | translate }}"
                placeholder="{{ 'select' | translate }} {{ 'client' | translate }}" 
                formControlName="client">
              </ng-select> -->
            </div>
            <div class="form-group col-md-4">
              <ng-select
                  [formControlName]="'status'"
                  class="form-control w-100 p-0 h-auto"
                  placeholder="{{'select' | translate}} {{'status' | translate}}" 
                  [clearable]="false"
                  (change)="unclaimedChange($event)">
                  <ng-option [value]="''">{{ lang.getText('status') }}</ng-option>

                  <ng-container *ngIf="customizeStatusEnabled">
                    <ng-option *ngFor="let status of customizeStatuses" [value]="status.id">
                      {{ lang.getProjectStatus(status.code) }}
                    </ng-option>
                    <ng-option disabled>{{ lang.getText('legacy_statuses') }}</ng-option>
                    <ng-option *ngFor="let status of statuses" [value]="'l_' + status.id">
                      {{ lang.getProjectStatus(status.code) }}
                    </ng-option>
                  </ng-container>

                  <ng-container *ngIf="!customizeStatusEnabled">
                    <ng-option *ngFor="let status of statuses" [value]="'l_' + status.id">
                      <!-- {{ status.name }}  -->
                      {{ lang.getProjectStatus(status.code) }}
                    </ng-option>
                  </ng-container>
                </ng-select>

              
              
              <!-- <select formControlName="status" class="form-control" (change)="unclaimedChange($event)">
                <option value="">{{lang.getText('status')}}</option>
                <option value="unclaimed">{{lang.getText('unclaimed')}}</option>
                <ng-container *ngIf="customizeStatusEnabled">
                    <option  *ngFor="let status of customizeStatuses" [value]="status.id" >
                      {{status.name}}
                    </option>
                  <optgroup label="{{lang.getText('legacy_statuses')}}">
                    <option  *ngFor="let status of statuses" [value]="'l_'+status.id" >{{lang.getProjectStatus(status.code)}}</option>  
                  </optgroup>  
                </ng-container>
                <ng-container *ngIf="!customizeStatusEnabled">
                  <option  *ngFor="let status of statuses" [value]="'l_'+status.id" >
                    {{status.name}}
                  </option>
                </ng-container>
              </select> -->
            </div>
            </div>
        <div class="form-row filter-sec-ii d-flex fc">
          <div class="form-group col-md-4 row" style="padding-right: 1rem;">
            <!-- <select formControlName="search_type" class="form-control inputbox_width" style = "text-transform:capitalize;">
              <option value="partial_match">{{lang.getText('search_for_partial_match')}}</option>
              <option value="exact_match">{{lang.getText('search_for_exact_match')}}</option>
            </select> -->
            <ng-select class="form-control w-100 p-0 h-auto"
              [ngStyle]="{'text-transform': 'capitalize'}"
              [items]="searchTypeOptions"
              [clearable]="false"
              bindLabel="name" 
              bindValue="id"
              notFoundText="{{ 'no_data_found' | translate }}"
              placeholder="{{ 'select' | translate }} {{ 'type' | translate }}" 
              formControlName="search_type">
            </ng-select>
          </div>
          <!-- <div class="form-group col-auto row">
            &nbsp;&nbsp;&nbsp;
          </div> -->
          <div class="form-group col-md-4 row">
            <div class="input-group mb-2 search_outer inputbox_width">
              <span class="search_custom fa fa-search"></span>
              <input formControlName="text" type="text" class="form-control in_search special_outer_input" [placeholder]="lang.getText('type_a_partial_search_word_number')">
            </div>
          </div>
          <div class="form-group col-md-4 row button_align_end">
            
            <button (click)="loadProjectsFreshly(true)" type="submit" class="btn btn-primary">{{lang.getText('apply')}}</button>
            <button type="button" (click)="openFilterFavouritesModal()" class="heart_button"><span class="heart_img"></span></button>
            <button (click)="resetForm()" type="reset" class="delete_button"><span class="delete_img"></span></button>
              </div>
            </div>
          </div>
          <div class="redesign-down-arrow-sec"  [ngClass]="[is_filter_hidden() ? 'hide' : 'redesign-show']">
            <a class="redesign-down-icon" (click)="toogle_filter()"></a>
          </div>
       </div>
    </div>

      <div
        [hidden]="reportingPage"
        class="col-lg-4 col-md-12 col-xs-12 col-sm-12"
      >
        <div
          class="redesign-expand-heading d-flex justify-content-between align-items-center"
        >
          <span class="text"> {{ lang.getText("actions") }} </span><br />
        </div>
        <div class="redesign-box-shadow actionMain">
          <div class="border-layout">
            <div [style.min-height.px]="getActionFormHeight()" class="actoins_outer button_group row no-margin" [hidden]="is_action_hidden()">
              <div class="form-group col-12 select-all-sec">
                <div class="form-check form-switch">
                  <input formControlName="isMasterSel" name="select-all" (change)="selectAllProjects($event)" class="form-check-input" type="checkbox" role="switch" />
                  <label class="form-check-label" for="select-all">{{lang.getText('select_all_displayed_projects')}}</label>
                </div>
              </div>
              <div class="control_wrap">
                <!-- <select [(ngModel)]="actionSelected" [ngModelOptions]="{standalone: true}" class="form-control change_date_input" style = "text-transform:capitalize;">
                <option value="">{{lang.getText('action')}}</option>
                <option value="claimModal">{{lang.getText('claim')}}</option>
                <option value="transferSModal">{{lang.getText('transfer')}}</option>
                <option value="archiveModal">{{lang.getText('archive')}}</option>
                <option value="reactivateModal">{{lang.getText('reactivate')}}</option>
                <option value="addUpModal">{{lang.getText('add_up')}}</option>
                </select> -->
                <ng-select class="form-control change_date_input w-100 p-0 h-auto"
                  [items]="actionOptions"
                  [clearable]="false"
                  bindLabel="name" 
                  bindValue="id"
                  notFoundText="{{ 'no_data_found' | translate }}"
                  placeholder="{{ 'select' | translate }} {{ 'type' | translate }}" 
                  [(ngModel)]="actionSelected" [ngModelOptions]="{standalone: true}">
                </ng-select>
                <button (click)="onClickApply(projectModal, reactivateModal, addUpModal)" class="btn btn-primary">{{lang.getText('apply')}}</button>
              </div>
              <div class="control_wrap">
                <!-- <select formControlName="changeStatus" class="form-control change_date_input">
                  <option value="">{{lang.getText('status')}}</option>
                  <option  *ngFor="let status of customizeStatuses" [value]="status.id" >{{status.name}}</option>
                </select> -->
                <ng-select
                [formControlName]="'changeStatus'"
                class="form-control change_date_input w-100 p-0 h-auto"
                placeholder="{{ 'select' | translate }} {{ 'action' | translate }}" 
                [clearable]="false"
                bindLabel="name" 
                bindValue="id"
                notFoundText="{{ 'no_data_found' | translate }}"
                >
                <ng-option [value]="''">{{ lang.getText('status') }}</ng-option>
                  <ng-option *ngFor="let status of customizeStatuses" [value]="status.id">
                    {{ lang.getProjectStatus(status.code) }}
                  </ng-option>
              </ng-select>
                <!-- <ng-select class="form-control change_date_input w-100 p-0 h-auto"
                  [items]="customizeStatuses"
                  [clearable]="false"
                  bindLabel="name" 
                  bindValue="id"
                  notFoundText="{{ 'no_data_found' | translate }}"
                  placeholder="{{ 'select' | translate }} {{ 'action' | translate }}" 
                  formControlName="changeStatus">
                </ng-select> -->
                <button (click)="openModal(projectModal, statusSelected)" class="btn btn-primary">{{lang.getText('apply')}}</button>
              </div>
              <div class="col-12 info-message action_error_msg">
                <p>{{lang.getText('selected_action_or_status_applied_message')}}</p>
              </div>
            </div>
          </div>
          <div
            class="redesign-down-arrow-sec"
            (click)="toogle_action()"
            [ngClass]="[is_action_hidden() ? 'hide' : 'redesign-show']"
          >
            <a class="redesign-down-icon"></a>
          </div>
        </div>
      </div>
    </div>
    <div class="display-settings row">
      <div class="col">
        <span class="text"> {{ lang.getText("display_settings") }} </span>
      </div>
    </div>
    <div class="display-settings row action-layout filterFormUi">
      <div class="col me-0">
        <div class="border-layout filter_main">
          <div class="display-settings-inner form-row">
            <div class="form-group row inline col-auto sort-by common_space_section">
              <label class="col-sm-12 col-form-label col-form-label-sm">{{lang.getText('sort_by')}}</label><br>
              <div class="col-sm-9 col-md-10 col-lg-9 col-xl-10 inline pe-0">
                <select formControlName="disSetSortBy" class="form-control sort-by-dd-fc">
                  <option *ngFor="let option of disSetSortByOptions" [value]="option.id">{{option.name}}</option>
                </select>
              </div>
              <div class="col-auto inline vertical_top ps-0">
                <input type="hidden" formControlName="order_direction"/>
                <button class="sort-btn" (click)="changeOrder()" [ngClass]="{'desc' : projectFilterForm.value['order_direction']=='desc'}"></button>
              </div>
            </div>
            <div class="form-group row inline col-auto delivery-window common_space_section">
              <label class="col-sm-12 col-form-label col-form-label-sm">{{lang.getText('delivery_dates')}}:</label><br>
              <div class="col-sm-10 inline">
                <select formControlName="disSetDeliveryDate" class="form-control sort-by-dd-fc">
                  <option *ngFor="let option of disSetDeliveryDateOptions" [value]="option.id">{{option.name}}</option>
                </select>
              </div>
            </div>
            <div class="form-group row inline col-auto unclaimed-count common_space_section one-line">
              <label class="col-form-label-sm">{{ lang.getText("unclaimed") }}:
                <span class="red">{{ unclaimedProjectsCount }}</span></label>
            </div>
            <div class="form-group row inline col-auto current-project-count common_space_section one-line">
              <label class="col-form-label-sm">{{lang.getText('current_view')}}: 
                <span class="red">{{projectCounts}}</span>
              </label>
            </div>
            <div class="form-group row inline col-auto include-batch common_space_section one-line">
              <label class="inline col-form-label-sm" for="autoSizingCheck">
                <div class="form-check form-switch">
                  <input formControlName="batch_delivery_date" class="form-check-input" type="checkbox" role="switch" />
                  <label _ngcontent-kic-c32="" for="batch_delivery_date" class="form-check-label">{{lang.getText('include_batch_delivery_date')}}</label>
                </div>
                &nbsp;
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <!-- for web above 1024 size -->
  <div
    *ngIf="projects !== null && projects.length"
    [hidden]="reportingPage && hideAllReports"
    class="for_web_projects_list project_main">
    <div class="container-fluid project-list-layout details-menu-hader">
      <div class="row header header_main for_web_only">
        <div class="col-lg-3 col-md-4 project_info_head text-center">{{lang.getText('project_summary')}}</div>
        <div class="col-lg-8 col-md-6  status_actions_head text-center">{{lang.getText('project_details')}}</div>
        <div class="col-lg-1 col-md-2  box_head text-center">{{lang.getText('actions')}}</div>
      </div>
    </div>
    <div [formGroup]="projectsForm" class="inner_project_list">
      
      <div formArrayName="projects" class="container-fluid content project_single" *ngFor="let project of projects;let pi = index;">
        <div class="row special_row" [formGroupName]="pi" [ngClass]="labelSer.getColor(project.label) === null ? 'default-background' : 'colorbackground'" [style.background]="labelSer.getColor(project.label)">
          <div class="col-lg-3 col-md-4 col-sm-12 project {{applyRedBGMClass}}">
            <div class="row no-padding main_info_head">
              <div class="col-md-7 col-sm-12">
                <div class="col-sm-12 hide_mobile  project_info_head text-center">
                  <span> {{lang.getText('project_information')}} </span>
                </div>
                <div *ngIf="project.quote_canceled_at == null"><a routerLink='/project/edit/{{project.id}}' class="single_order">{{lang.getText('order_number')}} {{project.id+initialOrderId}}</a></div>
                <div *ngIf="project.quote_canceled_at != null"><a class="single_order">{{lang.getText('order_number')}} {{project.id+initialOrderId}}</a></div>

                <div class="item" [hidden]="hideDisplayLabel('client_name')">
                  <span class="title">{{lang.getText('client_name')}}</span> {{getClientName(project.client_id)}}
                </div>
                <div class="item" [hidden]="hideDisplayLabel('owner')">
                    <span class="title">{{lang.getText('owner2')}} </span>
                    <span *ngIf="project.owner">{{project.owner.name}}</span>
                </div>
                <div class="item delivery_date_time" [hidden]="hideDisplayLabel('project_delivery_date')">
                  <span class="title">{{lang.getText('delivery_date_time')}}</span> {{helperService.formatDateTime(project.max_delivery_date)}}
                  <ng-container *ngFor="let batch of project['batches']; let bi = index;">
                    <p *ngIf="bi < 10" style="margin-left: 10px;">
                      <span class="batch-title" *ngIf="!bi && batch.api_data">{{lang.getText('sap_delivery_dates')}}</span>
                      <span class="batch-title" *ngIf="project['batches'][bi-1] && batch.api_data != project['batches'][bi-1]['api_data']">Manual Delivery Dates</span>
                      {{lang.getText('batch')}} {{bi+1}} - {{helperService.formatDateTime(batch.requested_at)}}
                    </p>
                  </ng-container>
                  <p style="margin-left: 10px;" *ngIf="project['batches'] && project['batches'].length > 10"><a routerLink='/project/edit/{{project.id}}'>{{ lang.getText('show_all_delivery_dates_time') }}</a></p>
                </div>
                <div [hidden]="reportingPage" class="plan-finance">
                  <span [hidden]="hideDisplayLabel('planning_link')" class="plan" (click)="open_project_detail_popup(project.id, 'delivery_schedule')">{{lang.getText('planning')}}</span>
                  <span [hidden]="hideDisplayLabel('finance_link')"
                    (click)="open_project_detail_popup(project.id, 'finance')"
                    class="finance">{{lang.getText('finance')}}</span>
                  <span class="relate-project medium" (click)="openRelatedProjectModal(project.id)" [hidden]="hideDisplayLabel('related_projects')">{{ lang.getText('related_projects') }}</span>
                </div>
                <div class="warning_sec">
                  <div [hidden]="hideDisplayLabel('deadline')">
                    <a [hidden]="!project.warn" class="btn btn-danger btn-sm text-white" (click)="openIgnoreWarningModal(ingoreWarningModal, project.id, pi)">{{lang.getText('deadline')}}</a>
                  </div>
                  <a *ngIf="project.delta_count" type="button" class="btn btn-info btn-sm text-white">
                    {{lang.getText('delta')}} <span class="badge badge-light bg-secondary">{{project.delta_count}}</span>
                  </a>
                </div>
                <div class="warning_sec">
                  <span *ngIf="project.trados_file_status != null">
                    <span class="badge badge-secondary">
                      {{lang.getText(project.trados_file_status?.status)}}
                        <span class="badge badge-light" *ngIf="project.trados_file_status.count > 0">
                          {{project.trados_file_status?.count}}
                        </span>
                    </span>
                  </span>  
                </div>
              </div>
              <div class="col-md-5 col-sm-12">
                <div
                  [hidden]="reportingPage"
                  *ngIf="(!project.is_project_owner || project.project_status_id == 0) && project.quote_canceled_at == null" class="claim_btn">
                  <a class="btn-primary btn" (click)="claimThisProject(project.id)">{{lang.getText('claim')}}</a>
                </div>
                <div *ngIf="project.quote_canceled_at != null" class="claim_btn">
                  <a class="btn btn-danger">{{lang.getText('cancelled')}}</a>
                </div>
                <div
                  class="claim_btn duplicate_btn"
                  [hidden]="hideDisplayLabel('duplicate_button')">
                  <a
                    (click)="duplicateProject(project.id)"
                    class="btn btn-primary">
                    {{lang.getText('duplicate')}}
                  </a>
                </div>
                <div
                  class="claim_btn catgorize-btn" [hidden]="hideDisplayLabel('categorize_button')">
                  <a (click)="openChangeLabelModal(project)" class="btn btn-primary btn-sm">{{lang.getText('categorize')}}</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8 col-md-6 col-sm-12 status_actions_content main_status_actions">
              <div class="col-sm-12 no-padding">
                <div class="hide_mobile status_actions_head text-center">
                  {{lang.getText('status')}}
                </div>
                <div [hidden]="hideDisplayLabel('status_bar')" class="row outer_progress">
                  <ul id="progress" *ngIf="project.project_status_id != 0 && project.quote_rejected_at == null && project.quote_canceled_at == null">
                    <ng-container *ngIf="project.customize_project_statuses">
                      <li (click)="setStatus(project, status.customize_statuses)" *ngFor="let status of project.customize_project_statuses;let si = index" class="{{status.customize_statuses.code}}" [className]="project.project_status_id >= status.customize_statuses.id ? 'active' : 'inactive'" >
                        {{status.customize_statuses.name}}
                      </li>
                    </ng-container>
                    <ng-container *ngIf="!project.customize_project_statuses">
                      <li (click)="setStatus(project, status)" *ngFor="let status of statuses;let si = index" class="{{status.code}}" [className]="project.project_status_id >= status.id ? 'active' : 'inactive'" >
                        {{lang.getProjectStatus(status.code)}}
                      </li>
                    </ng-container>
                  </ul>
                  <ul id="progress" *ngIf="project.quote_canceled_at != null">
                    <ng-container *ngIf="project.customize_project_statuses">
                      <li *ngFor="let status of project.customize_project_statuses;let si = index" class="{{status.customize_statuses.code}}" [className]="project.quote_canceled_at == null ? 'inactive' : ' '">
                        {{status.customize_statuses.name}}
                      </li>
                    </ng-container>
                    <ng-container *ngIf="!project.customize_project_statuses">
                      <li *ngFor="let status of statuses;let si = index" class="{{status.code}}" [className]="project.quote_canceled_at == null ? 'inactive' : ' '">
                        {{lang.getProjectStatus(status.code)}}
                      </li>
                    </ng-container>
                  </ul>
                  <div class="for_unclaimed" *ngIf="project.project_status_id == 0 && project.quote_canceled_at == null">
                    <p>{{lang.getText('project_unclaimed')}}</p>
                  </div>
                  <div class="for_unclaimed" *ngIf="project.quote_rejected_at != null  ">
                    <p>{{lang.getText('quote_rejected')}}</p>
                  </div>
                </div>
              </div>
              <div class="col-12 pe-md-4">
                <div class="row">
                <div class="col-10 p-0">
                  <div class="item div-display" [hidden]="hideDisplayLabel('description')">
                    <span class="title">{{lang.getText('project_name')}}</span>&nbsp;{{project.name}}
                  </div>
                  <div class="item div-display" [hidden]="hideDisplayLabel('description')">
                    <span class="title">{{lang.getText('description_with_colon')}}</span>&nbsp;{{project.discription}}
                  </div>
                  <div class="item div-display" [hidden]="hideDisplayLabel('comments')">
                    <span class="title">{{lang.getText('comments')}}</span>&nbsp;{{getComments(project.comments_details)}}
                  </div>
                  
                  <div *ngIf="project.word_counts" class="item div-display" [hidden]="hideDisplayLabel('total_wordcount')">
                    <span class="title">{{lang.getText('total_wordcount')}}:</span>&nbsp;{{project.word_counts?.total_word_count}}
                  </div>
                  <div *ngIf="!project.word_counts" class="sk_bg small" [hidden]="hideDisplayLabel('total_wordcount')"></div>
                  <div *ngIf="project.word_counts" class="item div-display" [hidden]="hideDisplayLabel('weighed_wordcount')">
                    <span class="title">{{lang.getText('weighed_wordcount')}}:</span>&nbsp;{{project.word_counts?.total_weighed_count}}
                  </div>
                  <div *ngIf="!project.word_counts" class="sk_bg small" [hidden]="hideDisplayLabel('weighed_wordcount')"></div>
                  <div *ngIf="project.label" class="item div-display" [hidden]="hideDisplayLabel('category')">
                    <span class="title">{{lang.getText('category')}}:</span>&nbsp;{{labelSer.getName(project.label)}}
                  </div>
      
                  <ng-container *ngIf="sapEnabled">
                  <div *ngIf="project.SAP_VALUES" class="sap-fields">
                    <p *ngFor="let field of sapFields" class="field item">
                      <ng-container *ngIf="displaySAPField(project, field)">
                        <span class="title">{{field.label}}: </span>
                        <span *ngIf="field.dataType == 'stri' || field.dataType == 'intg'" [class]="'content '+field.type">&nbsp;{{project.SAP_VALUES[field.valKey]}}</span>
                        <span *ngIf="field.dataType == 'dati'" [class]="'content '+field.type">&nbsp;{{getDateTime(project.SAP_VALUES[field.valKey])}}</span>
                        <span *ngIf="field.dataType == 'html'">&nbsp;</span>
                        <span *ngIf="field.dataType == 'html'" [innerHTML]=project.SAP_VALUES[field.valKey] [class]="'content '+field.type"></span>
                        <span *ngIf="field.dataType == 'json'" [class]="'content '+field.type">&nbsp;{{helperService.json_array_join_string(project.SAP_VALUES[field.valKey], ', ')}}</span>
                        <span *ngIf="field.dataType == 'jsbr'" [class]="'content '+field.type" [innerHtml]="'&nbsp;' + helperService.json_array_join_string(project.SAP_VALUES[field.valKey], '<br />&nbsp;')"></span>
                      </ng-container>
                    </p>
                  </div>
                  </ng-container>
                </div>
                <div class="col-2 p-0 cat-this-project text-end">
                  <button [hidden]="hideDisplayLabel('change_button')"
                      (click)="openEditCommentDescriptionModal(project,confirmClaimProject)"
                      class="btn btn-sm btn-primary change-button"
                      disabled={{project.quote_canceled_at}}>{{lang.getText('change')}}</button>
                  </div>
                </div>
              </div>
          </div>
          <div class="col-lg-1 col-md-2 col-sm-12 box_check_main text-center">
            <div class="main_box_head">
              <div [hidden]="reportingPage" class="col-sm-12 hide_mobile box_head text-center">{{lang.getText('actions')}}</div>
                 <input (change)="isAllSelected()" formControlName="selected" [hidden]="reportingPage" [value]="project.id" type="checkbox" class="form-check-input action_checkbox">
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div [hidden]="allResultsLoader" class="loader container-fluid">
    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
  </div>

  <div *ngIf="noMoreResult" class="container no_results">
    <div class="row text-center">
      <div class="col-md-12">
          <p><span class="search_custom_noresults"><em  class="fa fa-search"></em></span></p>
          <br>
          <h5>{{lang.getText('no_matching_search_results')}}</h5>
          <p>{{lang.getText('try_again_using_different_search_terms')}}</p>
    </div>
    </div>
  </div>
</div>



<ng-template #projectModal>
  <div *ngIf="this.selectedProjects.length == 0" class="modal-header">
    <h4 class="modal-title float-start">{{lang.getText('no_projects_selected_nothing_to')}}   {{lang.getText(selectedModal.replace('Modal', '').replace('S',''))}} </h4>
    <button type="button" class="close float-end" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div *ngIf="this.selectedProjects.length > 0" class="modal-header" [ngClass]="{'without_border' : selectedModal==statusSelected}">
    <h4 *ngIf="selectedModal!=statusSelected" class="modal-title float-start">{{lang.getText('are_you_sure_you_want_to')}}  {{selectedModal.replace('Modal', '').replace('S','')}} {{lang.getText('these_projects')}}</h4>
    <h4 *ngIf="selectedModal==statusSelected" class="modal-title float-start">{{lang.getText('are_you_sure_you_want_to_change_the_status_of_these_projects')}}</h4>
    <button type="button" class="close float-end" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-header font-weight-bold" *ngIf="selectedModal==statusSelected">
      {{lang.getText('long_change_status_popup_text')}}
  </div>
  <div *ngIf="this.selectedProjects.length == 0" class="modal-body">
      <div class="col-sm-12 text-center">
        <strong></strong>
      </div>

  </div>
  <div *ngIf="this.selectedProjects.length > 0" class="modal-body">
    <div class="col-sm-12 text-center font-weight-bold">
      {{lang.getText('project_information')}}
    </div>
    <div *ngFor="let project of selectedProjects;" class="row project">
      <div class="col-sm-12"><a  class="title"><strong>{{lang.getText('order_number')}}</strong> {{project.id+initialOrderId}}</a></div>
      <div class="col-sm-12">
        <p><span class="title">{{lang.getText('project_name')}}</span> {{project.name}}</p>
      </div>
      <div class="col-sm-12">
        <p>
          <span class="title">{{lang.getText('owner2')}}</span>
          <span *ngIf="project.owner"> {{project.owner.name}}</span>
        </p>
      </div>
      <div class="col-sm-12">
        <p><span class="title">{{lang.getText('description_with_colon')}}</span> {{project.discription}}</p>
      </div>
      <br/>
    </div>
    <div *ngIf="selectedModal == claimSelected" class="claim-accept">
      <button class="btn btn-success float-end" (click)="claimAll()">
        {{lang.getText('claim')}} <span *ngIf="selectedProjects.length > 1">{{lang.getText('all')}}</span>
      </button>
    </div>
    <div *ngIf="selectedModal == transferSelected" class="do-transfer">
      <h4>{{lang.getText('transfer_to')}}</h4>
      <div *ngFor="let owner of owners;" class="radio">
          <label>
            <input [value]="owner.id" type="radio" [(ngModel)]="transferToOwner" />
            {{owner.name}}
          </label>
      </div>
      <button class="btn btn-success float-end" (click)="transferTo()">{{lang.getText('transfer')}}</button>
    </div>
    <div *ngIf="selectedModal == archiveSelected" class="claim-accept">
      <button class="btn btn-success float-end" (click)="archiveAll()">
        {{lang.getText('archive')}}  <span *ngIf="selectedProjects.length > 1">{{lang.getText('all2')}}</span>
      </button>
    </div>
    <div *ngIf="selectedModal == statusSelected" class="claim-accept">
      <button class="btn btn-success float-end" (click)="changeStatusAll()">
        {{lang.getText('change')}}  <span *ngIf="selectedProjects.length > 1">{{lang.getText('all')}}</span>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #addUpModal>
  <div class="modal-header">
    <h4 class="modal-title float-start">{{lang.getText('wordcounts_of_selected_projects')}}</h4>
  </div>
  <div class="modal-body">
    <div *ngIf="projectWordcounts" class="col-sm-8 offset-sm-2">
      <div class="col-sm-12 font-weight-bold">
          {{lang.getText('total_wordcount')}}: {{projectWordcounts.total_wordcount}} 
      </div>
      <div class="col-sm-12 font-weight-bold">
        {{lang.getText('weighed_wordcount')}}: {{projectWordcounts.weighed_wordcount}} 
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-success float-end" (click)="closeModal()">
      {{lang.getText('close')}}
    </button>
  </div>
</ng-template>

<!-- favorite Modal  -->

<app-details-popup #details_popup_com></app-details-popup>

<ng-template #ingoreWarningModal>
    <div class="modal-header">
      <h4 class="modal-title float-start">{{lang.getText('confirm')}}</h4>
      <button type="button" class="close float-end" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="col-sm-12 text-center">
        <h6>{{lang.getText('long_text')}}</h6>
      </div>
      <div class="do-transfer">
        <button class="btn btn-sm btn-light float-end ms-2"
          (click)="closeModal()">
          {{lang.getText('no')}}
        </button>
      </div>
      <div class="claim-accept">
        <button class="btn btn-sm btn-success float-end"
          (click)="ignoreWarning(ignoreWarn.id, ignoreWarn.pi)">
          {{lang.getText('yes')}}
        </button>
      </div>
    </div>
  </ng-template>

  <ng-template #confirmClaimProject >
    <div class="modal-header">
      <h4 class="modal-title float-start">{{lang.getText('confirm')}}</h4>
      <button type="button" class="close float-end" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="col-md-12">
        <h6>
            {{lang.getText('claim_project_message')}}
        </h6>
      </div>
      <div class="col-md-12">
        <button type="button" class="btn btn-sm btn-primary float-end ms-2" (click)="closeModal()">
          <span aria-hidden="true">{{lang.getText('cancel')}}</span>
        </button>
        <button type="button" class="btn btn-sm btn-primary float-end"
          (click)="claimThisProject(selectedProjectForEdit.id); closeModal();">
          <span aria-hidden="true">{{lang.getText('claim')}}</span>
        </button>
      </div>
    </div>
  </ng-template>
  

<ng-template #reactivateModal>
  <div *ngIf="!postData['filters'].archived" class="modal-header">
    <h4 class="modal-title float-start">{{lang.getText('long_text20')}}</h4>
    <button type="button" class="close float-end" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div *ngIf="postData['filters'].archived&&selectedProjects.length<=0" class="modal-header">
    <h4 class="modal-title float-start">{{lang.getText('long_text21')}}</h4>
    <button type="button" class="close float-end" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div *ngIf="postData['filters'].archived&&selectedProjects.length>0" class="modal-header">
    <h4 class="modal-title float-start">{{lang.getText('long_text22')}}</h4>
    <button type="button" class="close float-end" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div *ngIf="postData['filters'].archived&&selectedProjects.length>0" class="modal-header">
    <button class="btn btn-success float-end" (click)="closeModal()">
      {{lang.getText('cancel')}}
    </button>
    <button class="btn btn-success float-end" (click)="reactivateAll()">
      {{lang.getText('ok')}}
    </button>
  </div>
</ng-template>
<ng-template #notChangedStatusPopup>
  <div class="modal-header">
    <h4 class="modal-title float-start">{{lang.getText('status_not_changed_for_projects')}}</h4>
    <button type="button" class="close float-end" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngFor="let project of notChangedStatus;" class="row project">
      <div class="col-sm-12"><a  class="title"><strong>{{lang.getText('order_number')}}</strong> {{project[0].id+initialOrderId}}</a></div>
      <div class="col-sm-12">
        <p><span class="title">{{lang.getText('project_name')}}</span> {{project[0].name}}</p>
      </div>
      <div class="col-sm-12">
        <p>
          <span class="title">{{lang.getText('owner')}}</span>
          <span *ngIf="project.owner"> {{project[0].owner.name}}</span>
        </p>
      </div>
      <div class="col-sm-12">
        <p><span class="title">{{lang.getText('description_with_colon')}}</span> {{project[0].discription}}</p>
      </div>
      <br/>
    </div>
    <div class="claim-accept">
      <button class="btn btn-sm btn-success float-end" (click)="closeModal()">
        {{lang.getText('ok')}}
      </button>
    </div>
  </div>
</ng-template>

<app-filter-favorites #filterFavoritesCom (resetForm)="resetForm()" (applyFav)="loadProjectFilterForm()"></app-filter-favorites>
<app-edit-project-description #editDescriptionComment (updateData)="getProject($event)"></app-edit-project-description>
<ng-template #changeLabelTemp>
  <div class="modal-header">
    <h4 class="modal-title float-start">{{lang.getText('select_category')}}</h4>
    <button type="button" class="close float-end" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body change-label-temp-modal-body">
    <div class="row labels">
      <div *ngIf="labels.length == 0" class="alert alert-danger" role="alert">
        {{lang.getText('no_categories_to_select')}}
      </div>
      <div class="col-12 label"
        *ngFor="let label of labels;">
        <button
          (click)="changeLabel(label.id)"
          class="btn btn-light btn-break"
          [style.backgroundColor]="label.color">
          {{label.name}}
        </button>
      </div>
      <div class="col-12 label mt-3">
        <button
          (click)="changeLabel(null)"
          class="btn btn-primary">
          {{ lang.getText('reset') }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
<app-project-relation-list #openProjectRelationsCom></app-project-relation-list>

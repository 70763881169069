<div id="calendar">
	<div id="calendar_header">
		<div class="arrow">
			<span class="fa fa-arrow-left" (click)="goToLastMonth()"></span>
		</div>
		<div class="title">
			{{currentMonth}} - {{currentYear}}
		</div>
	</div>
	<div id="calendar_weekdays">
		<div *ngFor="let day of dayNames" class="calendar_weekday">{{day}}</div>
	</div>
	<div id="calendar_content">
		<div *ngFor="let dayItem of daysInLastMonth">
			<div class="week-date disabled">
				<span class="date-text">{{dayItem.day}}</span>
			</div>
		</div>
		<div *ngFor="let dayItem of daysInThisMonth">
			<div class="week-date enabled {{dayItem.event}}" (click)="selectDate(dayItem, 1)">
				<span class="date-text">{{dayItem.day}}</span>
			</div>
		</div>
		<div *ngFor="let dayItem of daysInNextMonth">
			<div class="week-date disabled">
				<span class="date-text">{{dayItem.day}}</span>
			</div>
		</div>
	</div>
</div>
<div id="calendar" class="right">
	<div id="calendar_header">
		<div class="title">
			{{currentMonth2}} - {{currentYear2}}
		</div>
		<div class="arrow">
			<span class="fa fa-arrow-right" (click)="goToNextMonth()"></span>
		</div>
	</div>
	<div id="calendar_weekdays">
		<div *ngFor="let day of dayNames" class="calendar_weekday">{{day}}</div>
	</div>
	<div id="calendar_content">
		<div *ngFor="let dayItem of daysInLastMonth2">
			<div class="week-date disabled">
				<span class="date-text">{{dayItem.day}}</span>
			</div>
		</div>
		<div *ngFor="let dayItem of daysInThisMonth2">
			<div class="week-date enabled {{dayItem.event}}" (click)="selectDate(dayItem, 2)">
				<span class="date-text">{{dayItem.day}}</span>
			</div>
		</div>
		<div *ngFor="let dayItem of daysInNextMonth2">
			<div class="week-date disabled">
				<span class="date-text">{{dayItem.day}}</span>
			</div>
		</div>
	</div>
</div>

<ng-template #modalTemp>
    <div class="modal-header cliQuotePrevModal">
      <h1 class="modal-title pull-left">
          {{lang.getText('quote_preview')}}
      </h1>
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div [ngClass]="{loading: ( loading() && quoteSendStep != 3 ) }" class="modal-body modal-cus1 cliQuotePrevModalBody">
    <div [hidden]="quoteSendStep !== 1">
      <div class="pdf-editor">
        <textarea [(ngModel)]="clientQuoteHTML" [ngxSummernote]="summernoteConfig.configPDF"></textarea>
      </div>
      <div>
        <button class="btn btn-light preview-btn" (click)="getClientQuote()"> {{lang.getText('preview_pdf')}}</button>
      </div>
      <iframe *ngIf="clientQuotePDF" class="pdf-object" [src]="clientQuotePDF" type="application/pdf" title="Client Pdf"></iframe>
    </div>
    <div [hidden]="quoteSendStep !== 2" class="cliQuotePrevModal">
      <h4>{{lang.getText('send_email_to')}}</h4>
      <div class="col-md-12">
        <div>
          <span *ngFor="let email of quoteEmails; let ei = index; let elast = last;">
            <span>
              {{ email }}
              <span class="fa fa-trash fa-lg" title="{{lang.getText('remove')}}" (click)="quoteEmails.splice(ei, 1)"></span>{{elast ? '' : ', '}}&nbsp;
            </span>
          </span>
        </div>
        <h6>{{lang.getText('other_email_addresses')}}</h6>
        <form [formGroup]="quoteEmailForm" class="email-table">
          <div class="col-md-12">
            <div *ngFor="let email of allQuoteEmails; let ei = index; let elast = last;" [hidden]=quoteEmails.includes(email) class="row">
              <div class="col-md-9">
                {{ email }}
              </div>
              <div class="col-md-3">
                <span (click)="quoteEmails.splice(3, 1); quoteEmails.push(email);"
                    class="btn btn-sm btn-dark" title="Add" >
                    {{lang.getText('add')}}
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-9">
                <input formControlName="email" class="form-control" type="text" />
              </div>
              <div class="col-md-3">
                <span
                  (click)="addQuoteEmail()"
                  style="vertical-align: -webkit-baseline-middle;"
                  class="btn btn-sm btn-dark" title="Add" >
                  {{lang.getText('add')}}
                </span>
              </div>
            </div>
            <h6>{{lang.getText('subject_line')}}</h6>
            <div class="row">
              <div class="col-md-9">
                <input formControlName="subject" class="form-control" type="text" />
              </div>
              <div class="col-md-3">
                <span
                  (click)="addQuoteEmailSubSave()"
                  style="vertical-align: -webkit-baseline-middle;"
                  class="btn btn-sm btn-dark" title="Save" >
                  {{quoteEmailForm.get('subject').enabled ? lang.getText('save') : lang.getText('edit')}}
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
      <div [hidden]="quoteSendStep !== 3" class="alert alert-success">
        <strong>{{ 'sending' | translate }}</strong>
      </div>
      <div [hidden]="quoteSendStep !== 4" class="alert alert-success">
        <strong>{{ 'quote_sent' | translate }}</strong>
      </div>
      <div class="col-md-12 mt-3 d-flex gap-3">
        <button [hidden]="quoteSendStep !== 1" [disabled]="translationCount > 200" type="button" class="btn btn-primary" (click)="quoteSendStep = 2">
          <span aria-hidden="true">{{lang.getText('send_quote')}}</span>
        </button>
        <button [hidden]="quoteSendStep !== 1" type="button" class="btn btn-secondary" (click)="closeModal()">
          <span aria-hidden="true">{{lang.getText('edit_quote')}}</span>
        </button>
        <button [hidden]="quoteSendStep !== 2" type="button" class="btn btn-primary" (click)="submitQuote()">
          <span aria-hidden="true">{{lang.getText('send')}}</span>
        </button>
      </div>
    </div>
  </ng-template>

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public isLoadingSource: BehaviorSubject<Boolean> = new BehaviorSubject(false);
  public notifySource: Subject<string> = new Subject();
  
  constructor() { }
}

import { Component, Input, OnInit, Injector } from '@angular/core';
import { HelperService } from '../services/helper.service';
import { JwtauthService } from '../services/jwtauth.service';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../services/token.service';
import { LanguageService } from '../services/language.service';

type dayItem = { day: any, hasEvent: boolean, event: string };

@Component({
  selector: 'calendar-avail',
  templateUrl: './calendar-avail.component.html',
  styleUrls: ['./calendar-avail.component.scss']
})
export class CalendarAvailComponent implements OnInit {
  @Input() vendorId;
  date: any;
  daysInThisMonth: any;
  daysInLastMonth: Array<dayItem>;
  daysInNextMonth: any;
  monthNames: string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  currentMonth: any;
  currentYear: any;
  currentDate: any;
  eventList: any;
  selectedEvent: any;
  isSelected: any;
  apiURL: '';

  daysInThisMonth2: any;
  daysInLastMonth2: Array<dayItem>;
  daysInNextMonth2: any;

  currentMonth2: any;
  currentYear2: any;
  currentDate2: any;

  dayNames = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  lang;
  interval;

  constructor(
    private injector: Injector,
    private http: HttpClient,
    private tokenSer: TokenService,
    private helpSer: HelperService,
    private Jwtwis: JwtauthService,
    public language: LanguageService
  ) {
    this.apiURL = this.injector.get('API_URL');
    this.lang = language;
  }

  ngOnInit() {
    this.date = new Date();
    this.interval = setInterval(() => this.updateFuncText(this.lang),500);
    //this.monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    this.getStatus();
    this.loadEventThisMonth();
  }

  goToLastMonth() {
    this.date = new Date(this.date.getFullYear(), this.date.getMonth(), 0);
    this.getStatus();
    // this.getDaysOfMonth();
  }

  goToNextMonth() {
    this.date = new Date(this.date.getFullYear(), this.date.getMonth() + 2, 0);
    this.getStatus();
    // this.getDaysOfMonth();
  }

  getDaysOfMonth(events, events2) {
    this.daysInThisMonth = new Array();
    this.daysInLastMonth = new Array();
    this.daysInNextMonth = new Array();

    this.currentMonth = this.monthNames[this.date.getMonth()];
    this.currentYear = this.date.getFullYear();

    this.daysInThisMonth2 = new Array();
    this.daysInLastMonth2 = new Array();
    this.daysInNextMonth2 = new Array();

    this.currentMonth2 = this.monthNames[this.date.getMonth()+1];
    this.currentYear2 = this.date.getFullYear();

    if (this.date.getMonth() === new Date().getMonth()) {
      this.currentDate = new Date().getDate();
    } else {
      this.currentDate = 999;
    }

    const firstDayThisMonth = new Date(this.date.getFullYear(), this.date.getMonth(), 0).getDay();
    const prevNumOfDays = new Date(this.date.getFullYear(), this.date.getMonth(), 0).getDate();

    const firstDayThisMonth2 = new Date(this.date.getFullYear(), this.date.getMonth()+1, 0).getDay();
    const prevNumOfDays2 = new Date(this.date.getFullYear(), this.date.getMonth()+1, 0).getDate();

    for (var i = prevNumOfDays - (firstDayThisMonth); i <= prevNumOfDays; i++) {
      this.daysInLastMonth.push({ day: i, hasEvent: false, event: '' });
    }

    for (var i = prevNumOfDays2 - (firstDayThisMonth2); i <= prevNumOfDays2; i++) {
      this.daysInLastMonth2.push({ day: i, hasEvent: false, event: '' });
      // console.log(i)
    }

    const thisNumOfDays = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0).getDate();
    const thisNumOfDays2 = new Date(this.date.getFullYear(), this.date.getMonth() + 2, 0).getDate();

    for (var i = 0; i < thisNumOfDays; i++) {
      let status = 'avail';
      events.forEach(eve => {
        if ( (i + 1) == Number(eve.date_at[8]+eve.date_at[9]) )
          status = eve.status
      });
      this.daysInThisMonth.push({ day: i + 1, hasEvent: false, event: status });
    }
    for (var i = 0; i < thisNumOfDays2; i++) {
      let status = 'avail';
      events2.forEach(eve => {
        if ( (i + 1) == Number(eve.date_at[8]+eve.date_at[9]) )
          status = eve.status
      });
      this.daysInThisMonth2.push({ day: i + 1, hasEvent: false, event: status });
    }

    const lastDayThisMonth = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0).getDay();
    const lastDayThisMonth2 = new Date(this.date.getFullYear(), this.date.getMonth() + 2, 0).getDay();
    // const nextNumOfDays = new Date(this.date.getFullYear(), this.date.getMonth() + 2, 0).getDate();

    for (var i = 0; i < (6 - lastDayThisMonth); i++) {
      this.daysInNextMonth.push({ day: i + 1, hasEvent: false, event: '' });
    }

    for (var i = 0; i < (6 - lastDayThisMonth2); i++) {
      this.daysInNextMonth2.push({ day: i + 1, hasEvent: false, event: '' });
    }

    const totalDays = this.daysInLastMonth.length + this.daysInThisMonth.length + this.daysInNextMonth.length;
    if (totalDays < 36) {
      for (var i = (7 - lastDayThisMonth); i < ((7 - lastDayThisMonth) + 7); i++) {
        this.daysInNextMonth.push({ day: i , hasEvent: false });
      }
    }

    const totalDays2 = this.daysInLastMonth2.length + this.daysInThisMonth2.length + this.daysInNextMonth2.length;
    if (totalDays2 < 36) {
      for (var i = (7 - lastDayThisMonth2); i < ((7 - lastDayThisMonth2) + 7); i++) {
        this.daysInNextMonth2.push({ day: i , hasEvent: false });
      }
    }
  }

  selectDate(dayItem: dayItem, monthId) {
    dayItem.hasEvent = true;
    //let thisDate1 = this.date.getFullYear() + "-" + (this.date.getMonth() + 1) + "-" + dayItem.day
    let selDate = new Date(this.date.getFullYear(), this.date.getMonth(), dayItem.day)
    if (monthId == 2)
      selDate = new Date(this.date.getFullYear(), this.date.getMonth()+1, dayItem.day);


    // console.log(this.helpSer.formateDPDate(selDate));

    let status = 'avail';
    if (dayItem.event == 'avail')
      status = 'booked';
    else if (dayItem.event == 'booked')
      status = 'pending';

    this.updateStatus(this.helpSer.formateDPDate(selDate), dayItem, status);

    // this.isSelected = false;
    // this.selectedEvent = new Array();
    // var thisDate1 = this.date.getFullYear() + "-" + (this.date.getMonth() + 1) + "-" + day + " 00:00:00";
    // var thisDate2 = this.date.getFullYear() + "-" + (this.date.getMonth() + 1) + "-" + day + " 23:59:59";
    // this.eventList.forEach(event => {
    //   if (
    //     ((event.startDate >= thisDate1) && (event.startDate <= thisDate2)) ||
    //     ((event.endDate >= thisDate1) && (event.endDate <= thisDate2))) {

    //     this.isSelected = true;
    //     this.selectedEvent.push(event);
    //   }
    // });
  }

  loadEventThisMonth() {
    this.eventList = new Array();
    var startDate = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
    var endDate = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);
  }

  updateStatus(onDate, dayItem, status) {
    let formData = {
      token: this.tokenSer.get(),
      vendorId: this.vendorId,
      onDate: onDate,
      status: status
    }
    this.http.post(`${this.apiURL}vendor/update-avail`, formData).subscribe(
      data => {
        dayItem.event = status;
      },
      error => {
        this.handleError(error);
      }
    );
  }

  getStatus() {
    // console.log(this.vendorId)
    let formData = {
      token: this.tokenSer.get(),
      vendorId: this.vendorId,
      month: this.date.getMonth()+1,
      year: this.date.getFullYear(),
    }
    this.http.post(`${this.apiURL}vendor/get-avail`, formData).subscribe(
      data => {
        this.getDaysOfMonth(data['month'], data['nextMonth'])
      },
      error => {
        this.handleError(error);
      }
    );
  }

  handleError(error) {
    this.Jwtwis.handleError(error);
  }
  updateFuncText(lang){
    if(lang != undefined){
      this.monthNames = [this.lang.getText('january'), this.lang.getText('february'), this.lang.getText('march'), this.lang.getText('april'), this.lang.getText('may'), this.lang.getText('june'), this.lang.getText('july'), this.lang.getText('august'), this.lang.getText('september'), this.lang.getText('october'), this.lang.getText('november'), this.lang.getText('december')];
      this.dayNames = [this.lang.getText('short_sunday'), this.lang.getText('short_monday'), this.lang.getText('short_tuesday'), this.lang.getText('short_wednesday'), this.lang.getText('short_thursday'), this.lang.getText('short_friday'), this.lang.getText('short_saturday')];
      clearInterval(this.interval);
    }
  }
}

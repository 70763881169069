<div class="row justify-content-md-center new-redesign-style">
  <div class="col-md-11 mt-3 mb-3">
    <div class="tab-content row justify-content-md-center">
      <div class="col-md-11 mb-3">
        <div class="header mt-4 mb-4 d-flex">
          <h2>{{'edit_user' | translate }}</h2>
          <div class="client-actions ps-2 ms-2"></div>
        </div>
        <form *ngIf="user" [formGroup]='userEditForm' (ngSubmit)="onSubmitUserEditForm()">
          <div class="form-errors">
            <p class="error" *ngFor="let error of errors; let ei = index;">{{error}}</p>
          </div>
          <div class="row">
            <div class="form-group col-md-6">
              <label class="col-form-label">{{lang.getText('name')}}</label>
              <input type="text" class="form-control" formControlName='name' placeholder="{{lang.getText('enter_name')}}">
            </div>
            <div class="form-group col-md-6">
              <label class="col-form-label">{{lang.getText('email_address')}}</label>
              <input type="email" class="form-control" formControlName='email' aria-describedby="emailHelp" placeholder="{{lang.getText('enter_email_address2')}}">
            </div>
          </div>
          <div class="form-group mt-3">
            <div class="form-check form-switch pb-1">
              <input type="checkbox" 
                    class="form-check-input" 
                    id="send_password_reset" 
                    formControlName="send_reset_email"
                    value="true"
                    >
              <label class="form-check-label font-weight-bold" [style]="{'color' : 'black'}" for="send_password_reset">
                {{lang.getText('send_password_reset_link')}}
              </label>
            </div>
            <div class="form-check form-switch pb-1">
              <input type="checkbox" 
                    class="form-check-input mr-2" 
                    id="two_factor_auth" 
                    formControlName="mfa"
                    >
              <label class="form-check-label font-weight-bold" [style]="{'color' : 'black'}" for="two_factor_auth">
                {{lang.getText('two_factor_authentication')}}
              </label>
            </div>
          </div>
          <h3 class="mt-3 mb-3">{{lang.getText('roles')}}</h3>
          <div class="form-group">
              <div *ngFor="let role of roles; let i = index;" class="form-check form-switch pb-1">
                <input type="radio" 
                      class="form-check-input mr-2" 
                      id="role{{i}}" 
                      formControlName="role_id" 
                      [value]="role.id" 
                      name="role_id" 
                      [checked]="role.id === userEditForm.get('role_id')?.value">
                <label class="form-check-label font-weight-bold" [style]="{'color' : 'black'}" for="role{{i}}">
                  {{role.name}} 
                  &nbsp;<em class="fa fa-info-circle" [style]="{'color' : 'blue'}" [tooltip]="descriptions[role.code]" placement="right"></em>
                </label>
              </div>
          </div>
          <button type="submit" class="btn btn-primary">{{lang.getText('update')}}</button>
        </form>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { LanguageService } from '../services/language.service';

@Component({
  selector: 'app-currency-dropdown',
  templateUrl: './currency-dropdown.component.html',
  styleUrls: ['./currency-dropdown.component.scss']
})
export class CurrencyDropdownComponent implements OnInit {
  lang;
  constructor(
    public language: LanguageService
  ) {
    this.lang = language;
  }

  ngOnInit() {
  }

}

import { HttpClient } from '@angular/common/http';
import { Component, Injector, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HelperService } from 'src/app/services/helper.service';
import { JwtauthService } from 'src/app/services/jwtauth.service';
import { LanguageService } from 'src/app/services/language.service';
import { TokenService } from 'src/app/services/token.service';
import { DisplaySettingsService } from 'src/app/services/display-settings.service';

@Component({
  selector: 'app-dashboard-display-settings',
  templateUrl: './dashboard-display-settings.component.html',
  styleUrls: ['./dashboard-display-settings.component.scss']
})
export class DashboardDisplaySettingsComponent implements OnInit {
  private apiURL = '';
  publicURL;
  modalRef: BsModalRef;
  lang;
  settings;
  settingsFg: UntypedFormGroup;
  settingsFa: UntypedFormArray;
  unsavedSettings: Boolean = false;
  allSettingsEnabled = true;
  allSettingsLabel = 'all_enabled';

  constructor(
    private injector: Injector,
    private http: HttpClient,
    private token: TokenService,
    public jwtAuth: JwtauthService,
    public helpSer: HelperService,
    private modalService: BsModalService,
    private fb: UntypedFormBuilder,
    public language: LanguageService,
    public displaySettSer: DisplaySettingsService,
  ) {
    this.apiURL = this.injector.get('API_URL');
    this.lang = language;
  }

  ngOnInit() {
  }

  @ViewChild('dashboardSettingsPopup', {static: true}) dashboardSettingsPopup:TemplateRef<any>;
  openModal() {    
    this.displaySettSer.getAll().subscribe(
      (data) => {
        this.settingsFa = this.fb.array([]);
        Object(data).forEach(setting => {
          this.settingsFa.push(this.fb.group(setting));
        });
        this.settingsFg = this.fb.group({
          settingsFa: this.settingsFa
        });
        this.checkAllSettings();
        this.settingsFg.valueChanges.subscribe(val => {
          this.unsavedSettings = true;
        })
      },
      (error) => this.handleError(error)
    );
    ;
    this.modalRef = this.modalService.show(this.dashboardSettingsPopup, {class: "modal-lg new-redesign-style"});
  }

  saveDashboardSettings() {
    let postData = {
      token: this.token.get(),
      settings: this.settingsFa.getRawValue()
    }
    this.http.post(`${this.apiURL}display-settings/update`, postData).subscribe(
      data => {
        this.displaySettSer.getLabels();
        this.unsavedSettings = false;
        this.closeModal();
      },
      error => this.handleError(error)
    );
  }

  handleError(error) {
    this.jwtAuth.handleError(error);
  }

  closeModal() {
    if (this.unsavedSettings) {
      if (confirm(this.lang.getText('dashboard_settings_unsaved_warning'))) {
        this.modalRef.hide();
      }
    } else {
      this.modalRef.hide();
    }
  }

  checkAllSettings() {
    this.settingsFa.controls.forEach((setting, key) => {
      if(!setting.value.show) {
        this.allSettingsEnabled = false;
        this.allSettingsLabel = 'enable_all';
        return false;
      } else {
        this.allSettingsEnabled = true;
        this.allSettingsLabel = 'all_enabled';
        return false;
      }
    });
  }

  checkUncheckAllSettings() {
    this.allSettingsEnabled = !this.allSettingsEnabled;
    if(!this.allSettingsEnabled) {
      this.allSettingsLabel = 'enable_all';
    } else {
      this.allSettingsLabel = 'all_enabled';
    }
    this.settingsFa.controls.forEach((setting, key) => {
      setting.patchValue({
        'show': this.allSettingsEnabled
      });
    });
  }

}

import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppConfig } from 'src/app/configs/app.config';
import { JwtauthService } from 'src/app/services/jwtauth.service';
import { LanguageService } from 'src/app/services/language.service';
import { TokenService } from 'src/app/services/token.service';
import { environment as env } from 'src/environments/environment';

@Component({
  selector: 'app-release-notes-modal',
  templateUrl: './release-notes-modal.component.html',
  styleUrls: ['./release-notes-modal.component.scss']
})
export class ReleaseNotesModalComponent {
  apiURL = env['API_URL'];
  @ViewChild("thisModal") thisModal;
  modalRef: BsModalRef;
  appConfig: AppConfig = new AppConfig();

  constructor(
    public jwtAuth: JwtauthService,
    private modalService: BsModalService,
    public lang: LanguageService,
    private token: TokenService,
    private http: HttpClient
  ) { }

  openModal() {
    this.modalRef = this.modalService.show(this.thisModal, { class: 'modal-lg', backdrop: 'static' });
  }

  @Output() close: EventEmitter<String> = new EventEmitter();
  closeModal() {
    this.modalRef.hide();
    this.close.emit();
  }

  hideReleaseNote() {
    let postData = {
      token: this.token.get(),
      version: this.appConfig.releaseNoteVersion
    };
    this.http
      .post(`${this.apiURL}do-not-show-release-note`, postData)
      .subscribe(
        data => {
          //
        }
      );
    this.closeModal();
  }
}


<div class="app-login-main-container">
<div class="login-header">
  <div class="col-sm-12">
    <img alt="FlowDezk Logo" class="brand-logo" src="{{publicURL}}logo/brand-logo.webp">
  </div>
</div>
<div class="container-fluid">
  <div class=" justify-content-md-center">
    <div class="design-main-container col-lg-6 col-md-6 col-sm-8">
    <div class="login-layout">
      <div class="inner-login-layout">
      <p class="login-message font-weight-bold">Welcome to FlowDezk, the smart and flexible workflow solution for <br> your projects. Please log in below.</p>
<!--             
      <div class="login-message alert alert-warning" role="alert">
        FlowDezk will be down for a release update from 05:00 AM CET to 09.00 AM CET, on 1st August 2024, Thursday. Please make sure you log off before this time.
      </div>
       -->
      <form [formGroup]='loginForm' (ngSubmit)="onSubmitLoginForm()" autocomplete="off">
        <p *ngIf="sessionExp" class="error session-error">You have been logged out as your session expired due to inactivity. Please log in again to continue working.</p>
        <p class="error">{{error}}</p>
        <div class="form-group">
          <input type="email" class="form-control" formControlName='email' placeholder="Email address">
        </div>
        <div class="form-group">
          <input type="password" class="form-control" formControlName='password' placeholder="Password">
        </div>
        <div *ngIf="authCode" class="form-group">
          <label>Code</label>
          <input type="text" class="form-control" formControlName='code' placeholder="Code">
        </div>
       <div class="d-flex justify-content-center login-btn-container"> 
        <button type="submit" class="btn btn-primary login-btn">Login</button>
      </div>
      <p class="forgot-password text-center">
        <a *ngIf="this.group != 'vendor'" routerLink="/forgot-password" routerLinkActive="router-link-active"  class="forgot-password-link">Forgot password</a>
        <a *ngIf="this.group == 'vendor'" routerLink="/vendor/forgot-password" routerLinkActive="router-link-active"  class="forgot-password-link">Forgot password</a>
      </p>
      </form>
    </div>
    <div class="graphic-design-container">
      <div class="left-img">
        <img src="../../assets/img/teacup-flow-dezk.png" alt="Teacup Image">
      </div>
      <div class="right-img">
        <img src="../../assets/img/plant-flow-dezk.png" alt="Plant Image">
      </div>
    </div>
  </div>
    </div>
  </div>
</div>
</div>


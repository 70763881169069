import { Injectable, Injector } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as _ from "lodash-es"
import { HttpClient } from '@angular/common/http';
import { TokenService } from './token.service';
import { JwtauthService } from './jwtauth.service';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { AppConfig } from '../configs/app.config';
import { DomSanitizer } from '@angular/platform-browser';
import { LanguageService } from './language.service';

@Injectable()
export class HelperService {
  private apiURL = '';
  apiDateFormate = new AppConfig().apiDateFormate;
  dateFormate = new AppConfig().dateFormate;
  dateTimeFormate:string = new AppConfig().dateTimeFormate;
  constructor(
    private injector: Injector,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer,
    private token: TokenService,
    private http: HttpClient,
    private jwt: JwtauthService,
    private lang: LanguageService,
    private router: Router
  ) {
    this.apiURL = this.injector.get('API_URL');
  }
  
  allArrValuesEqual(arr) { 
    if(!arr.length) return true; 
    return arr.reduce(function(a, b) 
        {return (a === b)?a:(!b);}) === arr[0]; 
  }

  multiply(a, b) {
    let commonMultiplier = 1000000;
    a *= commonMultiplier;
    b *= commonMultiplier;
    return (a * b) / (commonMultiplier * commonMultiplier);
  };

  roundOfToFraction(value, decimals) {
    return _.round(value, decimals);
  }

  roundOfToFractionStr(value, decimals) {
    let multiple = Math.pow(10, decimals);
    return (Math.round(value * multiple) / multiple).toFixed(decimals);
  }

  calLibDateTOPDFDate(dt){
    var t, result = null;
    if( typeof dt === 'string' )
    {
      t = dt.split(/[/ :]/);
      result = new Date(t[2], t[1] - 1, t[0], t[3] || 0, t[4] || 0, t[5] || 0);
    } else if( typeof dt === 'object' ) {
      result = dt
    }
    return this.datePipe.transform(result, new AppConfig().pdfDateFormate);
  }

  formateDPDate(dt){
    let d = new Date(dt);
    if (
      dt == null || 
      dt == '' ||
      isNaN(d.getTime()) ||
      dt.length == 10 ||
      isNaN(d.valueOf())
    ){
      return dt;
    }
    return this.datePipe.transform(dt, this.apiDateFormate);
  }
  
  fromMysqlDateTime(mysql_string) {
    var t, result = null;
    if( typeof mysql_string === 'string' )
    {
      t = mysql_string.split(/[- :]/);
      //when t[3], t[4] and t[5] are missing they defaults to zero
      result = new Date(t[0], t[1] - 1, t[2], t[3] || 0, t[4] || 0, t[5] || 0);          
    }
    return result;
  }

  viewBase64File(base64) {
    let blob = this.base64ToBlob(base64);
    window.open(window.URL.createObjectURL(blob));
  }

  downloadBase64File(base64, fileName) {
    let blob = this.base64ToBlob(base64)
    this.downloadFile(blob, fileName);
  }

  downloadFile(blob, fileName){
    let url = window.URL.createObjectURL(blob);
    let a = window.document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    a.href = url;
    a.download = fileName;
    a.target = '_blank';
    a.click();
  }

  getFileBuffer(fileData){
    const byteString = atob(fileData);
    const fileBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(fileBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    return fileBuffer;
  }
  
  base64ToBlob(base64, mimeType = 'application/pdf') {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    if ( mimeType == null ) { //For Safari
      mimeType = 'application/pdf';
    }
    return new Blob([bytes], { type: mimeType });
  };

  ratingAry() {
    return Array.from(Array(5).keys());
  }

  getFileName(filePath){
    if (filePath == null) return '';
    return filePath.substring(filePath.lastIndexOf('/')+1);
  }

  downUserManual(type) {
    let formData = {
      token: this.token.get(),
      type: type
    };
    this.http.post(`${this.apiURL}user-manual/down`, formData).subscribe(
      data => {
        let blob = new Blob([this.getFileBuffer(data['file'])], { type: data['type'] });
        this.downloadFile(blob, data['fileName']+'.'+data['extension']);
      },
      error => {
        this.alertIt("User manual don't exist.");
        this.jwt.handleError(error);
      }
    );
  }

  vendorAuthCheck() {
    const priUrl = '/vendor/privacy-policy';
    // console.log(this.jwt.isVenodor(), this.jwt.venodorAgreed(), window.location.href.indexOf(priUrl))
    if ( this.jwt.isVenodor() && this.jwt.venodorAgreed() == false ) {
      this.router.navigate([priUrl]);
      // window.location.replace(priUrl);
    }
  }

  alertIt(msg) {
    alert(msg);
  }

  pushEmail(emails, emailIpt) {
    if ( !this.validEmail(emailIpt) ){
      return this.alertIt('Please enter valid email.');
    }
    emails.push(emailIpt);
    return emailIpt = '';
  }

  validEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  splitDateAndTime(dateTime) {
    var d = new Date(dateTime);
    if (
          dateTime == null || 
          isNaN(d.getTime()) ||
          dateTime.split("0").length > 14 ||
          isNaN(d.valueOf())
        ) {
      return { date: '', hour: '', minute: '' };
    }
    let date = this.datePipe.transform(d, 'dd/MM/yyyy');
    return {
      date: date,
      hour: d.getHours(),
      minute: d.getMinutes(),
    }
  }

  invalidDateTime(dt) {
    if (
      dt == null ||
      dt == '' ||
      dt == '0000-00-00 00:00:00'
    ) {
      return true;
    }
    return false;
  }

  formatDateTime(dt: string) {
    if ( this.invalidDateTime(dt) ) {
      return '';
    }
    return formatDate(dt, this.dateTimeFormate, 'en-US');
  }

  formatDate(dt: string) {
    if ( this.invalidDateTime(dt) ) {
      return '';
    }
    return formatDate(dt, this.dateFormate, 'en-US');
  }

  formateDateObj(dt) {
    if (dt == null) return '';
    dt = dt.date;
    if ( this.invalidDateTime(dt) ) {
      return '';
    }
    if ( typeof dt == 'string' && dt.includes('/') ) {
      dt = dt.split('/').reverse().join('-');
    }
    return this.formatDate(dt);
  }

  nonEmptyArray(arr) {
    return arr && arr.length > 0
  }

  json_array_join_string(jsonStr, str) {
    return this.json_parse(jsonStr).join(str);
  }

  json_parse(jsonStr) {
    try {
      return JSON.parse(jsonStr);
    } catch (error) {
      return [];
    }
  }
  
  objURL(blob) {
    return window.URL.createObjectURL(blob);
  }

  urlObjPDF(base64) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.objURL(this.base64ToBlob(base64))+'#toolbar=0&navpanes=0&scrollbar=0');
  }

  returnMeIfNull(defaVal, val) {
    return val ? val : defaVal;
  }

  non_numeric_val_alert(event){
    if (event.key.match(/[^$,.\d]/)){
      alert(this.lang.getText('long_text11_decimal'));
      return false;
    }
  }
  
  updateOrRemoveArrayValue(array, index){
    if ( array.includes(index) ){
      array.splice(array.indexOf(index), 1);
    } else {
      array.push(index);
    }
  }
}

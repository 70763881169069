import { Injectable } from '@angular/core';

@Injectable()
export class TokenService {
  public apiToken = {
    token: null
  };
  constructor() { }

  handle(token) {
    this.set(token);
  }

  set(token) {
    localStorage.setItem('token', token);
  }
  get() {// token is send so sending empty
    return '';
  }

  get_bearer() {
    return localStorage.getItem('token');
  }

  logout() {
    localStorage.clear();
  }

  isValid() {
    const token = this.get_bearer();
    if (token != null) {
        return true;
    }
    return false;
  }

  loggedIn() {
    return this.isValid();
  }

  //Trash it after lara 10 upgrade
  getAPITokenData() {// token is send so sending empty
    return {
      token: ''
    };
  }
}

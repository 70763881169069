import { HttpClient } from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { JwtauthService } from 'src/app/services/jwtauth.service';
import { TokenService } from 'src/app/services/token.service';
import { environment as env} from 'src/environments/environment';
import { LanguageService } from '../../../services/language.service';

@Component({
  selector: 'app-display-batches-cb',
  templateUrl: './display-batches-cb.component.html',
  styleUrls: ['./display-batches-cb.component.scss']
})
export class DisplayBatchesCbComponent implements OnInit {
  @Input() projectClaimed = false;
  apiURL = env['API_URL'];
  lang;
  constructor(
    private fb: UntypedFormBuilder,
    private http: HttpClient,
    private token: TokenService,
    private jwtwis: JwtauthService,
    public language: LanguageService
  ) {
    this.lang = language;
  }

  ngOnInit() {
    this.initForm();
    this.getData();
  }

  batchesForm;
  initForm() {
    let batchesFc = this.fb.control(0);
    this.batchesForm = this.fb.group({
      batches: batchesFc,
    });
    batchesFc.valueChanges.subscribe(val => {
      this.updateData(val);
    });
  }

  getData() {
    let formData = {
      token: this.token.get()
    }
    this.http.post(`${this.apiURL}user-disp-plp-batches-get`, formData).subscribe(
      data => {
        this.batchesForm.patchValue({
          batches: data == 1 ? true : false
        }, {emitEvent: false});
      },
      error => this.handleError(error)
    );
  }

  updateData(val) {
    let formData = {
      token: this.token.get(),
      batches: val
    }
    if(this.projectClaimed){
      this.http.post(`${this.apiURL}user-disp-plp-batches-update`, formData).subscribe(
        data => {
          //
        },
        error => this.handleError(error)
      );
    }
  }

  handleError(error) {
    this.jwtwis.handleError(error);
  }
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { HelperService } from 'src/app/services/helper.service';
import { JwtauthService } from 'src/app/services/jwtauth.service';
import { LanguageService } from 'src/app/services/language.service';
import { ProjectService } from 'src/app/services/project.service';
import { environment as env } from 'src/environments/environment';
import { PaginationComponent } from 'src/app/modules/shared/components/pagination/pagination.component';

@Component({
  selector: 'app-vendor-finance-breakdown',
  templateUrl: './vendor-finance-breakdown.component.html',
  styleUrls: ['./vendor-finance-breakdown.component.scss']
})
export class VendorFinanceBreakdownComponent implements OnInit {
  @Input() projectId;
  @Input() vendorId;
  @Input() projectEditForm: UntypedFormGroup;
  @Input() projectCurrencyName;
  @Input() vendor;
  apiURL = env['API_URL'];

  constructor(
    private Jwtwis: JwtauthService,
    public helperSer: HelperService,
    public lang: LanguageService,
    private projectSer: ProjectService,
  ) { }

  ngOnInit() {
    // this.getTranslations('');
  }

  ngAfterViewInit() {
    this.getTranslations(1);
  }

  page_no = 1;
  total_page = 0;
  translations;
  tranReq;
  per_page = 10;
  current_page = 1;

  @ViewChild(PaginationComponent) pagination_com: PaginationComponent;
  getTranslations(page_no = 1) {
    this.page_no = page_no;
    this.tranReq = this.projectSer.get_vendor_translations(this.projectId, this.vendorId, this.page_no, this.per_page).subscribe(
      data => {
        this.pagination_com.generate(data['translations'], page_no)
        this.current_page = this.page_no;
        this.translations = data['translations'];
        this.total_page = data['totalTrasnlationPages'];
      },
      error => {
        this.handleError(error)
      }
    );
  }

  handleError(error) {
    this.Jwtwis.handleError(error);
  }
}

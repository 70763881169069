import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../../../services/token.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AccountService {
  private apiURL = environment['API_URL'];
  apiURL_TFA;
  formData: FormData = new FormData();
  constructor(
    private token: TokenService,
    private http: HttpClient,
  ) {
    this.formData = new FormData();
    this.formData.append('token', this.token.get() );
    this.apiURL_TFA = this.apiURL + '2fa';
  }

  get2FA() {
    return this.http.post(`${this.apiURL_TFA}/get`, this.formData);
  }

  generate2FA() {
    return this.http.post(`${this.apiURL_TFA}/generate`, this.formData);
  }

  verify2FA(verifyCode) {
    this.formData.append('verifyCode', verifyCode);
    return this.http.post(`${this.apiURL_TFA}/verify`, this.formData);
  }
}

import { HttpClient } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { JwtauthService } from 'src/app/services/jwtauth.service';
import { TokenService } from 'src/app/services/token.service';
import { environment as env } from 'src/environments/environment';
import { LanguageService } from '../../../services/language.service';

@Component({
  selector: 'app-vendor-rates',
  templateUrl: './vendor-rates.component.html',
  styleUrls: ['./vendor-rates.component.scss']
})
export class VendorRatesComponent {
  apiURL = env['API_URL'];
  lang;
  constructor(
    private modalService: BsModalService,
    private http: HttpClient,
    private token: TokenService,
    private jwtAuth: JwtauthService,
    public language: LanguageService
  ) {
    this.lang = language;
  }

  @ViewChild('modalTemp') modalTemp;
  details
  modalRef: BsModalRef;
  openModal(id) {
    this.details = null;
    this.getDetails(id);
    this.modalRef = this.modalService.show(
      this.modalTemp,
      { class: 'modal-md' }
    );
  }

  closeModal() {
    this.modalRef.hide();
  }

  getDetails(id) {
    let postData = {
      token: this.token.get(),
      vendorId: id
    };
    this.http.post(`${this.apiURL}vendor/my-details`, postData).subscribe(
      data => {
        this.details = data
      },
      error => this.handleError(error)
    );
  }

  handleError(error) {
    this.jwtAuth.handleError(error);
  }
}

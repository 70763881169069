import { Component, Input, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';
import { TokenService } from 'src/app/services/token.service';
import { environment as env} from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { LanguageService } from '../../../services/language.service';

@Component({
  selector: 'app-sap-field',
  templateUrl: './sap-field.component.html',
  styleUrls: ['./sap-field.component.scss']
})
export class SapFieldComponent implements OnInit {
  @Input() field:any;
  @Input() fieldsValue:any;
  @Input() sapData:any;
  @Input() projectId;
  apiURL = env['API_URL'];
  lang;

  constructor(
    private http: HttpClient,
    private Token: TokenService,
    private helperSer: HelperService,
    public language: LanguageService
  ) {
    this.lang = language;
  }

  ngOnInit() {
  }

  getFieldValue(field) {
    let valKey = field.valKey;
    if (typeof this.fieldsValue[valKey] == 'undefined') return '';
    if (valKey == 'sap_ins_last_changed_at' || valKey == 'sap_start_date') {
      return this.getDateTime(this.fieldsValue[valKey]);
    }
    if ( field.dataType == 'jsbr' ) {
      return this.helperSer.json_array_join_string(this.fieldsValue[valKey], '\n');
    }
    if ( field.dataType == 'json' ) {
      return this.helperSer.json_array_join_string(this.fieldsValue[valKey], ', ');
    }
    return this.fieldsValue[valKey];
  }

  getDateTime(dt: string) {
    return this.helperSer.formatDateTime(dt);
  }
}

export class SapConfig {
    public fields:any = [
        {type: 'text', size: 4, dataType: 'stri', label: 'Project Type',      valKey: 'sap_project_type'},
        {type: 'text', size: 4, dataType: 'stri', label: 'Smartling URL',     valKey: 'sap_smartling_url'},
        {type: 'text', size: 4, dataType: 'stri', label: 'Service Step',      valKey: 'sap_service_step'},
        
        {type: 'text', size: 4, dataType: 'stri', label: 'Terminology Key',   valKey: 'sap_term_key'},
        {type: 'text', size: 4, dataType: 'json', label: 'Step Status',       valKey: 'sap_step_status_desc'},
        {type: 'text', size: 4, dataType: 'intg', label: 'Data Version',      valKey: 'sap_ins_version'},
        
        {type: 'tear', size: 4, dataType: 'html', label: 'Translation Area',  valKey: 'sap_trans_area'},
        {type: 'tear', size: 4, dataType: 'jsbr', label: 'Dita Project ID',   valKey: 'sap_lxe_project'},
        
        {type: 'text', size: 4, dataType: 'dati', label: 'Start Date',        valKey: 'sap_start_date'},
        {type: 'text', size: 4, dataType: 'dati', label: 'Instructions Date', valKey: 'sap_ins_last_changed_at'},
        
        {type: 'text', size: 4, dataType: 'stri', label: 'External Project ID',     valKey: 'sap_external_project_id'},
        {type: 'text', size: 4, dataType: 'stri', label: 'Worklist',          valKey: 'sap_work_list'},
        {type: 'thtm', size: 4, dataType: 'html', label: 'Instructions',      valKey: 'sap_instructions_long'},

        {type: 'text', size: 4, dataType: 'stri', label: 'Is XTM',            valKey: 'sap_is_xtm'},
        {type: 'text', size: 4, dataType: 'stri', label: 'Environment Name',  valKey: 'sap_env_name'},    
        {type: 'text', size: 4, dataType: 'stri', label: 'Content Name',      valKey: 'sap_content_name'},
        
        
    ]
}

<div *ngIf="projectId != 0 && allow_user" class="single_part_main container">
  <div class="single_part_inner">
    <div class="col-lg-10 col-md-12">
      <div class="row">
        <div class="col-md-12">
          <h1>{{lang.getText('project_files')}}</h1>
          <div class="form-check form-check-inline">
            <button *ngIf="!readOnly" (click)="enableUploadFile();" class="btn btn-upload">{{lang.getText('upload_files')}}</button>&nbsp;
          </div>
        </div>
      </div>
      <div *ngIf="!readOnly && uploadFilesEnable" class="row upload-form-layout">
        <div class="col-xl-8 col-lg-6 col-md-6">
          <input class="form-control" type="text" readonly placeholder="{{lang.getText('filename')}}">
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6">
          <input type="file" (change)="uploadFileChange($event)" multiple class="btn form-control">
        </div>
      </div>
      <div *ngFor="let progress of uploads">
        <div [hidden]="!progress.uploading" class="progress-bar-layout row">
          <div class="col-lg-9 progress">
              <div class="progress-bar progress-bar-striped" role="progressbar" [style.width.%]="progress.progress"></div>
          </div>
          <div *ngIf="progress.done" class="col-lg-3 status">
              <span class="alert-success"></span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <div class="row">
            <div class="col-xl-9 col-lg-6 col-md-6">
              <span class="download-indicator pull-right">
                <div *ngIf="downloadReq && !downloadReq.isStopped" class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
              </span>
            </div>
            <div class="col-xl-3 col-lg-6 col-md-6">
              <a (click)="downloadFiles()" class="btn btn-primary down-sel-btn">{{lang.getText('download_selection')}}</a>
            </div>
          </div>
        </div>
      </div>
      <form *ngIf="files" [formGroup]="form">
        <div formArrayName="files" class="row" *ngFor="let file of form.get('files').controls; let i = index;">
          <div class="col-xl-9 col-lg-6 col-md-6">
            <p>{{getFileName(files[i].path)}}</p>
          </div>
          <div class="col-xl-3 col-lg-6 col-md-6">
            <a (click)="viewFile(files[i].id)" class="btn btn-primary mr-3">{{lang.getText('view')}}</a>
            <div class="form-check form-check-inline">
              <input [formControlName]="i" type="checkbox" value="{{files[i].id}}" class="form-check-input">
            </div>
          </div>
        </div>
      </form>
      <div class="row comments_form" [formGroup]="commentsForm">
        <div class="col-xl-8 col-lg-6 col-md-6">&nbsp;</div>
        <div class="col-xl-4 col-lg-6 col-md-6">
          <div class="form-group">
            <label>{{lang.getText('delivery_comments')}}</label>
            <textarea class="form-control" formControlName="comment"></textarea>
            <button class="btn btn-primary" (click)="saveComment()">{{lang.getText('save')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

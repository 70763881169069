<ng-template #favoriteModal>
    <div class="modal-header" >
        <button (click)="addFavorites()" [disabled]="!canAddToFav()"  class="btn btn-primary">{{lang.getText('add_current_parameter_combination_to_favorites')}}</button>
        <button (click)="resetProjectsFilterForm()"  class="btn  btn-primary ml-2">{{lang.getText('deselect-favorite')}}</button>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="favorites">
            <h5 class="pb-3">{{lang.getText('favorite-popup-title')}}</h5>
            <div *ngFor="let fav of favorites.slice().reverse();let i = index" class="mb-3  item" >
                <input type="radio" name="fav-selected" class="mr-2" [(ngModel)]="favFilterRadioIpt" [value]="i" />
                <span class="value" *ngIf="fav.archived">{{lang.getText('archived')}}<span class="comma">, </span></span>
                <span class="value" *ngIf="fav.order_creation_date">{{lang.getText(fav.order_creation_date)}} <span class="comma">, </span>
                    <span class="value" *ngIf="fav.fromDate">{{lang.getText('date_from')}} {{fav.fromDate | date:appConfig.dateFormate}} </span>
                    <span class="value" *ngIf="fav.toDate">{{lang.getText('date_to')}} {{fav.toDate | date:appConfig.dateFormate}}<span class="comma">, </span></span>
                </span>
                <span class="value" *ngIf="fav.deliveryDate">{{lang.getText('project_delivery_date')}} {{fav.deliveryDate | date:appConfig.dateFormate}}<span class="comma">, </span></span>
                <span class="value" *ngIf="fav.owner">
                    <span *ngFor="let owner of owners">
                        <span *ngIf="owner.id == fav.owner">{{owner.name}}</span>
                    </span>
                    <span class="comma">, </span>
                </span>
                <span class="value" *ngIf="fav.client">  
                    <span *ngFor="let client of clients">
                        <span *ngIf="client.id == fav.client">{{client.name}}</span>
                    </span>
                    <span class="comma">, </span>
                </span>
                <span class="value" *ngIf="fav.unclaimed">
                  {{lang.getText('unclaimed')}}
                </span>
                <span class="value" *ngIf="fav.status">
                    <span *ngFor="let status of statuses">
                        <span *ngIf="status.id == fav.status">{{lang.getProjectStatus(status.code)}}</span>
                    </span>
                    <span class="comma">, </span>
                </span>
                <span class="value" *ngIf="fav.text">{{lang.getText('search_projects')}} {{fav.text}}</span>
            </div>
        </div>
    </div>
    <div class="modal-footer justify-content-lg-start">
        <button class="btn btn-primary" (click)="applyFavorites()" [disabled]="favFilterRadioIpt === null"> {{lang.getText('apply')}} </button>
    </div>
</ng-template>

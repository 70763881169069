import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import { JwtauthService } from 'src/app/services/jwtauth.service';
import { TokenService } from 'src/app/services/token.service';
import { environment as env } from 'src/environments/environment'
import { UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { HelperService } from 'src/app/services/helper.service';
import { ActivatedRoute } from '@angular/router';
import { LanguageService } from '../../services/language.service';
import { DisplaySettingsService } from '../../services/display-settings.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  /*
  acceptExcel = '.csv, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';
  */
  acceptExcel = '.pdf';
  apiURL = env['API_URL'];
  ratings = this.helpSer.ratingAry();
  lang;
  @ViewChild('langCombiTemp') langCombiTemp: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private token: TokenService,
    public jwtwis: JwtauthService,
    private fb: UntypedFormBuilder,
    private helpSer: HelperService,
    public language: LanguageService,
    public displaySettSer: DisplaySettingsService
    ) {
      helpSer.vendorAuthCheck();
      this.lang = language;
  }

  ngOnInit() {
    console.log("Lang" ,this.lang)
    this.getVendorId();
    this.getLanguages();
    this.getSpecCodes();
    this.getCurrencies();
    this.getMyDetails();
    this.getLogoData();
    this.getDisplayLabels();
  }

  disableEdit() {
    return ( !this.isVendor() && !this.jwtwis.isSuperUser() );
  }

  display:any="";
  actionStatus;
  vendorForm
  transServicesFa
  toolsExpertiseFa
  ratesFa = new UntypedFormArray([]);
  reviewItemsFa = new UntypedFormArray([]);
  contactsFa: UntypedFormArray;
  initForm() {
    this.vendorForm = this.fb.group(
      this.vendorDetail['vendor']
      /*
      name: this.vendorDetail['vendor'].name,
      email: this.vendorDetail['vendor'].email,
      address: this.vendorDetail['vendor'].address,
      c1_phone: this.vendorDetail['vendor'].c1_phone,
      c1_im: this.vendorDetail['vendor'].c1_im,
      c1_comment: this.vendorDetail['vendor'].address,
      */
    );
    this.transServicesFa = new UntypedFormArray([]);
    this.vendorForm.addControl('transServices', this.transServicesFa);
    this.toolsExpertiseFa = new UntypedFormArray([]);
    this.vendorForm.addControl('toolsExpertise', this.toolsExpertiseFa);
    if ( this.isVendor() == false ) {
      this.reviewItemsFa = new UntypedFormArray([]);
      this.vendorForm.addControl('reviewItems', this.reviewItemsFa);
    }
    this.contactsFa = this.fb.array([]);
    this.vendorDetail['contacts'].forEach((contact, ci) => {
      let fg = this.fb.group(contact);
      if (ci == 0) {
        fg.get('name').disable();
        fg.get('email').disable();
        fg.get('address').disable();
      } else {
        fg.disable();
      }
      this.contactsFa.push(fg);
    });
    this.vendorForm.addControl('contacts', this.contactsFa);

    this.vendorDetail['transServices'].map(v => {
      this.transServicesFa.push(this.fb.group(v))
    })
    this.vendorDetail['toolsExpertise'].map(v => {
      this.toolsExpertiseFa.push(this.fb.group(v))
    })
    this.vendorDetail['reviews'].map(v => {
      this.reviewItemsFa.push(this.fb.group(v))
    })
    this.reviewItemsFa.disable();

    if ( this.disableEdit() ) {
      this.vendorForm.disable();
    }

    if ( this.reloadOff ) {
      this.vendorForm.addControl('rates', this.ratesFa);
      return;
    } else {
      this.ratesFa = new UntypedFormArray([]);
      this.vendorForm.addControl('rates', this.ratesFa);
    }

    this.vendorDetail['rates'].map(v => {
      v.submited = false;
      this.ratesFa.push(this.fb.group(v))
    })
    this.ratesFa.disable();

    if ( this.disableEdit() ) this.vendorForm.disable();
  }

  addLangCombi() {
    if ( this.disableEdit() ) return;
    let fb = this.fb.group({
      source_lang_id: '',
      target_lang_id: '',
      native_speaker: false,
      specialization_code_id: null
    })
    this.transServicesFa.push(fb);
    this.setPitchHeight();
  }

  deleteLangCombi(tsi) {
    if ( confirm(this.lang.getText('are_you_sure')) )
      this.transServicesFa.removeAt(tsi);
  }

  addToolExp() {
    if ( this.disableEdit() ) return;
    let fb = this.fb.group({
      name: '',
      version: '',
      level: '',
      license: false,
      comments: '',
    })
    this.toolsExpertiseFa.push(fb);
  }

  deleteToolExp(tei) {
    if ( confirm(this.lang.getText('are_you_sure')) )
      this.toolsExpertiseFa.removeAt(tei);
  }

  addRate() {
    if ( this.disableEdit() ) return;
    let fb = this.fb.group({
      description: '',
      currency_id: 1,
      rate_per_unit: 0.00,
      status: '',
      by_vendor: this.isVendor() ? 1 : 0,
      submited: false
    })
    this.ratesFa.push(fb);
  }

  deleteRate(ri) {
    if ( confirm(this.lang.getText('are_you_sure')) )
      this.ratesFa.removeAt(ri);
  }

  addReviewItem() {
    if ( this.disableEdit() ) return;
    let fb = this.fb.group({
      title: '',
      review: 0.00,
    })
    this.reviewItemsFa.push(fb);
  }

  deleteReviewItem(rii) {
    if ( confirm(this.lang.getText('are_you_sure')) ) {
      this.reviewItemsFa.removeAt(rii);
    }
  }

  rateErrors
  submitRate(rate) {
    if ( this.disableEdit() ) return;
    let submited = false;
    if ( this.isVendor() ) {
      submited = true;
    } else {
      //
    }
    rate.patchValue({ status: this.lang.getText('rate-proposed'), submited: true });
    this.updateMyDetails();
    rate.patchValue({submited: false}); //to Fix: update as duplicate on second time
    rate.disable();
  }

  approveRate(rate) {
    if ( this.disableEdit() ) return;
    let postData = {
      token:    this.token.get(),
      vendorId: this.vendorId,
      values:   rate.value
    };
    postData['userType']  = 'user';
    if (this.isVendor())
      postData['userType']  = 'vendor';
    this.http.post(`${this.apiURL}vendor/rate-approve`, postData).subscribe(
      data => {
        rate.patchValue({status: 'approved'});
      },
      error => this.handleError(error)
    );
  }

  rejectRate(rate) {
    if ( this.disableEdit() ) return;
    let postData = {
      token:    this.token.get(),
      vendorId: this.vendorId,
      values:   rate.value
    };
    postData['userType']  = 'user';
    if (this.isVendor())
      postData['userType']  = 'vendor';
    this.http.post(`${this.apiURL}vendor/rate-reject`, postData).subscribe(
      data => {
        rate.patchValue({status: 'rejected'});
      },
      error => this.handleError(error)
    );
  }

  vendorDetail
  getMyDetails() {
    let postData = {
      token: this.token.get(),
      vendorId: this.vendorId
    };
    this.http.post(`${this.apiURL}vendor/my-details`, postData).subscribe(
      data => {
        this.vendorDetail = data;
        this.initForm();
      },
      error => this.handleError(error)
    );
  }

  languages
  getLanguages() {
    let formData = {
      token: this.token.get(),
      vendorId: this.vendorId
    };
    this.http.post(`${this.apiURL}languages`, formData).subscribe(
      data => {
        this.languages = data['result'];
      },
      error => this.handleError(error)
    );
  }

  specCodes
  getSpecCodes() {
    this.http.post(`${this.apiURL}spec-code`, this.token.getAPITokenData()).subscribe(
      data => {
        this.specCodes = data;
      },
      error => this.handleError(error)
    );
  }

  currencies
  getCurrencies() {
    let formData = {
      token: this.token.get(),
      vendorId: this.vendorId
    };
    this.http.post(`${this.apiURL}currencies`, formData).subscribe(
      data => {
        this.currencies = data['result'];
      },
      error => this.handleError(error)
    );
  }

  logoData
  getLogoData(){
    let formData = {
      token: this.token.get(),
      vendorId: this.vendorId
    };
    this.http.post(`${this.apiURL}vendor/logo-data`, formData).subscribe(
      data => {
        this.logoData = data;
      },
      error => this.handleError(error)
    );
  }

  reloadOff = false;
  validErrors;
  updateMyDetails() {
    if ( this.disableEdit() ) return;
    this.reloadOff = true;
    let postData = this.vendorForm.getRawValue();
    postData['token']     = this.token.get();
    postData['vendorId']  = this.vendorId;
    postData['userType']  = 'user';
    if (this.isVendor())
      postData['userType']  = 'vendor';
    this.validErrors = null;
    this.http.post(`${this.apiURL}vendor/update-my-details`, postData).subscribe(
      data => {
        this.reviewItemsFa.disable();
        this.actionStatus = data['result'];
        this.openModalDialog();
        this.getMyDetails();
      },
      error => {
        this.validErrors = error.error;
        this.handleError(error)
      }
    );
  }

  openModalDialog(){
    this.display='block'; //Set block css
 }
 closeModalDialog(){
  this.display='none'; //set none css after close dialog
 }

  uploadLogo(event) {
    if ( this.disableEdit() ) return;

    let fileList: FileList = event.target.files;
    if(fileList.length <= 0) return;
    let file: File = fileList[0];

    let formData:FormData = new FormData();
    formData.append('file', file);
    formData.append('token', this.token.get());
    formData.append('vendorId', this.vendorId);
    event.value = '';
    this.http.post(`${this.apiURL}vendor/logo-upload`, formData, {reportProgress: true, observe: 'events'})
      .subscribe(
        event => {
          if (event.type === HttpEventType.UploadProgress) {
            //this.uploadFilePercentDone(event);
          } else if (event instanceof HttpResponse) {
            this.getLogoData();
          }
        }
      );
  }

  updateLangCombi(event) {
    if ( this.disableEdit() ) return;
    let fileList: FileList = event.target.files;
    if(fileList.length <= 0) return;
    let file: File = fileList[0];
    let formData:FormData = new FormData();
    formData.append('file', file);
    formData.append('token', this.token.get());
    formData.append('vendorId', this.vendorId);
    event.value = '';
    this.http.post(`${this.apiURL}vendor/upload-lang-combi`, formData, {reportProgress: true, observe: 'events'})
      .subscribe(
        event => {
          if (event instanceof HttpResponse) {
            this.getMyDetails();
          }
        }
      );
  }

  updateHandTool(event) {
    if ( this.disableEdit() ) return;
    let fileList: FileList = event.target.files;
    if(fileList.length <= 0) return;
    let file: File = fileList[0];
    let formData:FormData = new FormData();
    formData.append('file', file);
    formData.append('token', this.token.get());
    formData.append('vendorId', this.vendorId);
    event.value = '';
    this.http.post(`${this.apiURL}vendor/upload-hand-tool`, formData, {reportProgress: true, observe: 'events'})
      .subscribe(
        event => {
          if (event instanceof HttpResponse) {
            this.getMyDetails();
          }
        }
      );
  }

  updateRateAgree(event) {
    if ( this.disableEdit() ) return;
    let fileList: FileList = event.target.files;
    if(fileList.length <= 0) return;
    let file: File = fileList[0];
    let formData:FormData = new FormData();
    formData.append('file', file);
    formData.append('token', this.token.get());
    formData.append('vendorId', this.vendorId);
    event.value = '';
    this.http.post(`${this.apiURL}vendor/upload-rates-agree`, formData, {reportProgress: true, observe: 'events'})
      .subscribe(
        event => {
          if (event instanceof HttpResponse) {
            this.getMyDetails();
          }
        }
      );
  }

  uploadDocId = null;
  updateISODoc(event) {
    if ( this.disableEdit() ) return;
    console.log(this.uploadDocId)
    let fileList: FileList = event.target.files;
    if(fileList.length <= 0) return;
    let file: File = fileList[0];
    let formData:FormData = new FormData();
    formData.append('file', file);
    formData.append('token', this.token.get());
    formData.append('iso_doc_id', this.uploadDocId);
    formData.append('vendorId', this.vendorId);
    event.value = '';
    this.http.post(`${this.apiURL}vendor/upload-iso-doc`, formData, {reportProgress: true, observe: 'events'})
      .subscribe(
        event => {
          if (event instanceof HttpResponse) {
            this.getMyDetails();
          }
        }
      );
  }

  updateReviewDoc(event) {
    if ( this.disableEdit() ) return;
    let fileList: FileList = event.target.files;
    if(fileList.length <= 0) return;
    let file: File = fileList[0];
    let formData:FormData = new FormData();
    formData.append('file', file);
    formData.append('token', this.token.get());
    formData.append('vendorId', this.vendorId);
    event.value = '';
    this.http.post(`${this.apiURL}review-document-upload`, formData, {reportProgress: true, observe: 'events'})
      .subscribe(
        event => {
          this.vendorForm.patchValue({su_review_document_path: file.name})
          if (event instanceof HttpResponse) {
            // this.getMyDetails();
          }
        }
      );
  }

  exportLangCombi() {
    let formData = {
      token: this.token.get(),
      vendorId: this.vendorId
    };
    this.http.post(`${this.apiURL}vendor/export-lang-combi`, formData).subscribe(
      data => {
        // console.log(data)
        let blob = new Blob([this.helpSer.getFileBuffer(data['file'])], { type: data['type'] });
        this.helpSer.downloadFile(blob, data['fileName']+'.'+data['extension']);
      },
      error => {
        this.handleError(error);
      }
    );
  }

  exportHandTool() {
    let formData = {
      token: this.token.get(),
      vendorId: this.vendorId
    };;
    this.http.post(`${this.apiURL}vendor/export-hand-tool`, formData).subscribe(
      data => {
        // console.log(data)
        let blob = new Blob([this.helpSer.getFileBuffer(data['file'])], { type: data['type'] });
        this.helpSer.downloadFile(blob, data['fileName']+'.'+data['extension']);
      },
      error => {
        this.handleError(error);
      }
    );
  }

  exportRateAgree() {
    let formData = {
      token: this.token.get(),
      vendorId: this.vendorId
    };;
    this.http.post(`${this.apiURL}vendor/export-rates-agree`, formData).subscribe(
      data => {
        let blob = new Blob([this.helpSer.getFileBuffer(data['file'])], { type: data['type'] });
        this.helpSer.downloadFile(blob, data['fileName']+'.'+data['extension']);
      },
      error => {
        this.handleError(error);
      }
    );
  }

  deleteISODoc(doc) {
    if ( doc.path == null ) {
      return;
    }
    if ( !confirm(this.lang.getText('are_you_sure')) ) {
      return;
    }
    let formData = {
      token: this.token.get(),
      iso_doc_id: doc.vendor_iso_document_id,
      vendorId: this.vendorId
    };
    this.http.post(`${this.apiURL}vendor/delete-iso-doc`, formData).subscribe(
      data => {
        doc.path = null;
      },
      error => {
        this.handleError(error);
      }
    );
  }

  downISODoc(isoDocId) {
    let formData = {
      token: this.token.get(),
      iso_doc_id: isoDocId,
      vendorId: this.vendorId
    };
    this.http.post(`${this.apiURL}vendor/down-iso-doc`, formData).subscribe(
      data => {
        let blob = new Blob([this.helpSer.getFileBuffer(data['file'])], { type: data['type'] });
        this.helpSer.downloadFile(blob, data['fileName']+'.'+data['extension']);
      },
      error => {
        this.handleError(error);
      }
    );
  }

  downReviewDoc() {
    let formData = {
      token: this.token.get(),
      vendorId: this.vendorId
    };
    this.http.post(`${this.apiURL}review-document-down`, formData).subscribe(
      data => {
        let blob = new Blob([this.helpSer.getFileBuffer(data['file'])], { type: data['type'] });
        this.helpSer.downloadFile(blob, data['fileName']+'.'+data['extension']);
      },
      error => {
        this.handleError(error);
      }
    );
  }

  overalReviewScore() {
    let totalRatings = 0;
    this.reviewItemsFa.controls.forEach(item => {
      totalRatings = totalRatings + parseInt(item.value.review);
    });
    if (totalRatings == 0)
      return totalRatings;
    return ( (totalRatings / this.reviewItemsFa.controls.length) / 5 ) * 100;
  }

  isVendor() {
    return this.jwtwis.isVenodor();
  }

  vendorId
  getVendorId(){
    this.vendorId = this.route.snapshot.paramMap.get('id');
    if (this.vendorId == null)
      this.vendorId = this.jwtwis.venodorId();
    console.log(this.vendorId)
  }

  getFileName(filePath){
    filePath = this.helpSer.getFileName(filePath);
    return filePath.replace(this.vendorId+"-", "");
  }

  starClicked(ri, item) {
    if (item.disabled) return;
    let si = ri+1;
    if ( item.value.review == si )
      si = ri;
    return item.patchValue({review: si});
  }

  disableSubmitRate(rate) {
    if ( rate.disabled )
      return true;
    return false;
  }

  disableChangeRate(rate) {
    if (this.vendorForm.value.cb_with_rate_agreement)
      return true;
    return !this.disableApproveRate(rate);
  }

  disableApproveRate(rate) {
    if ( this.isVendor() && rate.value.by_vendor == 1 )
      return true;
    if ( !this.isVendor() && rate.value.by_vendor == 0 )
      return true;
    return false;
  }

  disableRejectRate(rate) {
    return this.disableApproveRate(rate);
  }

  pitchHeight = 612
  setPitchHeight() {
    if (this.langCombiTemp.nativeElement == undefined) { return; }
    let height = this.langCombiTemp.nativeElement.offsetHeight+257;
    if (height < 590)
      return;
    let inc = 73;
    this.pitchHeight = height+inc;
    console.log(height, this.pitchHeight)
  }

  handleError(error) {
    this.jwtwis.handleError(error);
  }

  displayLabels = [];
  getDisplayLabels() {
    this.displaySettSer.getProjectDisplayLabels();
    this.displaySettSer.labelsObser.subscribe(labels => {
      this.displayLabels = labels;
    });
  }

  hideDisplayLabel(label) {
    return ! this.displayLabels.includes(label);
  }

}

import { Injectable } from '@angular/core';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class TranslationHelperService {
  decimalRound = 2;
  constructor(private helper_ser: HelperService) {
    //
  }

  calculateCatGro(catGroFA) {
    let subCost:number = 0;
    catGroFA.controls.map((catfb) => {
      let wordCont = catfb.getRawValue().word_count;
      wordCont *= 1;
      let amount = this.helper_ser.roundOfToFraction(this.helper_ser.multiply(wordCont, catfb.getRawValue().rate), this.decimalRound);
      catfb.patchValue({word_count: wordCont, amount: amount}, { emitEvent: false });
      subCost += amount;
    })
    return subCost;
  }

  calculateWordsCatGro(catGroFA) {
    let wordsCount: number = 0;
    catGroFA.controls.map((catfb) => {
      let values = catfb.getRawValue();
      let count = parseFloat(values.word_count);
      count = isNaN(count) || (values.display && !values.display) ? 0 : count;
      wordsCount += count;
    })
    return wordsCount;
  }

  calculateWordsWeightedCatGro(catGroFA, wordRate) {
    let wordsCount: number = 0;
    catGroFA.controls.map((catfb) => {
      let values = catfb.getRawValue();
      wordsCount += ( ( values.word_count / 100 ) * values.percentage );
    })
    return wordsCount;
  }
}

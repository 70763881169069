<div class="app-login-main-container">
<div class="login-header">
    <div class="col-sm-12">
      <img alt="Brand logo" class="brand-logo" src="{{publicURL}}logo/brand-logo.webp">
    </div>
  </div>
  <div class="container-fluid">
    <div class=" justify-content-md-center">
      <div class="design-main-container col-lg-6 col-md-6 col-sm-8">
      <div class="login-layout">
        <div class="inner-login-layout">
        <p class="login-message font-weight-bold">Enter the email address associated with your account and we'll send you a link to reset your password.</p>
        <form [formGroup]='forgotPasswordForm' (ngSubmit)="onSubmitForgotPasswordForm()" autocomplete="off">
          <p class="error">{{error}}</p>
          <p class="text-success">{{success}}</p>
          <div class="form-group">
            <input type="text" class="form-control" formControlName='email' placeholder="Enter Email">
          </div>
          <re-captcha id="recaptcha" class="recaptcha-wrap justify-content-center" name="recaptcha" (resolved)="resolved($event)" (errored)="errored($event)" errorMode="handled"></re-captcha>
          <div class="d-flex justify-content-center login-btn-container mt-3"> 
            <button type="submit" class="btn btn-primary login-btn">Reset Password</button>
          </div>
          <p class="forgot-password text-center">
            <a *ngIf="this.group != 'vendor'" routerLink="/" routerLinkActive="router-link-active"  class="forgot-password-link">Back to Login</a>
            <a *ngIf="this.group == 'vendor'" routerLink="/vendor" routerLinkActive="router-link-active"  class="forgot-password-link">Back to Login</a>
          </p>
        </form>
      </div>
      <div class="graphic-design-container">
        <div class="left-img">
          <img src="../../assets/img/teacup-flow-dezk.png" alt="Teacup Image">
        </div>
        <div class="right-img">
          <img src="../../assets/img/plant-flow-dezk.png" alt="Plant Image">
        </div>
      </div>
    </div>
      </div>
    </div>
  </div>
</div>

<div [hidden]="!saving  && initForm" class="container-fluid loader">
  <div class="body">
    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
  </div>
</div>
<form *ngIf="initForm" [formGroup]='projectEditForm' [ngClass]="{loading:saving}" class="outer_create_project innerproject_details project_deatails_edit pb-5">
  <input formControlName="vendorsCost" type="hidden" />
  <input formControlName="send_accounting" type="hidden" />
  <input formControlName="quote_rejected_at" type="hidden" />
  <input formControlName="submit_quote" type="hidden" />
  <input formControlName="approve_quote" type="hidden" />

  <div *ngIf="!createProject">
    <div class="prev-button">
      <span (click)="!loadingPrevNext() && nextProject()" style="cursor: pointer;">
        <i class="fa fa-arrow-left fa-lg" aria-hidden="true"></i></span>
    </div>
    <div class="next-button">
      <span (click)="!loadingPrevNext() && prevProject()" style="cursor: pointer;">
        <i class="fa fa-arrow-right fa-lg" aria-hidden="true"></i></span>
    </div>
  </div>

  <div *ngIf="saveProjectMsg == true" class="alert alert-success"role="alert" data-auto-dismiss="2000">
    <strong>{{lang.getText('success')}}</strong> {{lang.getText('project_saved')}}
  </div>
  <div class="project_status_outer" *ngIf="!(project.project_status_id != 0 && !isQuoteRejected() && !isQuoteCanceled())">
  </div>
  <div class="project_status_outer" *ngIf="project.project_status_id != 0 && !isQuoteRejected() && !isQuoteCanceled()">
    <h6>{{lang.getText('project_status')}}</h6>
    <div class="row outer_progress">
      <ul id="progress">
        <li *ngFor="let pstatus of pStatuses;" class="{{pstatus.code}}" [className]="project.project_status_id >= pstatus.id ? 'active' : 'inactive'">
          {{pstatus.name}}
        </li>
      </ul>
    </div>
  </div>
  <!-- [class]="'scrollable '+scrollable" -->
  <div [ngClass]="{statusFlowChart: project.project_status_id != 0 && !isQuoteRejected() && !isQuoteCanceled()}">
  <div class="project_deatails_edit project-form-top-section">
   <!--  The word rate format is invalid. -->
	<div class="form-errors">
		<p class="error" *ngFor="let error of errors; let ei = index;" [innerHtml]="getErrorMessage(error)"></p>
  </div>
  <div class="col manual-status-form" [hidden]=createProject>
    <div>
      <div class="row warn-form">
        <div class="col-xl-6">
          <label class="ms-3">{{lang.getText('manually_override_project_status_select_and_save_changes')}}</label>
          <select formControlName="project_status_id" class="form-control">
            <option></option>
            <option *ngFor="let status of pStatuses;" value="{{status.id}}">{{status.name}}</option>
          </select>
          <label
            [hidden]="projectEditForm.getRawValue().manual_status == 0"
            class="warning-text ms-3">
            {{lang.getText('warning_status_has_been_set_manually')}}
          </label>
        </div>
        <div class="col-xl-6">
          <label class="ms-3">{{ lang.getText('this_project_has') }} {{ project_relation_count }} {{ lang.getText('related_projects2') }}</label>
          <span class="related-project-view-add medium text-primary" (click)="openRelatedProjectModal(project.id)">{{lang.getText('view')}}/{{lang.getText('add')}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <span class="ms-3">{{lang.getText('order_number')}} {{orderId}}</span>
        </div>
      </div> 
    </div>
  </div>
	<div class="row">
    <div class="col-xl-6 col-lg-6 col-md-12">
      <div class="row">
        <div class="col-xl-12 mb-2 ms-1">
          <label>{{lang.getText('project_name2')}}</label>
        </div>
        <div class="col-xl-12">
          <input formControlName='name' appShadowCheck class="form-control" type="text">
          <!-- placeholder="{{lang.getText('type_project_name')}}" -->
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 mb-2 ms-1">
          <label>{{lang.getText('request_date')}}</label>
        </div>
        <div class="col-xl-12">
          <input formControlName='request_date' appShadowCheck class=" form-control " type="text" #dp="bsDatepicker" bsdatepickerdaydecorator    bsDatepicker  [bsConfig]="{ dateInputFormat: 'DD/MM/Y', customTodayClass: 'todaydate', rangeInputFormat: 'DD/MM/YYY' }"  (focus)="dp.show()"  (click)="dp.toggle()" />
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 mb-2 ms-1">
          <label>{{lang.getText('client_name2')}}</label>
        </div>
        <div class="col-xl-12">
          <ng-select
              *ngIf="!project['client_id']"
              class="client-dropdown"
              [clearable]="false"
              [items]="clients"
              bindLabel="name"
              bindValue="id"
              [multiple]="false"
              placeholder="Select Client"
              style="max-height: 320px;"
              (change)="clientsSelectionChanged($event.id)"
              [ngStyle]="{ 'max-height': '320px' }"
          >
          </ng-select>
          <input *ngIf="project['client_id']" class="form-control" [value]="clientName" readonly />
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 mb-2 ms-1">
          <label class="col-auto col-form-label col-form-label-sm">{{lang.getText('request_by_client')}}</label>
        </div>
        <div class="col-xl-12">
          <input appShadowCheck formControlName='request_by' class="smallin form-control " type="text" aria-label="Search">
          <!--  placeholder="{{lang.getText('type_client_contact_name')}}" -->
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 mb-2 ms-1">
          <label>{{lang.getText('client_reference')}}</label>
        </div>
        <div class="col-xl-12">
          <input appShadowCheck formControlName='client_ref' class=" form-control " type="text" aria-label="Search">
          <!-- placeholder="{{lang.getText('type_client_reference')}}" -->
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 mb-2 ms-1">
          <label>{{lang.getText('project_description')}}</label>
        </div>
        <div class="col-xl-12">
          <div class="form-group">
            <textarea appShadowCheck formControlName='discription' class="form-control" rows="5"></textarea>
            <!-- placeholder="{{lang.getText('project_description')}}" -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-12">
      <div class="row">
        <div class="col-xl-12 mb-2 ms-1">
          <label>{{lang.getText('currency')}}</label>
        </div>
        <div class="col-xl-12">
          <!-- <select appShadowCheck formControlName="currency_id" class="form-control" (change)="currencyChanged($event.target.value)">
            <option *ngFor="let currency of currencies;" value="{{currency.id}}">{{currency.name}}</option>
          </select> -->
          <ng-select (change)="currencyChanged($event.name)"
            [items]="currencies"
            [clearable]="false"
            bindLabel="name" 
            bindValue="id"
            notFoundText="{{ 'no_data_found' | translate }}" 
            formControlName="currency_id">
          </ng-select>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 mb-2 ms-1">
          <label>{{lang.getText('owner')}}</label>
        </div>
        <div class="col-xl-12">
          <input appShadowCheck formControlName='owner' class="form-control" type="text" readonly>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 mb-2 ms-1">
          <label>{{lang.getText('quote_issued_on')}}</label>
        </div>
        <div class="col-xl-12">
          <input appShadowCheck readonly formControlName='quote_issued_date' class="smallin form-control" type="text">
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 mb-2 ms-1">
          <label>{{lang.getText('cost_centre')}}</label>
        </div>
        <div class="col-xl-12">
          <input appShadowCheck formControlName='cost_centre' class="smallin form-control" type="text" />
          <!-- placeholder="{{lang.getText('type_cost_centre')}}"  -->
        </div>
      </div>

      <div class="row last_row">
        <div class="col-xl-12 mb-2 ms-1">
          <label style="vertical-align: top;">{{lang.getText('comments2')}} / {{lang.getText('instructions')}}</label>
          <!-- <br class="for_lsize"> -->
        </div>
        <div class="col-xl-12">
          <div class="form-group">
            <textarea formControlName='comments' class="form-control" rows="9"></textarea>
            <!--  placeholder="{{lang.getText('comments_instructions')}}" -->
          </div>
        </div>
      </div>

    </div>
  </div>
  </div>

<div [className]="scrollable == 'active' ? 'fixed-row-bottom' : ''">
  <div class="row save-btn-row align-items-center ps-2 mb-5">
    <div class="col-xl-12 col-lg-12 col-md-12 row d-flex justify-content-end align-items-center">
      <div class="col-xl-2 col-lg-3 col-md-4 clinet_search">
        <div class="scrollable-cb float-end">
          <input type="checkbox" class="form-check-input" [checked]="scrollable == 'active'" value="active" (change)=changedScrollable($event) />
          <span>{{lang.getText('lock_bar')}}</span>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-6">
        <a  class="btn btn-amber btn-sm px-4 float-end mb-md-2" (click)="cancelQuote()">{{lang.getText('cancel_without_quote')}}</a>
        <a [ngClass]="{'inactive': !unsavedProject}" (click)="checkClaimed() ? onSubmitProjectEditForm() : false" class="btn btn-primary save-btn btn-sm px-4 me-4 float-end mb-md-2" >{{lang.getText('save_changes')}}</a>
      </div>
    </div>
  </div>
</div>

<div class="project_tab_content">
<div class="main_tab_content">
  <tabset>
    <!-- QUOTING TAB -->
    <tab tab1 heading="{{lang.getText('quoting')}}" id="first">
      <div class="outer_main_tab">
        <div class="inner_main_tab">
          <div class="single_part_main">
            <div class="single_part_inner">
              <div class="main_head">
                <p>{{lang.getText('supplied_services')}}</p>
              </div>
              <div class="container supplied-services-container">
                <div class="col-12">
                  <app-checkboxes
                    [items]=supplierServices
                    [formArray]="projectEditForm.get('services')"
                    [disabled]="false" >
                  </app-checkboxes>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="isSAPSolProject" class="single_part_main">
            <div class="single_part_inner">
              <div class="main_head">
                <p>{{lang.getText('sap_integration')}}</p>
              </div>
              <app-sap-details [sapData]=sapData [projectId]=projectId></app-sap-details>
            </div>
          </div>
          <!-- <div class="single_part_main">
            <div class="single_part_inner">
            <div class="form-group">
              <div class="form-check">
                <label class="form-check-label">
                  <input formControlName="quoteDisplayBreakdowns" class="form-check-input" type="checkbox">{{lang.getText('display_breakdowns_in_quote')}}
                </label>
              </div>
            </div>
            </div>
          </div> -->
          <div *ngIf="clientId" class="single_part_main" [hidden]="hideDisplayLabel('quoting_supplied_languages_section')">
            <div class="single_part_inner">
              <div class="main_head">
                <p>{{lang.getText('supplied_languages')}}</p>
              </div>
              <div class="container supplied-languages-container">
                <div>
                  <app-project-client-language-translations
                    [projectEditDis] = projectEditDis
                    [projectId] = projectId
                    [wordMatchesA] = wordMatchesA
                    [wordMatchesB] = wordMatchesB
                    [translationsFA] = translationsFA
                    [projectEditForm] = projectEditForm
                    [deliveryBatchesFA] = deliveryBatchesFA
                    [showProjectProgress] ="showProjectProgress"
                    [showProjectJobProgress] ="showProjectJobProgress"
                    [languages] ="languages"
                    [trasnlation_pagination_data] ="trasnlation_pagination_data"
                    [checkClaimed] = isProjectClaimed
                    [checkXTMData] = checkXTMData()
                    [checkTradosData] = "checkTradosData('alc')"
                    [(unsavedProject)] = "unsavedProject"
                    (mapProjectClientTransEvent)="mapProjectQuoteTrasnlations($event)"
                    (updateCATEvent)="updateCAT($event)"
                    (openImportCATModalEvent)="openImportCATModal($event, importCATAnaTemp)"
                    (deleteClientTranEvent)="deleteClientTran($event)"
                    (onSourceLanguageChangeEvent)="onSourceLanguageChange()"
                    [translationsFAOrignal]=translationsFAOrignal
                    [quoteGoToLastPage]="quoteGoToLastPage"
                    (copyThisResTranEvent)="copyThisResTran($event)"
                    [client_id]="clientId"
                    [projectCurrencyName]="getProjectCurrencyCode()"
                  ></app-project-client-language-translations>
                </div>
                <div class="row col-12 mt-3">
                  <div class="col-9 my-auto">
                  <div class="col-md-12 col-sm-12 nospace">
                    <a *ngIf="!projectEditDis" (click)='checkClaimed() && ( checkTradosData("alc") || !checkXTMData()) ? addLanguageCombination() : false' class="btn btn-primary me-2 mb-2 btn-sm"
                    [class.disabled]="!checkTradosData('alc') || checkXTMData()"
                    >{{lang.getText('add_language_combination')}}</a>
                    <a *ngIf="! is_freelancer && !projectEditDis" 
                      (click)='checkClaimed() && ((checkTradosData("ccp") && !checkXTMData()) || checkXTMData()) ? openCreateCatModal() : false'
                      class="btn btn-primary me-2 mb-2 btn-sm" 
                      [class.disabled]="!checkTradosData('ccp') || checkXTMData() || checkPrhaseData()">
                      {{lang.getText('create_external_project')}}
                    </a>
                    <a *ngIf="!projectEditDis && (project.trados_data?.trados_project_id?.meta_value || checkXTMData())" 
                      (click)='checkClaimed() ? openCreateCatModalFilesOnly() : false'
                      class="btn btn-primary me-2 mb-2 btn-sm" 
                      [class.disabled]="!checkTradosData('vpp') && project.xtm_data?.xtm_project_status != 'NOT_STARTED'">
                      {{lang.getText('send_additional_files')}}
                    </a>

                    <a *ngIf="!projectEditDis && !checkXTMData() && project.trados_data?.trados_project_id?.meta_value" 
                      href="https://languagecloud.sdl.com/lc/t/{{project.trados_client_xlc_tenant}}/projects/{{project.trados_data?.trados_project_id?.meta_value}}/dashboard"
                      target="_blank"
                      class="btn btn-primary me-2 mb-2 btn-sm">
                      {{lang.getText('open_trados')}}
                    </a>
                    <a *ngIf="!projectEditDis && checkXTMData()" 
                      (click)='checkClaimed() ? viewProjectProgress() : false' 
                      class="btn btn-primary me-2 btn-sm mb-2"
                      >
                      {{lang.getText('view_project_progess')}}
                    </a>
                    <a *ngIf="! is_freelancer && (!projectEditDis && !checkXTMData())" 
                      (click)='checkClaimed() && enableTradosUpdateQuotingBtn() ? updateQuotingStatistics() : updateQuotingStatisticsWarning()'
                      class="btn btn-primary me-2 mb-2 btn-sm"
                      [class.disabled]="!enableTradosUpdateQuotingBtn()"
                      >
                      {{lang.getText('update_quoting_statistics')}}
                    </a>
                    <a *ngIf="!projectEditDis && checkXTMData()" 
                      (click)='checkClaimed() && enableUpdateQuotingBtn() ? updateQuotingStatistics() : updateQuotingStatisticsWarning()'
                      class="btn btn-primary me-2 mb-2 btn-sm update_quoting_metrics"
                      [class.disabled]="!enableUpdateQuotingBtn()"
                      >
                      {{lang.getText('sap_xtm_update_quoting_metrics')}}
                    </a>
                    <a *ngIf="!projectEditDis && checkXTMData()"
                      class="btn btn-primary me-2 mb-2 btn-sm"
                      (click)="financiallyCloseAllFinishedJobs('quote')"
                      >
                      {{ lang.getText('financially_close_all_finished_jobs') }}
                    </a>
                    <a *ngIf="!projectEditDis" [hidden]="!trasnlation_pagination_data.quote_show_hide_financially_closed"
                      class="btn btn-primary me-2 mb-2 btn-sm show_hide_financially_closed_btn"
                      (click)='showHideFinanciallyClosedJobs("quote", "hide")'
                      >
                      {{ lang.getText('hide_financially_closed_jobs') }}
                    </a>
                    <a *ngIf="!projectEditDis" [hidden]="trasnlation_pagination_data.quote_show_hide_financially_closed"
                      class="btn btn-primary me-2 mb-2 btn-sm show_hide_financially_closed_btn"
                      (click)='showHideFinanciallyClosedJobs("quote", "show")'
                      >
                      {{ lang.getText('show_financially_closed_jobs') }}
                    </a>

                    <a class="btn me-2 btn-sm mb-2" (click)="selectAllLanguageCombinations()" 
                    [ngClass]="isAllLanguagesCombinationsSelected() ? 'btn-disabled' : 'btn-success'"
                    >
                      {{lang.getText('display_all_language_combinations_in_quote')}}
                      <span *ngIf="isAllLanguagesCombinationsSelected()" class="color-success ps-1">
                        <em class="fa fa-check" aria-hidden="true"></em>
                      </span>
                    </a>
                    <a class="btn me-2 btn-sm mb-2" (click)="quoteDisplayBreakdownsFc.setValue(!quoteDisplayBreakdownsFc.value)" 
                    [ngClass]="quoteDisplayBreakdownsFc.value ? 'btn-disabled' : 'btn-success'"
                    >
                      {{lang.getText('include_all_breakdowns_in_quote')}}
                      <span *ngIf="quoteDisplayBreakdownsFc.value" class="color-success ps-1">
                        <em class="fa fa-check" aria-hidden="true"></em>
                      </span>
                    </a>
                  </div>
                  </div>
                  <div class="col-3">
                        <!-- <div class="badge-primary nospace text-center">
                          {{lang.getText('total_cost_supplied_languages')}}&nbsp;{{':'}}&nbsp;
                          <span class="text-nowrap">
                            {{ projectEditForm.getRawValue().langTransTotalCost | myCurrency: getProjectCurrencyCode()}} 
                          </span>
                          <input formControlName="langTransTotalCost" class="form-control font-weight-bold" type="hidden" min="0" readonly />
                        </div> -->
                        <app-total-display 
                          title = "total_cost_supplied_languages"
                          [amount] = "projectEditForm.getRawValue().langTransTotalCost"
                          [currencyCode] = "getProjectCurrencyCode()"
                        ></app-total-display>
                        <input formControlName="langTransTotalCost" class="form-control font-weight-bold" type="hidden" min="0" readonly />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="single_part_main" *ngIf="checkXTMData() && showXTMProjectProgress.display">
            <div class="single_part_inner">
              <div class="main_head">
                <p>{{lang.getText('project_progress')}}</p>
              </div>
              <div class="loader container-fluid text-center" *ngIf="loadingXTM">
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
              </div>
              <span>
                <div class="d-flex justify-between mb-3">
                  <div class="me-3">{{lang.getText('project_status')}}: 
                  </div>
                  <div style="text-transform: capitalize;">{{showXTMProjectProgress.data['status']}}</div> 
                </div>
                <div class="progress-bar-sec d-flex align-items-center">
                  <p>{{lang.getText('percentage_completed')}}: {{showXTMProjectProgress.data['percentage']}}%</p>
                  <div class="progress">
                    <div class="progress-bar progress-bar-striped {{showXTMProjectProgress.data['color']}} {{'w-' + showXTMProjectProgress.data['percentage']}}" role="progressbar" attr.aria-valuenow="{{showProjectProgress.data['percentage']}}" aria-valuemin="0"
                      aria-valuemax="100"
                      [style.width]="showXTMProjectProgress.data['percentage'] + '%'"></div>
                  </div>
                </div>
              </span>
            </div>
          </div>

          <div class="single_part_main">
            <div class="single_part_inner">
              <div class="main_head">
                <p>{{lang.getText('services')}}</p>
              </div>
              <div *ngIf="project['additionalServicesAPI'] && project['additionalServicesAPI'].length > 0" class="container">
                <div class="col-12">
                  <h4>{{lang.getText('sap_services')}}</h4>
                </div>
                <div *ngFor="let service of project['additionalServicesAPI']; let i = index" class="row">
                  <div class="col-3">
                    {{service['comment']}}
                  </div>
                  <div class="col-1">
                    {{service['unit_details']['name']}}
                  </div>
                  <div class="col-1">
                    {{service['volume']}}
                  </div>
                </div>
              </div>
              <div class="container services-container pb-2 my-2">
                <perfect-scrollbar style="max-height: 2000px;" [scrollIndicators]="true">
                  <div formArrayName="additionalServices" *ngFor="let trans of projectEditForm.get('additionalServices')['controls']; let i = index">
                    <div
                      [formGroupName]="i"
                      *ngIf="trans.getRawValue().delete != 'yes'"  class="row align-items-end" style="width:100%;">
                      <div class="col-sm-12 col-md-12 col-lg-4">
                        <div class="row">
                          <div class="col-md-12"> 
                            <div class="row">
                              <div class="col-md-6 col-sm-12 nospace invoiceable_service">
                                <div class="form-check form-switch">
                                  <input formControlName="invoiceable" type="checkbox" class="form-check-input">
                                  <label class="form-check-label">{{lang.getText('display_in_quote')}}</label>
                                </div>
                              </div>
                              <div class="col-md-6 col-sm-12 nospace invoiceable_service">
                                <div class="form-check form-switch">
                                  <input formControlName="financially_closed" type="checkbox" (change)="markServiceFinanciallyClosed($event.target.checked, trans)" class="form-check-input"> 
                                  <label class="form-check-label text-nowrap">{{ lang.getText('financially_closed') }}</label>
                                </div>
                              </div>
                            </div>
                            <label>{{lang.getText('item_description')}} {{i+1}}</label>
                          </div>
                        </div>
                        <div class="row nospace">
                          <div class="col-md-12">
                            <input appShadowCheck formControlName="comment" type="text" class="form-control" placeholder="{{lang.getText('free_text')}}" [attr.disabled]="trans.get('financially_closed').value ? true : null">
                          </div>
                          <div class="row nospace">
                            <div class="col-md-12" *ngFor="let lnk_batch of getLinkedBatches('service', trans['value'].pAsId)">
                              <p class="blue medium">{{lang.getText('batch_delivery_date')}}: {{helperSer.formateDateObj(lnk_batch.requested_at)}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-end col-md-12 col-lg-8 nospace">
                        <div class="col-2">
                          <label>{{lang.getText('unit')}}</label>
                          <select formControlName="unit" class="form-control" [attr.disabled]="trans.get('financially_closed').value ? true : null">
                            <option *ngFor="let addSerUnit of addSerUnits; let li = index" value="{{addSerUnit.id}}">{{addSerUnit.name}}</option>
                          </select>
                        </div>
                        <div class="col-2">
                          <label>{{lang.getText('volume')}}</label>
                          <input formControlName="volume" type="number" min="0" class="form-control" [attr.disabled]="trans.get('financially_closed').value ? true : null">
                        </div>
                        <div class="col-2">
                          <label>{{lang.getText('rate')}}</label>
                          <input formControlName="rate" type="number" min="0" class="form-control" [attr.disabled]="trans.get('financially_closed').value ? true : null">
                        </div>
                        <div class="col-1"></div>
                        <div class="col-5 row align-items-end pb-0">
                          <!-- <div class="col-md-3">
                            <div class="row ">
                              <div class="col-md-3 text-center">
                                <p>{{lang.getText('unit')}}</p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-12">
                                  <select appShadowCheck formControlName="unit" class="form-control" [attr.disabled]="trans.get('financially_closed').value ? true : null">
                                    <option *ngFor="let addSerUnit of addSerUnits; let li = index" value="{{addSerUnit.id}}">{{addSerUnit.name}}</option>
                                  </select>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-5">
                            <div class="row align-items-end ">
                              <div class="col-md-6">
                                <div class="row">
                                  <div class="col-md-12">
                                    <p>{{lang.getText('volume')}}</p>
                                  </div>
                                </div>
                                <div class="row pb-0">
                                  <div class="col-md-12">
                                    <input appShadowCheck formControlName="volume" type="number" min="0" class="form-control" [attr.disabled]="trans.get('financially_closed').value ? true : null">
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-5">
                                <div class="row">
                                  <div class="col-md-12">
                                    <p>{{lang.getText('rate')}}</p>
                                  </div>
                                </div>
                                <div class="row pb-0">
                                  <div class="col-md-12">
                                    <input appShadowCheck formControlName="rate" type="number" min="0" class="form-control" [attr.disabled]="trans.get('financially_closed').value ? true : null">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> -->
                          <div class="col-md-6 p-0 mt-3">
                            <div class="row md_rw badge-empty">
                              <div class="nospace text-center">
                                <app-total-display 
                                  title = "subtotal"
                                  [amount] = "trans.getRawValue().amount"
                                  [currencyCode] = "getProjectCurrencyCode()"
                                  type = ""
                                  col_l = "6"
                                  col_r = "6"
                                ></app-total-display>
                                <!-- <input formControlName="amount" readonly type="hidden" min="0" class="form-control" [attr.disabled]="trans.get('financially_closed').value ? true : null" /> -->
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 pe-0 pb-1">
                            <div class="row">
                              <div class="col-md-12 p-0">
                                <div class="p-0">
                                  <span *ngIf="!projectEditDis" (click)="checkClaimed() && !trans.get('financially_closed').value ? copyThisService(trans) : false" class="btn btn-circle color-primary" title="Copy">
                                    <svg style="width: 13px;"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M320 448v40c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V120c0-13.3 10.7-24 24-24h72v296c0 30.9 25.1 56 56 56h168zm0-344V0H152c-13.3 0-24 10.7-24 24v368c0 13.3 10.7 24 24 24h272c13.3 0 24-10.7 24-24V128H344c-13.2 0-24-10.8-24-24zm121-31L375 7A24 24 0 0 0 358.1 0H352v96h96v-6.1a24 24 0 0 0 -7-17z" fill="#18347b"/></svg>
                                  </span>
                                  <span *ngIf="!projectEditDis" (click)="checkClaimed() && !trans.get('financially_closed').value ? deleteClientService(trans) : false" class="btn btn-circle color-secondary" title="Delete">
                                    <em class="fa fa-trash" aria-hidden="true"></em></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center mt-3">
                    <div class="col-md-9">
                      <div class="row">
                        <div class="col-md-4 col-lg-12 nospace">
                          <a  *ngIf="!projectEditDis" class="btn me-2" (click)="selectAllServices()" 
                            [ngClass]="isAllServicesSelected() ? 'btn-disabled' : 'btn-success'"
                            >{{lang.getText('display_all_services_in_quote')}}
                            <span *ngIf="isAllServicesSelected()" class="color-success ps-1">
                              <em class="fa fa-check" aria-hidden="true"></em>
                            </span>
                          </a>
                          <a *ngIf="!projectEditDis" (click)="checkClaimed() ? addAdditionalService() : false" class="btn btn-success">{{lang.getText('add_service')}}</a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <app-total-display 
                          title = "total_cost_services"
                          [amount] = "projectEditForm.getRawValue().additionalServiceCost"
                          [currencyCode] = "getProjectCurrencyCode()"
                      ></app-total-display>
                      <input formControlName="additionalServiceCost" readonly class="form-control special_cost font-weight-bold" type="hidden" min="0"/>
                      <!-- <div class="row md_row badge-primary d-flex align-items-center">
                        <div class="nospace text-center">
                          {{lang.getText('total_cost_services')}}&nbsp;{{':'}}&nbsp;
                          <span class="text-nowrap">
                            {{ projectEditForm.getRawValue().additionalServiceCost | myCurrency: getProjectCurrencyCode() }}
                          </span>
                          <input formControlName="additionalServiceCost" readonly class="form-control special_cost font-weight-bold" type="hidden" min="0"/>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </perfect-scrollbar>
              </div>
            </div>
          </div>
          <div class="single_part_main">
            <div class="single_part_inner" *ngIf="project">
              <app-delivery-batches
                #delivery_batches_com
                [project_id]="projectId"
                [project]="project"
                [projectEditForm]="projectEditForm"
                [deliveryBatchesFA]="deliveryBatchesFA"
                [projectEditDis]="projectEditDis"
                [checkXTMData]="checkXTMData()"
                [bsConfig]="bsConfig"
                [hours]="hours"
                [minutes]="minutes"
                [languages]="languages"
                [checkClaimed]="checkClaimed()"
                [projectClaimed]="projectClaimed"
                [allowLinkingBathch]="allowLinkingBathch"
                [orderId]="orderId"
                >
              </app-delivery-batches>
            </div>
          </div>

          <file-upload *ngIf="all_files"
            (isClaimProject)="claimFromComponent($event)"
            [isCheckClaimed]="true"
            [readOnly]="projectEditDis"
            projectId="{{projectId}}"
            tag="quote-files"
            projectClaimed="projectClaimed"
            [input_files]="all_files"
            [hidden]="hideDisplayLabel('quoting_file_management_section')"
          >
          </file-upload>

          <div class="single_part_main cat_analysis" *ngIf="createProject">
            <div class="single_part_inner">
              <div class="main_head">
                <p>{{lang.getText('file_management')}}</p>
                {{lang.getText('file_access')}}
                  <a
                    class="btn btn-secondary small_size_text2"
                    (click)="checkClaimed() ? alertFileEnable() : false"
                    >{{lang.getText('enable')}}</a>
                  <span class="btn color-red">{{lang.getText('file_access_disabled')}}</span>
              </div>
            </div>
          </div>
          <div class="single_part_main">
            <div class="single_part_inner">
              <div class="main_head">
                <p>{{lang.getText('quote_approval_status')}}</p>
              </div>
              <div class="container quote-approval-status-container">
                <div class="row" *ngIf="project['quoteUpdatedByPM']">
                  <div class="col-md-5">
                    <span>{{lang.getText('updated_by_pm')}}</span>
                    <span>
                      ({{project['quoteUpdatedByPM']}})
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-9">
                      <div class="row mb-1">
                        <span class="ps-0">{{lang.getText('quote_approved_by')}}
                        <span *ngIf="isQuoteApproved()">
                          ({{clientName}})
                          <input class="ms-2 requestByInput" type="text" readonly [value]=projectEditForm.getRawValue().request_by />
                        </span></span>
                      </div>
                      <div class="form-group row">
                        <div for="inputPassword" class="col-sm-3 col-form-label no-padding-left">{{lang.getText('client_comments')}}</div>
                        <div class="col-sm-12 nospace">
                          <div class="col-sm-8">
                          <textarea formControlName='client_comment' class="form-control" rows="5"></textarea>
                          </div>  
                        </div>
                      </div>
                  </div>
                  <div class="col-md-3 align-content-end mt-4 nospace">
                    <div *ngIf="quoteLastUpdated" class="form-group row">
                      <label for="staticEmail" class="col-sm-3 col-form-label">{{lang.getText('updated_on')}}</label>
                      <div class="col-sm-5">
                        <input class="form-control" type="text" readonly [value]="quoteLastUpdated">
                      </div>
                    </div>
                    <div class="mb-3">
                      <app-total-display 
                        type="badge-empty"
                        title = "total_cost_before_discount"
                        [amount] = "projectEditForm.getRawValue().totalBeforeDiscount"
                        [currencyCode] = "getProjectCurrencyCode()"
                      ></app-total-display>
                      <input formControlName="totalBeforeDiscount" class="form-control  text-start" type="hidden" min="0" readonly />
                    </div>

                    <div [hidden]="clientDiscountHidden" class="mb-3">
                      <div  class="nospace col-12 text-center align-items-center badge-empty d-flex" >
                        <div class="nospace ps-2 text-start col-8">
                          {{lang.getText('discount_amount')}}&nbsp;{{':'}}&nbsp;
                        </div> 
                        <div class="nospace pe-2 text-start col-4 d-flex align-items-center">
                          <span>{{ null | myCurrency:getProjectCurrencyCode() }}</span>
                          <span class="word-break">
                            <input formControlName="clientDiscount" class="form-control remove-num-arrow text-start d-inline-flex align-items-center" type="number" min="0" style="padding: 0px 5px;">
                          </span>
                        </div>
                      </div>
                    </div>
                    <div [hidden]="clientDiscountPercentageHidden" class="mb-3">
                      <div  class="nospace col-12 text-center align-items-center badge-empty d-flex" >
                        <div class="nospace ps-2 text-start col-8">
                          {{lang.getText('discount_percentage')}}&nbsp;{{':'}}&nbsp;
                        </div> 
                        <div class="nospace pe-2 text-start col-4">
                          <span class="word-break">
                            <input formControlName="clientDiscountPercentage" class="form-control remove-num-arrow text-start d-inline-flex align-items-center" type="number" min="0" style="padding: 0px 5px;">
                          </span>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="row badge-empty mb-3 align-items-center">
                      <div class="nospace text-center">
                        {{lang.getText('total_cost_before_discount')}}&nbsp;{{':'}}&nbsp;
                        <span class="text-nowrap">
                          {{ projectEditForm.getRawValue().totalBeforeDiscount | myCurrency: getProjectCurrencyCode() }}
                        </span>
                        <input formControlName="totalBeforeDiscount" class="form-control  text-start" type="hidden" min="0" readonly />
                      </div>
                    </div> -->
                    <!-- <div [hidden]="clientDiscountHidden" class="row badge-empty mb-3 align-items-center">
                      <div class="nospace text-center">
                        {{lang.getText('discount_amount')}}&nbsp;{{':'}}&nbsp;
                        <span class="text-nowrap">
                          {{ projectEditForm.getRawValue().clientDiscount | myCurrency: getProjectCurrencyCode() }}
                        </span>
                        <input appTwoDigitDecimaNumber formControlName="clientDiscount" class="form-control remove-num-arrow  text-start" type="hidden" min="0">
                      </div>
                    </div> -->
                    <!-- <div [hidden]="clientDiscountPercentageHidden" class="row badge-empty mb-3 align-items-center">
                      <div class="nospace text-center">
                        {{lang.getText('discount_percentage')}}&nbsp;{{':'}}&nbsp;
                        <span class="text-nowrap">
                          {{ projectEditForm.getRawValue().clientDiscountPercentage }}
                        </span>
                        <input appTwoDigitDecimaNumber formControlName="clientDiscountPercentage" class="form-control remove-num-arrow text-start" type="hidden" min="0">
                      </div>
                    </div> -->
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-9">
                    <!-- <span>{{lang.getText('status2')}}</span> -->
                    <div *ngIf="checkClaimed(false)"
                      (click)="checkClaimed() ? openGenerateQuoteModal() : false"
                      class="form-check form-check-inline nospace">
                      <a
                        class="btn btn-primary me-2">
                        {{lang.getText('submit_quote')}}
                      </a>
                    </div>
                    <div *ngIf="!checkClaimed(false)"
                        (click)="checkClaimed()"
                        class="form-check form-check-inline nospace">
                      <a
                        class="btn btn-primary me2">
                        {{lang.getText('submit_quote')}}
                      </a>
                    </div>
                    <div *ngIf="!isQuoteSent()" (click)="checkClaimed() ? helperSer.alertIt(lang.getText('please_submit_the_quote_before_you_approve_it')) : false" class="form-check form-check-inline nospace">
                      <a class="btn btn-secondary me-2">{{lang.getText('approved')}}</a>
                    </div>
                    <div *ngIf="isQuoteSent()" (click)="checkClaimed() ? switchStatus(3) : false" class="form-check form-check-inline nospace">
                      <a [ngClass]="isQuoteApproved() ? 'btn-success' : 'btn-secondary'" class="btn me-2">{{lang.getText('approved')}}</a>
                    </div>
                    <div *ngIf="!isQuoteSent()"
                      (click)="checkClaimed() ? helperSer.alertIt(lang.getText('please_submit_the_quote_before_you_reject_it')) : false"
                      class="form-check form-check-inline nospace">
                      <a class="btn btn-disabled me-2">{{lang.getText('rejected')}}</a>
                    </div>
                    <div
                      *ngIf="isQuoteSent()"
                      (click)="checkClaimed() ? quoteRejected() : false"
                      class="form-check form-check-inline nospace">
                        <a [ngClass]="isQuoteRejected() ? 'btn-danger' : 'btn-primary'" class="btn me2">{{lang.getText('rejected')}}</a>
                    </div>
                    <div
                      *ngIf="isQuoteApproved()"
                      class="form-check form-check-inline">
                      <span class="text-uppercase success_custom_col color-green">{{lang.getText('quote_approved')}}</span>
                    </div>
                    <div
                      *ngIf="isQuoteRejected()"
                      class="form-check form-check-inline">
                      <span class="text-uppercase success_custom_col color-red">{{lang.getText('quote_rejected')}}</span>
                    </div>
                    <div
                      *ngIf="isQuoteIssued()"
                      class="form-check form-check-inline">
                      <span class="text-uppercase success_custom_col color-amber">{{lang.getText('quote_issued')}}</span>
                    </div>
                  </div>
                  <div class="col-md-3 nospace">
                    <app-total-display 
                      title = "total_cost"
                      [amount] = "projectEditForm.getRawValue().totalCost"
                      [currencyCode] = "getProjectCurrencyCode()"
                    ></app-total-display>
                    <input formControlName="totalCost" class="form-control text-center" type="hidden" min="0" readonly />
                    <!-- <div class="row align-items-center badge-primary">
                      <div class="nospace text-center">
                        {{lang.getText('total_cost')}}&nbsp;{{':'}}&nbsp;
                        <span class="text-nowrap">
                          {{ projectEditForm.getRawValue().totalCost | myCurrency: getProjectCurrencyCode() }}
                        </span>
                        <input formControlName="totalCost" class="form-control  text-center" type="hidden" min="0" readonly />
                      </div>
                    </div> -->
                  </div>
                </div>  
              </div>
            </div>
          </div>
        </div>
      </div>
    </tab>
    
    <!--  RESOURCING TAB -->
    <tab tab2 [active]="resourcingTab" heading="{{lang.getText('resourcing')}}" id="second" class="resourcing-tab">
      <div class="outer_main_tab">
        <div class="inner_main_tab">
          <div class="single_part_main">
            <div class="single_part_inner">
              <div class="main_head">
                <p>{{lang.getText('vendor_summary')}}</p>
              </div>
              <div class="container vendor-summary pt-4 pb-2">
                <div class="vendor_outer" id="vendors-body">
                  <div class="row row-secondary p-2 text-start">
                    <div class="col-2"><label>{{lang.getText('vendor_name')}}</label></div>
                    <div class="col-1"><label>{{lang.getText('role')}}</label></div>
                    <div class="col-2"><label>{{lang.getText('start_date')}}</label></div>
                    <div class="col-2"><label>{{lang.getText('delivery_schedule')}}</label></div>
                    <div class="col-1"><label>{{lang.getText('status')}}</label></div>
                    <div class="col-2"><label>{{lang.getText('po_amount')}}</label></div>
                    <div class="col-1"></div>
                    <div class="col-1">
                      <select formControlName="currencyAllVendors" class="form-control ps-2 text-center" (change)="currencyChanged($event.target.value)">
                        <option *ngFor="let currency of currencies;" value="{{currency.id}}">
                          <span>
                            {{ null | myCurrency:currency.name }}
                          </span> 
                          {{currency.name}}</option>
                      </select>
                    </div> 
                  </div>
                  <div class="row row-primary mt-2 p-2 text-start vendor-row" *ngFor="let vendor of projectEditForm.get('vendors')['controls']; let vi = index;"
                  (click)="checkClaimed() ? displayThisVendor(vendor.getRawValue().vendor_id) : false"
                  [hidden]="vendor.getRawValue().delete == true  || !vendor.getRawValue().show_page"
                  [ngClass]="vendor.getRawValue().display ? 'selected' : ''" >
                    <div class="col-2 ds_link">{{getVendorName(vendor.getRawValue().vendor_id)}}</div>
                    <div class="col-1">{{getVendorServiceRoles(vendor.getRawValue().vendor_id)}}</div>
                    <div class="col-2">{{vendor.getRawValue().created_at}}</div>
                    <div class="col-2" (click)="checkClaimed() ? openModalWithClass(vendorScheduleModal, vi) : false">
                      <a class="ds_link">{{lang.getText('delivery_schedule')}}</a>
                    </div>
                    <div class="col-1">
                      <span *ngIf="vendor.getRawValue().delivered_at != null && vendor.getRawValue().delivered_at.length > 12" class="vendor-status-job-delivered-text">
                        {{lang.getText('job_delivered')}}
                      </span>
                      
                      <span *ngIf="vendor.getRawValue().confirm == null && vendor.getRawValue().delivered_at == null">
                        <span *ngIf="vendor.getRawValue().heads_up === 0" class="vendor-status-submited-text">
                          {{lang.getText('heads_up_submitted')}}
                        </span>
                        <span *ngIf="vendor.getRawValue().heads_up === 1" class="vendor-status-accepted-text">
                          {{lang.getText('heads_up_accepted')}}
                        </span>
                        <span *ngIf="vendor.getRawValue().heads_up === 2" class="vendor-status-cancel-text">
                          {{lang.getText('heads_up_rejected')}}
                        </span>
                      </span>

                      <span *ngIf="vendor.getRawValue().delivered_at == null">
                        <span *ngIf="vendor.getRawValue().confirm === 1" class="vendor-status-confirmed-text">
                          {{lang.getText('confirmed_project')}}
                        </span>
                        <span *ngIf="vendor.getRawValue().confirm === 0" class="vendor-status-cancel-text">
                          {{lang.getText('canceled_project')}}
                        </span>
                      </span>
                    </div>

                    <div class="col-2">
                      <app-total-display 
                        title = ""
                        [amount] = "vendor.value.totalCost"
                        [currencyCode] = "getProjectVendorCurrencyCode()"
                        type = ""
                        col_l = "0"
                        col_r = "12"
                      ></app-total-display>
                    </div>
                    <div class="col-1 comment-text-style">
                      <span *ngIf="vendor.value.deliveryComments">
                        {{lang.getText('comments2')}}
                      </span>
                    </div>
                    <div class="col-1">
                      <div class="form-check form-switch">
                      <input class="form-check-input"
                          type="checkbox"
                          (click)="vendor.patchValue({selected: !vendor.getRawValue().selected}, formEventStop)" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-9 nospace">
                    <div class="row">
                      <div class="col-md-4 nospace">
                        <div class="form-check form-check-inline ps-0">
                          <a *ngIf="!projectEditDis" (click)="checkClaimed() ? openAddVendorModal() : false" class="btn btn-primary">{{lang.getText('add_vendor')}}</a>
                          <a class="btn btn-primary ms-2" (click)="checkClaimed() ? deleteVendor() : false">{{lang.getText('delete_selection')}}</a>
                        </div>
                      </div>
                      <div class="col-md-8">
                        <!-- <div class="form-group row">
                          <label for="staticEmail" class="col-sm-6 col-form-label">{{lang.getText('currency_all_vendors')}}</label>
                          <div class="col-sm-6">
                            <select formControlName="currencyAllVendors" class="form-control" (change)="currencyChanged($event.target.value)">
                              <option *ngFor="let currency of currencies;" value="{{currency.id}}">{{currency.name}}</option>
                            </select>
                          </div>
                        </div> -->
                        <app-pagination
                          #paginationVendorCom
                          theme = 'center-align-btn'
                          (go_to_page) = "paginateVendors($event)"
                        >
                        </app-pagination>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 nospace">
                    <div class="row ">
                      <app-total-display 
                        title = "total_po_amount_all_vendors"
                        [amount] = "projectEditForm.value.vendorsCost"
                        [currencyCode] = "getProjectVendorCurrencyCode()"
                      ></app-total-display>
                      <!-- <div class="row badge-primary text-center">
                        <div class="nospace text-center">
                          {{lang.getText('total_po_amount_all_vendors')}}&nbsp;{{':'}}&nbsp;
                          <span class="text-nowrap">
                            {{ projectEditForm.value.vendorsCost | myCurrency: getProjectVendorCurrencyCode()}}
                          </span>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="main_vendor_details">
            <div formArrayName="vendors" *ngFor="let vendor of projectEditForm.get('vendors')['controls']; let vi = index" class="single_part_main">
              <div [formGroupName]="vi" class="single_part_inner" *ngIf="vendor.value.display && !vendor.getRawValue().delete">
                <input fromControlName="display" type="hidden"/>
                <input fromControlName="selected" type="hidden"/>
                <input fromControlName="delete" type="hidden"/>
                <input fromControlName="totalCost" type="hidden"/>
                <input fromControlName="heads_up" type="hidden"/>
                <input fromControlName="confirm" type="hidden"/>
                <input fromControlName="delivered_at" type="hidden"/>
                <input formControlName="file_access" type="hidden">
                <input formControlName="created_at" type="hidden">
                <input formControlName="pay_req_CB" type="hidden">
                <div class="main_head">
                  <p>{{lang.getText('vendor')}} {{vi+1}} {{lang.getText('details')}}</p>
                </div>
                <div class="container">
                  <div class="row">
                    <table class="table table-border" aria-label="details">
                      <thead role="rowgroup">
                        <tr role="row">
                          <th id="name" role="">{{lang.getText('name')}}</th>
                          <th id="email" role="columnheader">{{lang.getText('email')}}</th>
                          <th id="review_score" role="columnheader">{{lang.getText('review_score')}}</th>
                          <th id="rates" role="columnheader"></th>
                          <th id="th-view" role="columnheader"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {{getVendorName(vendor.getRawValue().vendor_id)}}
                          </td>
                          <td>
                            {{vendor.getRawValue().contact}}
                          </td>
                          <td>
                            <span class="score">
                              <div class="score-wrap">
                                <span class="stars-active" [style.width.%]="( 100 * getVendorDetails(vendor.getRawValue().vendor_id).reviewAvg ) / 5">
                                  <i
                                    *ngFor="let rate of helperSer.ratingAry(); let ri = index;"
                                    class="fa fa-star icon-c rating orange" aria-hidden="true"></i>
                                </span>
                                <span class="stars-inactive">
                                  <i
                                    *ngFor="let rate of helperSer.ratingAry(); let ri = index;"
                                    class="fa fa-star icon-c rating" aria-hidden="true"></i>
                                </span>
                              </div>
                            </span>
                          </td>
                          <td>
                            <a (click)="checkClaimed() ? openRatesModal(vendor.getRawValue().vendor_id) : false" class="cur-pointer">
                              <u>{{lang.getText('rates')}}</u>
                            </a>
                          </td>
                          <td>
                            <a [href]="'/vend/profile/'+vendor.getRawValue().vendor_id" target="_blank" style="color: black;">
                              <u>{{lang.getText('display_all_details')}}</u>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="single_part_main" [hidden]="hideDisplayLabel('resourcing_vendor_supplied_languages_section')">
                    <div class="single_part_inner">
                      <div class="main_head">
                        <p>{{lang.getText('vendor')}} {{vi+1}} {{lang.getText('supplied_languages')}}</p>
                      </div>
                      <div class="ms-4">
                        <div class="ms-4 mb-4 supplied-services-container">
                          <app-project-vendor-language-translations
                            [vendorFc] = vendor
                            [projectEditDis] = projectEditDis
                            [projectId] = projectId
                            [pvi] = vi
                            [wordMatchesA] = wordMatchesA
                            [wordMatchesB] = wordMatchesB
                            [languages] ="languages"
                            [trasnlation_pagination_data] ="trasnlation_pagination_data"
                            [supplierServices]=supplierServices
                            [checkClaimed] = checkClaimed()
                            [checkXTMData] = checkXTMData()
                            [updateChildTrasnFA] = "updateChildTrasnFA"
                            [hours]=hours
                            [minutes]=minutes
                            [bsConfig]=bsConfig
                            (mapProjectResTrasnlationsEvent)="mapProjectResTrasnlations($event)"
                            (updateCATEvent)="updateCAT($event)"
                            (openImportCATModalEvent)="openImportCATModal($event, importCATAnaTemp)"
                            (deleteVendorTranEvent)="deleteVendorTran($event)"
                            [resGoToLastPage]="resGoToLastPage"
                            (submit_project)="onSubmitProjectEditForm()"
                            (amount_changed)="update_financial_details()"
                            [addVenTrans]="addVenTrans"
                          ></app-project-vendor-language-translations>
                        </div>
                      </div>
                      <div class="row align-items-center">
                        <div class="col-lg-9 col-md-12 no-padding">
                          <div class="">
                            <!-- <div class="col-md-3 col-sm-12 nospace">
                              <button class="btn btn-yellow btn-sm me-2"
                              [attr.disabled]="trasnlation_pagination_data.res_page == 1 || trasnlation_pagination_data.res_page_loading ? true : null"
                              (click)="trasnlation_pagination_data.res_page == 1 || trasnlation_pagination_data.res_page_loading ? false : getPageOfResLangTrans(vi, 'prev')">{{lang.getText('previous')}}</button>
                              <button class="btn btn-yellow btn-sm"
                              [attr.disabled]="trasnlation_pagination_data.res_page == trasnlation_pagination_data.res_total_pages || trasnlation_pagination_data.res_page_loading ? true : null"
                              (click)="trasnlation_pagination_data.res_page == trasnlation_pagination_data.res_total_pages || trasnlation_pagination_data.res_page_loading ? false : getPageOfResLangTrans(vi, 'next')">{{lang.getText('next')}}</button>
                            </div> -->
                            <!-- <div class="col-md-9 col-sm-12 vendor no-padding"> -->
                              <a *ngIf="!projectEditDis" 
                                (click)="checkClaimed() && !checkXTMData() ? addVendorTranslation(vi) : false" 
                                class="btn btn-primary btn-sm me-2"
                                [class.disabled]="checkXTMData()">{{lang.getText('add_language_combination')}}</a>
                              <a *ngIf="!projectEditDis && checkXTMData()"
                                class="btn btn-primary me-2 btn-sm"
                                (click)="financiallyCloseAllFinishedJobs('res', vendor.get('vendor_id').value)"
                                >
                                {{ lang.getText('financially_close_all_jobs') }}
                              </a>
                              <a *ngIf="!projectEditDis" [hidden]="!trasnlation_pagination_data.res_show_hide_financially_closed"
                                class="btn btn-primary btn-sm me-3 show_hide_financially_closed_btn"
                                (click)='showHideFinanciallyClosedJobs("res", "hide")'
                                >
                                {{ lang.getText('hide_financially_closed_jobs') }}
                              </a>
                              <a *ngIf="!projectEditDis" [hidden]="trasnlation_pagination_data.res_show_hide_financially_closed"
                                class="btn btn-primary btn-sm me-3 show_hide_financially_closed_btn"
                                (click)='showHideFinanciallyClosedJobs("res", "show")'
                                >
                                {{ lang.getText('show_financially_closed_jobs') }}
                              </a>
                            <!-- </div>
                            <div class="col-md-6 ">

                            </div> -->
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-12 nospace">
                          <app-total-display 
                            title = "total_cost_supplied_languages"
                            [amount] = "vendor.getRawValue().LangTransCost"
                            [currencyCode] = "getProjectVendorCurrencyCode()"
                          ></app-total-display>
                          <input formControlName="LangTransCost" class="form-control" type="hidden" min="0" readonly>
                          <!-- <div class="row md_rw badge-primary">
                            <div class="nospace text-center">
                              {{lang.getText('total_cost_supplied_languages')}}&nbsp;{{':'}}&nbsp;
                              <span class="text-nowrap">
                                {{ vendor.getRawValue().LangTransCost | myCurrency: getProjectVendorCurrencyCode()}}
                              </span>
                              <input formControlName="LangTransCost" class="form-control" type="hidden" min="0" readonly>
                            </div>
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="single_part_main">
                    <div class="single_part_inner">
                      <div class="main_head">
                        <p>{{lang.getText('vendor')}} {{vi+1}} {{lang.getText('services')}}</p>
                      </div>
                      <div class="row">
                        <perfect-scrollbar class="nospace" style="max-height: 2080px;" [scrollIndicators]="true">
                          <div formArrayName="additionalServices" *ngFor="let addService of vendor.get('additionalServices').controls; let asi = index" class="row">
                            <div [formGroupName]="asi" class="row" *ngIf="addService.getRawValue().delete != 'yes'" >

                              <input formControlName="pAsId" type="hidden">
                              <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="row">
                                  <div class="col-md-3 col-sm-12">
                                    <label>
                                      <input 
                                        formControlName="financially_closed" 
                                        type="checkbox"
                                        (change)="markServiceFinanciallyClosed($event.target.checked, addService)"> 
                                        {{ lang.getText('financially_closed') }}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-4 col-lg-4">
                                <div class="row">
                                  <div class="col-md-12">
                                    <p>{{lang.getText('item_description')}} {{asi+1}}</p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-8">
                                    <input formControlName="comment" type="text" class="form-control" placeholder="" [attr.disabled]="addService.get('financially_closed').value ? true : null">
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-5 col-lg-8">
                                <div class="row">
                                  <div class="col-md-3">
                                    <div class="row ">
                                      <div class="col-md-3 text-center">
                                        <p>{{lang.getText('unit')}}</p>
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col-md-12">
                                          <select formControlName="unit" class="form-control" [attr.disabled]="addService.get('financially_closed').value ? true : null">
                                            <option *ngFor="let addSerUnit of addSerUnits; let li = index" value="{{addSerUnit.id}}">{{addSerUnit.name}}</option>
                                          </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-5">
                                    <div class="row pt-0">
                                      <div class="col-md-5">
                                        <div class="row">
                                          <div class="col-md-12">
                                            <p>{{lang.getText('volume')}}</p>
                                          </div>
                                        </div>
                                        <div class="row">
                                          <div class="col-md-12">
                                            <input formControlName="volume" type="number" min="0" class="form-control" [attr.disabled]="addService.get('financially_closed').value ? true : null">
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-6">
                                        <div class="row">
                                          <div class="col-md-12">
                                            <p>{{lang.getText('rate')}}</p>
                                          </div>
                                        </div>
                                        <div class="row">
                                          <div class="col-md-12">
                                            <input formControlName="rate" type="number" min="0" class="form-control" [attr.disabled]="addService.get('financially_closed').value ? true : null">
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-2">
                                    <div class="row">
                                      <div class="col-md-12">
                                        <p>{{lang.getText('subtotal')}}&nbsp;{{':'}}&nbsp;</p>
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col-md-12">
                                          <input formControlName="amount" readonly type="number" min="0" class="form-control" [attr.disabled]="addService.get('financially_closed').value ? true : null">
                                      </div>
                                    </div>
                                  </div>
                                <div class="col-md-2 pe-0">
                                  <div class="row">
                                    <div class="col-md-12 pe-0 ps-0">
                                      <div class="col-lg-4 col-md-6 col-sm-12 pe-0">
                                        <span *ngIf="!projectEditDis" (click)="!addService.get('financially_closed').value && checkClaimed() ? deleteClientService(addService) : false" [class.disabled]="addService.get('financially_closed').value ? true : false" title="Delete">
                                          <em class="fa fa-trash fa-lg" style="padding-top:35px;" aria-hidden="true"></em>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                </div>
                              </div>
                              <div formArrayName="batches" class="container">
                                <div *ngFor="let batch of addService.get('batches').controls; let bi = index;">
                                  <div [formGroupName]="bi" *ngIf="batch.getRawValue().delete != 'yes'" class="row">
                                    <input formControlName="project_translate_id" type="hidden" />
                                    <div class="col-xl-6 col-lg-12 col-md-12">
                                      <div class="row" formGroupName="delivery_at">
                                        <div class="col-xl-4 col-lg-2 col-md-5 nospace" style="padding-top: 15px;">
                                          <p>{{lang.getText('delivery_date_batch')}} {{bi+1}}</p>
                                        </div>
                                        <div class="col-xl-8 col-lg-10 col-md-7 nospace">
                                          <div class="row">
                                            <div class="col-lg-8 col-md-6">
                                              <span>{{lang.getText('date')}}</span>
                                              <input formControlName="date" class="form-control" type="text" bsDatepicker [bsConfig]="bsConfig" [attr.disabled]="addService.get('financially_closed').value ? true : null">&nbsp;
                                            </div>
                                            <div class="col-lg-2 col-md-3 nospace">
                                              <span>{{lang.getText('hours')}}</span>
                                              <select formControlName="hour" class="in_small_other-2 ps-2" [attr.disabled]="addService.get('financially_closed').value ? true : null">
                                                <option *ngFor="let hour of hours;" value="{{hour}}">{{hour}}</option>
                                              </select>
                                                &nbsp;
                                            </div>
                                            <div class="col-lg-2 col-md-3 nospace">
                                              <span>{{lang.getText('minutes')}}</span>
                                              <select formControlName="minute" class="in_small_other-2 ps-2" [attr.disabled]="addService.get('financially_closed').value ? true : null">
                                                <option *ngFor="let minute of minutes;" value="{{minute}}">{{minute}}</option>
                                              </select>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                      <div class="col-xl-6 col-lg-12">
                                        <div class="row" style="padding-top: 30px;">
                                          <div class="col-xl-1 col-lg-1 col-md-1">
                                          </div>
                                          <div class="col-xl-3 col-lg-3 col-md-3 nospace">
                                            <span style="padding-top: 15px;display: block;">{{lang.getText('description')}}</span>
                                          </div>
                                          <div class="col-xl-6 col-lg-8 col-md-8" style="padding-right: 0;">
                                            <div class="row">
                                              <div class="col-md-12">
                                                <input formControlName="description" type="text" class="form-control" [attr.disabled]="addService.get('financially_closed').value ? true : null" />
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-lg-2 col-md-6 col-sm-12 pe-0">
                                            <div class="row">
                                              <span *ngIf="!projectEditDis" (click)="!addService.get('financially_closed').value && checkClaimed() ? deletedeliverybatch(batch) : false" [class.disabled]="addService.get('financially_closed').value ? true : false" title="Delete">
                                                <em class="fa fa-trash fa-lg" style="padding-top:10px;" aria-hidden="true"></em>
                                              </span>
                                            </div>
                                          </div>                                       
                                        </div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-lg-12 col-md-12 nospace">
                                  <div class="row">
                                    <div class="col-md-12 ms-0 col-lg-12">
                                      <a *ngIf="!projectEditDis" (click)="!addService.get('financially_closed').value && checkClaimed() ? addVendorBatch(vi, 'additionalServices', asi) : false" class="btn btn-primary ms-3" [class.disabled]="addService.get('financially_closed').value ? true : false">{{lang.getText('add_batch')}}</a>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-5 col-md-12">
                                  <div class="row md_rw">
                                    <div class="col-lg-8 col-md-6">
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-9 col-md-12 nospace">
                              <div class="row">
                                <div class="col-md-6 col-lg-8">
                                  <a *ngIf="!projectEditDis" (click)="checkClaimed() ? addVendorAddiService(vi) : false" class="btn btn-primary">{{lang.getText('add_service')}}</a>
                                </div>
                                <div class="col-md-6 ">
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-3 col-md-12 nospace">
                              <app-total-display 
                                title = "total_cost_services"
                                [amount] = "vendor.getRawValue().AddServicesCost"
                                [currencyCode] = "getProjectVendorCurrencyCode()"
                              ></app-total-display>
                              <input formControlName="AddServicesCost" type="hidden" class="form-control" min="0" readonly>
                              <!-- <div class="row md_rw badge-primary">
                                <div class="nospace text-center">
                                  {{lang.getText('total_cost_services')}}&nbsp;{{':'}}&nbsp;
                                  <span class="text-nowrap">
                                    {{ vendor.getRawValue().AddServicesCost | myCurrency: getProjectVendorCurrencyCode() }}
                                  </span>
                                  <input formControlName="AddServicesCost" type="hidden" class="form-control" min="0" readonly>
                                </div>
                              </div> -->
                            </div>
                          </div>
                        </perfect-scrollbar>
                      </div>
                    </div>
                  </div>
                  <div class="single_part_main cat_analysis" *ngIf="createProject">
                    <div class="single_part_inner">
                      <div class="main_head">
                        <p>{{lang.getText('vendor')}} {{vi+1}} {{lang.getText('files')}}</p>
                        {{lang.getText('file_access')}}
                        <span *ngIf="vendor.getRawValue().file_access == 0">
                          <a
                            class="btn btn-secondary small_size_text2"
                            (click)="checkClaimed() ? alertFileEnable() : false"
                            >{{lang.getText('enable')}}</a>
                          <span class="btn color-red">File Access Disabled</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="single_part_main cat_analysis" *ngIf="!createProject" [hidden]="hideDisplayLabel('resourcing_vendor_files_section')">
                    <div class="single_part_inner">
                      <div class="main_head">
                        <p>{{lang.getText('vendor')}} {{vi+1}} {{lang.getText('files')}}</p>
                      </div>
                      <file-upload *ngIf="all_files"
                        (isClaimProject)="claimFromComponent($event)"
                        [isCheckClaimed]="true"
                        [readOnly]="projectEditDis"
                        isVendor="true"
                        [deliveryComment]="vendor.value.deliveryComments"
                        projectId="{{projectId}}"
                        tag="vendor-files.vendor_id,{{vendor.getRawValue().vendor_id}}"
                        [input_files]="all_files"
                        projectClaimed="projectClaimed"
                        >
                      </file-upload>

                    </div>
                  </div>
                  <div class="single_part_main cat_analysis" [hidden]="hideDisplayLabel('resourcing_vendor_handoff_status')">
                    <div class="single_part_inner">
                      <div class="main_head">
                        <p>{{lang.getText('vendor')}} {{vi+1}} {{lang.getText('hand_off_status')}}</p>
                      </div>
                      <div class="container p-0">
                        <div class="row">
                          <div class="col-md-auto ps-0">
                            <div class="form-check form-switch pe-2 mt-2">
                              <label class="d-flex align-items-center">
                                <input class="form-check-input me-2" formControlName="download_link" type="checkbox">
                                {{lang.getText('add_download_link')}}
                              </label>
                            </div>
                          </div>
                          <div class="col-md-auto">
                            <div class="form-check form-switch pe-2 mt-2">
                              <label class="d-flex align-items-center">
                                <input class="form-check-input me-2" formControlName="upload_link" type="checkbox">
                                {{lang.getText('add_upload_link')}}
                              </label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <select formControlName="heads_up_template_id" class="badge-empty w-100 drop-shadow">
                              <option value="0">{{lang.getText('select_heads_up_template')}}</option>
                              <option *ngFor="let hedUpTemp of headsUpClientTemplates;" value="{{hedUpTemp.id}}">
                                {{hedUpTemp.name}}
                              </option>
                            </select>
                          </div>
                          <div class="col-md-auto">
                            <label class="me-3" for="">{{ 'updated_on2' | translate }}</label>
                            <input formControlName="last_updated_at" readonly class="badge-empty" type="text" style="border-radius: 42px;">
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12 ps-0">
                            <div class="form-group">
                              <label>{{lang.getText('heads_up_template_please_adapt_before_you_submit')}}</label>
                              <div formControlName="headsUpContent" [ngxSummernote]="summernoteConfig.config"></div>
                            </div>
                            <div *ngIf="getVendorTranslationCount(vendor.getRawValue().vendor_id) > 50" class="alert alert-danger">
                              {{lang.getText('project_vendor_langu_combi_max_50_breakdown_alert')}}
                            </div>
                          </div>
                        </div>
                        <div class="row smallfull_size">
                          <div class="col-md-12 ps-0">
                            <div class="row">
                              <div class="col-auto ps-0">
                                <a
                                (click)="checkClaimed() ? onSubmitProjectEditForm() : false"
                                class="btn btn-primary small_size_text2">
                                {{lang.getText('save_heads_up')}}
                              </a>
                              </div>
                              <div class="col-auto">
                                <button 
                                  *ngIf="checkClaimed(false)"
                                  (click)="addAnalysisToHeadsUp(vendor)"
                                class="btn btn-primary ms-2 small_size_text2">
                                {{lang.getText('add_analysis_to_heads_up')}}
                              </button>
                                <a *ngIf="!checkClaimed(false)"
                                  (click)="checkClaimed(false)"
                                  class="btn btn-primary ms-2 small_size_text2">
                                  {{lang.getText('add_analysis_to_heads_up')}}
                                </a>
                              </div>
                              <div class="col-auto">
                                <a (click)="checkClaimed() ? addCostToVendorMailTemplate(vendor) : false"
                                  class="btn btn-primary ms-2 small_size_text2" >{{lang.getText('add_cost_to_heads_up')}}</a>
                              </div>
                              <div class="col-auto">
                                <a (click)="addDeliverySheduleHeadeup(vendor, projectId)"
                                   class="btn btn-primary ms-2 small_size_text2" >{{lang.getText('add_delivery_schedule_to_heads_up')}}</a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="vendor.getRawValue().project_vendor_id" class="row smallfull_size">
                          <div class="col-md-12 ps-0">
                            <div class="note-text color-red ms-0">
                              {{lang.getText('long_text5')}}
                            </div>
                          </div>
                          <div class="col-lg-9 col-md-12 ps-0">
                            <div class="row">
                              <div class="col-md-12 ps-0">
                                <div class="row">
                                  <div class="col-auto ps-0">
                                    <button
                                      (click)="checkClaimed() ? openEmailsTemp(vendor, lang.getText('heads_up_to'), lang.getText('submit'), 'heads_up') : false"
                                      [disabled]="getVendorTranslationCount(vendor.getRawValue().vendor_id) > 50"
                                      class="btn btn-primary small_size_text2">
                                      {{lang.getText('submit_heads_up')}}
                                    </button>
                                    <span></span>
                                  </div>
                                  <div *ngIf="vendor.getRawValue().heads_up === null"
                                    (click)="checkClaimed() ? helperSer.alertIt(lang.getText('please_submit_the_heads_up_before_you_indicate_it_has_been_accepted')) : false"
                                    class="col-auto">
                                    <a class="btn btn-secondary ms-2 small_size_text2">
                                      {{lang.getText('accept_heads_up')}}
                                    </a>
                                  </div>
                                  <div *ngIf="vendor.getRawValue().heads_up !== null&&checkClaimed(false)"
                                    (click)="vendor.patchValue({heads_up: 'accept'}, formEventStop); onSubmitProjectEditForm();"
                                    class="col-auto">
                                    <a [ngClass]="vendor.getRawValue().heads_up === 1 ? 'btn-success' : 'btn-primary'"
                                      class="btn ms-2 small_size_text2">
                                      {{lang.getText('accept_heads_up')}}
                                    </a>
                                  </div>
                                  <div *ngIf="vendor.getRawValue().heads_up !== null&&!checkClaimed(false)"
                                       (click)="checkClaimed()"
                                       class="col-auto">
                                    <a [ngClass]="vendor.getRawValue().heads_up === 1 ? 'btn-success' : 'btn-primary'"
                                       class="btn ms-2 small_size_text2">
                                      {{lang.getText('accept_heads_up')}}
                                    </a>
                                  </div>
    
                                  <div *ngIf="vendor.getRawValue().heads_up === null"
                                    (click)="checkClaimed() ? helperSer.alertIt(lang.getText('please_submit_the_heads_up_before_you_indicate_it_has_been_rejected')) : false"
                                    class="col-auto">
                                    <a class="btn btn-secondary ms-2 small_size_text2">
                                      {{lang.getText('reject_heads_up')}}
                                    </a>
                                  </div>
                                  <div *ngIf="vendor.getRawValue().heads_up !== null&&checkClaimed(false)"
                                    (click)="vendor.patchValue({heads_up: 'reject'}, formEventStop); onSubmitProjectEditForm();"
                                    class="col-auto">
                                    <a [ngClass]="vendor.getRawValue().heads_up === 2 ? 'btn-danger' : 'btn-primary'"
                                      class="btn ms-2 small_size_text2">
                                      {{lang.getText('reject_heads_up')}}
                                    </a>
                                  </div>
                                  <div *ngIf="vendor.getRawValue().heads_up !== null&&!checkClaimed(false)"
                                       (click)="checkClaimed()"
                                       class="col-auto">
                                    <a [ngClass]="vendor.getRawValue().heads_up === 2 ? 'btn-danger' : 'btn-primary'"
                                       class="btn ms-2 small_size_text2">
                                      {{lang.getText('reject_heads_up')}}
                                    </a>
                                  </div>
    
                                  <div *ngIf="vendor.getRawValue().heads_up !== 1"
                                    (click)="checkClaimed() ? helperSer.alertIt(lang.getText('long_text6')) : false"
                                    class="col-auto ">
                                    <a class="btn btn-secondary ms-2 small_size_text2">{{lang.getText('confirm_project_to_vendor')}}</a>
                                  </div>
                                  <div *ngIf="vendor.getRawValue().heads_up === 1"
                                    (click)="checkClaimed() ? confirmProjectToVendor(confirmYesNoModal, vendor) : false"
                                    class="col-auto ">
                                    <a [ngClass]="vendor.getRawValue().confirm === 1 ? 'btn-success' : 'btn-primary'"
                                      class="btn btn-secondary ms-2 small_size_text2">{{lang.getText('confirm_project_to_vendor')}}</a>
                                  </div>
                                  <div *ngIf="vendor.getRawValue().heads_up === null"
                                    (click)="checkClaimed() ? helperSer.alertIt(lang.getText('you_can_only_cancel_a_project_that_has_been_submitted_to_the_vendor')) : false"
                                    class="col-auto">
                                    <a class="btn btn-secondary ms-2 small_size_text2">{{lang.getText('cancel_project_for_vendor')}}</a>
                                  </div>
                                  <div *ngIf="vendor.getRawValue().heads_up !== null && checkClaimed(false)"
                                    (click)="checkClaimed() ? openEmailsTemp(vendor, 'Cancellation mail to:', 'Submit', 'cancel') : false;"
                                    class="col-auto">
                                    <a [ngClass]="vendor.getRawValue().confirm === 0 ? 'btn-danger' : 'btn-primary'"
                                      class="btn btn-secondary ms-2 small_size_text2">{{lang.getText('cancel_project_for_vendor')}}</a>
                                  </div>
                                  <div *ngIf="vendor.getRawValue().heads_up !== null&&!checkClaimed(false)"
                                       (click)="checkClaimed()"
                                       class="col-auto">
                                    <a [ngClass]="vendor.getRawValue().confirm === 0 ? 'btn-danger' : 'btn-primary'"
                                       class="btn btn-secondary ms-2 small_size_text2">{{lang.getText('cancel_project_for_vendor')}}</a>
                                  </div>
                                  <div *ngIf="vendor.getRawValue().confirm !== 1"
                                    (click)="checkClaimed() ? helperSer.alertIt(lang.getText('please_confirm_the_project_to_the_vendor_before_you_indicate_it_has_been_delivered')) : false"
                                    class="col-auto">
                                      <a class="btn btn-secondary ms-2 small_size_text2">{{lang.getText('job_delivered')}}</a>
                                  </div>
                                  <div *ngIf="vendor.getRawValue().confirm === 1&&checkClaimed(false)"
                                    (click)="vendor.patchValue({delivered_at: 'confirm'}); onSubmitProjectEditForm();"
                                    class="col-auto">
                                    <a [ngClass]="vendor.getRawValue().delivered_at != null ? 'btn-success' : 'btn-primary'"
                                      class="btn btn-secondary ms-2 small_size_text2">{{lang.getText('job_delivered')}}</a>
                                  </div>
                                  <div *ngIf="vendor.getRawValue().confirm === 1&&!checkClaimed(false)"
                                       (click)="checkClaimed()"
                                       class="col-auto">
                                    <a [ngClass]="vendor.getRawValue().delivered_at != null ? 'btn-success' : 'btn-primary'"
                                       class="btn btn-secondary ms-2 small_size_text2">{{lang.getText('job_delivered')}}</a>
                                  </div>
                                  <div class="col-md-3 ps-0">
                                    <span
                                      *ngIf="vendor.getRawValue().delivered_at != null"
                                      class="btn small_size_text2  color-blue ps-0">
                                        {{lang.getText('job_delivered')}}
                                    </span>
                                    <span
                                      *ngIf="(vendor.getRawValue().confirm == 1 || vendor.getRawValue().confirm == 'confirm') && vendor.getRawValue().delivered_at == null"
                                      class="btn small_size_text2 color-blue ps-0">
                                        {{lang.getText('hand_off_confirmed')}}
                                    </span>
                                    <span
                                    *ngIf="(vendor.getRawValue().confirm === 0 || vendor.getRawValue().confirm === 'cancel') && vendor.getRawValue().delivered_at == null"
                                    class="btn small_size_text2  color-red ps-0">
                                        {{lang.getText('canceled_project_to_vendor')}}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12 ps-0">
                                <div class="row">
                                  <div *ngIf="vendor.getRawValue().heads_up === 0 || vendor.getRawValue().heads_up === 'yes'" class="col-auto ps-0">
                                    <a class="btn ps-0 small_size_text2 color-amber">{{lang.getText('heads_up_submitted')}}</a>
                                </div>
                                <div *ngIf="vendor.getRawValue().heads_up === 1 || vendor.getRawValue().heads_up === 'accept'"  class="col-auto ps-0">
                                    <a class="btn ps-0 small_size_text2 color-green">{{lang.getText('heads_up_accepted')}}</a>
                                </div>
                                <div *ngIf="vendor.getRawValue().heads_up === 2 || vendor.getRawValue().heads_up === 'reject'"  class="col-auto ps-0">
                                    <a class="btn ps-0 small_size_text2 color-red">{{lang.getText('heads_up_rejected')}}</a>
                                </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-3 col-md-6">
                            <app-total-display 
                              title = "total_cost"
                              [amount] = "vendor.getRawValue().totalCost"
                              [currencyCode] = "getProjectVendorCurrencyCode()"
                              [check_string_length]="false"
                              type="badge-empty"
                            ></app-total-display>
                            <input class="form-control" readonly type="hidden" [value]="vendor.getRawValue().totalCost">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </tab>

    <!-- Delivery -->
    <tab tab2 heading="{{lang.getText('delivery')}}" id="third">
      <div class="outer_main_tab">
        <div class="inner_main_tab">
          
          <div class="single_part_main" *ngIf="project.type_of == 'trados' || project.type_of == 'xtm' ||  project.type_of == 'phrase'">
            <div class="single_part_inner">
              <div class="main_head">
                <p>{{lang.getText('project_progress')}}</p>
                <a *ngIf="!projectEditDis && project.type_of == 'xtm'" 
                  (click)='checkClaimed() && checkXTMData() ? viewProjectProgress("", true) : false' 
                  class="btn btn-primary me-3"
                  [class.disabled]="loading">
                  {{lang.getText('view_project_progess')}}
                </a>
                <a *ngIf="!projectEditDis && project.type_of == 'trados'" 
                  (click)='checkClaimed() && (checkTradosData("vpp")) ? viewProjectProgress("", true) : false' 
                  class="btn btn-primary me-3"
                  [class.disabled]="!checkTradosData('vpp') || loading">
                  {{lang.getText('view_project_progess')}}
                </a>
                <a *ngIf="!projectEditDis && project.type_of == 'phrase'" 
                  (click)='checkClaimed() && checkPrhaseData() ? viewProjectProgress("", true) : false' 
                  class="btn btn-primary me-3"
                  [class.disabled]="loading"
                  >
                  {{lang.getText('view_project_progess')}}
                </a>
              </div>
              <div class="loader container-fluid text-center" *ngIf="loading">
                <div class="body">
                  <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>
              </div>
              <span *ngIf="!loading && showProjectProgress.display">
                <div class="d-flex justify-content-between mb-3 mt-3">
                  <div class="me-3">{{lang.getText('project_status')}}: 
                    <span style="text-transform: capitalize;">{{showProjectProgress.data['status']}}</span> 
                  </div>
                  <div class="me-3 color-red">
                    {{lang.getText('latest_status_update')}}: 
                    {{showProjectProgress.data['target_files_last_api_call_at']}}
                  </div>
                </div>
                <div class="progress-bar-sec d-flex align-items-center">
                  <p>{{lang.getText('percentage_completed')}}: {{showProjectProgress.data['percentage']}}%</p>
                  <div class="progress">
                    <div class="progress-bar progress-bar-striped {{showProjectProgress.data['color']}} {{'w-' + showProjectProgress.data['percentage']}}" role="progressbar" attr.aria-valuenow="{{showProjectProgress.data['percentage']}}" aria-valuemin="0"
                      aria-valuemax="100"
                      [style.width]="showProjectProgress.data['percentage'] + '%'"></div>
                  </div>
                </div>
                <div class="language-combination-sec" style="max-height: 400px;overflow: scroll;">
                    <div class="row">
                      <div class="col-3"><strong>{{lang.getText('downloadable_files')}}</strong></div>
                      <div class="col-3"><strong>{{lang.getText('target_language')}}</strong></div>
                      <div class="col-3 text-center"><strong>{{lang.getText('available_in_flowdezk')}}</strong></div>
                      <div class="col-3">
                        <input type="checkbox" id="vehicle1" name="vehicle1" value="Select All" 
                        [(ngModel)]="filesSelected"
                        [ngModelOptions]="{standalone: true}"
                        (change)="checkUncheckAllFiles()">
                        <label for="vehicle1" class="ms-2">{{lang.getText('select_all')}}</label>
                      </div>
                    </div>
                    <div class="row" *ngFor="let item of checklistFiles; let ti = index;">
                      <ng-container *ngIf="project.type_of == 'trados'">
                        <div class="col-3"><p style="word-break: break-all;">{{'V'+item.latestVersion?.version+'_'}}{{item.name}}<span *ngIf="item.latestVersion?.type == 'bcm'">.sdlxliff</span>
                        </p></div>
                        <div class="col-3"><p>{{item.languageDirection.targetLanguage.englishName}}</p></div>
                        <div class="col-3 text-center"><p>
                          <span *ngIf="item.local_data?.export_id && !item.local_data?.export_status; else showS3Status">
                            <em class="fa fa-solid fa-spinner color-darksalmon"></em>
                          </span>
                          <ng-template #showS3Status>
                            <span *ngIf="item.available_in_s3"><em class="fa fa-solid fa-check color-green"></em></span>
                            <span *ngIf="!item.available_in_s3"><em class="fa fa-solid fa-xmark color-red"></em></span>
                          </ng-template>
                          
                          </p>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="project.type_of == 'xtm'">
                        <div class="col-3">
                          <p style="word-break: break-all;">{{item.xtm_file_name}}</p>
                        </div>
                        <div class="col-3"><p>{{item.target_language_data.name}}</p></div>
                        <div class="col-3 text-center">
                          <p>
                            <span><em class="fa fa-solid fa-check color-green"></em></span>
                          </p>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="project.type_of == 'phrase'">
                        <div class="col-3">
                          <p style="word-break: break-all;">{{item.name}}</p>
                        </div>
                        <div class="col-3"><p>{{item.target_language}}</p></div>
                        <div class="col-3 text-center">
                          <p>
                            <span *ngIf="item.available_in_s3"><em class="fa fa-solid fa-check color-green"></em></span>
                            <span *ngIf="!item.available_in_s3"><i class="fa fa-times color-red" aria-hidden="true"></i></span>
                          </p>
                        </div>
                      </ng-container>
                      <div class="col-3"> 
                        <input type="checkbox" id="file-{{ti}}" name="file-{{ti}}" value="{{item.id}}" 
                        [(ngModel)]="item.isSelected"
                        [ngModelOptions]="{standalone: true}"
                        (change)="isAllSelectedFiles()"
                        >
                        <span class="ps-1" style="text-transform: capitalize;">{{item.job_status}}</span>
                      </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between">
                  <ng-container *ngIf="project.type_of == 'trados'">
                    <div *ngIf="!projectEditDis" 
                      class="btn btn-primary me-3"
                      [class.disabled]="trasnlation_pagination_data.trados_files_page == 1 || trasnlation_pagination_data.trados_files_loading  ? true : null"
                      (click)="trasnlation_pagination_data.trados_files_page == 1 || trasnlation_pagination_data.trados_files_loading   ? false : checkClaimed() && checkTradosData('vpp') ? viewProjectProgress('prev') : false"
                      ><em class="fa fa-angle-left"></em>  {{lang.getText('previous')}} {{trasnlation_pagination_data.trados_files_per_page}}
                    </div>
                    <div *ngIf="!projectEditDis" 
                      class="btn btn-primary me-3"
                      [class.disabled]="trasnlation_pagination_data.trados_files_page == trasnlation_pagination_data.trados_files_total_pages || trasnlation_pagination_data.trados_files_loading || checklistFiles.length < 50 ? true : null"
                      (click)="trasnlation_pagination_data.trados_files_page == trasnlation_pagination_data.trados_files_total_pages || trasnlation_pagination_data.trados_files_loading || checklistFiles.length < 50 ? false : checkClaimed() && checkTradosData('vpp') ? viewProjectProgress('next') : false">{{lang.getText('next')}} {{trasnlation_pagination_data.trados_files_per_page}} <em class="fa fa-angle-right"></em>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="project.type_of == 'xtm'">
                    <div *ngIf="!projectEditDis" 
                      class="btn btn-primary me-3"
                      [class.disabled]="trasnlation_pagination_data.xtm_files_page == 1 || trasnlation_pagination_data.xtm_files_loading ? true : null"
                      (click)="trasnlation_pagination_data.xtm_files_page == 1 || trasnlation_pagination_data.xtm_files_loading  ? false : checkClaimed() ? viewProjectProgress('prev', true) : false"
                      ><em class="fa fa-angle-left"></em>  {{lang.getText('previous')}} {{ trasnlation_pagination_data.xtm_files_per_page }}
                    </div>
                    <div *ngIf="!projectEditDis" 
                      class="btn btn-primary me-3"
                      [class.disabled]="trasnlation_pagination_data.xtm_files_page == trasnlation_pagination_data.xtm_files_total_pages || trasnlation_pagination_data.xtm_files_loading ? true : null"
                      (click)="trasnlation_pagination_data.xtm_files_page == trasnlation_pagination_data.xtm_files_total_pages || trasnlation_pagination_data.xtm_files_loading ? false : checkClaimed() ? viewProjectProgress('next', true) : false">{{lang.getText('next')}} {{ trasnlation_pagination_data.xtm_files_per_page }} <em class="fa fa-angle-right"></em>
                    </div>

                  </ng-container>
                </div>
                <div class="d-flex justify-content-center text-capitalize">
                  <div>
                    <a [class.disabled]="checkedListFiles && checkedListFiles.length == 0" (click)='checkClaimed() && downloadCatFiles()' class="btn btn-primary me-3">
                      {{lang.getText('get')}} {{lang.getText('files')}}
                    </a>
                  </div>
                  <div>
                    <a [class.disabled]="checkedListFiles && checkedListFiles.length == 0" (click)='checkClaimed()  && downloadCatFilesLocal()' class="btn btn-primary me-3">
                      {{lang.getText('download')}} {{lang.getText('files')}}
                    </a>
                  </div>
                  <div>
                    <a [class.disabled]="
                        (project.type_of == 'trados' && showProjectProgress.data['status']=='completed') || 
                        (project.type_of == 'xtm' && showProjectProgress.data['status']=='FINISHED') ||
                        (project.type_of == 'phrase' && showProjectProgress.data['status'] == 'COMPLETED')" 
                        (click)='checkClaimed() && completeProject()' class="btn btn-primary me-3">
                      {{lang.getText('complete')}} {{lang.getText('project')}}
                    </a>
                  </div>
                </div>
              </span>
            </div>
          </div>

          <div class="single_part_main" [hidden]="hideDisplayLabel('delivery_quality_assurance_section')">
            <div class="single_part_inner">
              <div class="main_head">
                <p>{{lang.getText('quality_assurance')}}</p>
              </div>
              <div class="container quality-assurance-container">
                <div class="row align_top">
                  <div class="col-md-12">
                    <div class="row mt-2">
                      <div class="col-md-6">
                        <div class="col-md-6 badge-status ms-0 mb-3">
                          <p>{{lang.getText('iso_status')}}&nbsp;{{':'}}&nbsp;
                            <span *ngIf="isQualityAssurances()" class="success_custom_col">{{lang.getText('qa_completed')}}</span>
                            <span *ngIf="!isQualityAssurances()" class="warning_custom_col">{{lang.getText('qa_pending')}}</span>
                          </p>
                        </div>
                        <div class="form-check checkbox" formArrayName="qualityAssurances" *ngFor="let qualityAssurance of projectEditForm.get('qualityAssurances')['controls']; let i = index;" >
                          <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox"  value="{{qualityAssurances[i].id}}" [formControlName]="i"/>
                            <label class="form-check-label">
                                {{qualityAssurances[i].title}}?
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <p>{{lang.getText('comments2')}}</p>
                        <div class="form-group">
                          <textarea formControlName='qa_comments' class="form-control" rows="7" maxlength="1000"></textarea>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="row">
                      <div class="col-md-12">
                        <p>{{lang.getText('comments2')}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <textarea formControlName='qa_comments' class="form-control" rows="3" maxlength="1000"></textarea>
                        </div>
                      </div>
                    </div> -->
                  </div>
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-12 text-end">
                        <!-- <p>{{lang.getText('status2')}}&nbsp;&nbsp;
                            <span *ngIf="isQualityAssurances()" class="success_custom_col">{{lang.getText('qa_completed')}}</span>
                            <span *ngIf="!isQualityAssurances()" class="warning_custom_col">{{lang.getText('qa_pending')}}</span>
                        </p> -->
                       <a *ngIf="allVendorsComments.length" (click)="openVendorsCommentModal()" class="btn btn-primary del-sel-btn ms-3 mt-1">{{lang.getText('view_vendor_comment')}}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="single_part_main" [hidden]="hideDisplayLabel('delivery_language_combinations_section')">
            <div class="single_part_inner">
              <div class="main_head">
                <p>{{lang.getText('language_combinations')}}</p>
              </div>

              <!-- New listing  -->
              <div class=" quality-assurance-container p-4">
                <div class="row col-12 row-secondary mb-2 p-12">
                  <div class="col-md-2 col-sm-2 nospace">{{ lang.getText('source_language') }}</div>
                  <div class="col-md-2 col-sm-2 nospace">{{ lang.getText('target_language') }}</div>
                  <div class="col-md-2 col-sm-2 nospace">{{ lang.getText('word_rate') }}</div>
                  <div class="col-md-3 col-sm-3 nospace">{{ lang.getText('description_with_colon') }} {{lang.getText('optional')}}</div>
                  <div class="col-md-2 col-sm-2 nospace">{{ lang.getText('subtotal_combo') }}</div>
                  <div class="col-md-1 col-sm-1 nospace text-end pe-3">{{ lang.getText('more') }}</div>
                </div>
                
                <div class="row quality-assurance-container mb-3 p-0 align-items-center" formArrayName="translations" *ngFor="let trans of projectEditForm.get('translationsFiles')['controls']; let ti = index">
                  <div class="row row-primary" [ngClass]="openedFileDeliverSecions.includes(ti) ? '' : 'filter-none'">
                    <div class="col-md-2 col-sm-12 nospace text-center">
                      <span class="ps-4 float-start">
                        <span *ngIf="trans.value.delivered == 1" class="color-success">
                          <em class="fa fa-check" aria-hidden="true"></em>
                        </span>
                      </span>
                      <span>{{getLanguageById(trans.value.source_lang_id)['name']}}</span>
                    </div>
                    <div class="col-md-2 col-sm-2 nospace text-center">
                      {{getLanguageById(trans.value.target_lang_id)['name']}}
                    </div>
                    <div class="col-md-2 col-sm-2 nospace text-center">{{trans.value.word_rate}}</div>
                    <div class="col-md-3 col-sm-3 nospace text-center">{{trans.value.comment}}</div>
                    <div class="col-md-2 col-sm-2 nospace text-center">
                      <app-total-display 
                        title = ""
                        [amount] = "trans.value.amount"
                        [currencyCode] = "getProjectCurrencyCode()"
                        type = ""
                        col_l = "4"
                        col_r = "8"
                      ></app-total-display>
                    </div>
                    <div class="col-md-1 col-sm-1 nospace float-end pe-3">
                      <span class="btn btn-circle color-primary float-end"
                        (click)="checkClaimed ? helperSer.updateOrRemoveArrayValue(openedFileDeliverSecions, ti) : false">
                        <i class="fa" style="font-size:24px;vertical-align: middle;" aria-hidden="true" 
                          [ngClass]="openedFileDeliverSecions.includes(ti) ? 'fa-angle-up' : 'fa-angle-down'"></i>
                      </span>
                    </div>
                  </div>
                  <div class="row" *ngIf="openedFileDeliverSecions.includes(ti)">
                    <file-upload *ngIf="all_files" 
                        (isClaimProject)="claimFromComponent($event)"
                        [isCheckClaimed]="true"
                        [readOnly]="projectEditDis"
                        projectId="{{projectId}}"
                        delivery="ON"
                        [inputDeliverEmails]=deliveryEmails
                        tag="delivery-files.translate_id,{{trans.getRawValue().project_translate_id}}"
                        [input_files]="all_files"
                        projectClaimed="projectClaimed"
                        tabName="delivery"
                        type="language"
                        (deliverFilesEvent)="deliverFilesFromChild($event)"
                        (undoDeliverFilesEvent)="undoDeliverFilesFromChild($event)"
                        translation_files_delivered = "{{trans.value.delivered}}"
                        fileIndex = "{{ti}}"
                        >
                      </file-upload>
                  </div>
                </div>      
                <app-pagination
                  theme = 'center-align-btn'
                  (go_to_page) = "getPageOfDeliveryLangTrans($event)"
                  >
                </app-pagination>
              </div>
              <!-- <perfect-scrollbar style="max-height: 600px;" [scrollIndicators]="true">
                <div class="quality-assurance-container single_part_main cat_analysis" *ngFor="let trans of projectEditForm.get('translationsFiles')['controls']; let ti = index">
                  <div class="single_part_inner" *ngIf="trans.value.project_translate_id">
                    <div class="main_head">
                      <p>{{lang.getText('upload_copy_files_source')}} {{((trasnlation_pagination_data.delivery_page - 1) * trasnlation_pagination_data.delivery_per_page) + ti + 1}} - {{lang.getText('target')}} {{((trasnlation_pagination_data.delivery_page - 1) * trasnlation_pagination_data.delivery_per_page) + ti + 1}} <strong>({{getLanguageById(trans.value.source_lang_id)['name']+' - '+getLanguageById(trans.value.target_lang_id)['name']}})</strong></p>
                    </div>
  
                    <file-upload *ngIf="all_files" 
                      (isClaimProject)="claimFromComponent($event)"
                      [isCheckClaimed]="true"
                      [readOnly]="projectEditDis"
                      projectId="{{projectId}}"
                      delivery="ON"
                      [inputDeliverEmails]=deliveryEmails
                      tag="delivery-files.translate_id,{{trans.getRawValue().project_translate_id}}"
                      [input_files]="all_files"
                      projectClaimed="projectClaimed"
                      >
                    </file-upload>
  
                    <div class="row">
                      <div class="col-md-12">
                        <div class="for_bd"></div>
                      </div>
                    </div>
                    <div class="row combo_main">
                      <div class="col-md-12">
                        <div class="form-check form-check-inline">
                          <span class="pe-2">{{lang.getText('status2')}}</span>
                          <a *ngIf="!trans.value.delivered" class="btn btn-default" (click)="checkClaimed() ? deliverFiles(trans, ti) : false">{{lang.getText('deliver_files')}}</a>
                          <a *ngIf="trans.value.delivered" class="btn btn-secondary">{{lang.getText('combo_delivery_completed')}}</a>
                        </div>
                        <div *ngIf="trans.value.delivered && !projectEditDis" class="form-check form-check-inline">
                          <a class="btn btn-default" (click)="checkClaimed() ? undoDeliverFiles(trans, ti) : false">{{lang.getText('undo')}}</a>
                        </div>
                        <div *ngIf="trans.value.delivered" class="form-check form-check-inline">
                          <span class="success_custom_col">{{lang.getText('combo_delivery_completed')}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </perfect-scrollbar>
              <div class="row">
                <div class="col-md-3 col-sm-12 nospace">
                  <button class="btn btn-yellow me-2"
                    [attr.disabled]="trasnlation_pagination_data.delivery_page == 1 || trasnlation_pagination_data.delivery_page_loading  ? true : null"
                    (click)="trasnlation_pagination_data.delivery_page == 1 || trasnlation_pagination_data.delivery_page_loading ? false : getPageOfDeliveryLangTrans('prev')">{{lang.getText('previous')}}</button>
                  <button class="btn btn-yellow"
                    [attr.disabled]="trasnlation_pagination_data.delivery_page == trasnlation_pagination_data.delivery_total_pages || trasnlation_pagination_data.delivery_page_loading ? true : null"
                    (click)="trasnlation_pagination_data.delivery_page == trasnlation_pagination_data.delivery_total_pages || trasnlation_pagination_data.delivery_page_loading ? false : getPageOfDeliveryLangTrans('next')">{{lang.getText('next')}}</button>
                </div>
              </div> -->
            </div>
          </div>
          <div class="single_part_main" [hidden]="hideDisplayLabel('delivery_services_section')">
            <div class="single_part_inner">
              <div class="main_head">
                <p>{{lang.getText('services')}} </p>
              </div>
              <!-- New listing  -->
              <div class=" quality-assurance-container p-4">
                <div class="row col-12 row-secondary mb-2 p-12">
                  <div class="col-md-3 col-sm-3 nospace">{{ lang.getText('item_description')}}</div>
                  <div class="col-md-2 col-sm-2 nospace">{{ lang.getText('unit') }}</div>
                  <div class="col-md-2 col-sm-2 nospace">{{ lang.getText('volume') }}</div>
                  <div class="col-md-2 col-sm-2 nospace">{{ lang.getText('rate') }} </div>
                  <div class="col-md-2 col-sm-2 nospace">{{ lang.getText('subtotal') }}</div>
                  <div class="col-md-1 col-sm-1 nospace text-end pe-3">{{ lang.getText('more') }}</div>
                </div>
                
                <div class="row quality-assurance-container mb-3 p-0 align-items-center" *ngFor="let addService of projectEditForm.get('additionalServices')['controls']; let ti = index">
                  <div *ngIf="addService.value.pAsId" class="row row-primary" [ngClass]="openedFileDeliverSecions.includes(ti) ? '' : 'filter-none'">
                    <div class="col-md-3 col-sm-3 nospace text-center">
                      <span class="ps-4 float-start">
                        <span *ngIf="addService.value.delivered == 1" class="color-success">
                          <em class="fa fa-check" aria-hidden="true"></em>
                        </span>
                      </span>
                      <span class="pe-3">{{addService.value.comment}}</span></div>
                    <div class="col-md-2 col-sm-2 nospace text-center">{{addService.value.unit}}</div>
                    <div class="col-md-2 col-sm-2 nospace text-center">{{addService.value.volume}}</div>
                    <div class="col-md-2 col-sm-2 nospace text-center">{{addService.value.rate}}</div>
                    <div class="col-md-2 col-sm-2 nospace text-center">
                      <app-total-display 
                        title = ""
                        [amount] = "addService.value.amount"
                        [currencyCode] = "getProjectCurrencyCode()"
                        type = ""
                        col_l = "4"
                        col_r = "8"
                      ></app-total-display>
                      <!-- {{addService.value.amount | myCurrency : getProjectCurrencyCode() }} -->
                    </div>
                    <div class="col-md-1 col-sm-1 nospace float-end pe-3">
                      <span class="btn btn-circle color-primary float-end"
                        (click)="checkClaimed ? helperSer.updateOrRemoveArrayValue(openedServicesFileDeliverSecions, ti) : false">
                        <i class="fa" style="font-size:24px;vertical-align: middle;" aria-hidden="true" 
                          [ngClass]="openedServicesFileDeliverSecions.includes(ti) ? 'fa-angle-up' : 'fa-angle-down'"></i>
                      </span>
                    </div>
                  </div>
                  <div class="row" *ngIf="openedServicesFileDeliverSecions.includes(ti)">
                    <file-upload *ngIf="all_files" 
                        (isClaimProject)="claimFromComponent($event)"
                        [isCheckClaimed]="true"
                        [readOnly]="projectEditDis"
                        projectId="{{projectId}}"
                        delivery="ON"
                        [inputDeliverEmails]=deliveryEmails
                        tag="delivery-files.add_service_id,{{addService.getRawValue().pAsId}}"
                        [input_files]="all_files"
                        projectClaimed="projectClaimed"
                        tabName="delivery"
                        type="service"
                        (deliverFilesSerEvent)="deliverFilesSerFromChild($event)"
                        (undoDeliverFilesSerEvent)="undoDeliverFilesSerFromChild($event)"
                        services_delivered = "{{addService.value.delivered}}"
                        fileIndexSer = "{{ti}}"
                        >
                      </file-upload>
                  </div>
                </div> 
              </div>

              <!-- <div *ngFor="let addService of projectEditForm.get('additionalServices')['controls']; let asi = index;" class="single_part_main cat_analysis">
                <div *ngIf="addService.value.pAsId" class="single_part_inner">
                  <div class="main_head">
                    <p>{{lang.getText('upload_copy_files_service')}} {{asi+1}} <strong>({{addService.value.comment}})</strong></p>
                  </div>

                  <file-upload *ngIf="all_files" 
                    (isClaimProject)="claimFromComponent($event)"
                    [isCheckClaimed]="true"
                    [readOnly]="projectEditDis"
                    projectId="{{projectId}}"
                    delivery="ON"
                    [inputDeliverEmails]=deliveryEmails
                    tag="delivery-files.add_service_id,{{addService.getRawValue().pAsId}}"
                    [input_files]="all_files"
                    projectClaimed="projectClaimed"
                    >
                  </file-upload>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="for_bd"></div>
                    </div>
                  </div>
                  <div class="row combo_main">
                    <div class="col-md-12">
                      <div class="form-check form-check-inline">
                        <span class="pe-2">{{lang.getText('status2')}}</span>
                        <a *ngIf="!addService.value.delivered" class="btn btn-default" (click)="checkClaimed() ? addService.patchValue({delivered: 1}, formEventStop) : false">{{lang.getText('deliver_files')}}</a>
                        <a *ngIf="addService.value.delivered" class="btn btn-secondary">{{lang.getText('service_delivery_completed')}}</a>
                      </div>
                      <div *ngIf="addService.value.delivered && !projectEditDis" class="form-check form-check-inline">
                        <a class="btn btn-default" (click)="checkClaimed() ? addService.patchValue({delivered: 0}, formEventStop) : false">{{lang.getText('undo')}}</a>
                      </div>
                      <div *ngIf="addService.value.delivered" class="form-check form-check-inline">
                        <span class="success_custom_col">{{lang.getText('service_delivery_completed')}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
          <div class="single_part_main">
            <div class="single_part_inner">
              <div class="main_head">
                <p>{{lang.getText('project_delivery_status')}}</p>
              </div>
              <div class="container quality-assurance-container">
                <div class="row p-4">
                  <div class="col-md-12">
                    <div class="row justify-content-around">
                      <div class="col-md-4 text-center">
                        <!-- <div class="btn btn-lg d-flex align-items-center justify-content-between" [ngClass]="projectEditForm.getRawValue().delivered_at == null ? 'btn-success' : 'btn-disabled'">
                          <span class="btn btn-circle float-start p-2 color-success">
                            <em class="fa fa-check fa-2x"></em>
                          </span>
                          <span class="f-w-500" style="font-size: 14px;" >{{lang.getText('set_the_project_as_delivered')}}</span>
                          
                          <div *ngIf="projectEditForm.getRawValue().delivered_at == null">
                          <a (click)="checkClaimed() ? projectEditForm.patchValue({delivered_at: 'yes'}, formEventStop) : false"
                          [ngClass]="projectEditForm.getRawValue().delivered_at == null ? 'btn-success' : 'btn-secondary'"
                          class="btn btn-success small_size_text2">
                          <span class="btn btn-circle float-end color-disabled">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512"><path d="M48 448l416-192L48 64v149.333L346 256 48 298.667z" fill="#a8a8a8"/></svg>
                          </span>
                          </a>
                          </div>
                          <div *ngIf="projectEditForm.getRawValue().delivered_at != null">
                            <a
                            (click)="checkClaimed() ? projectEditForm.patchValue({delivered_at: null}, formEventStop) : false"
                            class="btn btn-disabled">
                            <span class="btn btn-circle float-end color-secondary">
                              <em class="fa fa-close"></em>
                            </span>
                          </a>
                          </div>
                        </div> -->

                      <div class="btn btn-md btn-rounded d-flex align-items-center justify-content-between rounded-pill w-100"
                          [ngClass]="projectEditForm.getRawValue().delivered_at == null ? 'badge-primary' : 'btn-success'"
                          (click)="checkClaimed() ? projectEditForm.getRawValue().delivered_at == null ? projectEditForm.patchValue({delivered_at: 'yes'}, formEventStop) : projectEditForm.patchValue({delivered_at: null}) : false">
                        
                        <div class="text-center flex-grow-1 d-flex justify-content-center">
                          <span class="f-w-500 ps-3">
                            <ng-container *ngIf="projectEditForm.getRawValue().delivered_at == null">
                              {{lang.getText('set_the_project_as_delivered2')}}
                            </ng-container>
                            <ng-container *ngIf="projectEditForm.getRawValue().delivered_at != null">
                              {{lang.getText('project_is_set_to_delivered')}}
                            </ng-container>
                          </span>
                        </div>
                        <div class="icon-container">
                          <span class="btn btn-circle p-2" *ngIf="projectEditForm.getRawValue().delivered_at == null">
                            <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 512 512">
                              <path d="M48 448l416-192L48 64v149.333L346 256 48 298.667z" fill="#5491e8"/>
                            </svg>
                          </span>
                          <span class="btn btn-circle p-2" *ngIf="projectEditForm.getRawValue().delivered_at != null">
                            <em class="fa fa-check fa-2x color-success"></em>
                          </span>
                        </div>
                      </div>


                        <!-- <div class="align-items-center btn btn-md btn-rounded d-flex justify-content-between rounded-pill" [ngClass]="projectEditForm.getRawValue().delivered_at == null ? 'badge-primary' : 'btn-success'"
                          (click)="checkClaimed() ? projectEditForm.getRawValue().delivered_at == null ? projectEditForm.patchValue({delivered_at: 'yes'}, formEventStop) : projectEditForm.patchValue({delivered_at: null}) : false">
                          <span class="f-w-500 f-w-500 set-the-project-as-delivered2">
                            <ng-container *ngIf="projectEditForm.getRawValue().delivered_at == null">
                              {{lang.getText('set_the_project_as_delivered2')}}
                            </ng-container>
                            <ng-container *ngIf="projectEditForm.getRawValue().delivered_at != null">
                              {{lang.getText('project_is_set_to_delivered')}}
                            </ng-container>
                          </span>
                          <div *ngIf="projectEditForm.getRawValue().delivered_at == null">
                            <span class="btn btn-circle float-end p-2" *ngIf="projectEditForm.getRawValue().delivered_at == null">
                              <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 512 512"><path d="M48 448l416-192L48 64v149.333L346 256 48 298.667z" fill="#5491e8"/></svg>
                            </span>
                          </div>
                          <div *ngIf="projectEditForm.getRawValue().delivered_at != null">
                            <span class="btn btn-circle float-end p-2">
                              <em class="fa fa-check fa-2x color-success"></em>
                            </span>
                          </div>
                        </div> -->
                        <div class="mt-3">
                          <span *ngIf="projectEditForm.getRawValue().delivered_at != null && !projectEditForm.value.send_accounting" class="project-delivery-incomplete-info" >{{lang.getText('project_fully_delivered')}}</span>
                          <span *ngIf="projectEditForm.getRawValue().delivered_at == null" class="project-delivery-incomplete-info" >{{lang.getText('project_delivery_incomplete')}}</span>
                      </div>
                        <!-- <div *ngIf="projectEditForm.getRawValue().delivered_at != null" class="col-md-3">
                          <a
                            (click)="checkClaimed() ? projectEditForm.patchValue({delivered_at: null}, formEventStop) : false"
                            class="btn btn-default">{{lang.getText('undo')}}</a>
                        </div> -->
                      </div>
                       <div class="col-md-4 text-center">

                        <div class="btn btn-md btn-rounded d-flex align-items-center justify-content-between rounded-pill w-100"
                          [ngClass]="projectDelivered && !projectEditForm.value.send_accounting ? 'badge-primary' : 'btn-success'"
                          (click)="checkClaimed() ? !projectEditForm.value.send_accounting ? projectEditForm.patchValue({send_accounting: 1}, formEventStop) : projectEditForm.patchValue({send_accounting: 0}, formEventStop) : false">
                      
                      <div class="text-center flex-grow-1 d-flex justify-content-center">
                        <span class="f-w-500 ps-3">
                          <ng-container *ngIf="!projectEditForm.value.send_accounting">
                            {{lang.getText('send_project_to_accounting')}}
                          </ng-container>
                          <ng-container *ngIf="projectEditForm.value.send_accounting">
                            {{lang.getText('project_is_sent_to_accounting')}}
                          </ng-container>
                        </span>
                      </div>
                      <div class="icon-container">
                        <span class="btn btn-circle p-2" *ngIf="projectDelivered && !projectEditForm.value.send_accounting">
                          <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 512 512">
                            <path d="M48 448l416-192L48 64v149.333L346 256 48 298.667z" fill="#5491e8"/>
                          </svg>
                        </span>
                        <span class="btn btn-circle p-2" *ngIf="projectDelivered && projectEditForm.value.send_accounting">
                          <em class="fa fa-check fa-2x color-success"></em>
                        </span>
                          </div>
                          </div>
                        <div class="col-md-12 mt-3" *ngIf="false" >
                        <!-- <div class="col-md-12 mt-3" *ngIf="projectDelivered && projectEditForm.value.send_accounting" > -->
                          <span class="project-delivery-accounitng-info">{{lang.getText('project_frozen_for_accounting')}}</span>
                          <!-- <div *ngIf="!project.finance_closed" class="col-md-3">
                            <a (click)="checkClaimed() ? projectEditForm.patchValue({send_accounting: 0}, formEventStop) : false" class="btn btn-default">{{lang.getText('undo')}}</a>
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 text-center mt-2 ps-4">
                    <span *ngIf="project.finance_closed || projectEditForm.value.send_accounting" class="project-delivery-incomplete-info">
                      {{lang.getText('long_text7')}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </tab>

    <!-- Finance -->
    <tab tab2 heading="{{lang.getText('finance')}}" id="fourth">
      <div class="outer_main_tab">
        <div class="inner_main_tab" *ngIf="project.send_accounting || true">
          <div class="single_part_main">
            <div class="single_part_inner">
              <div class="main_head">
                <p>{{lang.getText('client')}}</p>
              </div>
              <div class="container finance-container">
                <!-- <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <p>
                        <strong>{{lang.getText('order_date')}}</strong  >
                        <span class="ps-3">{{splitDateAndTime(project.created_at).date}}</span>
                      </p>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <button (click)="archiveProject()" class="btn btn-sm btn-primary float-end">{{lang.getText('archive')}} {{lang.getText('project')}}</button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-7">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <p>
                            <strong>{{lang.getText('client_number')}}</strong>
                            <span class="ps-3">{{project.client_id}}</span>
                          </p>
                        </div>
                        <div class="form-group">
                          <p>
                            <strong>{{lang.getText('order_number')}}</strong>
                            <span class="ps-3">{{orderId}}</span>
                          </p>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <p>
                            <strong>{{lang.getText('client_name')}}</strong>
                            <span *ngIf="projectId != 0" class="ps-3">{{project.client.name}}</span>
                          </p>
                        </div>
                        <div class="form-group">
                          <p>
                            <strong>{{lang.getText('currency2')}}</strong>
                            <span *ngIf="projectId != 0" class="ps-3">{{(getCurrencyById(project.currency_id))[0].name}}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div [hidden]="createProject" class="col-md-5 text-end gen-invoice col-nopadd">
                    <div class="row">
                      <a (click)=openGenerateInvoiceModal() class="btn btn-sm btn-primary">{{lang.getText('generate_invoice')}}</a>
                      <a (click)="openInvoiceHistoryModal()" class="btn btn-sm btn-primary">{{lang.getText('invoice_history')}}</a>
                    </div>
                  </div>
                </div> -->

                <div class="row">
                  <div class="col-md-12 mb-2">
                    <p>{{lang.getText('accounting_summary')}}</p>
                  </div>
                </div>  
                <div class="row row-secondary p-2">
                  <div class="col-2"><label>{{lang.getText('client_name')}}</label></div>
                  <div class="col-2"><label>{{lang.getText('client_number')}}</label></div>
                  <div class="col-2"><label>{{lang.getText('order_number')}}</label></div>
                  <div class="col-2"><label>{{lang.getText('currency')}}</label></div>
                  <div class="col-2"><label>{{lang.getText('order_date')}}</label></div>
                  <div class="col-2"><label>{{lang.getText('actions')}}</label></div>
                </div>
                <div class="row row-primary mt-3">
                  <div class="col-2">
                    <span *ngIf="projectId != 0" class="ps-3">{{project.client.name}}</span>
                  </div>
                  <div class="col-2">{{project.client_id}}</div>
                  <div class="col-2">{{orderId}}</div>
                  <div class="col-2">
                    <span *ngIf="projectId != 0" class="ps-3">{{(getCurrencyById(projectEditForm.getRawValue().currency_id))[0].name}}</span>
                  </div>
                  <div class="col-2">{{splitDateAndTime(project.created_at).date}}</div>
                  <div class="col-2">
                    <a (click)=openGenerateInvoiceModal() class="btn btn-circle btn-sm color-primary h-auto w-auto" title="{{lang.getText('generate_invoice')}}">
                      <!-- {{lang.getText('generate_invoice')}} -->
                      <svg style="width: 12px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="#18347b" d="M288 256H96v64h192v-64zm89-151L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-153 31V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zM64 72c0-4.4 3.6-8 8-8h80c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H72c-4.4 0-8-3.6-8-8V72zm0 64c0-4.4 3.6-8 8-8h80c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H72c-4.4 0-8-3.6-8-8v-16zm256 304c0 4.4-3.6 8-8 8h-80c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h80c4.4 0 8 3.6 8 8v16zm0-200v96c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16v-96c0-8.8 7.2-16 16-16h224c8.8 0 16 7.2 16 16z"/></svg>
                    </a>
                    <a (click)="openInvoiceHistoryModal()" class="btn btn-circle btn-sm color-primary h-auto w-auto" title="{{lang.getText('invoice_history')}}">
                      <!-- {{lang.getText('invoice_history')}} -->
                      <em class="fa fa-history"></em>
                    </a>
                    <a (click)="archiveProject()" class="btn btn-circle btn-sm color-primary h-auto w-auto" title="{{lang.getText('archive')}} {{lang.getText('project')}}">
                      <!-- {{lang.getText('archive')}} {{lang.getText('project')}} -->
                      <em class="fa fa-archive"></em>
                    </a>
                  </div>
                </div>  

                <div class="mt-3 mb-2">
                  <div *ngIf="project['invoiced_at'] == null" class="row for_small_mob">
                    <div class="col-md-9 nospace">
                      <div class="row row-primary p-2">
                        <div class="col-9" style="text-align: left;">
                          {{lang.getText('all_amounts_are_correct')}} <span style="color:#de8b57">(API Users Only)</span> 
                        </div>
                        <div class="col-3" style="text-align: right;">
                          <div class="form-check form-switch float-end">
                            <input formControlName="invoiceItCB" class="form-check-input" type="checkbox" role="switch" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 text-center">
                      <a (click)="checkClaimed() ? invoiceIt() : false"
                        [ngClass]="projectEditForm.getRawValue().invoiceItCB ? 'btn-primary' : 'btn-secondary'"
                        class="btn">
                        {{lang.getText('confirm_send')}}
                      </a>
                    </div>
                  </div>
                  <div *ngIf="project['invoiced_at'] != null" class="row for_small_mob">
                    {{lang.getText('amount_has_already_been_sent_to_accounting_system')}}
                  </div>
                </div>

                <div *ngIf="projectId && projectEditForm" class="row">
                  <div class="col-md-12">
                    <app-finance-breakdown #finance_breakdown_com
                      [projectId]="projectId"
                      [projectEditForm]="projectEditForm"
                      [projectCurrencyName]="getProjectCurrencyCode()"
                      >
                    </app-finance-breakdown>
                  </div>
                  <!-- <div class="col-md-3 api-invoice client">
                    <div class="single_part_main">
                      <div class="single_part_inner">
                        <div class="main_head">
                          <p>API Users Only</p>
                          <div *ngIf="project['invoiced_at'] == null" class="form-check form-check-inline for_small_mob">
                            <span class="pe-4">{{lang.getText('all_amounts_are_correct')}}</span>
                            <input formControlName="invoiceItCB" class="form-check-input" type="checkbox" />
                            <a
                              (click)="checkClaimed() ? invoiceIt() : false"
                              [ngClass]="projectEditForm.getRawValue().invoiceItCB ? 'btn-primary' : 'btn-secondary'"
                              class="btn ms-4"
                              style="margin-right: 35px;">
                              {{lang.getText('confirm_send')}}
                            </a>
                          </div>
                          <div *ngIf="project['invoiced_at'] != null" class="form-check form-check-inline for_small_mob">
                            {{lang.getText('amount_has_already_been_sent_to_accounting_system')}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          
          <div class="single_part_main">
            <div class="single_part_inner">
              <div class="main_head">
                <p>{{lang.getText('vendors')}}</p>
              </div>
              <div *ngFor="let vendor of projectEditForm.get('vendors')['controls']; let vi = index;" class="single_part_main">
                <div class="single_part_inner">
                  <div class="container finance-container">
                    <div class="main_head pt-2">
                      <p>{{lang.getText('vendor')}} {{vi+1}} </p>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <p>{{lang.getText('accounting_summary')}}</p>
                      </div>
                    </div>
                    <div class="row row-secondary p-2">
                      <div class="col-2"><label>{{lang.getText('vendor_name2')}}</label></div>
                      <div class="col-2"><label>{{lang.getText('vendor_number')}}</label></div>
                      <div class="col-2"><label>{{lang.getText('order_number')}}</label></div>
                      <div class="col-2"><label>{{lang.getText('vendor_role')}}</label></div>
                      <div class="col-1"><label>{{lang.getText('currency')}}</label></div>
                      <div class="col-2"><label>{{lang.getText('order_date')}}</label></div>
                      <div class="col-1"><label>{{lang.getText('more')}}</label></div>
                    </div>
                    <div class="row row-primary mt-3">
                      <div class="col-2">{{getVendorName(vendor.getRawValue().vendor_id)}}</div>
                      <div class="col-2">{{vendor.getRawValue().vendor_id}}</div>
                      <div class="col-2">{{orderId}}</div>
                      <div class="col-2"></div>
                      <div class="col-1"><span *ngIf="(getCurrencyById(projectEditForm.value.currencyAllVendors)).length > 0" class="ps-3">{{(getCurrencyById(projectEditForm.value.currencyAllVendors))[0]?.name}}</span></div>
                      <div class="col-2">{{splitDateAndTime(project.created_at).date}}</div>
                      <div class="col-1">
                        <a (click)="openGeneratePOHTMLModal(vendor)" class="btn btn-circle btn-sm  color-primary" title="{{lang.getText('regenerate_po')}}">
                          <!-- {{lang.getText('regenerate_po')}} -->
                          <em class="fa fa-refresh"></em>
                        </a>
                        <a (click)="openPOHistoryModal(project.id, vendor.value.project_vendor_id)"
                        class="btn btn-circle btn-sm  color-primary" title="{{lang.getText('po_history')}}">
                          <!-- {{lang.getText('po_history')}} -->
                          <em class="fa fa-history"></em>
                        </a>
                      </div>
                    </div>

                    <div class="mt-3 mb-2">
                      <div *ngIf="!vendorPayRequested(vendor)" class="row for_small_mob">
                        <div class="col-md-9 nospace">
                          <div class="row row-primary p-2">
                            <div class="col-9" style="text-align: left;">
                              {{lang.getText('all_amounts_are_correct')}} <span style="color:#de8b57">(API Users Only)</span>
                            </div>
                            <div class="col-3" style="text-align: right;">
                              <div class="form-check form-switch float-end">
                                <input class="form-check-input" type="checkbox"
                                  (change)="vendor.patchValue({pay_req_CB: !vendor.getRawValue().pay_req_CB})" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3 text-center">
                          <a (click)="checkClaimed() ? vendorPayReq(vendor) : false"
                          [ngClass]="vendor.getRawValue().pay_req_CB ? 'btn-primary' : 'btn-secondary'"
                          class="btn btn-secondary">
                            {{lang.getText('confirm_send')}}
                          </a>
                        </div>
                      </div>
                      <div *ngIf="vendorPayRequested(vendor)" class="row for_small_mob">
                        <span>{{lang.getText('amount_has_already_been_sent_to_accounting_system')}}</span>
                      </div>
                    </div>

                    <app-vendor-invoice #generateVendorInvoiceCom
                      *ngIf="vendor.value.project_vendor_id"
                      (reloadProjectFinance)=getProjectFinances()
                      [invoiceReceivedDateArr]="invoiceReceivedDateArr"
                    ></app-vendor-invoice>

                    <app-po-list #poListCom
                      [financial_dashboard]="0"
                      (saveEvent)="openModalSuccess()"
                    ></app-po-list>
                    
                    <app-vendor-finance-breakdown #vendor_finance_breakdown_com
                      [projectId]="projectId"
                      [vendorId]="vendor.getRawValue().vendor_id"
                      [vendor]="vendor"
                      [projectEditForm]="projectEditForm"
                      [projectCurrencyName]="getProjectCurrencyCode()"
                    ></app-vendor-finance-breakdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="single_part_main">
            <div class="single_part_inner">
              <div class="main_head">
                <p>{{lang.getText('financial_status')}}</p>
              </div>
              <div class="container finance-container">
                <div class="row p-4">
                  <div class="col-3">
                    <label class="f-w-500 mb-2">{{lang.getText('total_amount_in')}}</label>
                    <div class="badge-primary w-70 p-3 text-center">
                      <app-total-display 
                        title = ""
                        [amount] = "projectEditForm.value.totalCost"
                        [currencyCode] = "getProjectCurrencyCode()"
                        type = ""
                        col_l = "4"
                        col_r = "8"
                      ></app-total-display>
                      <!-- {{ projectEditForm.value.totalCost | myCurrency: getProjectCurrencyCode() }} -->
                    </div>
                  </div>
                  <div class="col-3">
                    <label class="f-w-500 mb-2">{{lang.getText('total_amount_out')}}</label>
                    <div class="badge-primary w-70 p-3 text-center">
                      <app-total-display 
                        title = ""
                        [amount] = "projectEditForm.value.vendorsCost"
                        [currencyCode] = "getProjectCurrencyCode()"
                        type = ""
                        col_l = "4"
                        col_r = "8"
                      ></app-total-display>
                      <!-- {{ projectEditForm.value.vendorsCost | myCurrency: getProjectCurrencyCode() }} -->
                    </div>
                  </div>
                  <div class="col-3">
                    <label class="f-w-500 mb-2">{{lang.getText('profit_margin_amount')}}</label>
                    <div class="badge-primary w-70 p-3 text-center">
                      <app-total-display 
                        title = ""
                        [amount] = "calculateProfitMargin()"
                        [currencyCode] = "getProjectCurrencyCode()"
                        type = ""
                        col_l = "4"
                        col_r = "8"
                      ></app-total-display>
                      <!-- {{ calculateProfitMargin() | myCurrency: getProjectCurrencyCode()}} -->
                    </div>
                  </div>
                  <div class="col-3">
                    <label class="f-w-500 mb-2">{{lang.getText('profit_margin_percentage')}}</label>
                    <div class="badge-primary w-70 p-4 text-center">
                      {{calculateProfitPercentage()}}
                    </div>
                  </div>
                </div> 
                <div class="row">
                  <div *ngIf="project.project_status_id > 7" class="btn color-green">
                    <strong>{{lang.getText('financially_closed')}}</strong>
                  </div>
                </div>   
                <!-- <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <strong class="col-md-6">{{lang.getText('total_amount_in')}}</strong>
                          <span class="ps-3 col-md-6">{{projectEditForm.value.totalCost}}</span>
                        </div>
                        <div class="row">
                          <strong class="col-md-6">{{lang.getText('total_amount_out')}}</strong>
                          <span class="ps-3 col-md-6">{{projectEditForm.value.vendorsCost}}</span>
                        </div>
                        <div class="row">
                          <strong class="col-md-6">{{lang.getText('profit_margin_amount')}}</strong>
                          <span class="ps-3 col-md-6">{{calculateProfitMargin()}}</span>
                        </div>
                        <div class="row">
                          <strong class="col-md-6">{{lang.getText('profit_margin_percentage')}}</strong>
                          <span class="ps-3 col-md-6">{{calculateProfitPercentage()}}</span>
                        </div>
                        <div *ngIf="project.project_status_id > 7" class="row btn color-green">
                            <strong class="col-md-6">{{lang.getText('financially_closed')}}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </tab>
  </tabset>
</div>

  </div>
  </div>
</form>

<ng-template #vendorScheduleModal>

  <div class="modal-header">
    <h4 class="modal-title float-start">
        {{lang.getText('delivery_schedule_information')}}
    </h4>
    <button type="button" class="close float-end" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div *ngIf="schVendor" class="modal-body">
    <h5>{{lang.getText('translations')}}</h5>
    <div *ngFor="let tran of schVendor.translations" class="col-md-12">
      <div class="row">
        <div class="col-md-3">
          <span *ngFor="let lang of languages; let lai = index">
            <span *ngIf="tran.source_lang_id == lang.id" >{{lang.name}}</span>
          </span>
        </div>
        <div class="col-md-3">
          <span *ngFor="let lang of languages; let lai = index">
            <span *ngIf="tran.target_lang_id == lang.id" >{{lang.name}}</span>
          </span>
        </div>
        <div class="col-md-3">
          <span>{{tran.word_rate}}</span>
        </div>
        <div class="col-md-3">
          {{tran.amount}}
        </div>
      </div>
      <div *ngIf="tran.batches" class="col-md-10 col-md-offset-2">
        <div *ngFor="let batch of tran.batches; let bi = index;" class="row">
          <div class="col-md-5">
            {{lang.getText('delivery_batch')}} {{bi+1}}
          </div>
          <div class="col-md-7">
            {{batch.delivery_at.date}}
            &nbsp;&nbsp;
            {{ batch.delivery_at.hour.toString().padStart(2, '0') }}:
            {{ batch.delivery_at.minute.toString().padStart(2, '0') }}
          </div>
        </div>
      </div>
    </div>
    <h5 *ngIf="schVendor.additionalServices">{{lang.getText('services')}}</h5>
    <div *ngFor="let addSer of schVendor.additionalServices; let i = index" class="col-md-12">
      <div class="row">
        <div class="col-md-3">
            {{addSer.comment}}
        </div>
        <div class="col-md-3">
            {{addSer.unit}}
        </div>
        <div class="col-md-3">
            {{addSer.volume}}
        </div>
        <div class="col-md-3">
            {{addSer.amount}}
        </div>
      </div>
      <div *ngIf="addSer.batches" class="col-md-10 col-md-offset-2 deliver_batch">
        <div *ngFor="let batch of addSer.batches; let bi = index;" class="row">
          <div class="col-md-5">
            {{lang.getText('delivery_batch')}} {{bi+1}}
          </div>
          <div class="col-md-7">
            {{batch.delivery_at.date}} &nbsp;&nbsp;
            {{ batch.delivery_at.hour.toString().padStart(2, '0') }}:
            {{ batch.delivery_at.minute.toString().padStart(2, '0') }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12"><a  class="title"><strong></strong></a></div>
    </div>
  </div>
</ng-template>

<ng-template #confirmYesNoModal>
  <div class="modal-body">
    <div class="col-md-12">
      <h6 class="modal-title">
          {{lang.getText('does_the_heads_up_need_to_be_edited_for_this_hand_off_confirmation')}}
      </h6>
    </div>
    <div class="col-md-12">
      <button type="button" class="btn float-end" (click)="checkClaimed() ? closeModal() : false">
        <span aria-hidden="true">{{lang.getText('yes')}}</span>
      </button>
      <button type="button" class="btn float-end"
        (click)=" closeModal(); openEmailsTemp(activeVendor, lang.getText('confirm_to'), lang.getText('submit'), 'confirm');">
        <span aria-hidden="true">{{lang.getText('no')}}</span>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #jobDeliverEmailsTemp>
  <div class="modal-header">
    <h4 class="modal-title float-start">
      {{lang.getText('job_deliver_emails')}}
    </h4>
    <button type="button" class="close float-end" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-md-12">
      <h4>{{lang.getText('deliver_to')}}</h4>
      <div class="col-md-12 no-padding">
        <div>
          <span *ngFor="let email of jobDeliverEmails; let ei = index">
            <span>
              {{ email }} &nbsp;
              <span class="fa fa-trash fa-lg" title="{{lang.getText('remove')}}" (click)="checkClaimed() ? jobDeliverEmails.splice(ei, 1) : false"></span>,
            </span>
          </span>
        </div>
        <div class="form-group row">
          <div class="col-md-8">
            <input class="form-control" type="text" [(ngModel)]="jobDeliverEmailIpt">
          </div>
          <div class="col-md-4">
            <input class="btn btn-secondary" type="button" value="{{lang.getText('add')}}" (click)="checkClaimed() ? pushEmail(jobDeliverEmails, jobDeliverEmailIpt) : false">
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-8">
            <input class="btn btn-secondary" type="button" value="{{lang.getText('deliver')}}" (click)="checkClaimed() ? submitDeliver() : false">
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<app-add-vendor #addVendorCom (addVendor)="addVendor($event)" [hide]="hideDisplayLabel('resourcing_general_and_favourite_parameters_section')"></app-add-vendor>
<app-vendor-rates #vendorRatesCom></app-vendor-rates>
<ng-template #getEmailsTemp>
  <div class="modal-header">
    <h4 class="modal-title float-start">
      {{getEmailsTempTitle}}
    </h4>
    <button type="button" class="close float-end" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="vendorEmailForm" class="col-md-12">
      <div class="col-md-12 no-padding">
        <div>
          <span *ngFor="let email of getEmailsTempEmails; let ei = index">
            <span class="ps-1">
              {{ email }} &nbsp;
              <span class="fa fa-trash fa-lg" title="{{lang.getText('remove')}}" (click)="checkClaimed() ? getEmailsTempEmails.splice(ei, 1) : false"></span>,
            </span>
          </span>
        </div>
        <div class="form-group row vendor-email-section">
          <div class="col-md-8">
            <input formControlName="email" class="form-control" type="text" placeholder="{{lang.getText('enter_email_address')}}">
          </div>
          <div class="col-md-4">
            <input class="btn btn-secondary" type="button" value="{{lang.getText('add')}}" (click)="checkClaimed() ? vendorEmailForm.patchValue({email: helperSer.pushEmail(getEmailsTempEmails, vendorEmailForm.get('email').value)}) : false">
          </div>
        </div>
        <div class="form-group row mt-3">
          <div class="col-md-8">
            <input formControlName="subject" class="form-control" type="text">
          </div>
          <div class="col-md-4">
            <input
              [hidden]="vendorEmailForm.get('subject').disabled"
              class="btn btn-secondary" type="button"
              (click)="checkClaimed() ? vendorEmailForm.get('subject').disable() : false"
              value="{{lang.getText('save')}}" />
            <input
              [hidden]="vendorEmailForm.get('subject').enabled"
              class="btn btn-secondary" type="button"
              (click)="checkClaimed() ? vendorEmailForm.get('subject').enable() : false"
              value="{{lang.getText('edit')}}" />
          </div>
        </div>
        <div class="form-group row mt-3 pt-3">
          <div class="col-md-3">
            <input class="btn btn-secondary" type="button" [value]="getEmailsTempBtn" [disabled]="getEmailsTempEmails.length < 1" (click)="checkClaimed() ? getEmailsTempSubmit() : false">
          </div>
          <div class="col-md-3 no-padding">
              <div [hidden]="true" class="lds-ellipsis cus-lodder"><div></div><div></div><div></div><div></div></div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #importCATAnaTemp>
  <div class="modal-header ms-4">
    <h3 class="modal-title float-start">
      {{lang.getText('import_cat_analysis')}}
    </h3>
    <button type="button" class="close float-end" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" id="importCATAnaTemp">
    <div class="col-md-12 body-input">
      <div class="col-md-12 no-padding">
        <div class="form-group">
          <!-- <label for="cat_format">{{lang.getText('select_one_of_the_available_formats_from_the_list')}}</label> -->
          <select class="form-control p-2 ps-4" [(ngModel)]="importCATAnaFormat">
            <option selected value="sdlStudio">{{lang.getText('sdl_studio')}}</option>
            <option value="memoQ">{{lang.getText('memoq')}}</option>
            <option value="memSource">{{lang.getText('memsource')}}</option>
            <option value="smartling">{{lang.getText('smartling')}}</option>
          </select>
        </div>
        <hr style="color:#d5d5d5;"/>
        <h6 for="cat_file font-weight-bold">{{lang.getText('select_the_analysis_file_on_your_local_disk')}}</h6>
        <div class="form-group pt-2 mt-1">
          <span class="file-upload-span d-inline-block w-100 p-1">
          <label (click)="CATFileUpload.click()" class="badge-empty ms-1 p-7-10">
            <!-- {{lang.getText('upload_files')}} -->
             <i class="fa fa-lg fa-file color-primary"></i>  
          </label>
          <input #CATFileUpload name="file-upload" id="file-upload" type="file" class="form-control" (change)="importCATAnaFileChange($event)" />
            {{importCATFileName}}
          </span>
        </div>
        <!-- <div class="form-group">
          <label>{{lang.getText('click_on_the_button_below_to_import_the_analysis_in_flowdezk')}}</label>
        </div> -->
        <div *ngIf="importCATAnaValiFail" class="alert alert-danger" role="alert">
          {{lang.getText('please_select_the_correct_format')}}
        </div>
        <div class="form-group pt-4 mb-4">
          <button [disabled]="!importCATAnaBtnEn" (click)="checkClaimed() ? uploadCATAnalysis() : false" class="btn btn-primary ps-4 pe-4 border-radius-42">{{lang.getText('import_cat_analysis')}}</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #claimedPopup>
  <div class="modal-header">
    <h4 class="modal-title float-start">{{lang.getText('claim')}} {{lang.getText('project')}}</h4>
  </div>
  <div class="modal-body">
    <div class="col-sm-12">
      <h6>{{lang.getText('you_cant_edit_this_project')}}</h6>
    </div>
    <div class="col-md-12">
      <button class="btn btn-primary ms-0 ps-4 pe-4 border-radius-42 filter-drop-shadow mt-2" (click)="claimThisProject(projectId)" style="margin-left:10px">
        {{lang.getText('claim')}}
      </button>
      <button class="btn btn-danger  ps-4 pe-4 border-radius-42 filter-drop-shadow ms-2 mt-2" (click)="goToHome()" style="margin-left:10px">
        {{lang.getText('cancel')}}
      </button>
    </div>
    </div>
</ng-template>
<app-generate-invoice #generateInvoiceCom
  *ngIf="projectId && clientId && orderId"
  [projectId]=projectId
  [clientId]=clientId
  [orderId]=orderId
  [translationCount]="getTranslationCount()"
  (reloadInvoice)=getInvoice($event)
  (reloadProject)=getInvoiceDate()
  (reloadInvoiceList)="reloadInvoiceList($event)"
  [currencies]=currencies
  >
</app-generate-invoice>
<app-generate-quote #generateQuoteCom
  *ngIf="projectId && clientId && !createProject"
  [projectId]=projectId
  [orderId]=orderId
  [clientId]=clientId
  [pMDetails]="pMDetails"
  [quoteSendStep]=quoteSendStep
  [translationCount]=getTranslationCount()
  [displayBreakdownsFc]=quoteDisplayBreakdownsFc
  (afterSubmitQuote)="submitQuote()"
>
</app-generate-quote>
<ng-template #vendorsComment>
  <div class="modal-header">
    <h5 class="modal-title">{{lang.getText('view_vendor_comment')}}</h5>
    <button (click)=  "closeModal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row" >
      <div *ngIf="allVendorsComments.length" class="col-md-5" >
        <p>{{lang.getText('vendor_comments')}}</p>
      </div>
      <div class="col-md-6 no-padding">
        <div *ngFor="let comment of allVendorsComments;">
          <h4>{{comment.details.name}}</h4>
          <p>{{comment.comments | json}}</p>
        </div>
      </div>
      <div *ngIf=" ! allVendorsComments.length" class="col-md-12">
        <div>
          {{lang.getText('there_are_no_vendor_comments')}}
        </div>
      </div>
    </div>
  </div>
</ng-template>

<app-create-cat #createCat 
(tradosCreateResponse)="tradosCreateResponse($event)"
(xtmCreateResponse)="xtmCreateResponse($event)"
(phraseCreateResponse)="phraseCreateResponse($event)"
(showLoader)="showLoader($event)"
></app-create-cat>
<div class="backdrop" [ngStyle]="{'display':display}"></div>
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display}"> 
  <div class="modal-dialog" role="document">
    <div class="modal-content" [ngClass]="status">
      <div class="modal-header text-center">
        <em class="fa fa-check" style="padding-top: 4px;"></em> &nbsp;
        {{message}}
        <button type="button" class="close" aria-label="Close" (click)="closeModalDialog()"><span
            aria-hidden="true">&times;</span></button>
      </div>
    </div>
  </div>
</div>
<app-project-relation-list #openProjectRelationsCom (updateProjectRelationsCount)="updateProjectRelationsCount($event)"></app-project-relation-list>
<ng-template #successModal>
  <div class="modal-header">
    <h5 class="modal-title">{{lang.getText('alert')}}</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModalSuccess()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-md-12">
      <div class="alert alert-success" role="alert">
        {{lang.getText('updated_successfully')}}
      </div>
    </div>
  </div>
</ng-template>
<ng-template #projectCloseModal>
  <div class="modal-header">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeProjectModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-md-12">
      <div class="alert alert-warning" role="alert">
        {{ phraseFilesInProgress ?  lang.getText('project_close_in_phrase_2') : lang.getText('project_close_in_phrase') }}
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeProjectModal()">{{lang.getText('cancel')}}</button>
    <button type="button" class="btn btn-primary" (click)="confirmProjectCompletion()">{{lang.getText('ok')}}</button>
  </div>
</ng-template>

<app-invoice-list #invoiceListCom
[financial_dashboard]="0"
(saveEvent)="openModalSuccess()"
(sendInvoiceEvent)="sendInvoiceEvent($event)"
></app-invoice-list>

import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HelperService } from 'src/app/services/helper.service';
import { TokenService } from 'src/app/services/token.service';
import { environment as env } from 'src/environments/environment';
import { clone } from 'lodash-es';
import { LanguageService } from 'src/app/services/language.service';
import { SummerNoteConfig } from 'src/app/configs/summer-note-config';

@Component({
  selector: 'app-vendor-invoice',
  templateUrl: './vendor-invoice.component.html',
  styleUrls: ['./vendor-invoice.component.scss']
})
export class VendorInvoiceComponent implements OnInit {
  projectId;
  projectVendorId;
  payRequested: Boolean;
  invoiceEmails;
  translationCount;
  lang;

  @Input() invoiceReceivedDateArr;
  @Output() reloadProjectFinance = new EventEmitter();
  apiURL = env['API_URL'];
  bsConfig = {
    dateInputFormat: 'DD/MM/Y',
    rangeInputFormat: 'DD/MM/Y',
  };
  summernoteConfig:SummerNoteConfig = new SummerNoteConfig();
  
  constructor(
    private http: HttpClient,
    private token: TokenService,
    public helpSer: HelperService,
    private modalService: BsModalService,
    private fb: UntypedFormBuilder,
    public language: LanguageService
  ) {
    this.lang=language
  }

  ngOnInit() {
    this.initForm();
    this.getInvoiceFormData();
    this.loadInvoice();
    this.getPayment();
  }

  ngOnChanges() {
    console.log("this.invoiceReceivedDateArr", this.invoiceReceivedDateArr);
    if (this.invoiceReceivedDateArr[this.projectVendorId]) {
      let invoice_received_at_date = this.invoiceReceivedDateArr[this.projectVendorId];
      this.invoiceForm.patchValue({
        invoice_received_at: invoice_received_at_date,
        invoice_received_at_cb: true
      });
    }
  }

  invoiceForm: UntypedFormGroup;
  initForm() {
    this.invoiceForm = this.fb.group({
      invoice_received_at: this.fb.control({ value: '', disabled: false }),
      invoice_received_at_cb: false,
      invoiced_paid_at: this.fb.control({ value: '', disabled: false }),
      invoiced_paid_at_cb: false
    });

    this.invoiceForm.get('invoice_received_at').valueChanges.subscribe(val => {
      setTimeout(() => {this.setManualDate()});
    });
    this.invoiceForm.get('invoice_received_at_cb').valueChanges.subscribe(val => {
      if ( val === true ) this.setManualDate();
    });
    this.invoiceForm.get('invoiced_paid_at').valueChanges.subscribe(val => {
      setTimeout(() => {this.setPayment()});
    });
    this.invoiceForm.get('invoiced_paid_at_cb').valueChanges.subscribe(val => {
      if ( val === true ) this.setPayment();
    });
  }

  isInvoicedReceived() {
    return this.invoiceForm.value.invoice_received_at_cb;
  }

  invoice;
  loadInvoice() {
    this.http.post(`${this.apiURL}vendor/invoice/get`, this.invoiceFromData)
      .subscribe(
        data => {
          this.invoice = data;
          if ( this.invoice['invoiced_at'] != null ) {
            this.invoiceForm.patchValue({
              invoice_received_at: this.helpSer.splitDateAndTime(this.invoice['invoiced_at'].date).date,
              invoice_received_at_cb: true,
            }, {emitEvent: false});
          }
        }
      );
  }

  getInvoice(method) {
    let b64PDF = this.invoice['pdf']['b64']
    if ( b64PDF == null ) return;
    if (method == 'view') {
      this.helpSer.viewBase64File(b64PDF);
    } else if (method == 'down') {
      this.helpSer.downloadBase64File(b64PDF, this.invoice.pdf.name);
    }
  }

  invoicePayment;
  getPayment() {
    this.http.post(`${this.apiURL}vendor/invoice/get-payment`, this.invoiceFromData)
      .subscribe(
        data => {
          this.invoicePayment = data;
          if ( data['status'] == 'paid' ) {
            this.invoiceForm.patchValue({
              invoiced_paid_at: data['created_at'],
              invoiced_paid_at_cb: true,
            }, {emitEvent: false});
          }
        }
      );
  }

  setPayment() {
    let date = this.invoiceForm.value.invoiced_paid_at;
    if ( date == '' || date == null ) {
      date = new Date();
    }
    let formData = clone(this.invoiceFromData);
    formData['paid_at'] = this.helpSer.formateDPDate(date);
    this.invoiceForm.patchValue({invoiced_paid_at: formData['paid_at']}, { emitEvent: false });
    this.http.post(`${this.apiURL}vendor-invoice/set-payment`, formData)
      .subscribe(
        data => {
          this.getPayment();
        }
      );
  }

  setManualDate() {
    let date = this.invoiceForm.value.invoice_received_at;
    if ( date == '' || date == null ) {
      date = new Date();
    }
    let formData = clone(this.invoiceFromData);
    formData['invoiced_at'] = this.helpSer.formateDPDate(date);
    this.invoiceForm.patchValue({invoice_received_at: formData['invoiced_at']}, { emitEvent: false });
    this.http.post(`${this.apiURL}vendor-invoice/set-man-date`, formData)
      .subscribe(
        data => {
          //
        }
      );
  }

  invoiceFromData;
  getInvoiceFormData() {
    this.invoiceFromData = {
      token: this.token.get(),
      projectId: this.projectId,
      projectVendorId: this.projectVendorId
    };
  }

  @ViewChild('sendModalTemp') sendModalTemp;
  poPDF;
  invoiceSendStep = 1;
  invoiceEmailIpt;
  modalRef: BsModalRef;
  openSendModal(invoiceEmails) {
    this.invoiceEmails = clone(invoiceEmails);
    this.invoiceSendStep = 1;
    this.modalRef = this.modalService.show(
      this.sendModalTemp,
      { class: 'modal-xl' }
    );
  }

  closeModal() {
    this.modalRef.hide();
  }

  poHTML
  generatePOHTML() {
    if ( this.translationCount > 50 ) {
      this.helpSer.alertIt(this.lang.getText('project_vendor_langu_combi_max_50_breakdown_alert'));
      return;
    }
    let formData = this.invoiceFromData;
    formData['langCode'] = this.lang.getCode();
    this.http.post(`${this.apiURL}project/vendorPOGen/html`, this.invoiceFromData).subscribe(
      data => {
        this.poHTML = data['html'];
        this.openSendModal(this.invoiceEmails);
      }
    );
  }
  generatePOModalWithParams(projectId, projectVendorId, invoiceEmails, translationCount = 0, payRequested = true, hidden = true) {
    this.projectId = projectId;
    this.projectVendorId = projectVendorId;
    this.invoiceEmails = invoiceEmails;
    this.translationCount = translationCount;
    this.payRequested = payRequested;
    this.getInvoiceFormData();
    this.generatePOHTML();
  }

  generatePOPDF() {
    let formData = this.invoiceFromData;
    formData['langCode'] = this.lang.getCode();
    formData['html'] = this.poHTML;
    this.http.post(`${this.apiURL}project/vendorPOGen/pdf`, this.invoiceFromData).subscribe(
      data => {
        this.poPDF = this.helpSer.urlObjPDF(data['pdfB64']);
      }
    );
  }

  sendPO() {
    this.invoiceSendStep = 3;
    this.invoiceFromData['html'] = this.poHTML;
    this.invoiceFromData['emails'] = JSON.stringify(this.invoiceEmails);
    this.http.post(`${this.apiURL}project/vendorPOMail`, this.invoiceFromData).subscribe(
      data => {
        this.invoiceSendStep = 4;
        setTimeout(()=>{
          this.reloadProjectFinance.emit();
          this.closeModal();
        }, 2 * 1000);

      }
    );
  }
}

<ng-template #thisModal>
    <div class="modal-header">
       <h3 class="modal-heading text-center">{{lang.getText('new_features')}}</h3>
    </div>
    <div class="modal-body pr-3 pl-3">
        <div>
            <p>Welcome to release 4.0 of FlowDezk, the first of our releases with a completely new user experience. This is what we bring you this time:</p>
            <br />
        </div>
        <ul class="row releasenotes-list ml-0 mr-0">
            <li><p>We’ve completely revamped the look and feel of the project workflow. It’s all become more intuitive and less crowded. Let us know what you think as we’ll gradually be upgrading all of FlowDezk’s interface to this standard (you’ll still find the old interface in Configuration, Reporting, the vendor portal, etc, but that’ll change soon).</p></li>
            <li><p>You can now set client rate lists under Clients -> Pricing and select these rates on the Quoting tab of your projects. You’ll no longer have to look up rates to enter them manually. This is another big time saver.</p></li>
            <li><p>When you set a filter in your dashboard, your setting will now be stored when you leave FlowDezk and displayed when you log back in. This will be very convenient if you always sort your projects using the same filter.</p></li>
        </ul>
        <div>
            <p>Of course we also introduce a number of bug fixes and smaller improvements with this release. Our bidirectional XTM integration has been completed too but will be released separately in a couple of weeks. And all of the Phrase enthusiasts in the community will be happy to hear that our Phrase integration is nearing completion as well and will most probably be released before Tekom in November.</p><br />
            <p>We’re also creating a freelance version of FlowDezk, which will be released in a couple of weeks. This version has a minimum and a maximum of 1 user, has no integrations and has limited data storage, but other than that has all the great project management features of FlowDezk. It will be available at EUR 25/month. If you know of anyone who’d be interested, they can send a request to <a href="mailto:sales@flowdezk.com">sales@flowdezk.com</a>.</p><br />
            <p>For now enjoy this new release and don’t hesitate to create a support ticket from FlowDezk or by sending an e-mail to <a href="mailto:support@flowdezk.com">support@flowdezk.com</a> if you run into trouble or would like some advice on FlowDezk best practices.</p>
        </div>
    </div>
    <div class="modal-bottom px-3">
        <div class="d-flex justify-content-end">
            <div class="col-auto me-2">
                <button type="button" (click)="hideReleaseNote()" class="btn btn-primary mr-2">{{lang.getText('i_ve_read_this')}}</button>
            </div>
            <div class="col-auto">
                <button type="button" (click)="closeModal()" class="btn btn-secondary">{{lang.getText('remind_me_next_time')}}</button>
            </div>
        </div>
    </div>
</ng-template>

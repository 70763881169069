import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Output,Input, ViewChild, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HelperService } from 'src/app/services/helper.service';
import { JwtauthService } from 'src/app/services/jwtauth.service';
import { TokenService } from 'src/app/services/token.service';
import { environment as env } from 'src/environments/environment';
import { VendorRatesComponent } from '../vendor-rates/vendor-rates.component';
import { LanguageService } from '../../../services/language.service';

@Component({
  selector: 'app-add-vendor',
  templateUrl: './add-vendor.component.html',
  styleUrls: [
    './add-vendor.component.scss',
    '../../project-main-tab-page.component.scss',
    '../../project-create-page.component.scss' 
  ]
})
export class AddVendorComponent implements OnInit {
  @Input() hide;
  apiURL: String = env['API_URL'];
  lang;
  constructor(
    private fb: UntypedFormBuilder,
    private jwtAuth: JwtauthService,
    private token: TokenService,
    private http: HttpClient,
    private modalService: BsModalService,
    public helperSer: HelperService,
    public language: LanguageService
  ) {
    this.lang = language;
  }

  ngOnInit() {
    this.initForm();
    this.getSpecCodes();
    this.getLanguages();
    this.getFavorite();
  }

  vendorForm
  initForm() {
    let source = this.fb.control('');
    source.valueChanges.subscribe(value => {
      this.sourceLangChanged(value);
    });
    this.vendorForm = this.fb.group({
      source: source,
      target: '',
      spec_code: '',
      name: '',
      applyFormValue: false,
    });
  }

  @ViewChild('addVendorModal') addVendorModal;
  modalRef: BsModalRef;
  transVal;
  openModal(transVal) {
    this.transVal = transVal
    this.vendors = [];
    this.modalRef = this.modalService.show(
      this.addVendorModal,
      { class: 'new-redesign-style modal-xl', backdrop: 'static' }
    );
    if (transVal.length == 0) return;
    this.patchFormValue(this.transVal[0].source_lang_id, this.transVal[0].target_lang_id);
  }

  sourceLangChanged(slId) {
    this.transVal.forEach(tran => {
      if ( tran.source_lang_id == slId ) {
        this.patchFormValue(slId, tran.target_lang_id);
      }
    });
  }

  patchFormValue(source, target) {
    this.vendorForm.patchValue({
      source: source,
      target: target
    }, { emitEvent: false });
  }

  vendors: any[] = [];
  findVendors() {
    let formData = this.vendorForm.value;
    formData['token'] = this.token.get();
    this.http.post(`${this.apiURL}find-vendor/translation`, formData).subscribe(
      data => {
        this.vendors = [];
        Object.values(data).forEach(vendor => {
          vendor['checked'] = false;
          this.vendors.push(vendor)
        });
        this.checklist = this.vendors;
        this.masterSelected = false;
      },
      error => this.handleError(error)
    );
  }

  @Output() addVendor: EventEmitter<Object> = new EventEmitter();
  addVendorClick(id) {
    this.addVendor.emit({ vendorId: id, searchValues: this.vendorForm.getRawValue() });
  }

  addSelectedVendors() {
    this.vendors.forEach(vendor => {
      if (vendor.checked) {
        console.log(vendor.vendor.id)
        this.addVendorClick(vendor.vendor.id)
      }
    });
  }

  closeModal() {
    this.modalRef.hide();
  }
  @ViewChild('vendorRatesCom') vendorRatesCom: VendorRatesComponent;
  openRatesModal(vendorId) {
    this.vendorRatesCom.openModal(vendorId);
  }

  languages
  getLanguages() {
    this.http.post(`${this.apiURL}languages`, this.token.getAPITokenData()).subscribe(
      data => {
        this.languages = data['result'];
      },
      error => this.handleError(error)
    );
  }

  getLangName(id) {
    let name = '';
    this.languages.forEach(lang => {
      if (lang.id == id)
        name = lang.name;
    });
    return name;
  }

  specCodes
  getSpecCodes() {
    this.http.post(`${this.apiURL}spec-code`, this.token.getAPITokenData()).subscribe(
      data => {
        this.specCodes = data;
      },
      error => this.handleError(error)
    );
  }

  getSpecCodeName(id) {
    let name = '';
    this.specCodes.forEach(code => {
      if (code.id == id)
        name = code.name;
    });
    return name;
  }

  favorites: any[] = [];
  addFavorite() {
    let values = this.vendorForm.value;
    let formData = {
      token: this.token.get(),
      values: JSON.stringify({
        source: values.source,
        target: values.target,
        spec_code: values.spec_code
      })
    };
    this.http.post(`${this.apiURL}find-vendor/add-favorite`, formData).subscribe(
      data => { this.pushFavorites(data) },
      error => this.handleError(error)
    );
  }

  getFavorite() {
    this.http.post(`${this.apiURL}find-vendor/get-favorite`, this.token.getAPITokenData()).subscribe(
      data => { this.pushFavorites(data) },
      error => this.handleError(error)
    );
  }

  pushFavorites(favorites) {
    this.favorites = [];
    favorites.forEach(fav => {
      this.favorites.push(JSON.parse(fav))
    });
  }

  handleError(error) {
    this.jwtAuth.handleError(error);
  }


  checklist:any;
  masterSelected:boolean;
  checkUncheckAll(evt) {
    this.checklist.forEach((c) => c.checked = evt.target.checked)
  }
  isAllSelected(evt, index) {
    this.checklist[index].checked = evt.target.checked
    this.masterSelected = this.checklist.every((item) => item.checked == true);
  }

}

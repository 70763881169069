import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import {UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, FormControl} from '@angular/forms';

import { TokenService } from '../services/token.service';
import { JwtauthService } from '../services/jwtauth.service';
import { LanguageService } from '../services/language.service';

@Component({
  selector: 'app-vendor-actions-page',
  templateUrl: './vendor-actions-page.component.html',
  styleUrls: ['./vendor-actions-page.component.scss']
})
export class VendorActionsPageComponent implements OnInit {
  clientForm: UntypedFormGroup;
  apiURL = '';
  vendor;
  clientId;
  formData;
  errors;
  displayForm = false;
  type = '';
  isoDocsFa = new UntypedFormArray([]);
  lang;

  constructor(
    private injector: Injector,
    private http: HttpClient,
    private route: ActivatedRoute,
    private Token: TokenService,
    private Jwtwis: JwtauthService,
    private router: Router,
    private fb: UntypedFormBuilder,
    public language: LanguageService
    ) {
    this.apiURL = this.injector.get('API_URL');
    this.lang = language;
    this.getType()
    this.getISODocs();
    // this.initForm();
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.clientId =  params['id'];
      if ( this.isUpdateType() )
        this.getClientDetails();
    });
  }

  initForm(){
    let loadedData = this.isoDocs;
    let mfa = false;
    if ( this.isUpdateType() ) {
      loadedData = loadedData && this.vendor;
      mfa = this.vendor.mfa;
    }
    if ( ! loadedData ) return

    this.clientForm = this.fb.group({
      name: '',
      email: '',
      av_ref_id: '',
      address: '',
      send_reset_email: '',
      isoDocs: this.isoDocsFa,
      mfa: [{ value: mfa, disabled: ! mfa }],
    });

    this.isoDocs.map(doc => {
      let selected = false;
      if ( this.isUpdateType() ) {
        this.vendor.iso_docs.forEach(venDoc => {
          if (venDoc.vendor_iso_document_id == doc.id)
            selected = true;
        });
      }
      doc['selected'] = selected;
      this.isoDocsFa.push(this.fb.group(doc))
    })
    this.displayForm = true;
    if ( ! this.isUpdateType() ) return;

    this.clientForm.patchValue(this.vendor);
  }

  uri
  getType(){
    this.uri = this.type;
    if ( (this.router.url).includes('client') )
      this.type = 'client';
    else {
      this.type = 'vendor';
      this.uri = 'vend';
    }
  }

  getClientDetails(){
    this.formData = this.Token.getAPITokenData();
    this.formData[this.type+'Id'] = this.clientId;

    this.http.post(`${this.apiURL+this.type}/view`, this.formData).subscribe(
      data => {
        this.vendor = data['result'];
        this.initForm();
      },
      error => this.handleError(error)
    );
  }

  isoDocs
  getISODocs() {
    this.http.post(`${this.apiURL}iso-documentation`, this.Token.getAPITokenData()).subscribe(
      data => {
        this.isoDocs = data['result'];
        this.initForm();
      },
      error => this.handleError(error)
    );
  }

  isUpdateType(){
    return (typeof this.clientId != 'undefined');
  }

  onSubmitClientForm() {
    this.formData = this.clientForm.value;
    this.formData['token'] = this.Token.get()
    let url = `${this.apiURL+this.type}/create`;
    if ( this.isUpdateType() ) url = `${this.apiURL+this.type}/update/${this.clientId}`;

    this.http.post(url, this.formData).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    );
  }

  handleResponse(data) {
    this.router.navigateByUrl('/'+this.uri);
  }

  handleError(error) {
    this.Jwtwis.handleError(error);
    this.errors = error.error.error;
  }
}

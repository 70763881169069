    <div class="row row_end">

        <hr style="width: 80%; margin: 1rem auto; color: var(--DisabledColor);">

        <div class="col-md-12 my-2 d-flex align-items-center">
            <p>{{lang.getText('amount_breakdown')}}</p>
            <app-pagination class="ms-3"
            theme = 'center-align-btn'
            (go_to_page) = "getTranslations($event)"
            >
            </app-pagination>
        </div>
        <div class="col-md-12">
            <div class="row">
                <div class="row col-md-12">
                    <div *ngFor="let tran of translations; let ti = index;" class="col-3 mt-2">
                        <label class="mb-1">{{lang.getText('combo')}} {{((current_page-1) * per_page) + ti + 1}}</label>
                        <div class="badge-empty w-70 p-2 text-center">
                            <app-total-display 
                                title = ""
                                [amount] = "tran.amount"
                                [currencyCode] = "projectCurrencyName"
                                type = ""
                                col_l="4"
                                col_r="8"
                            ></app-total-display>
                        </div>
                        <!-- <span class="pl-3 col-md-6">{{tran.amount}}</span> -->
                    </div>
                </div>
                <!-- <div class="col-md-3 col-sm-12 nospace">
                    <button class="btn btn-primary mr-2"
                        [disabled]="is_request_active() || page_no == 1"
                        (click)="getTranslations('prev')">{{lang.getText('previous')}}
                    </button>
                    <button class="btn btn-primary"
                        [disabled]="is_request_active() || page_no == total_page"
                        (click)="getTranslations('next')">{{lang.getText('next')}}
                    </button>
                </div> -->
            </div>
            <div class="row">
                <div class="row col-md-12">
                    <div *ngFor="let addSer of projectEditForm.get('additionalServices')['controls']; let adi = index;" class="col-3 mt-2">
                        <label class="mb-1">{{lang.getText('service')}} {{adi+1}}</label>
                        <div class="badge-empty w-70 p-2 text-center">
                            <!-- {{ addSer.value.amount | myCurrency: projectCurrencyName }}  -->
                            <app-total-display 
                                title = ""
                                [amount] = "addSer.value.amount"
                                [currencyCode] = "projectCurrencyName"
                                type = ""
                                col_l="4"
                                col_r="8"
                            ></app-total-display>
                        </div>
                        <!-- <span class="pl-3 col-md-6">{{addSer.value.amount}}</span> -->
                    </div>
                </div>
            </div>
        </div>
        
        <hr style="width: 80%; margin: 1rem auto; color: var(--DisabledColor);">
            
        <div class="row">
            <div class="col-3">
                <label class="mb-1">{{lang.getText('invoice_amount')}}</label>
                <div class="badge-primary w-70 p-3 text-center">
                    <app-total-display 
                        title = ""
                        [amount] = "projectEditForm.value.totalCost"
                        [currencyCode] = "projectCurrencyName"
                        type = ""
                        col_l="4"
                        col_r="8"
                    ></app-total-display>
                </div>
            </div>
        </div>
    </div>

<div class="innerproject_details">

  <form [formGroup]='projectFilterForm'>
  <div class="row action-layout">
    <div class="col ">
      <div class="border-layout filter_main">
        <span class="text"> {{lang.getText('filters')}} </span>
          <div class="form-row filter-sec-i">
            <div class="form-group col-auto d-flex align-items-center">
              <input formControlName="headsup" class="form-check-input filter-checkbox mid-checkbox" type="checkbox"  />
              <label class="form-check-label mid-text-box" for="autoSizingCheck">
                {{lang.getText('received')}}
              </label>
            </div>
            <div class="form-group col-auto d-flex align-items-center">
              <input formControlName="accepted" class="form-check-input mid-checkbox" type="checkbox"/>
              <label class="form-check-label mid-text-box" for="autoSizingCheck">
               {{lang.getText('accepted')}}
              </label>
            </div>
            <div class="form-group col-auto d-flex align-items-center">
              <input formControlName="confirmed" class="form-check-input mid-checkbox" type="checkbox"/>
              <label class="form-check-label mid-text-box" for="autoSizingCheck">
                {{lang.getText('confirmed')}}
              </label>
            </div>
            <div class="form-group col-auto d-flex align-items-center">
              <input formControlName="delivered" class="form-check-input mid-checkbox" type="checkbox"/>
              <label class="form-check-label mid-text-box" for="autoSizingCheck">
                {{lang.getText('delivered')}}
              </label>
            </div>
            <div class="form-group col-auto d-flex align-items-center">
              <input formControlName="invoiced" class="form-check-input mid-checkbox" type="checkbox"/>
              <label class="form-check-label mid-text-box" for="autoSizingCheck">
                {{lang.getText('invoiced')}}
              </label>
            </div>
            <div class="form-group col-auto d-flex align-items-center">
              <input formControlName="paid" class="form-check-input mid-checkbox" type="checkbox"/>
              <label class="form-check-label mid-text-box" for="autoSizingCheck">
                {{lang.getText('paid_without_colun')}}
              </label>
            </div>
            <div class="form-group col-auto d-flex align-items-center">
              <input formControlName="archived" class="form-check-input mid-checkbox" type="checkbox"/>
              <label class="form-check-label mid-text-box" for="autoSizingCheck">
                {{lang.getText('archived')}}
              </label>
            </div>
            <div class="form-group col-auto">
              <button (click)="loadProjectsFreshly()" class="btn btn-primary">{{lang.getText('filter')}}</button>
              <button (click)="resetForm()" class="btn btn-primary" type="reset">{{lang.getText('clear')}}</button>
            </div>
          </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12 col-lg-4 col-xs-12 col-sm-12 ">
      <div class="border-layout">
        <div class="actoins_outer col-auto col-form-label col-form-label-sm2">
          <span class="text"> {{lang.getText('actions')}} </span><br />
          <span class="fontSize">{{lang.getText('with_selected')}}</span>
          <button (click)="openModal(projectModal, acceptSelected)" class="btn btn-primary"> {{lang.getText('accept')}} </button>
          <button (click)="openModal(projectModal, rejectSelected)" class="btn btn-primary"> {{lang.getText('reject')}} </button>
          <button (click)="openModal(projectModal, deliverSelected)" class="btn btn-primary"> {{lang.getText('deliver')}} </button>
          <button (click)="openModal(projectModal, archiveSelected)" class="btn btn-primary"> {{lang.getText('archive')}} </button>
          <button class="btn btn-secondary" [ngClass]="postData['filters'].archived ? 'btn-primary' : 'btn-secondary'" (click)="openReactivateModal(reactivateModal)"> {{lang.getText('reactivate')}}</button>
        </div>
      </div>
    </div>
  </div>
    <div class="display-settings row action-layout">
      <div class="col ">
        <div class="border-layout filter_main">
          <span class="text"> {{lang.getText('display_settings')}} </span>
          <div class="display-settings-inner">
            <div class="form-group row inline col-md-3">
              <label class="col-sm-4 col-form-label">{{lang.getText('sort_by')}}</label>
              <div class="col-sm-8 inline">
                <select formControlName="disSetSortBy" class="form-control">
                  <option *ngFor="let option of disSetSortByOptions" [value]="option.id">{{option.name}}</option>
                </select>
              </div>
            </div>
            <div class="form-group row inline col-auto vtop">
              <input type="hidden" formControlName="order_direction"/>
              <button class="sort-btn" (click)="changeOrder()" [ngClass]="{'desc' : projectFilterForm.value['order_direction']=='desc'}"></button>
            </div>
            <div class="form-group row inline col-md-4">
              <label class="col-sm-6 col-form-label">{{lang.getText('mark_delivery_dates')}}</label>
              <div class="col-sm-6 inline">
                <select formControlName="disSetDeliveryDate" class="form-control">
                  <option *ngFor="let option of disSetDeliveryDateOptions" [value]="option.id">{{option.name}}</option>
                </select>
              </div>
            </div>
            <div class="form-group row inline col-md-3"></div>
            <div class="form-group row inline col-auto">
              <div class="col-sm-12 inline">
                <input formControlName="batch_delivery_date" class="form-check-input" type="checkbox"/>
                <label class="form-check-label" for="autoSizingCheck">
                  Include batch delivery dates
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <!-- for web above 1024 size -->
  <div
    *ngIf="projects !== null && projects.length"
    class="for_web_projects_list">
    <div class="container-fluid project-list-layout ">
      <div class="row header header_main for_web_only">
        <div class="col-lg-3 col-md-4 project_info_head text-center">{{lang.getText('project_summary')}}</div>
        <div class="col-lg-8 col-md-6  status_actions_head text-center">{{lang.getText('project_details')}}</div>
        <div class="col-lg-1 col-md-2  box_head text-center">{{lang.getText('actions')}}</div>
      </div>
    </div>
    <div [formGroup]="projectsForm" class="inner_project_list">
      <div class="container-fluid content project_single" *ngFor="let project of projects;let pi = index;">
        <div [ngClass]="{cancelled: cancelledProject(project)}" class="row special_row" [style.background]="labelSer.getColor(project.vendor_label)">
          <div
            class="col-lg-3 col-md-4 col-sm-12 project {{applyRedBGMClass}}"
            [ngClass]="{'applyRedBGM': (project.vendor_status === 0)}"
            >
             <div class="col-sm-12 hide_mobile  project_info_head text-center">{{lang.getText('project_information')}}</div>
            <div>
              <a
                [ngClass]="{ord_num: !cancelledProject(project), order_number: cancelledProject(project)}"
                class="single_order">
                {{lang.getText('order_number')}} {{project.id+initialOrderId}}
              </a>
            </div>

            <div>
              <p><span class="title"><strong>{{lang.getText('status2')}}</strong></span> {{getVenStatusNameSummaryBox(project)}}</p>
            </div>
            <div>
              <p>
                <span class="title"><strong>{{lang.getText('owner2')}} </strong></span>
                <span>{{project.owner_name}}</span>
              </p>
            </div>
            <div>
              <p><span class="title"><strong>{{lang.getText('project_delivery_date2')}}</strong></span>
              <span *ngIf="project.deliver_date != '1000-01-01'">{{helperService.formatDateTime(project.deliver_date)}} </span>
              </p>

            <div *ngIf="project.batches!='null'">
              <div style="margin-left: 10px;">
                  <p *ngFor="let batch of project['batches']; let bi = index;">
                  <span class="title">{{lang.getText('batch')}} {{bi+1}} - </span>
                  {{helperService.formatDateTime(project.batches[bi])}}
                </p> 
              </div>
            </div> 

            </div>
            <div class="plan-finance">
              <span class="plan" (click)="openProjectPlanModal(projectPlanModal, project.id)">{{lang.getText('planning')}}</span>
            </div>
            <div [hidden]="!project.warn" class="warning_btn">
              <a class="btn-danger" (click)="openIgnoreWarningModal(ingoreWarningModal, project.id, pi)">{{lang.getText('deadline')}}</a>
            </div>
            <div class="claim_btn">
              <a *ngIf="cancelledProject(project)" class="btn-danger">{{lang.getText('cancelled')}}</a>
              <a (click)="openChangeLabelModal(project)" class="btn btn-primary btn-sm right-top-btn">{{lang.getText('categorize')}}</a>
            </div>
            <div class="cat-this-project">
              <span *ngIf="project.vendor_label">{{labelSer.getName(project.vendor_label)}}</span><br />
            </div>
          </div>
          <div class="col-lg-8 col-md-6 col-sm-12 status_actions_content row">
            <div class="col-md-8">

              <div class="col-sm-12 hide_mobile status_actions_head text-center">{{lang.getText('status')}}</div>
            <div class="row outer_progress">
              <ul *ngIf="project.vendor_status !== 0" id="progress">
                <ng-container *ngFor="let status of vendorStatuses">
                  <li
                    *ngIf="status.ui"
                    (click)="setStatus(project, status.label, pi)"
                    class="{{status.label}}"
                    [ngClass]="{active: project['vendor_status'] >= status.id}">
                   {{lang.getText(status.label)}}
                    <span><i class="fa fa-check-circle" aria-hidden="true"></i> </span>
                  </li>
                </ng-container>
              </ul>
              <ul *ngIf="project.vendor_status === 0" id="progress" >
                <li style="padding-right: 33px; background-color:rgb(165, 164, 164)">
                  {{vendorStatusesSummaryBox[0].name}}
                  <span><i class="fa fa-check-circle" aria-hidden="true"></i> </span>
                </li>
              </ul>
            </div>
            <div>
              <p><span class="title"><strong>{{lang.getText('project_name')}}</strong></span> {{project.name}}</p>
            </div>
            <table class='list-tabel' aria-label="project">
              <tr class="hidden">
                <th scope="col1"></th>
                <th scope="col2"></th>
              </tr>
              <tr>
                <td class="po-title"><span class="title">{{lang.getText('po_amount2')}}</span> {{project.pv_amount}}</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <span>
                    <a
                      (click)="downloadFiles(project)" 
                      class="btn btn-sm btn-primary title">
                      <u *ngIf="project['vendor_status'] < 3">{{lang.getText('download_heads_up_files')}}</u>
                      <u *ngIf="project['vendor_status'] > 2">{{lang.getText('download hand_off_files')}}</u>
                    </a>
                  </span>
                </td>
                <td>
                  <a
                    (click)=uploadDeliveryFiles(project)
                    class="btn btn-sm btn-primary title">
                    <u>{{lang.getText('upload_delivery_files')}}</u>
                  </a>
                </td>
                <td>
                  <a (click)="downloadHandOff(project.pv_id)" class="btn btn-sm btn-primary title">
                    <u *ngIf="project['vendor_status'] < 3">{{lang.getText('download_heads_up')}}</u>
                    <u *ngIf="project['vendor_status'] > 2">{{lang.getText('download_hand_off')}}</u>
                  </a>
                </td>
              </tr>
            </table>
            </div>
            <div *ngIf="canVendorUploadInvoice" class="col-md-4 invoice-doc-layout">
              <h6>{{lang.getText('invoicing_documents')}}</h6>
              <table aria-label="invoicing">
                <tr class="hidden">
                  <th scope="col1"></th>
                  <th scope="col2"></th>
                  <th scope="col3"></th>
                </tr>
                <tr>
                  <td><button (click)="openPOHistoryModal(project)" class="btn btn-sm btn-primary">{{lang.getText('history')}}</button></td>
                  <td></td>
                </tr>
              </table>
            </div>
            <div *ngIf="!canVendorUploadInvoice" class="col-md-4 invoice-doc-layout">
              <h6>NA</h6>
            </div>
          </div>
          <div formArrayName="selectedProjects" class="col-lg-1 col-md-2 col-sm-12 box_check_main text-center">
            <div class="col-sm-12 hide_mobile box_head text-center">{{lang.getText('actions')}}</div>
            <input [hidden]="cancelledProject(project)" [formControlName]="pi" [value]="project.id" type="checkbox">
            <span
              (click)="archiveProject(project.id)"
              [hidden]="!cancelledProject(project)"
              data-toggle="tooltip"
              class="archive-icon cur-pointer fa fa-1x fa-file-archive-o tooltipc">
              <span class="tooltiptext">{{lang.getText('archive')}}</span>
            </span>
          </div>
        </div>
      </div>
      <div [hidden]="allResultsLoader" class="loader container-fluid">
          <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      </div>
    </div>
  </div>

  <div *ngIf="noMoreResult" class="container no_results">
    <div class="row text-center">
      <div class="col-md-12">
          <p><span class="search_custom_noresults"><em  class="fa fa-search"></em></span></p>
          <br>
          <h5>{{lang.getText('no_orders_to_be_displayed')}}</h5>
          <p>{{lang.getText('try_again_using_different_search_terms2')}}</p>
    </div>
    </div>
  </div>
</div>

<ng-template #projectModal>
  <div *ngIf="this.selectedProjects.length == 0" class="modal-header">
    <h4 class="modal-title pull-left">{{lang.getText('no_projects_selected_nothing_to')}}   {{selectedModal.replace('Modal', '').replace('S','')}} </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div *ngIf="this.selectedProjects.length > 0" class="modal-header">
    <h4 class="modal-title pull-left">{{lang.getText('are_you_sure_you_want_to')}}  {{selectedModal.replace('Modal', '').replace('S','')}} {{lang.getText('these_projects')}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div *ngIf="this.selectedProjects.length == 0" class="modal-body">
      <div class="col-sm-12 text-center">
        <strong></strong>
      </div>

  </div>
  <div *ngIf="this.selectedProjects.length > 0" class="modal-body">
    <div class="col-sm-12 text-center">
      <strong>{{lang.getText('project_information')}}</strong>
    </div>
    <div *ngFor="let project of selectedProjects;" class="row project">
      <div class="col-sm-12"><a  class="title"><strong>{{lang.getText('order_number')}}</strong> {{project.id+initialOrderId}}</a></div>
      <div class="col-sm-12">
        <p><span class="title">{{lang.getText('project_name')}}</span> {{project.name}}</p>
      </div>
      <div class="col-sm-12">
        <p>
          <span class="title">{{lang.getText('owner2')}}</span>
          <span> {{project.owner_name}}</span>
        </p>
      </div>
      <br/>
    </div>
    <div *ngIf="selectedModal == acceptSelected" class="claim-accept">
      <button class="btn btn-success pull-right" (click)="acceptAll();">
        {{lang.getText('accept')}} <span *ngIf="selectedProjects.length > 1">{{lang.getText('all')}}</span>
      </button>
    </div>
    <div *ngIf="selectedModal == rejectSelected" class="claim-accept">
      <button class="btn btn-success pull-right" (click)="rejectAll();">
        {{lang.getText('reject')}} <span *ngIf="selectedProjects.length > 1">{{lang.getText('all')}}</span>
      </button>
    </div>
    <div *ngIf="selectedModal == deliverSelected" class="claim-accept">
      <button class="btn btn-success pull-right" (click)="deliverAll();">
        {{lang.getText('deliver')}} <span *ngIf="selectedProjects.length > 1">{{lang.getText('all')}}</span>
      </button>
    </div>
    <div *ngIf="selectedModal == archiveSelected" class="claim-accept">
      <button class="btn btn-success pull-right" (click)="archiveAll();">
        {{lang.getText('archive')}} <span *ngIf="selectedProjects.length > 1">{{lang.getText('all')}}</span>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #projectPlanModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
        {{lang.getText('delivery_schedule_information')}}
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div *ngIf="projectDetails" class="modal-body planning-modal">
    <div *ngFor="let vendor of getVendors(projectDetails)">
      <div *ngIf="vendor.trans.length > 0">
        <h3 class="vendor-title">{{vendor['details'].name}}</h3>
        <div *ngIf="vendor.trans">
          <h5>{{lang.getText('translations')}}</h5>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-3 col-title">
                {{lang.getText('source')}}
              </div>
              <div class="col-md-3 col-title">
                {{lang.getText('target')}}
              </div>
              <div class="col-md-3 col-title">
                {{lang.getText('rate')}}
              </div>
              <div class="col-md-3 col-title">
                {{lang.getText('total')}}
              </div>
            </div>
          </div>
          <div *ngFor="let tran of vendor.trans" class="col-md-12">
            <div class="row">
              <div class="col-md-3">
                <span *ngFor="let lang of languages; let lai = index">
                  <span *ngIf="tran.source_lang_id == lang.id" >{{lang.name}}</span>
                </span>
              </div>
              <div class="col-md-3">
                <span *ngFor="let lang of languages; let lai = index">
                  <span *ngIf="tran.target_lang_id == lang.id" >{{lang.name}}</span>
                </span>
              </div>
              <div class="col-md-3">
                <span>{{tran.word_rate}}</span>
              </div>
              <div class="col-md-3">
                {{tran.amount}}
              </div>
            </div>
            <div *ngIf="tran.batches" class="col-md-10 col-md-offset-2">
              <div *ngFor="let batch of tran.batches; let bi = index;" class="row">
                <div class="col-md-5">
                  {{lang.getText('delivery_batch')}} {{bi+1}}
                </div>
                <div class="col-md-7">
                  <span [innerHTML]=helperService.formatDateTime(batch.delivery_at)></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h5 *ngIf="vendor.services.length > 0">{{lang.getText('services')}}</h5>
      <div *ngIf="vendor.services.length" class="col-md-12">
        <div class="row">
          <div class="col-md-3 col-title">
            {{lang.getText('description')}}
          </div>
          <div class="col-md-3 col-title">
            {{lang.getText('rate')}}
          </div>
          <div class="col-md-3 col-title">
            {{lang.getText('volume')}}
          </div>
          <div class="col-md-3 col-title">
            {{lang.getText('total')}}
          </div>
        </div>
      </div>
      <div *ngFor="let addSer of vendor.services; let i = index" class="col-md-12">
        <div class="row">
          <div class="col-md-3">
              {{addSer.comment}}
          </div>
          <div class="col-md-3">
              {{addSer.rate}}
          </div>
          <div class="col-md-3">
              {{addSer.volume}}
          </div>
          <div class="col-md-3">
            {{addSer.rate * addSer.volume}}
          </div>
        </div>
        <div *ngIf="addSer.batches" class="col-md-10 col-md-offset-2 deliver_batch">
          <div *ngFor="let batch of addSer.batches; let bi = index;" class="row">
            <div class="col-md-3">
              {{lang.getText('delivery_batch')}} {{bi+1}}
            </div>
            <div class="col-md-4">
                {{batch.description}}
            </div>
            <div class="col-md-4">
                <span [innerHTML]=helperService.formatDateTime(batch.delivery_at)></span>
              </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12"><a  class="title"><strong></strong></a></div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #reactivateModal>
  <div *ngIf="!postData['filters'].archived" class="modal-header">
    <h4 class="modal-title pull-left">{{lang.getText('long_text20')}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div *ngIf="postData['filters'].archived&&selectedProjects.length<=0" class="modal-header">
    <h4 class="modal-title pull-left">{{lang.getText('long_text21')}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div *ngIf="postData['filters'].archived&&selectedProjects.length>0" class="modal-header">
    <h4 class="modal-title pull-left">{{lang.getText('long_text22')}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div *ngIf="postData['filters'].archived&&selectedProjects.length>0" class="modal-header">
    <button class="btn btn-success pull-right" (click)="closeModal()">
      {{lang.getText('cancel')}}
    </button>
    <button class="btn btn-success pull-right" (click)="reactivateAll()">
      {{lang.getText('ok')}}
    </button>
  </div>
</ng-template>

  
  <ng-template #changeLabelTemp>
    <div class="modal-header">
      <h4 class="modal-title pull-left">{{lang.getText('select_category')}}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body change-label-temp-modal-body">
      <div class="row labels">
        <div *ngIf="labels.length == 0" class="alert alert-danger" role="alert">
          {{lang.getText('no_categories_to_select')}}
        </div>
        <div class="col-12 label"
          *ngFor="let label of labels;">
          <button
            (click)="changeLabel(label.id)"
            class="btn btn-light btn-break"
            [style.backgroundColor]="label.color">
            {{label.name}}
          </button>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #ingoreWarningModal>
    <div class="modal-header">
      <h4 class="modal-title pull-left">{{lang.getText('confirm')}}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="col-sm-12 text-center">
        <h6>{{lang.getText('vendor_delivery_deadline_text')}}</h6>
      </div>
      <div class="do-transfer">
        <button class="btn btn-sm btn-light pull-right ml-2"
          (click)="closeModal()">
          {{lang.getText('no')}}
        </button>
      </div>
      <div class="claim-accept">
        <button class="btn btn-sm btn-success pull-right"
          (click)="ignoreWarning(ignoreWarn.id, ignoreWarn.pi)">
          {{lang.getText('yes')}}
        </button>
      </div>
    </div>
  </ng-template>

<app-po-history #poHistoryCom></app-po-history>

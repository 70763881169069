import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { HelperService } from "src/app/services/helper.service";
import { LanguageService } from "src/app/services/language.service";
import { TokenService } from "src/app/services/token.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-suggest-improvement",
  templateUrl: "./suggest-improvement.component.html",
  styleUrls: ["./suggest-improvement.component.scss"],
})
export class SuggestImprovementComponent implements OnInit {
  apiURL = environment["API_URL"];
  modalRef: BsModalRef;
  @ViewChild("suggestImprovement") suggestImprovement;
  constructor(
    private tokenSer: TokenService,
    private http: HttpClient,
    private modalService: BsModalService,
    public lang: LanguageService,
    private fb: UntypedFormBuilder,
    private helpSer: HelperService
  ) {
    //
  }

  suggestionFg: UntypedFormGroup;
  ngOnInit() {
    //
  }

  openModal() {
    this.suggestionFg = this.fb.group({
      suggestion: "",
      email: "",
    });
    this.suggestionSubmited = false;
    this.modalRef = this.modalService.show(
      this.suggestImprovement,
      Object.assign({}, { class: "modal-lg" })
    );
  }

  closeModal() {
    this.modalRef.hide();
  }

  suggestionSubmited: boolean = false;
  submitSuggestion() {
    let formData = this.suggestionFg.value;
    if (formData.suggestion == "") {
      return this.helpSer.alertIt(this.lang.getText('please_enter_a_suggestion_or_click_cancel_to_close_the_form'));
    } else if ( formData.email && ! this.helpSer.validEmail(formData.email) ) {
      return this.helpSer.alertIt(this.lang.getText('please_enter_valid_email'));
    } else {
      formData["token"] = this.tokenSer.get();
      this.http
        .post(`${this.apiURL}suggestion/submit`, formData)
        .subscribe((data) => {
          if (data) {
            this.suggestionSubmited = true;
          }
        });
    }
  }
}

import { HttpClient } from '@angular/common/http';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HelperService } from 'src/app/services/helper.service';
import { LanguageService } from 'src/app/services/language.service';
import { ProjectService } from 'src/app/services/project.service';
import { environment as env } from 'src/environments/environment';

@Component({
  selector: 'app-details-popup',
  templateUrl: './details-popup.component.html',
  styleUrls: ['./details-popup.component.scss']
})
export class DetailsPopupComponent {
  private apiURL = env['API_URL'];
  @ViewChild('delivery_schedule_temp') delivery_schedule_temp: TemplateRef<any>;
  @ViewChild('finance_temp') finance_temp: TemplateRef<any>;
  calendarInfo = {
    openList: false,
    calendar: 0
  }
  project;

  constructor(
    private http: HttpClient,
    private modal_service: BsModalService,
    private project_ser: ProjectService,
    public helper_ser: HelperService,
    public lang: LanguageService
    ) {
      //
  }

  modal_ref: BsModalRef;
  languages;
  vendors;
  currencies;
  open(project_id, pop_up, languages, vendors, currencies) {
    this.languages = languages;
    this.vendors = vendors;
    this.project = null;
    this.currencies = currencies;
    this.calendarInfo.openList = false;
    this.get_project(project_id);
    this.get_project_translations(project_id);
    if ( pop_up == 'finance' ) {
      this.modal_ref = this.modal_service.show(
        this.finance_temp,
        Object.assign({}, { class: 'modal-xl new-redesign-style' })
      );
      return;
    }
    this.modal_ref = this.modal_service.show(
      this.delivery_schedule_temp,
      Object.assign({}, { class: 'modal-lg new-redesign-style' })
    );
  }
  
  get_project(id) {
    let postData = {
      projectId: id
    };
    this.http.post(`${this.apiURL}project/view`, postData).subscribe(
      data => {
        this.project = data['result'];
        this.addDeliveryBatchDesciption(data['result']);
        this.project.vendors = this.getVendors();
      }
    );
  }

  addDeliveryBatchDesciption(project){
    project.deliveryBatches.forEach(batch => {
      if(batch.batch_link){
        if(batch.batch_link.service_type == 'translation'){
          batch.description = this.getTranslation(batch.batch_link.trans_service_id).comment;
        }
        else if(batch.batch_link.service_type == 'service'){
          batch.description = this.getService(batch.batch_link.trans_service_id).comment;
        }
      }
    });
  }

  getService(id) {
    let service;
    this.project.additionalServices.forEach(s => {
      if ( s.id == id  ) {
        service = s;
      }
    });
    return service;
  }

  getTranslation(id) {
    let source, target = null;
    let transl;
    this.project.translations.forEach(t => {
      if ( t.id == id  ) {
        transl = t;
        source = this.getLanguageById(transl['source_lang_id']);
        target = this.getLanguageById(transl['target_lang_id']);
      }
    });
    transl['source_lang'] = source;
    transl['target_lang'] = target;
    return transl;
  }

  getLanguageById(langId) {
    let language = [];
    this.languages.forEach(lang => {
      if ( lang.id == langId )
        language = lang;
    });
    return language;
  }

  modal2Ref;
  openCalendarsConfirmPopup(calendar, template: TemplateRef<any>){
    this.calendarInfo.calendar = calendar;
    this.calendarInfo.openList = false;
    this.modal2Ref = this.modal_service.show(template);
    //this.openModal(template,0);
  }

  addEventToCalendar(){
    let date = this.helper_ser.splitDateAndTime(this.project.project_delivery);
    let minute = date.minute;
    let calendarType: string;
    if(this.calendarInfo.calendar == 2){
      calendarType = "html";
    }
    else{
      calendarType = "plaintext";
    }
    if( minute < 10)
      minute = '0' + minute;
    let event  = {
      "@context":"https://schema.org",
      "@type":"Event",
      "name":this.project.name,
      "description": this.generateDescriptionString(calendarType),
      "startDate":date.date,
      "endDate":date.date,
      "startTime": ("0"+date.hour).slice(-2) + ':' + ("0"+date.minute).slice(-2),
      "endTime": ("0"+date.hour).slice(-2) + ':' + ("0"+minute).slice(-2),
      "location": (this.project.order_number)
    };
    //alert(date.hour + ':' + minute);
   if(this.calendarInfo.calendar == 2){
      this.generateGoogle(event);
    }
    else{
      this.generateIcal(event);
    }
    this.modal2Ref.hide();
  }

  generateGoogle(data) {
    // base url
    let url = 'https://calendar.google.com/calendar/render?action=TEMPLATE';
    // generate and add date
    let formattedDate = this.generateTimeToCalendar(data, 'clean', 'google');
    url += '&dates=' + formattedDate['start'] + '%2F' + formattedDate['end'];
    // add details (if set)
    if (data['description'] != null && data['description'] != '') {
      url += '&details=' + encodeURIComponent(data['description']);
    }
    if (data['location'] != null && data['location'] != '') {
      url += '&location=' + encodeURIComponent(data['location']);
    }
    if (data['name'] != null && data['name'] != '') {
      url += '&text=' + encodeURIComponent(data['name']);
    }
    window.open(url, '_blank').focus();
  }


  generateIcal(data) {
    let now:any = new Date();
    now = now.toISOString().replace(/\-/g, '').replace(/\:/g, '').replace(/\..../g, '');
    const formattedDate = this.generateTimeToCalendar(data, 'clean', 'ical');
    let timeslot = '';
    if (formattedDate['allday']) {
      timeslot = ';VALUE=DATE';
    }
    let ics_lines = [
      "BEGIN:VCALENDAR",
      "VERSION:2.0",
      "CALSCALE:GREGORIAN",
      "BEGIN:VEVENT",
      "DTSTAMP:" + formattedDate['start'],
      "DTSTART" + timeslot + ":" + formattedDate['start'],
      "DTEND" + timeslot + ":" + formattedDate['end'],
      "DESCRIPTION:" + data['description'].replace(/\n/g, '\\n'),
      "SUMMARY:" + data['name'],
      "LOCATION:" + data['location'],
      "STATUS:CONFIRMED",
      "LAST-MODIFIED:" + now,
      "SEQUENCE:0",
      "END:VEVENT",
      "END:VCALENDAR"
    ];
    let dlurl = 'data:text/calendar;base64,'+btoa(ics_lines.join('\r\n').replace(/[\u00A0-\u2666]/g, function(c) { return '&#' + c.charCodeAt(0) + ';'; })); // includes a fix to not throw an error with non-Latin1 characters. However, still needs improvement, since it shows up encoded in the iCal file.
    try {
      let save = document.createElement('a');
      save.href = dlurl;
      save.target = '_blank';
      save.download = data['iCalFileName'] || 'event-to-save-in-my-calendar';
      let evt = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': false
      });
      save.dispatchEvent(evt);
      (window.URL).revokeObjectURL(save.href);
    } catch(e) {
      console.log(e);
    }
  }
  
  generateTimeToCalendar(data, style = 'delimiters', targetCal = 'general') {
    let startDate:any = data['startDate'].split('/');
    let endDate:any = data['endDate'].split('/');
    let start:any = '';
    let end:any = '';
    let allday = false;
    if (data['startTime'] != null && data['endTime'] != null) {
      // Adjust for timezone, if set (see https://en.wikipedia.org/wiki/List_of_tz_database_time_zones for either the TZ name or the offset)
      if (data['timeZoneOffset'] != null && data['timeZoneOffset'] != '') {
        // if we have a timezone offset given, consider it
        start = new Date( startDate[2] + '-' + startDate[1] + '-' + startDate[0] + 'T' + data['startTime'] + ':00.000' + data['timeZoneOffset'] );
        end = new Date( endDate[2] + '-' + endDate[1] + '-' + endDate[0] + 'T' + data['endTime'] + ':00.000' + data['timeZoneOffset'] );
        start = start.toISOString().replace('.000', '');
        end = end.toISOString().replace('.000', '');
        if (style == 'clean') {
          start = start.replace(/\-/g, '').replace(/\:/g, '');
          end = end.replace(/\-/g, '').replace(/\:/g, '');
        }
      } else {
        // if there is no offset, we prepare the time, assuming it is UTC formatted
        start = new Date( startDate[2] + '-' + startDate[1] + '-' + startDate[0] + 'T' + data['startTime'] + ':00.000+00:00' );
        end = new Date( endDate[2] + '-' + endDate[1] + '-' + endDate[0] + 'T' + data['endTime'] + ':00.000+00:00' );
        if (data['timeZone'] != null && data['timeZone'] != '') {
          // if a timezone is given, we adjust dynamically with the modern toLocaleString function
          let utcDate = new Date(start.toLocaleString('en-US', { timeZone: "UTC" }));
          if (data['timeZone'] == 'currentBrowser') {
            data['timeZone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
          }
          let tzDate = new Date(start.toLocaleString('en-US', { timeZone: data['timeZone'] }));
          let offset = utcDate.getTime() - tzDate.getTime();
          start.setTime( start.getTime() + offset );
          end.setTime( end.getTime() + offset );
        }
        start = start.toISOString().replace('.000', '').replace('Z','');
        end = end.toISOString().replace('.000', '').replace('Z','');
        if (style == 'clean') {
          start = start.replace(/\-/g, '').replace(/\:/g, '');
          end = end.replace(/\-/g, '').replace(/\:/g, '');
        }
      }
    } else { // would be an allday event then
      allday = true;
      start = new Date( startDate[2], startDate[0] - 1, startDate[1]);
      start.setDate(start.getDate() + 1); // increment the day by 1
      let breakStart = start.toISOString().split('T');
      end = new Date( endDate[2], endDate[0] - 1, endDate[1]);
      if (targetCal == 'google' || targetCal == 'microsoft' || targetCal == 'ical') {
        end.setDate(end.getDate() + 2); // increment the day by 2 for Google Calendar, iCal and Outlook
      } else {
        end.setDate(end.getDate() + 1); // otherwise by 1
      }
      let breakEnd = end.toISOString().split('T');
      if (style == 'clean') {
        breakStart[0] = breakStart[0].replace(/\-/g, '');
        breakEnd[0] = breakEnd[0].replace(/\-/g, '');
      }
      start = breakStart[0];
      end = breakEnd[0];
    }
    let returnObject = {'start':start, 'end':end, 'allday':allday};
    return returnObject;
  }

  generateDescriptionString(strType){
    let space = ' ';
    let projectDeliveryHeading = this.lang.getText('project_delivery_date');
    let sapDeliveryHeading = this.lang.getText('sap_delivery_dates');
    let manualDeliveryHeading = this.lang.getText('manual_delivery_dates');
    if ( strType == 'html' ) {
      space = '\xa0';
      projectDeliveryHeading = '<b>' + this.lang.getText('project_delivery_date') + '</b>';
      sapDeliveryHeading = '<b>' + this.lang.getText('sap_delivery_dates') + '</b>';
      manualDeliveryHeading = '<b>' + this.lang.getText('manual_delivery_dates') +'</b>';
    }
    let str = projectDeliveryHeading+' '+space+space+space+space+space+space+space+space+space+space + this.helper_ser.formatDateTime(this.project.project_delivery) + '\n';

    if(this.helper_ser.nonEmptyArray(this.project.deliveryBatchesAPI)){
      str += ' \n \n' + '  '+ sapDeliveryHeading + ' \n';
      this.project.deliveryBatchesAPI.forEach((batch,i)=>{
        str += space+space+space+space+space+space + this.lang.getText('delivery_date_batch') + ' ' + (i+1) + space+space+space+space+space+space+space+space+space+space ;
        str += this.helper_ser.formatDateTime(batch.requested_at) + space+space+space+space+space+space+space+space+space+space + '\n';
      });
    }
    if(this.helper_ser.nonEmptyArray(this.project.deliveryBatches)){
      str += ' \n \n' + '  ' +manualDeliveryHeading+'\n';
      this.project.deliveryBatches.forEach((batch,i)=>{
        let description = this.getBatchDescriptionNew(batch);
        str += space+space+space+space+space+space + this.lang.getText('delivery_date_batch') + '  ' + (i+1) +space+space+space+space+space+space+space+space+space+space;
        str += this.helper_ser.formatDateTime(batch.requested_at) + space+space+space+space+space+space+space+space+space+space +description +'\n';
      });
    }
    return str;
  }

  getBatchDescriptionNew(batch){
    let description = batch.description;
    if(batch.batch_link){
      let serviceType = batch.batch_link.service_type;
      let serviceTypeId = batch.batch_link.trans_service_id;
      var description_string="";
      if ( serviceType == 'translation' ) {
        description_string = this.getTranslation(serviceTypeId).comment;
      } else if ( serviceType == 'service') {
        description_string = this.getService(serviceTypeId).comment;
      }
      description = description_string;
    }
    return description;
  }

  openCalendarsList(){
    this.calendarInfo.openList ? this.calendarInfo.openList=false : this.calendarInfo.openList=true;
  }

  closeModal() {
    this.modal_ref.hide();
  }

  getVendors() {
    let vendors = [];
    this.project.vendors.forEach(vendor => {
      vendor['trans'] = [];
      vendor['details'] = this.getVendorName(vendor.vendor_id);
      
      this.get_vendor_translations(vendor, this.project['id'], vendor.vendor_id);

      vendor['services'] = [];
      this.project.vendorAdditionalServices.forEach(addSer => {
        if (addSer.vendor_id != vendor.vendor_id)
          return;

        let batches = [];
        this.project.vendorAddServiceBatches.forEach(batch => {
          if ( batch.project_tran_or_service_id == addSer.id && batch.service == 1 ) {
            batches.push(batch);
          }
        });
        addSer['batches'] = batches;
        vendor['services'].push(addSer);
      });
      vendors.push(vendor)
    });
    return vendors;
  }

  get_vendor_translations(vendor, project_id, vendor_id) {
    vendor['translations'] = null;
    this.project_ser.get_vendor_translations(project_id, vendor_id, 1, 20).subscribe(
      data => {
        vendor['translations'] = data['translations'];
      }
    );
  }
  get_project_translations(projectId) {
    this.project_ser.get_translations(projectId, 1, 10).subscribe(
      data => {
        this.project.translations = data['translations'];
      },
      error => {
        undefined;
      }
    );
  }

  getVendorName(vendorId) {
    let vendorDetails;
    this.vendors.forEach(vendor => {
      if ( vendor.id == vendorId )
        vendorDetails = vendor;
    });
    return vendorDetails;
  }

  getCurrencyCode(currencyId){
    let code = '';
    this.currencies.forEach(currency => {
      if (currency.id == currencyId){
        code = currency.name;
      }
    });
    return code;
  }

  getVendorsCurrencyCode(proj){
    let currId = 0;
    currId = proj.currency_id;
    proj.vendors.forEach(ven => {
      if (ven.currency_id != 0)
        currId = ven.currency_id;
    });
    return this.getCurrencyCode(currId);
  }

  decimalRound = 2
  getVendorsAmt(proj) {
    let amt = 0;
    proj.vendors.forEach(ven => {
      amt += ven.amount;
    });
    return this.helper_ser.roundOfToFraction(amt, this.decimalRound);
  }

  getMarginAmt(proj) {
    return this.helper_ser.roundOfToFraction(this.getInvoiceAmt(proj) - this.getVendorsAmt(proj), this.decimalRound);
  }

  getInvoiceAmt(proj) {
    return proj.client_amount == null ? 0 : proj.client_amount;
  }

  getMarginPercentage(proj) {
    let per = 0;
    if (this.getCurrencyCode(proj.currency_id) == this.getVendorsCurrencyCode(proj))
      per = this.getPercentageOf(this.getInvoiceAmt(proj),this.getVendorsAmt(proj));
    return per;
  }

  getPercentageOf(min, max) {
    let per = (min == 0) ? (max == 0) ? 100 : 0 : ( (min - max) / min * 100 );
    return this.helper_ser.roundOfToFraction(per, 2);
  }
}

<ng-template #delivery_schedule_temp>
  <div class="new-redesign-style">
  <div class="modal-header">
    <h4 class="modal-title pull-left">
        {{lang.getText('delivery_schedule_information')}}
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div *ngIf="project == null">
    <app-loader></app-loader>
  </div>
  <div *ngIf="project" class="modal-body planning-modal">
    <h5>{{lang.getText('language_combinations')}}</h5>
    <div class="row row-secondary mt-2 mb-2 p-2" *ngIf="project.translations.length">
      <div class="col-3 text-start">{{lang.getText('source')}}</div>
      <div class="col-3 text-start">  {{lang.getText('target')}}</div>
      <div class="col-3 text-start">  {{lang.getText('rate')}}</div>
      <div class="col-3 text-start"> {{lang.getText('total')}}</div>
    </div>
    
    <div *ngFor="let tran of project.translations" class="row content row-primary mb-2 p-1">
      <div class="col-md-3 col-sm-12 text-start">
        <span *ngFor="let lang of languages; let lai = index">
          <span *ngIf="tran.source_lang_id == lang.id" >{{lang.name}}</span>
        </span>
      </div>
      <div class="col-md-3 col-sm-12 text-start">
        <span *ngFor="let lang of languages; let lai = index">
          <span *ngIf="tran.target_lang_id == lang.id" >{{lang.name}}</span>
        </span>
      </div>
      <div class="col-md-3 col-sm-12 text-start">
        <span>{{tran.word_rate}}</span>
      </div>
      <div class="col-md-3 col-sm-12 text-start">
        <span>{{tran.amount}}</span>
      </div>
    </div>
    <!-- <div *ngFor="let tran of project.translations" class="col-md-12">
      <div class="row">
        <div class="col-md-3">
          <span *ngFor="let lang of languages; let lai = index">
            <span *ngIf="tran.source_lang_id == lang.id" >{{lang.name}}</span>
          </span>
        </div>
        <div class="col-md-3">
          <span *ngFor="let lang of languages; let lai = index">
            <span *ngIf="tran.target_lang_id == lang.id" >{{lang.name}}</span>
          </span>
        </div>
        <div class="col-md-3">
          <span>{{tran.word_rate}}</span>
        </div>
        <div class="col-md-3">
          <span>{{tran.amount}}</span>
        </div>
      </div>
    </div> -->
    <div *ngIf="helper_ser.nonEmptyArray(project.additionalServicesAPI)">
      <hr class="mt-4 mb-4" style="color:#d5d5d5;"/>
      <h5>{{lang.getText('sap_services')}}</h5>
    </div>
    <div *ngIf="helper_ser.nonEmptyArray(project.additionalServicesAPI)" class="col-md-12">
      <div class="row row-secondary mt-2 mb-2 p-2">
        <div class="col-md-5 col-title text-start">
          {{lang.getText('description')}}
        </div>
        <div class="col-md-2 col-title text-start">
          {{lang.getText('rate')}}
        </div>
        <div class="col-md-2 col-title text-start">
          {{lang.getText('volume')}}
        </div>
        <div class="col-md-3 col-title text-start">
          {{lang.getText('total')}}
        </div>
      </div>
    </div>
    <div *ngFor="let service of project.additionalServicesAPI" class="col-md-12">
      <div class="row content row-primary mb-2 p-1">
        <div class="col-md-5 text-start">
          {{service.comment}}
        </div>
        <div class="col-md-2 text-start">
          {{service.rate}}
        </div>
        <div class="col-md-2 text-start">
          {{service.volume}}
        </div>
        <div class="col-md-3 text-start">
          {{service.amount}}
        </div>
      </div>
    </div>
    <div *ngIf="helper_ser.nonEmptyArray(project.additionalServices)">
      <hr class="mt-4 mb-4" style="color:#d5d5d5;"/>
      <h5>{{lang.getText('services')}}</h5>
    </div>
    <div *ngIf="helper_ser.nonEmptyArray(project.additionalServices)" class="col-md-12">
      <div class="row row-secondary mt-2 mb-2 p-2">
        <div class="col-md-5 col-title text-start">
          {{lang.getText('description')}}
        </div>
        <div class="col-md-2 col-title text-start">
          {{lang.getText('rate')}}
        </div>
        <div class="col-md-2 col-title text-start">
          {{lang.getText('volume')}}
        </div>
        <div class="col-md-3 col-title text-start">
          {{lang.getText('total')}}
        </div>
      </div>
    </div>
    <div *ngFor="let service of project.additionalServices" class="col-md-12">
      <div class="row row-primary mb-2 p-1">
        <div class="col-md-5 text-start">
          {{service.comment}}
        </div>
        <div class="col-md-2 text-start">
          {{service.rate}}
        </div>
        <div class="col-md-2 text-start">
          {{service.volume}}
        </div>
        <div class="col-md-3 text-start">
          {{service.amount}}
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <hr class="mt-4 mb-4" style="color:#d5d5d5;"/>
      <div *ngIf="project.project_delivery" class="row">
        <div class="col-md-12 mb-2">
          <strong>{{lang.getText('project_delivery_date')}}</strong>
        </div>
        <div class="col-md-4 badge-empty mb-2">
          <span [innerHTML]=this.helper_ser.formatDateTime(project.project_delivery)></span>
        </div>
        <div class="col-md-4">
          <div class="select_wrap">
            <button class="btn btn-primary px-4" (click)="openCalendarsList()" style="border-radius: 42px;">{{lang.getText('add_date_to_calendar')}}</button>
            <ul [hidden]="!calendarInfo.openList" class="calendars_list ps-0" style="list-style-type: none;">
              <li (click)="openCalendarsConfirmPopup(0, calendarConfirm)">
                <button class="btn  px-2 mt-2">{{lang.getText('add_to_local_outlook_calendar')}}</button>
              </li>
              <li (click)="openCalendarsConfirmPopup(2, calendarConfirmGoogle)">
                <button class="btn  px-2 mt-2">{{lang.getText('add_to_online_google_calendar')}}</button>
              </li>
            </ul>        
          </div>
        </div>
      </div>
      <div *ngIf="helper_ser.nonEmptyArray(project.deliveryBatchesAPI)" class="col-md-12">
        <br />
        <hr class="mt-4 mb-4" style="color:#d5d5d5;"/>
        <h6><strong>{{lang.getText('sap_delivery_dates')}}</strong></h6>
        <div *ngFor="let batch of project.deliveryBatchesAPI; let bi = index;" class="row">
          <div class="col-md-6 badge-empty mb-2">
            {{lang.getText('batch')}} {{bi+1}} &nbsp; -&nbsp;
            <span [innerHTML]=helper_ser.formatDateTime(batch.requested_at)></span>
          </div>
        </div>
      </div>
      <div *ngIf="helper_ser.nonEmptyArray(project.deliveryBatches)" class="col-md-12 no-spacing">
        <div *ngFor="let batch of project.deliveryBatches; let bi = index;" class="row row-primary mb-2 p-1">
          <div class="col-md-5 text-start" style="padding-left: 30px;">
            {{lang.getText('delivery_date_batch')}} {{bi+1}}:
          </div>
          <div class="col-md-4 text-start">
            <span [innerHTML]=helper_ser.formatDateTime(batch.requested_at)></span>
          </div>
          <div class="col-md-3 text-start">
            <span [innerHTML]=batch.description></span>
          </div>
        </div>
      </div>
    </div>
    <div *ngFor="let vendor of project.vendors">
      <div>
        <h3 class="vendor-title">{{vendor['details'].name}}</h3>
        <div *ngIf="vendor.translations == null">
          <app-loader></app-loader>
        </div>
        <div *ngIf="helper_ser.nonEmptyArray(vendor.translations)">
          <h5>{{lang.getText('language_combinations')}}</h5>
          <div class="col-md-12">
            <div class="row row-secondary mt-2 mb-2 p-2">
              <div class="col-md-3 col-title text-start">
                {{lang.getText('source')}}
              </div>
              <div class="col-md-3 col-title text-start">
                {{lang.getText('target')}}
              </div>
              <div class="col-md-3 col-title text-start">
                {{lang.getText('rate')}}
              </div>
              <div class="col-md-3 col-title text-start">
                {{lang.getText('total')}}
              </div>
            </div>
          </div>
          <div *ngFor="let tran of vendor.translations" class="col-md-12">
            <div class="row content row-primary mb-2 p-1">
              <div class="col-md-3 text-start">
                <span *ngFor="let lang of languages; let lai = index">
                  <span *ngIf="tran.source_lang_id == lang.id" >{{lang.name}}</span>
                </span>
              </div>
              <div class="col-md-3 text-start">
                <span *ngFor="let lang of languages; let lai = index">
                  <span *ngIf="tran.target_lang_id == lang.id" >{{lang.name}}</span>
                </span>
              </div>
              <div class="col-md-3 text-start">
                <span>{{tran.word_rate}}</span>
              </div>
              <div class="col-md-3 text-start">
                {{tran.amount}}
              </div>
            </div>
            <div class="col-md-10 offset-md-2">
              <div *ngFor="let batch of tran.vedor_batches; let bi = index;" class="row row-primary mb-2 p-1">
                <div class="col-md-5 text-start">
                  {{lang.getText('delivery_batch')}} {{bi+1}}
                </div>
                <div class="col-md-7 text-start">
                  <span [innerHTML]=helper_ser.formatDateTime(batch.delivery_at)></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr *ngIf="helper_ser.nonEmptyArray(vendor.services)" class="mt-2 mb-2" style="color:#d5d5d5;"/>
      <h5 *ngIf="helper_ser.nonEmptyArray(vendor.services)">{{lang.getText('services')}}</h5>
      <div *ngIf="helper_ser.nonEmptyArray(vendor.services)" class="col-md-12">
        <div class="row row-secondary mt-2 mb-2 p-2">
          <div class="col-md-5 col-title text-start">
            {{lang.getText('description')}}
          </div>
          <div class="col-md-2 col-title text-start">
            {{lang.getText('rate')}}
          </div>
          <div class="col-md-2 col-title text-start">
            {{lang.getText('volume')}}
          </div>
          <div class="col-md-3 col-title text-start">
            {{lang.getText('total')}}
          </div>
        </div>
      </div>
      <div *ngFor="let addSer of vendor.services; let i = index" class="col-md-12">
        <div class="row content row-primary mb-2 p-1">
          <div class="col-md-5 text-start">
              {{addSer.comment}}
          </div>
          <div class="col-md-2 text-start">
              {{addSer.rate}}
          </div>
          <div class="col-md-2 text-start">
              {{addSer.volume}}
          </div>
          <div class="col-md-3 text-start">
            {{addSer.amount}}
          </div>
        </div>
        <div *ngIf="addSer.batches" class="col-md-10 offset-md-2 deliver_batch">
          <div *ngFor="let batch of addSer.batches; let bi = index;" class="row content row-primary mb-2 p-1">
            <div class="col-md-3 text-start">
              {{lang.getText('delivery_batch')}} {{bi+1}}
            </div>
            <div class="col-md-4 text-start">
                {{batch.description}}
            </div>
            <div class="col-md-4 text-start">
                <span [innerHTML]=this.helper_ser.formatDateTime(batch.delivery_at)></span>
              </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12"><a  class="title"><strong></strong></a></div>
      </div>
    </div>
  </div>
</div>
</ng-template>

<ng-template #finance_temp>
  <div class="new-redesign-style">
    <div class="modal-header">
      <h4 class="modal-title pull-left">
        {{lang.getText('project_finance_information')}} 
      </h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <h5> {{ lang.getText('project_details') }} </h5>
      <div class="col-12 row"> <a  class="title"><strong>{{lang.getText('project_name')}}</strong> {{project.name}}</a> </div>
      <div class="col-12 row border-bottom"> <a  class="title"><strong>{{lang.getText('order_number')}}</strong> {{project.order_number}}</a> </div>
      <h5> {{ lang.getText('finance') }} {{ lang.getText('details') }} </h5>
      <div class="col-12 row mt-4 mb-3"> 
        <div class="col-auto"> <a  class="badge-empty"> <strong> {{lang.getText('total_invoice_amount2')}}</strong>  {{getCurrencyCode(project.currency_id)}} {{project.client_amount}} </a></div>
        <div class="col-auto"> <a  class="badge-empty"> <strong> {{lang.getText('total_po_amount2')}}</strong> {{getVendorsCurrencyCode(project)}} {{getVendorsAmt(project)}} </a></div>
        <div class="col-auto"> <a  class="badge-empty"> <strong> {{lang.getText('total_margin')}}</strong>
          <span *ngIf="getCurrencyCode(project.currency_id) == getVendorsCurrencyCode(project)">
            {{getCurrencyCode(project.currency_id)}}
          </span>
          {{getMarginAmt(project)}}
        </a></div>
        <div class="col-auto"> <a  class="badge-empty"> <strong>{{lang.getText('total_margin_percentage')}}</strong>
          {{getMarginPercentage(project)}}  %</a>
        </div>
      </div>
      <!-- <div *ngIf="project" class="col-sm-8 offset-sm-2">
        <div class="col-sm-12"><a  class="title"><strong>{{lang.getText('project_name')}}</strong> {{project.name}}</a></div>
        <div class="col-sm-12"><a  class="title"><strong>{{lang.getText('order_number')}}</strong> {{project.order_number}}</a></div>
        <div class="finance-report col-sm-12">
          <div><strong>{{lang.getText('total_invoice_amount2')}}</strong>  {{getCurrencyCode(project.currency_id)}} {{project.client_amount}} </div>
          <div><strong>{{lang.getText('total_po_amount2')}}</strong> {{getVendorsCurrencyCode(project)}} {{getVendorsAmt(project)}} </div>
          <div><strong>{{lang.getText('total_margin')}}</strong>
            <span *ngIf="getCurrencyCode(project.currency_id) == getVendorsCurrencyCode(project)">
              {{getCurrencyCode(project.currency_id)}}
            </span>
            {{getMarginAmt(project)}}
          </div>
          <div><strong>{{lang.getText('total_margin_percentage')}}</strong>
            {{getMarginPercentage(project)}}  %
          </div>
        </div>
      </div> -->
    </div>
  </div>
</ng-template>

<ng-template #calendarConfirm>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      {{lang.getText('add_date_to_calendar')}}
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modal2Ref.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{lang.getText('calendar_confirmation')}}</p>
    <div class="float-right">
      <button class="btn btn-primary" (click)="modal2Ref.hide()">{{lang.getText('cancel')}}</button>
      <button class="btn btn-primary" (click)="addEventToCalendar()" style="margin-left:10px;">{{lang.getText('continue')}}</button>
    </div>
  </div>
</ng-template>

<ng-template #calendarConfirmGoogle>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      {{lang.getText('add_date_to_calendar')}}
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modal2Ref.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{lang.getText('calendar_confirmation_google')}}</p>
    <div class="float-right">
      <button class="btn btn-primary" (click)="modal2Ref.hide()">{{lang.getText('cancel')}}</button>
      <button class="btn btn-primary" (click)="addEventToCalendar()" style="margin-left:10px;">{{lang.getText('continue')}}</button>
    </div>
  </div>
</ng-template>

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppHeaderComponent } from './app-header/app-header.component';
import { ProjectsListPageComponent } from './projects-list-page/projects-list-page.component';
import { ProjectEditPageComponent } from './project-edit-page/project-edit-page.component';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CurrencyDropdownComponent } from './currency-dropdown/currency-dropdown.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { environment } from './../environments/environment';

import { LanguageModalComponent } from './language-modal/language-modal.component';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './dashboard/dashboard.component';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { CurrencyPipe } from '@angular/common';
import { JwtauthService } from './services/jwtauth.service';
import { TokenService } from './services/token.service';
import { DataService } from './services/data.service';
import { HelperService } from './services/helper.service';

import { UsersListPageComponent } from './users-list-page/users-list-page.component';
import { UserEditPageComponent } from './user-edit-page/user-edit-page.component';
import { UserCreatePageComponent } from './user-create-page/user-create-page.component';

import { ProjectEditModule } from './project-edit-page/project-edit-page.module';
import { CompanyResourceManagementComponent } from './company-resource-management/company-resource-management.component';
import { VendorActionsPageComponent } from './vendor-actions-page/vendor-actions-page.component';

import { AppRoutingModule } from './app-routing.module';
import { ProfileComponent as venProfileCom } from './vendor-pages/profile/profile.component';
import { CalendarAvailComponent } from './calendar-avail/calendar-avail.component';
import {LanguageService} from './services/language.service';
import { NgxSummernoteModule } from 'ngx-summernote';
import { SuggestImprovementComponent } from './components/suggest-improvement/suggest-improvement.component';
import { HelpModalComponent } from './components/help-modal/help-modal.component';
import { FilterFavoritesComponent } from './projects-list-page/components/filter-favorites/filter-favorites.component';
import { EditProjectDescriptionComponent } from './components/edit-project-description/edit-project-description.component';
import { ConfigureService } from './modules/configure/services/configure.service';
import { AccountComponent } from './modules/main/pages/account/account.component';
import { AccountService } from './modules/main/services/account.service';
import { ProjectLabelComponent } from './components/project-label/project-label.component';
import { ReleaseNotesModalComponent } from './components/release-notes-modal/release-notes-modal.component';
import { TwoDigitDecimaNumberDirective } from './twodigitdecimalnumber.directive';
import { VendorListComponent } from './modules/vendor/pages/vendor-list/vendor-list.component';
import { LoaderService } from './services/loader.service';
import { LoaderInterceptor } from './interceptors/loader-interceptor.service';
import { DashboardDisplaySettingsComponent } from './app-header/components/dashboard-display-settings/dashboard-display-settings.component';
import { AuthModule } from "./modules/auth/auth.module";
import { SharedModule } from './modules/shared/shared.module';
import { VendorPortalModule } from './modules/vendor-portal/vendor-portal.module';
import { ProjectsModule } from './modules/projects/projects.module';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { SupportComponent } from './components/support/support.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

const NOTIFIER_CONFIG: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12
    },
    vertical: {
      position: 'top',
      distance: 12,
      gap: 10
    }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    CalendarAvailComponent,
    AppHeaderComponent,
    ProjectsListPageComponent,
    ProjectEditPageComponent,
    CurrencyDropdownComponent,
    LanguageModalComponent,
    DashboardComponent,
    UsersListPageComponent,
    UserEditPageComponent,
    UserCreatePageComponent,
    CompanyResourceManagementComponent,
    VendorActionsPageComponent,
    venProfileCom,
    SuggestImprovementComponent,
    HelpModalComponent,
    FilterFavoritesComponent,
    EditProjectDescriptionComponent,
    AccountComponent,
    ProjectLabelComponent,
    ReleaseNotesModalComponent,
    TwoDigitDecimaNumberDirective,
    VendorListComponent,
    DashboardDisplaySettingsComponent,
    SupportComponent
  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    HttpClientModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    SharedModule,
    ProjectsModule,
    ProjectEditModule,
    AppRoutingModule,
    NgxSummernoteModule,
    AuthModule,
    NotifierModule.withConfig(NOTIFIER_CONFIG),
    VendorPortalModule
  ],

  providers: [
    JwtauthService,
    TokenService,
    DataService,
    HelperService,
    LanguageService,
    ConfigureService,
    AccountService,
    DatePipe,
    CurrencyPipe,
    LoaderService,
    { provide: 'API_URL', useValue: environment.API_URL},
    { provide: 'PUBLIC_URL', useValue: environment.PUBLIC_URL},
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }

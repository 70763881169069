import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  @Output() go_to_page = new EventEmitter<Number>();
  @Input() theme: string;
  current_page;
  total_pages = null;
  next_page = null;
  prev_page = null;
  freeze: boolean = true;
  pages = [];

  generate(total_pages, currrent_page) {
    this.freeze = false;
    this.total_pages = total_pages;
    this.current_page = currrent_page;

    this.prev_page = this.next_page = null
    this.pages = [];

    // To add 2 page back page in last page
    if ( this.current_page == total_pages && this.current_page > 2 ) {
      this.add_page(this.current_page - 2, false);
    }

    if ( currrent_page > 1 ) {
      this.prev_page = currrent_page - 1;
      this.add_page(this.prev_page, false);
    }

    if ( this.current_page ) {
      this.add_page(this.current_page, true);
    }

    if ( currrent_page < total_pages ) {
      this.next_page = currrent_page + 1;
      this.add_page(this.next_page, false);
    }

    // To add 3rd page in 1st page
    if ( this.current_page == 1 && total_pages >= 3 ) {
      this.add_page(3, false);
    }

    this.freeze = false;
  }

  add_page(label, active) {
    this.pages.push(this.create_page(label, active));
  }

  create_page(label, active) {
    return {
      label: label,
      active: active
    }
  }

  get_page(page_no) {
    if ( this.freeze ) {
      return false;
    }
    this.freeze = true;
    this.go_to_page.emit(page_no);
  }
}

import { HostListener, Component, OnInit, TemplateRef,ViewChild,ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import {UntypedFormBuilder, FormGroup, FormArray, FormControl} from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { TokenService } from '../services/token.service';
import { JwtauthService } from '../services/jwtauth.service';
import { timeout } from 'rxjs/operators';
import { HelperService } from '../services/helper.service';
import { SapConfig } from '../configs/sap.config';
import { ProjectService } from '../services/project.service';
import { LanguageService } from '../services/language.service';
import { FilterFavoritesComponent } from './components/filter-favorites/filter-favorites.component';
import { EditProjectDescriptionComponent } from 'src/app/components/edit-project-description/edit-project-description.component';
import { ProjectLabelService } from '../services/project-label.service';
import { ProjectRelationListComponent } from '../project-edit-page/components/project-relation-list/project-relation-list.component';
import { DisplaySettingsService } from '../services/display-settings.service';
import { DetailsPopupComponent } from '../modules/projects/components/details-popup/details-popup.component';
import { TranslatePipe } from '../modules/shared/pipes/translate.pipe';

@Component({
  selector: 'app-page',
  templateUrl: './projects-list-page.component.html',
  styleUrls: ['./projects-list-page.component.scss']
})
export class ProjectsListPageComponent implements OnInit {
  projects = [];
  statuses;
  customizeStatuses;
  customizeStatusEnabled = false;
  deliveryDates;
  apiURL;
  pageNum;
  pageNumPara;
  projectsPP;
  postData;
  projectCounts=0;
  unclaimedProjectsCount=0;
  projectFilterForm;
  clients;
  owners;
  modalRef: BsModalRef;
  modal2Ref: BsModalRef;
  projectsForm;
  projectsFa = this.fb.array([]);
  selectedProjects;
  selectedModal = null;
  claimSelected = 'claimModal';
  transferSelected = 'transferSModal';
  archiveSelected = 'archiveModal';
  statusSelected = 'change the status';
  actionSelected = '';
  editCommentDescriptionSelected = 'editCommentDescription';
  transferToOwner;
  userId;
  loading = false;
  noMoreResult = false;
  applyRedBGMClass;
  comments;
  sapEnabled: Boolean;
  sapConf:SapConfig = new SapConfig();
  sapFields:any = this.sapConf.fields
  disSetSortByOptions = [];
  disSetDeliveryDateOptions = [];
  lang;
  interval;
  notChangedStatus:any = [];
  statusPopupOpened = false;
  is_project_filter_saved: boolean = true
  @ViewChild('notChangedStatusPopup') notChangedStatusPopup: TemplateRef<any>;
  actionDDConfig;
  filterStatusOptions;
  actionOptions;
  searchTypeOptions;
  

  userDashboardSettings;
  
  constructor(
    private injector: Injector,
    private http: HttpClient,
    private projectSer: ProjectService,
    private Token: TokenService,
    private Jwtwis: JwtauthService,
    public helperService: HelperService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private modalService: BsModalService,
    public language: LanguageService,
    private changeDetect: ChangeDetectorRef,
    private project_ser: ProjectService,
    public labelSer: ProjectLabelService,
    public displaySettSer: DisplaySettingsService,
    public translate_pipe: TranslatePipe,
    ) {
    this.apiURL = this.injector.get('API_URL');
    this.sapEnabled = Jwtwis.sapEnabled();
    this.lang = language;
    this.projectFilterForm = this.fb.group({
      unclaimed: false,
      archived: '',
      fromDate: '',
      toDate: '',
      text: '',
      owner: '',
      client: '',
      status: '',
      search_type: 'partial_match',
      order_direction: 'desc',
      deliveryDate: '',
      disSetSortBy: 'orderNo',
      order_creation_date: '',
      disSetDeliveryDate: '',
      batch_delivery_date: false,
      changeStatus: '',
      browserTime: - ( new Date().toLocaleString() ),
      isMasterSel: false
    })
    this.projectFilterForm.valueChanges.subscribe(val => {
      this.is_project_filter_saved = false;
    });
    this.projectFilterForm.get('disSetSortBy').valueChanges.subscribe(val => {
      setTimeout( () => this.loadProjectsFreshly(false) );

    })
    this.projectFilterForm.get('disSetDeliveryDate').valueChanges.subscribe(val => {
      setTimeout( () => this.loadProjectsFreshly(false) );
    })
    this.projectFilterForm.get('batch_delivery_date').valueChanges.subscribe(val => {
      setTimeout( () => this.loadProjectsFreshly(false) );
    })

    this.projectsForm = this.fb.group({
      projects: this.projectsFa,
    })

    this.searchTypeOptions = [
      { id: 'partial_match', name: this.lang.getText('search_for_partial_match') },
      { id: 'exact_match', name: this.lang.getText('search_for_exact_match') }
    ];
  }
  @ViewChild('filterForm') filterFormEle: ElementRef;
  filterFormHeight: number;
  reportingPage = false;

  ngOnInit() {
    this.userId = this.Jwtwis.getMyDetails().id;

    this.interval = setInterval(() => this.updateFuncText(this.lang),500);
    document.body.classList.add('add-bg-grey');
    /*
    this.route.params.subscribe(params => {
      this.pageNumPara = params['pageNum'];
      if ( typeof(this.pageNumPara) == 'undefined' ){
        this.router.navigateByUrl('/project/page/1');
        return;
      }
      this.loadAllDatas();
    });
    */

    this.loadAllDatas();
    this.getStatuses();
    this.getCustomizeStatuses();
    this.getClients();
    this.getOwners();
    this.getInitialOrder();
    this.getLanguages();
    this.getVendorsList();
    this.getCurrencies();
    this.getLabels();
    this.getDisplayLabels();

    this.actionOptions = [
      { id: '', name: this.translate_pipe.transform('action')},
      { id: 'claimModal', name: this.translate_pipe.transform('claim')},
      { id: 'transferSModal', name: this.translate_pipe.transform('transfer')   },
      { id: 'archiveModal', name: this.translate_pipe.transform('archive') },
      { id: 'reactivateModal', name: this.translate_pipe.transform('reactivate') },
      { id: 'addUpModal', name: this.translate_pipe.transform('add_up') },
    ];
  }

  getActionFormHeight() {
    return this.is_action_hidden() || ! this.filterFormEle ? 0 : (this.filterFormEle.nativeElement.offsetHeight+20);
  }

  loadAllDatas() {
    if ( typeof(this.pageNumPara) == 'undefined' )
      this.pageNum = 1;
    else
      this.pageNum = parseInt(this.pageNumPara);

    this.loadProjectFilterForm();
    if (!this.reportingPage)
      this.loadProjectsFreshly(false);
  }

  claimThisProject(projectId) {
    this.postData = this.Token.getAPITokenData();
    this.postData['projectId'] = projectId;
    this.http.post(`${this.apiURL}claim-project`, this.postData).subscribe(
      data => {
        this.getProject(projectId)
        this.unclaimedProjectsCount--;
      },
      error => this.handleError(error)
    );
  }

  archiveProject(projectId) {
    this.http.post(`${this.apiURL}project/archive/${projectId}`, this.Token.getAPITokenData()).subscribe(
      data => this.removeProjectFromList(projectId),
      error => this.handleError(error)
    );
  }

  removeProjectFromList(id){
    this.projects = this.projects.filter((vals, key) => {
      if ( vals.id === id ){
        this.projectsFa.removeAt(key);
        return false;
      }
      return true;
    })
    this.selectedProjects = this.selectedProjects.filter((vals) => {
      return vals.id !== id;
    })
  }

  transferProjectTo(projectId, userId) {
    let postData = this.Token.getAPITokenData();
    postData['user_id'] = userId;
    this.http.post(`${this.apiURL}project/transfer-to/${projectId}`, postData).subscribe(
      data => this.getProject(projectId),
      error => this.handleError(error)
    );
  }

  openModal(template: TemplateRef<any>, selected) {
    this.selectedModal = selected;
    //this.selectedAction=this.selectedModal;
    // let actionMessage=this.selected.repalce("Modal"," ");
    // let messagestring=this.selectedModal.replace('Modal','');
    this.loadSelectedProjects()
    this.modalRef = this.modalService.show(template);
  }

  @ViewChild('details_popup_com') details_popup_com: DetailsPopupComponent;
  open_project_detail_popup(projectId, pop_up) {
    this.details_popup_com.open(projectId, pop_up, this.languages, this.vendors, this.currencies);
  }

  closeModal() {
    this.unselectCheckBox();
    this.statusPopupOpened = false;
    this.modalRef.hide();
  }

  unselectCheckBox() {
    this.projectFilterForm.patchValue({'isMasterSel': false});
    this.projectsForm.reset();
  }

  claimAll() {
    this.selectedProjects.map(proj => this.claimThisProject(proj.id));
    this.closeModal();
  }

  transferTo(){
    if ( ! this.varfifyHavePermission() ) { return; }
    this.selectedProjects.map(proj => this.transferProjectTo(proj.id, this.transferToOwner));
    this.closeModal();
  }

  archiveAll(){
    this.selectedProjects.map(proj => this.archiveProject(proj.id));
    this.closeModal();
  }

  getStatuses() {
    this.http.post(`${this.apiURL}project/statuses`, this.Token.getAPITokenData()).subscribe(
      data => {
        this.statuses = data['result'];
      },
      error => this.handleError(error)
    );
  }

  getCustomizeStatuses() {
    this.http.post(`${this.apiURL}customize-statuses/get`, this.Token.getAPITokenData()).subscribe(
      data => {
        this.customizeStatuses = data['result'];
        this.customizeStatusEnabled = data['customize_status_enabled'];
        // this.populateFilterStatues();
      },
      error => {
        this.handleError(error)
      }
    );
  }

  populateFilterStatues() {
    this.filterStatusOptions = [
      { id: 'unclaimed', name: this.lang.getText('unclaimed')}
    ];
    if(this.customizeStatusEnabled) {
      this.customizeStatuses.forEach((status, key) => {
        this.filterStatusOptions.push({ id:status.id, name: status.name });
      });
      this.statuses.forEach((status, key) => {
        this.filterStatusOptions.push({ id:'l_'+status.id, name: this.lang.getProjectStatus(status.code), country: this.lang.getText('legacy_statuses'), child: { state: 'Active' } });
      });
    } else {
      this.statuses.forEach((status, key) => {
        this.filterStatusOptions.push({ id:'l_'+status.id, name: status.name });
      });
    }
  }

  getClients() {
    this.http.post(`${this.apiURL}clients`, this.Token.getAPITokenData()).subscribe(
      data => {
        this.clients = data['result'];
      },
      error => this.handleError(error)
    );
  }

  getOwners() {
    this.http.post(`${this.apiURL}owners`, this.Token.getAPITokenData()).subscribe(
      data => {
        this.owners = data['result'];
      },
      error => this.handleError(error)
    );
  }

  currencies = [];
  getCurrencies() {
    this.http.post(`${this.apiURL}currencies`, this.Token.getAPITokenData()).subscribe(
      data => {
        this.currencies = data['result'];
      },
      error => this.handleError(error)
    );
  }

  labels;
  getLabels() {
    this.labelSer.get();
    this.labelSer.currentLabels.subscribe(labels => {
      this.labels = labels;
    })
  }

  displayLabels = [];
  getDisplayLabels() {
    this.displaySettSer.getLabels();
    this.displaySettSer.labelsObser.subscribe(labels => {
      this.displayLabels = labels;
    });
  }

  hideDisplayLabel(label) {
    return ! this.displayLabels.includes(label);
  }

  getClientName(clientId) {
    let clientName = ''
    if (typeof this.clients == 'undefined')
      return clientName;
    this.clients.forEach(client => {
      if ( client.id == clientId )
        clientName = client.name
    })
    return clientName;
  }

  initialOrderId=0;
  getInitialOrder() {
    this.http.post(`${this.apiURL}initial-order-id`, this.Token.getAPITokenData()).subscribe(
      data => {
        this.initialOrderId = parseInt(data['result']['value']);
      },
      error => this.handleError(error)
    );
  }

  lSFilterDataKey = 'projectListFilterData';
  resetForm() {
    console.log(this.projectFilterForm);
    this.projectFilterForm.reset({
      'order_direction' : 'desc',
      'owner' : '',
      'client' : '',
      'status' : '',
      'disSetDeliveryDate' : ''
    }, { emitEvent: false });
    this.saveProjectFilterForm();
    this.loadProjectsFreshly(false);
    // location.reload()
  }

  loadProjectsFreshly(validate: Boolean){
    if ( validate && ! this.formDataValid() ) {
      return false;
    }
    this.projects = [];
    this.pageNum = 1;
    this.loading = false;
    this.totalProjects = 1;
    this.allResultsLoader = false;
    this.saveProjectFilterForm();
    this.getProjects();
  }
  unclaimedChange(event) {
    if(event == "unclaimed"){
      this.projectFilterForm.patchValue({'unclaimed': true});
    }else{
      this.projectFilterForm.patchValue({'unclaimed': false});
    }
  }

  formDataValid() {
    let filterData = this.projectFilterForm.value;
    if(filterData.order_creation_date){
      if( ! filterData.fromDate ){
        this.helperService.alertIt(this.lang.getText('field_from_cant_be_empty'));
        return false;
      }
      if( ! filterData.toDate ){
        this.helperService.alertIt(this.lang.getText('field_to_cant_be_empty'));
        return false;
      }
    } else {
      if ( filterData.fromDate || filterData.toDate ) {
        alert("Select a 'Date Criteria'");
        return false;
      }
    }
    return true;
  }

  saveProjectFilterForm() {
    this.applyRedBGMClass = '';
    let disSetDeliveryDate = this.projectFilterForm.getRawValue().disSetDeliveryDate;
    if ( disSetDeliveryDate != null && disSetDeliveryDate.includes('hours') ){
      this.applyRedBGMClass = 'applyRedBGM';
    }
    if(this.projectFilterForm.value['order_direction']==null)
      this.projectFilterForm.patchValue({'order_direction': 'desc'},{emitEvent: !this.reportingPage});
    if(this.projectFilterForm.value['search_type']==null)
      this.projectFilterForm.patchValue({'search_type': 'partial_match'},{emitEvent: !this.reportingPage});
    if(this.projectFilterForm.value['order_creation_date']==null)
      this.projectFilterForm.patchValue({'order_creation_date': ''},{emitEvent: !this.reportingPage});
    if(this.projectFilterForm.value['disSetSortBy']==null)
      this.projectFilterForm.patchValue({'disSetSortBy': 'orderNo'},{emitEvent: !this.reportingPage});
    localStorage.setItem(this.lSFilterDataKey, JSON.stringify(this.projectFilterForm.getRawValue()));
  }

  loadProjectFilterForm() {
    let formValues = JSON.parse(localStorage.getItem(this.lSFilterDataKey));
    if (!formValues) {
      return;
    }
    formValues.fromDate     = this.stringToDate(formValues.fromDate);
    formValues.toDate       = this.stringToDate(formValues.toDate)
    formValues.deliveryDate = this.stringToDate(formValues.deliveryDate);
    formValues.owner        = formValues.owner == null ? '' : formValues.owner;
    formValues.client       = formValues.client == null ? '' : formValues.client;
    formValues.status       = formValues.status == null ? '' : formValues.status;
    formValues.search_type       = formValues.search_type == null ? 'partial_match' : formValues.search_type;
    formValues.order_direction       = formValues.order_direction == null ? 'desc' : formValues.order_direction;
    formValues.order_creation_date     = formValues.order_creation_date == null ? '' : formValues.order_creation_date;
    formValues.disSetSortBy = formValues.disSetSortBy == null ? 'orderNo' : formValues.disSetSortBy;
    // formValues.disSetDeliveryDate = formValues.disSetDeliveryDate == null ? '' : formValues.status;
    formValues.changeStatus = formValues.changeStatus == undefined || formValues.changeStatus == null ? '' : formValues.changeStatus;
    formValues.disSetDeliveryDate = formValues.disSetDeliveryDate == null ? '' : formValues.disSetDeliveryDate;
    this.projectFilterForm.patchValue(formValues, {emitEvent: !this.reportingPage});
  }

  stringToDate(dateTime) {
    var d = new Date(dateTime);
    if (
      dateTime == null ||
      isNaN(d.getTime()) ||
      isNaN(d.valueOf())
    )
      return null;
    return  new Date(dateTime);
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    if ( this.loading ) { return; }
    let doc = document.documentElement;
    let top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
    let maxX = (document.body.offsetHeight - window.innerHeight);
    if ( (maxX - top) < 1500 && this.loadingNxtPage === false) {
      this.loadingNxtPage = true;
      this.pageNum++;
      this.getProjects();
    }
  }

  loadingNxtPage = false;
  getProject(ids) {
    let postData = {
      token: this.Token.get(),
      filters: {
        byIds: ids,
        archived: this.projectFilterForm.get('archived').value
      }
    }
    this.http.post(`${this.apiURL}projects`, postData).subscribe(
      data => {
        data['result']['projects'].forEach(vals => {
          this.projects = this.projects.map(projVals => {
            if (projVals.id === vals.id){
              vals.warn = projVals.warn
              vals.word_counts = projVals.word_counts;
              return vals;
            }
            else
              return projVals;
          })
        });

      },
      error => this.handleError(error)
    );
  }

  openReactivateModal(template: TemplateRef<any>){
    this.loadSelectedProjects()
    
    this.modalRef = this.modalService.show(template);
  }

  loadSelectedProjects() {
    this.selectedProjects = this.projectsFa.value
      .map((v, i) => v.selected ? this.projects[i] : null )
      .filter(v => v !== null);
  }

  openAddUpModal(template: TemplateRef<any>){
    const selectedIds = this.projectsFa.value
      .map((v, i) => v.selected ? this.projects[i] : null )
      .filter(v => v !== null)
      .map(({ id }) => id);
    this.projectWordcounts = null;
    this.getProjectWordCounts(selectedIds);
    this.modalRef = this.modalService.show(template);
  }

  projectWordcounts;
  getProjectWordCounts(ids) {
    let total_wordcount = 0;
    let weighed_wordcount = 0;
    this.projects.forEach(projVals => {
      if (ids.indexOf(projVals.id) !== -1){
        total_wordcount += parseInt(projVals.word_counts.total_word_count);
        weighed_wordcount += parseInt(projVals.word_counts.total_weighed_count);
      }
    })
    total_wordcount = this.helperService.roundOfToFraction(total_wordcount,0);
    weighed_wordcount = this.helperService.roundOfToFraction(weighed_wordcount,0);
    let totals = {
      total_wordcount,
      weighed_wordcount
    };
    this.projectWordcounts = totals;
    this.changeDetect.detectChanges();
  }

  allResultsLoader = false;
  proGerReq;
  getProjects() {
    if (this.projects.length >= this.totalProjects){ //all results loaded
      this.allResultsLoader = true;
      return;
    }
    if ( this.loading ) { return; }
    this.loading = true;
    if (this.proGerReq) this.proGerReq.unsubscribe();
    this.postData = this.Token.getAPITokenData();
    this.projectsPP = 20;
    this.postData['perPage'] = this.projectsPP;
    this.postData['pageNum'] = this.pageNum;
    this.postData['filters'] = this.projectFilterForm.value;
    this.postData['withReportingData'] = this.reportingPage;
    this.postData['filters'].deliveryDate = this.convertDate(this.postData['filters'].deliveryDate);
    this.postData['filters'].fromDate     = this.convertDate(this.postData['filters'].fromDate);
    this.postData['filters'].toDate       = this.convertDate(this.postData['filters'].toDate);
    if ( this.postData['filters'].unclaimed ) {
      this.postData['filters'].status = "";
    }
    localStorage.setItem('projects_list_filters', JSON.stringify(this.postData));
    this.save_the_filter()
    this.proGerReq = this.http.post(`${this.apiURL}projects`, this.postData)
    .pipe(
      timeout(60 * 1000)
    )
    .subscribe(
      data => this.handleResponse(data),
      error => {
        //this.loadingNxtPage = false;
        this.handleError(error);
        this.loading = false;
      }


    );

  }

  save_the_filter() {
    if ( this.is_project_filter_saved ) {
      return;
    }
    this.project_ser.save_favorites(JSON.stringify(this.projectFilterForm.value), true).subscribe(
      data => {
        this.is_project_filter_saved = true;
      }
    );
  }

  getVendorBatches(pIds){
    if ( pIds.length < 1 )
      return;
    let poData = {
      pIds: pIds
    };
    this.http.post(`${this.apiURL}vendor-batches`, poData)
      .subscribe(
        data => {
          //console.log(data)
          data['result'].forEach(vals => {
            this.projects = this.projects.map(projVals => {
              if (projVals.id === vals.project_id){
                let warn = false;
                vals.batches.forEach(batch => {
                  if ( warn == false &&
                      this.checkWarning(batch, projVals) ){
                    warn = true;
                  }
                });
                if (warn)
                  projVals.warn = warn;
              }
              return projVals;
            })
          });
        },
        error => {
          this.handleError(error);
        }
    );
  }

  getWordCounts(pIds){
    if ( pIds.length < 1 )
      return;
    let poData = {
      'pIds' : pIds
    };
    this.http.post(`${this.apiURL}word-counts`, poData)
      .subscribe(
        data => {
          //console.log(data)
          data['result'].forEach(vals => {
            this.projects = this.projects.map(projVals => {
              if (projVals.id === vals.project_id){
                let wordcounts = {
                  total_word_count    : this.helperService.roundOfToFraction((vals.word_counts.total_word_count || 0),0),
                  total_weighed_count : this.helperService.roundOfToFraction((vals.word_counts.total_weighed_count || 0),0)
                };
                projVals.word_counts = wordcounts;
              }
              return projVals;
            })
          });
        },
        error => {
          this.handleError(error);
        }
    );
  }

  convertDate(str) {
    if (str == '' || str == null)
      return str;
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  ignoreWarn;
  openIgnoreWarningModal(template: TemplateRef<any>, id, pi) {
    this.ignoreWarn = {id: id, pi: pi};
    this.modalRef = this.modalService.show(template);
  }

  ignoreWarning(id, pi) {
    let poData = {
      token: this.Token.get(),
      id: id,
    };
    console.log(poData);
    this.http.post(`${this.apiURL}project/ignore-warning`, poData)
      .subscribe(
        data => {
          console.log(data);
          this.projects[pi]['warn'] = false;
        },
        error => {
          this.handleError(error);
        }
    );
    this.closeModal();
  }

  checkWarning(deliAt, proj){
    let deliveryAt = this.helperService.fromMysqlDateTime(deliAt);
    var tmwDate = new Date();
    tmwDate.setHours(tmwDate.getHours() + 24);
    //console.log(tmwDate, deliveryAt, statusId)
    //!(proj['project_status_id'] == 0 && proj['quote_canceled_at'] == null) ;
    return (deliveryAt < tmwDate && proj['project_status_id'] < 7) &&
      (proj['quote_canceled_at'] == null) ;
  }

  totalProjects = 1;
  handleResponse(data) {
    let getVendorBatchesFor = [];
    let getWordCountsFor = [];
    this.loadingNxtPage = false;
    data['result']['projects'].forEach(vals => {
      getWordCountsFor.push(vals.id);
      // this.selectedProjectsFA.push(new FormControl(false));
      vals['selected'] = false;
      this.projectsFa.push(this.fb.group(vals));
      this.projects.push(vals);
     // console.log(vals['project_delivery']);
     vals['warn'] = false;
      vals['project_delivery_date'] = '';
      let ignoreWarning = false;
      if (vals['ignore_warning'] != null) {
        if (new Date(vals['ignore_warning']) > new Date()) {
          ignoreWarning = true;
        }
      }
      if (ignoreWarning == false && vals['recent_delivery_batch']) {
        if ( this.checkWarning(vals['recent_delivery_batch'], vals) ){
          vals['warn'] = true;
        }
      }
      if ( ignoreWarning == false &&
            vals['warn'] == false &&
            vals['project_delivery'] != null &&
            vals['project_delivery'] != '0000-00-00 00:00:00') {
        var date = new Date(vals['project_delivery']),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
        vals['project_delivery_date'] = [day , mnth, date.getFullYear()].join("-");
        if ( this.checkWarning(vals['project_delivery'], vals) ){
          vals['warn'] = true;
        } else if (vals['project_status_id'] < 7) {
          getVendorBatchesFor.push(vals.id);
        }
      }
    });
    //console.log('getWordCountsFor',getWordCountsFor);
    this.getVendorBatches(getVendorBatchesFor);
    this.getWordCounts(getWordCountsFor);
    
    /*Moved to backend
    if (this.reportingPage){
      this.currencies.map(curr => {
        console.log(this.getAllProjectAmt(curr.id))
        curr.finance = this.getAllProjectAmt(curr.id);
        return curr;
      });
    }
      //this.getAllProjectAmt();
      */
    this.projectCounts = data['result']['total'];
    this.unclaimedProjectsCount = data['result']['unclaimed_projects'];
    //console.log(this.projects);
    if (this.projects.length < 1){
      this.allResultsLoader = true;
      this.noMoreResult = true;
    }
    else this.noMoreResult = false;
    if (this.projects.length >= data['result']['total'])
      this.loading = true;
    else
      this.totalProjects =  data['result']['total'];
      this.loading = false;
      console.log(this.pageNum, this.projects.length, data['result']['total'], this.projects.length == data['result']['total'], this.loading)
      if (this.projects.length >= this.totalProjects){ //all results loaded
        this.allResultsLoader = true;
      }
    }

  handleError(error) {
    this.Jwtwis.handleError(error);
  }

  languages
  getLanguages() {
    let postData = this.Token.getAPITokenData();
    this.http.post(`${this.apiURL}languages`, postData).subscribe(
      data => {
        this.languages = data['result'];
      },
      error => this.handleError(error)
    );
  }

  vendors
  getVendorsList() {
    let postData = this.Token.getAPITokenData();
    this.http.post(`${this.apiURL}vendors`, postData).subscribe(
      data => {
        this.vendors = data['result'];
      },
      error => this.handleError(error)
    );
  }

  hideAllReports = true;
  decimalRound = 2;

  getInvoiceAmt(proj) {
    return proj.client_amount == null ? 0 : proj.client_amount;
  }

  getVendorsCurr(proj) {
    let curr = 0;
    curr = proj.currency_id;
    proj.vendors.forEach(ven => {
      if (ven.currency_id != 0)
        curr = ven.currency_id;
    });
    return curr;
  }

  getComments(comments){
    try {
      comments = JSON.parse(comments);
    } catch (e) {
      return '';
    }
    return comments.comments
   }

  displaySAPField(proj, field) {
    return typeof proj.SAP_VALUES[field.valKey] != 'undefined'
  }

  getDateTime(dt: string) {
    return this.helperService.formatDateTime(dt);
  }

  duplicateProject(id) {
    let cMsg = this.lang.getText('long_text2');
    if ( confirm(cMsg) == false ) {
      return false;
    }
    let postData = {
      parId: id,
    };
    this.projectSer.duplicate({
      parId: id,
    }).subscribe(
      data => {
        this.loadProjectsFreshly(false);
      },
      error => {
        this.handleError(error);
      }
    );
  }
  updateFuncText(lang){
    if(lang != undefined){
      this.disSetSortByOptions = [
        { id: 'orderNo',  name: lang.getText('project_number')},
        { id: 'deadline', name: lang.getText('deadline2')},
        { id: 'delta', name: lang.getText('delta')},
      ];
      this.disSetDeliveryDateOptions = [
        { id: '', name: lang.getText('all2')},
        { id: '12hours', name: lang.getText('next_12_hours')},
        { id: '24hours', name: lang.getText('next_24_hours')},
        { id: '48hours', name: lang.getText('next_48_hours')},
      ];
      console.log('')
      if (lang.getText('next_48_hours')) {
        clearInterval(this.interval);
      }
    }
  }

  reactivateAll(){
    this.selectedProjects.map(proj => this.reactivateProject(proj.id));
    this.closeModal();
  }
 
  reactivateProject(projectId) {
    this.http.post(`${this.apiURL}project/reactivate/${projectId}`, this.Token.getAPITokenData()).subscribe(
      data => this.removeProjectFromList(projectId),
      error => this.handleError(error)
    );
  }

  changeOrder(){
    this.projectFilterForm.value['order_direction'] == 'desc'||this.projectFilterForm.value['order_direction']==null ? this.projectFilterForm.patchValue({'order_direction': 'asc'},{emitEvent: !this.reportingPage}) : this.projectFilterForm.patchValue({'order_direction': 'desc'},{emitEvent: !this.reportingPage});
    setTimeout( () => this.loadProjectsFreshly(false) );
  }

  varfifyHavePermission() {
    this.notChangedStatus = [];
    const checkStatus = obj => obj.project_status_id === 0;

    let allowed = true;
    this.selectedProjects.map(proj => {
      if ( allowed && proj.project_status_id === 0 ) {
          allowed = false;
      }
    });
    const status = this.projectFilterForm.get('changeStatus').value;
    if ( ! allowed ){
      alert(this.lang.getText('long_change_status_error_text'));
      this.modalRef.hide();
      return false;
    }
    return true;
  }
  
  changeStatusAll(){
    if ( this.varfifyHavePermission() ) {
      this.selectedProjects.map(proj => this.changeStatus(proj.id,this.projectFilterForm.get('changeStatus').value));
      this.closeModal();
    }
  }
  changeStatus(projectId,statusId,showNotChangedList = true) {
    let postData=this.Token.getAPITokenData();
    postData['projectStatus'] = statusId;
    postData['projectId'] = projectId;
    this.http.post(`${this.apiURL}change-project-status-manual`, postData).subscribe(
      data => {
        if(data['result'] == "Status doesn't changed" && showNotChangedList){
          this.notChangedStatus.push(this.projects.filter(x => x.id === projectId));
          setTimeout(()=>this.openNotChangedStatusModal(this.notChangedStatusPopup),500);
        }
        this.getProject(projectId);
    },
      error => this.handleError(error)
    );
  }
  openNotChangedStatusModal(template: TemplateRef<any>){
    if(!this.statusPopupOpened){
      this.statusPopupOpened = true;
      this.modalRef = this.modalService.show(template);
    }
  }

  @ViewChild('filterFavoritesCom') filterFavoritesCom: FilterFavoritesComponent;
  openFilterFavouritesModal() {
    this.filterFavoritesCom.openModal(this.projectFilterForm, this.clients, this.owners, this.statuses, this.lSFilterDataKey);
  }

  @ViewChild('editDescriptionComment') editDescriptionComment: EditProjectDescriptionComponent;
  selectedProjectForEdit;
  openEditCommentDescriptionModal(project, templateName) {  
    this.selectedProjectForEdit = project;
    if (project.is_project_owner && project.project_status_id != 0 && project.quote_canceled_at == null) {
      this.editDescriptionComment.openModal(project.id)
      return
    }
    this.confirmClaimProject(templateName)
  }

  confirmClaimProject(template: TemplateRef<any>) {
    const project = {
      id: this.selectedProjectForEdit.id
    };
    this.modalRef = this.modalService.show(
      template,
      { backdrop: 'static' , initialState : project , class: "modal-md new-redesign-style"}
    );
  }

  @ViewChild('changeLabelTemp') changeLabelTemp: TemplateRef<any>;
  selectedProjectToSetLabel;
  openChangeLabelModal(project) {  
    this.selectedProjectToSetLabel = project;
    this.modalRef = this.modalService.show(this.changeLabelTemp, {class: "modal-lg new-redesign-style"});
  }

  changeLabel(labelId) {
    let projectId = this.selectedProjectToSetLabel.id;
    let postData = {
      token: this.Token.get(),
      projectId: projectId,
      labelId: labelId
    };
    this.http.post(`${this.apiURL}user-project-labels/set`, postData).subscribe(
      data => {
        this.modalRef.hide();
        this.getProject(projectId);
    },
      error => this.handleError(error)
    );
  }

  setStatus(project, status) {
    if ( ! confirm(this.lang.getTextWDS('are_you_sure_you_want_to_set_status_manually', [this.toTitleCase(this.lang.getProjectStatus(status.code))]) ) )
      return;
    this.changeStatus(project.id,status.id,false);
    return;
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  @ViewChild('openProjectRelationsCom') openProjectRelationsCom: ProjectRelationListComponent;
  openRelatedProjectModal(parent_project_id) {
    this.openProjectRelationsCom.getProjectRelations(parent_project_id);
  }
  
  onClickApply(template,reactivateModal,addUpModal){
    if(this.actionSelected != '')
    switch(this.actionSelected){
      case 'reactivateModal': this.openReactivateModal(reactivateModal); break;
      case 'addUpModal': this.openAddUpModal(addUpModal); break;
      default: this.openModal(template,this.actionSelected);
    }
  }
  ngOnDestroy() {
    document.body.classList.remove('add-bg-grey');
  }

  selectAllProjects(e) {
    this.projects = this.projects.map((projVals,i) => {
        projVals.selected = e.target.checked;
        this.projectsFa.at(i).get('selected').setValue(e.target.checked);
        return projVals;
    });
  }

  isAllSelected() {

    let isMasterSel = this.projectsFa.value.every(function(item:any) {
      return item.selected == true;
    })
    this.projectFilterForm.get('isMasterSel').setValue(isMasterSel);
  }

  filterShowLSKey = 'project_list_filter_hide';
  is_filter_hidden() {
    return localStorage.getItem(this.filterShowLSKey) == 'Yes';
  }

  toogle_filter() {
    let val = this.is_filter_hidden() ? 'No' : 'Yes';
    localStorage.setItem(this.filterShowLSKey, val);
  }

  actionShowLSKey = 'project_list_action_hide';
  is_action_hidden() {
    return localStorage.getItem(this.actionShowLSKey) == 'Yes';
  }

  toogle_action() {
    let val = this.is_action_hidden() ? 'No' : 'Yes';
    localStorage.setItem(this.actionShowLSKey, val);
  }
}

import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TokenService } from 'src/app/services/token.service';
import { JwtauthService } from 'src/app/services/jwtauth.service';
import { HelperService } from 'src/app/services/helper.service';
import { LanguageService } from 'src/app/services/language.service';
import { ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { UntypedFormArray } from '@angular/forms';
import { VendorCatPercentageChangeComponent } from '../vendor-cat-percentage-change/vendor-cat-percentage-change.component';
import { ProjectService } from 'src/app/services/project.service';
import { TranslationHelperService } from 'src/app/services/translation.helper.service';

@Component({
  selector: 'app-project-vendor-language-translations',
  templateUrl: './project-vendor-language-translations.component.html',
  styleUrls: [
    '../../project-create-page.component.scss',
    '../../project-main-tab-page.component.scss'
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class ProjectVendorLanguageTranslationsComponent implements OnInit {
  lang;
  action;
  apiURL;
  loading = false;
  allowLinkingBathch = true;
  errors;
  saveProjectMsg = false;
  formEventStop = { emitEvent: false };
  display = 'none';
  status = '';
  message = '';
  vendor = null;
  @ViewChild('appVendorCATPercentageChange') vendCatPerCom: VendorCatPercentageChangeComponent;
  selectedVendorTran;
  selectedVendorTranCombi;
  selectedVendorTranWordRate;
  translationsFa;

  @Input() vendorFc;
  @Input() projectEditDis;
  @Input() projectId;
  @Input() type;
  @Input() pvi = 0;
  @Input() trasnlation_pagination_data;
  @Input() wordMatchesA;
  @Input() wordMatchesB;
  @Input() languages;
  @Input() supplierServices;
  @Input() checkClaimed;
  @Input() checkXTMData;
  @Input() updateChildTrasnFA: EventEmitter<any> = new EventEmitter();
  @Input() hours;
  @Input() minutes;
  @Input() bsConfig;
  @Input() resGoToLastPage: boolean;
  @Input() addVenTrans: boolean;
  @Output() mapProjectResTrasnlationsEvent: EventEmitter<any> = new EventEmitter();
  @Output() addVendorBatchEvent: EventEmitter<any> = new EventEmitter();
  @Output() openImportCATModalEvent: EventEmitter<any> = new EventEmitter();
  @Output() isCATCombinationCopiableEvent: EventEmitter<any> = new EventEmitter();
  @Output() updateCATEvent: EventEmitter<any> = new EventEmitter();
  @Output() copyCATCombinationEvent: EventEmitter<any> = new EventEmitter();
  @Output() deleteVendorTranEvent: EventEmitter<any> = new EventEmitter();
  @Output() submit_project: EventEmitter<any> = new EventEmitter();
  @Output() amount_changed: EventEmitter<any> = new EventEmitter();

  constructor(
    private injector: Injector,
    private http: HttpClient,
    private Token: TokenService,
    private Jwtwis: JwtauthService,
    private fb: UntypedFormBuilder,
    public helperSer: HelperService,
    public language: LanguageService,
    public controlContainer: ControlContainer,
    private translation_helper: TranslationHelperService,
    private projectSer: ProjectService,
  ) {
    this.lang = language;
    this.apiURL = this.injector.get('API_URL');
  }

  ngOnInit() {
    this.translationsFa = this.vendorFc.get('translations');
    this.translationsFa.valueChanges.subscribe(id => {
      this.listen_changes()
    });
    if (this.updateChildTrasnFA) {
      this.updateChildTrasnFA.subscribe(vendor => {
        this.vendor = vendor;
      });
    }
    this.getPageOfResLangTrans();
  }

  ngOnChanges() {
    this.translationsFa = this.vendorFc.get('translations');
    if (this.resGoToLastPage) {
      this.getPageOfResLangTrans();
    }
    
    if(this.addVenTrans){
      // if (this.vendorsFA.length > 0) {
        const index = this.translationsFa.controls.findIndex(
          (tran) => tran.value.display === true
        );
        if(index != -1){
          this.getCopiableCATCombination(this.pvi, index);
        }
      // }
    }
  }

  get_translation_http
  getPageOfResLangTrans(action = '') {
    this.trasnlation_pagination_data.res_page_loading = true;
    let res_page = this.trasnlation_pagination_data.res_page;
    // if (this.vendorsFA.length > 0) {
      this.action = action;
      if (this.resGoToLastPage) {
        res_page = this.trasnlation_pagination_data.res_total_pages;
      } else if (action == 'prev') {
        res_page--;
      } else if (action == 'next') {
        res_page++;
      }
      let formData = this.Token.getAPITokenData();
      formData['projectId'] = this.projectId;
      formData['page'] = res_page;
      formData['per_page'] = this.trasnlation_pagination_data.res_per_page;
      formData['vendor_id'] = this.vendorFc.get('vendor_id').value;
      this.get_translation_http = this.http.post(`${this.apiURL}project/res-translations`, formData).subscribe(
        data => {
          this.clear_translations();
          this.trasnlation_pagination_data.res_total_pages = data['totalResTrasnlationPages'];
          this.trasnlation_pagination_data.res_page_loading = false;
          this.trasnlation_pagination_data.res_page = res_page;
          this.mapProjectResTrasnlationsEvent.emit({ pvi: this.pvi, venTras: data['translations'] });
        },
        error => {
          this.loading = false;
          if (error.status == 401) {
            this.handleError(error)
          } else {
            this.openModalDialog('failed', error.error.error);
            setTimeout(() => {
              this.closeModalDialog();
            }, 2 * 1000);
          }
        }
      );
    // }
  }

  loading_translations() {
    return ! this.get_translation_http || ! this.get_translation_http.isStopped;
  }

  clear_translations() {
    this.translationsFa.clear();
    return;
    this.translationsFa.controls.forEach((tran, ti) => {
      if ( tran.get('project_translate_id').value.length != 0 ) {
        this.translationsFa.removeAt(ti);
      }
    });
  }

  openImportCATModal(vendor, ti) {
    this.openImportCATModalEvent.emit({
      vendor: vendor,
      ti: ti
    })
  }

  displayThisVendTran(vi, i){
    this.copiableCATCombi = [];
    this.translationsFa.controls.forEach(tran => {
      tran.patchValue({display: false});
    });
    let tran = this.translationsFa.at(i);
    tran.patchValue({display: true}, this.formEventStop);
    this.enable_language_input(tran);
    this.enable_word_rate_input(tran);
    this.getCopiableCATCombination(vi, i);
  }

  copiableCATCombi = [];
  getCopiableCATCombination(vi, ti) {
    this.copiableCATCombi = [];
    if ( ! this.checkClaimed ) {
      return;
    }
    let venTran = this.translationsFa.at(ti).getRawValue();
    let formData = {
      token: this.Token.get(),
      projectId: this.projectId,
      page: 1,
      per_page: 5,
      source_lang_id: venTran.source_lang_id,
      target_lang_id: venTran.target_lang_id
    }
    this.http.post(`${this.apiURL}project/quoting-translations`, formData).subscribe(
      data => {
        let ptId = null;
        data['translations'].forEach(tran => {
          ptId = tran.id;
        });
        data['catAnalysis'].forEach(catAna => {
          if ( catAna.project_translate_id == ptId ) {
            this.copiableCATCombi.push(catAna);
          }
        });
      },
      error => {
        this.handleError(error)
      }
    );
  }

  copyCATCombination(vi, ti) {
    // if ( !this.isCATCombinationCopiable(vi, ti) ) {
    //   return
    // }
    if ( !confirm(this.lang.getText('are_you_sure')) ) {
      return;
    }

    let venCatCombFa = this.translationsFa.at(ti);
    this.copyCATCombinationGroup(venCatCombFa.get('catAnalysisGroA'), this.copiableCATCombi);
    this.copyCATCombinationGroup(venCatCombFa.get('catAnalysisGroB'), this.copiableCATCombi);
  }

  copyCATCombinationGroup(venCatCombsFa, catGroVals) {
    catGroVals.forEach(catGroVal => {
      venCatCombsFa.controls.forEach(venCatFa => {
        if (venCatFa.controls.word_match_id.value == catGroVal.word_match_id) {
          venCatFa.patchValue({
            word_count: catGroVal.word_count,
          });
        }
      });
    });
  }

  updateCAT(type, translation, index, ti) {
    if ( this.vendorFc.get('project_vendor_id').value == '' ) {
      this.submit_project.emit()
      return;
    }

    let form_data = {
      projectId: this.projectId,
      translation: translation.value,
      vendor_id: this.vendorFc.get('vendor_id').value,
      bTz: - ( new Date().getTimezoneOffset() )
    }
    translation.patchValue({ 'display': false });
    this.http.post(`${this.apiURL}project/translation-update-cat`, form_data).subscribe(
      data => {
        if ( data['result'] == 'created' ) {
          translation.patchValue({project_translate_id: data['id']}, this.formEventStop);
        }
        translation.patchValue({is_cat_changed: false}, this.formEventStop);
        this.amount_changed.emit();
      }
    );

    translation.controls['source_lang_id'].disable();
    translation.controls['target_lang_id'].disable();
    translation.controls['word_rate'].disable();
  }

  deletedeliverybatch(batch) {
    if ( !confirm(this.lang.getText('are_you_sure_you_want_to_delete_this_item')) )
      return;
      batch.patchValue({delete: 'yes'}, this.formEventStop);
  }

  initVendorBatchForm(vi, type, ti) {
    let dateTime = {date: 0, hour: 10, minute: 0};
    const fa = this.vendorFc.get(type).controls[ti].get('batches') as UntypedFormArray;
    const fg = this.fb.group({
      delete: '',
      project_translate_id: '',
      description: new UntypedFormControl({value: '', disabled: this.projectEditDis}),
      delivery_at: this.getDateTimeForm(dateTime),
    })
    fa.push(fg);
    return fg;
  }

  addVendorBatch(vi, type, ti) {
    this.initVendorBatchForm(vi, type, ti);
  }

  getDateTimeForm(dateT) {
    return this.fb.group({
      date: new UntypedFormControl({value: dateT.date, disabled: this.projectEditDis}),
      hour: new UntypedFormControl({value: dateT.hour, disabled: this.projectEditDis}),
      minute: new UntypedFormControl({value: dateT.minute, disabled: this.projectEditDis}),
    })
  }

  openVendorCATChangModal(vi, ti) {
    this.selectedVendorTranCombi = this.translationsFa.at(ti);
    this.selectedVendorTran = this.translationsFa.at(ti);
    let wordMatches = {
      wordMatchesA: this.wordMatchesA,
      wordMatchesB: this.wordMatchesB
    };
    this.vendCatPerCom.openModel(this.selectedVendorTranCombi, wordMatches);
  }

  vendorCATChangRes(tran) {
    let wordRate = this.selectedVendorTran.get('word_rate').value;
    this.selectedVendorTran.patchValue({word_rate: wordRate});
  }

  updateCATtoAll(vi, ti) {
    if ( ! confirm(this.lang.getText('are_you_sure')) ) {
      return;
    }
    let vendorFA = this.vendorFc;
    let vendor_id = vendorFA.get('vendor_id').value;
    let project_translate_id = this.translationsFa.at(ti).get('project_translate_id').value;
    let formData = {
      token: this.Token.get(),
      project_id: this.projectId,
      project_translate_id: project_translate_id,
      vendor_id: vendor_id
    };
    this.http.post(`${this.apiURL}project/translation/copy-cat/vendor`, formData).subscribe(
      data => {
        if ( data['success'] ) {
          this.getPageOfResLangTrans('');
        }
      },
      error => {
        this.handleError(error)
      }
    );
  }

  deleteVendorTran(trans, index) {
    if ( !confirm(this.lang.getText('are_you_sure_you_want_to_delete_this_item')) )
      return;
    if (trans.value.project_translate_id != null && trans.value.project_translate_id != '') {
      this.projectSer.deleteTranslation(this.projectId, trans.value.project_translate_id).subscribe(
        data => {
          this.deleteVendorTranFromArr(trans, index);
        },
        error => {
          this.handleError(error);
        }
      );
    } else {
      this.deleteVendorTranFromArr(trans, index);
    }
  }

  deleteVendorTranFromArr(trans, index) {
    trans.patchValue({ delete: 'yes' }, this.formEventStop);
    let vTranCons = this.vendorFc.controls
    let trans_orignal = vTranCons.translations_orignal.controls[(this.trasnlation_pagination_data.res_per_page * (this.trasnlation_pagination_data.res_page - 1)) + index]
    // trans_orignal.patchValue({ delete: 'yes' }, this.formEventStop);
    let trans_combi = vTranCons.translationsCombi.controls[(this.trasnlation_pagination_data.res_per_page * (this.trasnlation_pagination_data.res_page - 1)) + index]
    // trans_combi.patchValue({ delete: 'yes' }, this.formEventStop);
    vTranCons.translations_orignal.removeAt((this.trasnlation_pagination_data.res_per_page * (this.trasnlation_pagination_data.res_page - 1)) + index)
    this.deleteVendorTranEvent.emit();
    this.translationsFa.removeAt(index);
  }

  openModalDialog(status,message){
    this.display='block';
    this.status = status;
    this.message = message;
  }
  closeModalDialog(){
    this.display='none';
  }
  nonNumericValPress(event){
    if (event.key.match(/[^$,.\d]/)){
      alert(this.lang.getText('long_text11'));
      return false;
    }
  }

  nonNumericValPressDecimal(event){
    if (event.key.match(/[^$,.\d]/)){
      alert(this.lang.getText('long_text11_decimal'));
      return false;
    }
  }

  markTrasnlationFinanciallyClosed(checked, translation) {
    // if (!this.checkXTMData) {
    //   this.changeTrasnlationtoFinanciallyClosed(translation, checked);
    // } else {
    // }
    if (checked) {
      if (translation.get('project_finances_id').value == null) {
        if (!confirm(this.lang.getText('not_part_of_any_invoice_warning'))) {
          this.changeTrasnlationtoFinanciallyClosed(translation, false);
        }
      } else if (!confirm(this.lang.getText('are_you_sure_this_cannot_be_undone'))) {
        this.changeTrasnlationtoFinanciallyClosed(translation, false);
      }
    } else { // unchecked FinanciallyClosed checkbox
      alert(this.lang.getText('this_cannot_be_undone'));
      this.changeTrasnlationtoFinanciallyClosed(translation, true);
    }
  }
  changeTrasnlationtoFinanciallyClosed(translation, value) {
    translation.patchValue({
      "financially_closed": value
    });
  }

  handleError(error) {
    this.Jwtwis.handleError(error);
    this.errors = error.error.error;
    this.saveProjectMsg = false;
  }

  enable_word_rate_input(tran) {
    if (tran.value.display && !tran.value.financially_closed && !this.checkXTMData) {
      tran.controls['word_rate'].enable();
    }
  }

  enable_language_input(tran) {
    if ( tran.value.display && !tran.value.financially_closed && !this.checkXTMData ) {
      tran.controls['source_lang_id'].enable();
      tran.controls['target_lang_id'].enable();
    }
  }

  decimalRound = 2;
  listen_changes() {
    let lTsCost = 0;
    this.vendorFc.get('translations').controls.map((tcFg, tci) => {
      let wordsCount = 0;
      let subCost = 0;
      subCost = subCost + this.translation_helper.calculateCatGro(tcFg.controls.catAnalysisGroA)
      subCost = subCost + this.translation_helper.calculateCatGro(tcFg.controls.catAnalysisGroB)
      let tfg = this.translationsFa.controls[tci]
      let sub_total = this.helperSer.roundOfToFraction(subCost, this.decimalRound).toFixed(this.decimalRound);
      tfg.patchValue({amount: sub_total}, this.formEventStop);
      lTsCost = lTsCost + subCost;

      wordsCount =
        this.translation_helper.calculateWordsCatGro(tcFg.controls.catAnalysisGroA) +
        this.translation_helper.calculateWordsCatGro(tcFg.controls.catAnalysisGroB)

      let wordRate = tfg.getRawValue().word_rate;
      let weightedWordsCount =
        this.translation_helper.calculateWordsWeightedCatGro(tcFg.controls.catAnalysisGroA, wordRate) +
        this.translation_helper.calculateWordsWeightedCatGro(tcFg.controls.catAnalysisGroB, wordRate)
        tcFg.patchValue({
          words_count: wordsCount,
          weighted_count: this.helperSer.roundOfToFraction(weightedWordsCount, this.decimalRound),
          sub_total: this.helperSer.roundOfToFraction(subCost, this.decimalRound).toFixed(this.decimalRound),
        }, this.formEventStop);
    })
  }
}

<ng-template #modalTemp>
  <div *ngIf="details" class="modal-header">
    <h4 class="modal-title pull-left">
      <span>{{details.vendor.name}}</span> - {{lang.getText('rates')}}
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="details">
    <div>
      <table class="table table-border" aria-label="vendor-rat">
        <thead role="rowgroup">
          <tr class="rate-head-row" role="row">
            <th id="rate_unit" role="">{{lang.getText('rate_unit_description')}}</th>
            <th id="currency2" role="columnheader">{{lang.getText('currency2')}}</th>
            <th id="rate_per" role="columnheader">{{lang.getText('rate_per_unit')}}</th>
            <th id="status2" role="columnheader">{{lang.getText('status2')}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let rate of details.rates;">
            <td>
              {{rate.description}}
            </td>
            <td>
              {{rate.currency.name}}
            </td>
            <td>
              {{rate.rate_per_unit}}
            </td>
            <td>
              {{rate.status}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>

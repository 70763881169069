<div *ngIf="fieldsGroups" class="no-padding row sap-integration sap-container">
  <ng-container *ngFor="let field of fieldsGroups[0]">
    <app-sap-field [ngClass]="field.cp"
      class="row fd-inline-form"
      [fieldsValue]=fieldsValue [field]=field [sapData]=sapData [projectId]=projectId>
    </app-sap-field>
  </ng-container>
  <ng-container *ngFor="let field of fieldsGroups[1]">
    <app-sap-field [ngClass]="field.cp"
      class="row fd-inline-form"
      [fieldsValue]=fieldsValue [field]=field [sapData]=sapData [projectId]=projectId >
    </app-sap-field>
  </ng-container>
  <div class="col-lg-8 row no-padding col-c6c6">
    <ng-container *ngFor="let field of fieldsGroups[2]">
      <app-sap-field [ngClass]="field.cp"
        class="row fd-inline-form"
        [fieldsValue]=fieldsValue [field]=field [sapData]=sapData [projectId]=projectId >
      </app-sap-field>
    </ng-container>
  </div>
  <div class="col-lg-4 row fd-inline-form ">
    <ng-container *ngFor="let field of fieldsGroups[3]">
      <app-sap-field [ngClass]="field.cp"
        class="row fd-inline-form"
        [fieldsValue]=fieldsValue [field]=field [sapData]=sapData [projectId]=projectId >
      </app-sap-field>
    </ng-container>
    <ng-container *ngFor="let field of fieldsGroups[4]">
      <app-sap-field [ngClass]="field.cp"
        class="row fd-inline-form"
        [fieldsValue]=fieldsValue [field]=field [sapData]=sapData [projectId]=projectId >
      </app-sap-field>
    </ng-container>
  </div>
  <div class="col-lg-4 row fd-inline-form">
    <div class="label mb-2 color-black f-w-500 f-s-14 mt-3"><span>{{lang.getText('display_in_dashboard')}}</span></div>
    <div *ngIf="sapForm" [formGroup]='sapForm' class="col-lg-9 fd-form-con">
      <div class="fd-form-checkbox form-control" style="border-radius: 15px !important;">
      <div formArrayName="sapkeys" *ngFor="let field of fields let i = index" class="col">
        <input class="form-check-input" type="checkbox" value="{{field.valKey}}" [formControlName]=i />
        <label class="form-check-label" [for]=field.label>{{language.getText(field.valKey)}}</label>
      </div>
      </div>
    </div>
  </div>
  <div class="col-lg-4 row fd-inline-form">
    <ng-container *ngFor="let field of fieldsGroups[5]">
      <app-sap-field [ngClass]="field.cp"
        class="row fd-inline-form" 
        [fieldsValue]=fieldsValue [field]=field [sapData]=sapData [projectId]=projectId >
      </app-sap-field>
    </ng-container>
    <ng-container *ngFor="let field of fieldsGroups[6]">
      <app-sap-field [ngClass]="field.cp"
        class="row fd-inline-form" 
        [fieldsValue]=fieldsValue [field]=field [sapData]=sapData [projectId]=projectId >
      </app-sap-field>
    </ng-container>
  </div>
  <div class="col-lg-4 row fd-inline-form">
    <ng-container *ngFor="let field of fieldsGroups[7]">
      <app-sap-field [ngClass]="field.cp"
        class="row fd-inline-form" 
        [fieldsValue]=fieldsValue [field]=field [sapData]=sapData [projectId]=projectId >
      </app-sap-field>
    </ng-container>
  </div>

  <ng-container *ngFor="let field of fieldsGroups[8]">
    <app-sap-field [ngClass]="field.cp"
      class="row fd-inline-form"
      [fieldsValue]=fieldsValue [field]=field [sapData]=sapData [projectId]=projectId >
    </app-sap-field>
  </ng-container>
  
  <div class="row fd-inline-form col-12 my-4">
    <div class="col-lg-12 server-status">
      <a (click)="getInstructions()" class="btn btn-primary btn-sm ms-0">{{lang.getText('download_instructions_pdf')}}</a>
      <a (click)="uploadFile()" class="btn btn-primary btn-sm ms-3">{{lang.getText('copy_instructions_pdf_to_all_project_vendors')}}</a>
      <span class="badge-empty ms-3">
      <label class="status-label">{{lang.getText('server_status')}}</label>
      <span [class]="'status '+serverHealth"></span>
      </span>
    </div>
  </div>
</div>

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { TokenService } from "./token.service";
import { environment as env } from "src/environments/environment";
import { BehaviorSubject } from "rxjs";
import { JwtauthService } from "./jwtauth.service";

@Injectable({
  providedIn: "root",
})
export class DisplaySettingsService {
  private apiURL = env["API_URL"];
  private settingsBS = new BehaviorSubject<Array<any>>([]);
  public labelsObser = this.settingsBS.asObservable();
  public userDashboardSettings;
  public dashboardSettingsForm;
  public dashboardSettings: any[] = [];

  constructor(
    private token: TokenService,
    private http: HttpClient,
    private jwtAuth: JwtauthService
  ) {
    //
  }

  getLabels() {
    let postData = {
      token: this.token.get(),
      cat: 'project_dashboard'
    }
    this.http.post(`${this.apiURL}display-settings/labels`, postData).subscribe(
      (data) => {
        this.settingsBS.next(Object(data));
      },
      (error) => this.handleError(error)
    );
  }

  getAll() {
    let postData = {
      token: this.token.get(),
      cat: 'project_dashboard'
    }
    return this.http.post(`${this.apiURL}display-settings/get`, postData)
  }

  getProjectDisplaySettings(){
    let postData = {
      token: this.token.get(),
      // cat: 'project_display_quoting_tab,project_display_resourcing_tab,project_display_delivery,project_display_vendor',
      cat_start: 'project_display'
    }
    return this.http.post(`${this.apiURL}display-settings/get-project-display`, postData)
  }

  getProjectDisplayLabels() {
    let postData = {
      token: this.token.get(),
      // cat: 'project_display_quoting_tab,project_display_resourcing_tab,project_display_delivery,project_display_vendor',
      cat_start: 'project_display'
    }
    this.http.post(`${this.apiURL}display-settings/project-display-labels`, postData).subscribe(
      (data) => {
        this.settingsBS.next(Object(data));
      },
      (error) => this.handleError(error)
    );
  }

  handleError(error) {
    this.jwtAuth.handleError(error);
  }
}

import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EventEmitter } from '@angular/core';
import { JwtauthService } from 'src/app/services/jwtauth.service';
import { TokenService } from 'src/app/services/token.service';
import { environment as env } from 'src/environments/environment';
import { clone } from 'lodash-es';
import { HelperService } from 'src/app/services/helper.service';
import { LanguageService } from 'src/app/services/language.service';
import { SummerNoteConfig } from 'src/app/configs/summer-note-config';

@Component({
  selector: 'app-generate-invoice',
  templateUrl: './generate-invoice.component.html',
  styleUrls: ['./generate-invoice.component.scss']
})
export class GenerateInvoiceComponent implements OnInit {
  @Input() projectId;
  @Input() clientId;
  @Input() orderId: String;
  @Input() translationCount;
  @Input() currencies;
  @Output() reloadInvoice = new EventEmitter();
  @Output() reloadInvoiceList = new EventEmitter();
  @Output() reloadProject = new EventEmitter();
  @Output() reloadProjectFinance = new EventEmitter();
  apiURL = env['API_URL'];
  lang;
  summernoteConfig: SummerNoteConfig = new SummerNoteConfig();
  langCode;
  httpReq;
  showCurrencyDropdown: boolean = false;
  invoiceId;

  constructor(
    private http: HttpClient,
    private token: TokenService,
    private Jwtwis: JwtauthService,
    private fb: UntypedFormBuilder,
    private modalService: BsModalService,
    public helpSer: HelperService,
    public language:LanguageService
  ) {
    this.lang=language
  }

  ngOnInit() {
    this.intiForm();
    this.getTaxDesc();
    this.getTaxRates();
  }

  reloadFormData() {
    this.invoiceHTML = '';
    this.invoicePDF = '';
    this.getClientAddress();
  }

  @ViewChild('modalTemp') modalTemp;
  modalRef: BsModalRef;
  openModal(langCode) {
    this.langCode = langCode;// remove later
    this.reloadFormData();
    this.modalRef = this.modalService.show(
      this.modalTemp,
      { class: 'modal-lg new-redesign-style' }
    );
  }

  openModalWithParams(projectId, clientId, orderId) {
    this.projectId = projectId;
    this.clientId = clientId;
    this.orderId = orderId;
    this.reloadFormData()
    this.invoiceForm.patchValue({
      number: '#'+this.orderId,
    });
    this.modalRef = this.modalService.show(
      this.modalTemp,
      { class: 'modal-lg new-redesign-style' }
    );
  }

  @ViewChild('sendModalTemp') sendModalTemp;
  invoiceSendStep = 1;
  pdfObjUrl
  invoiceEmails = []; invoiceEmailIpt;
  openSendModal(pdfBlob, invoiceEmails,pinvoiceId,projectId = null, clientId = null, orderId = null) {
    this.pdfObjUrl = this.helpSer.urlObjPDF(pdfBlob);
    if (this.projectId == undefined && projectId != null) this.projectId = projectId;
    if (this.clientId == undefined && clientId != null) this.clientId = clientId;
    if (this.orderId == undefined && orderId != null) this.orderId = orderId;

    if (invoiceEmails != null && invoiceEmails.length > 0) {
      this.invoiceEmails.push(clone(invoiceEmails));
    }
    this.invoiceSendStep = 1;
    this.invoiceId = pinvoiceId;

    this.modalRef = this.modalService.show(
      this.sendModalTemp,
      { class: 'modal-xl' }
    );
  }

  closeModal() {
    this.invoiceHTML = '';
    this.invoicePDF = '';
    this.modalRef.hide();
  }

  invoiceForm: UntypedFormGroup;
  includesFa: UntypedFormArray = new UntypedFormArray([]);
  intiForm() {
    this.getIncluds().forEach(inc => {
      const control = new UntypedFormControl(false);
      this.includesFa.push(control);
    });
    this.invoiceForm = this.fb.group({
      address: '',
      number: '#'+this.orderId,
      decimal_separator: '.',
      taxDesc: '',
      taxRate: '',
      clientTaxID: '',
      includes: this.includesFa,
      secondCurrency: ''
    });
  }

  getIncluds() {
    return [
      { key: 'projectDesc', label: 'Project description', langKey: 'project_description' },
      { key: 'costCenter', label: 'Cost center' , langKey: 'cost_center' },
      { key: 'requester', label: 'Requester', langKey: 'requester' },
      { key: 'clientTaxID', label: 'Client Tax ID', langKey: 'client_tax_id' },
      { key: 'gridlines', label: 'Gridlines', langKey: 'gridlines' },
      { key: 'fullCATBreakdown', label: 'Full CAT breakdown', langKey: 'full_cat_breakdown' },
      { key: 'fullDeliverySchedule', label: 'Full Delivery schedule', langKey: 'full_delivery_schedule' },
    ]
  }

  taxDescs;
  getTaxDesc() {
    this.http.post(`${this.apiURL}tax/descriptions`, this.token.getAPITokenData()).subscribe(
      data => {
        this.taxDescs = data;
      },
      error => {
        this.handleError(error);
      }
    );
  }

  taxRates;
  getTaxRates() {
    this.http.post(`${this.apiURL}tax/rates`, this.token.getAPITokenData()).subscribe(
      data => {
        this.taxRates = data;
      },
      error => {
        this.handleError(error);
      }
    );
  }

  getPostData() {
    let postData = {token: this.token.get() };
    postData['projectId'] = this.projectId;
    let formData = this.invoiceForm.getRawValue();
    formData['includes'] = this.includesFa.value.map((v, i) => v ? this.getIncluds()[i].key : null ).filter(v => v !== null);
    postData['formData'] = formData;
    postData['formData']['number'] = postData['formData']['number'].replace('#', '');
    postData['langCode'] = this.langCode;
    postData['html'] = this.invoiceHTML;
    postData['decimal_separator'] = formData.decimal_separator;
    return postData;
  }

  generateInvoice() {
    this.reloadInvoice.emit('yes');
    this.reloadInvoice.emit('clear');
    this.reloadProjectFinance.emit();
    this.invoiceHTML = '';
    this.invoicePDF = '';
    this.closeModal();
  }

  invoiceHTML = '';
  generateInvoiceHTML() {
    if ( this.invoiceForm.get('includes').value[5] && this.translationCount > 100) {
      this.helpSer.alertIt(this.lang.getText('project_langu_combi_max_100_breakdown_alert'));
    }
    this.httpReq = this.http.post(`${this.apiURL}project/generate-invoice/html`, this.getPostData()).subscribe(
      data => {
        this.invoiceHTML = data['html'];
      },
      error => {
        this.handleError(error);
      }
    );
  }

  invoicePDF;
  generateInvoicePDF(emitEvent) {
    this.httpReq = this.http.post(`${this.apiURL}project/generate-invoice/pdf`, this.getPostData()).subscribe(
      data => {
        this.invoicePDF = this.helpSer.urlObjPDF(data['pdfB64']);
        if (emitEvent) {
          this.generateInvoice();
        }
      },
      error => {
        this.handleError(error);
      }
    );
  }

  sendInvoice() {
    if (this.invoiceEmails.length == 0) {
      alert(this.lang.getText("add_atleast_one_email_warning"));
      return;
    }
    let formData = {
      token: this.token.get(),
        projectId: this.projectId,
        emails: this.invoiceEmails,
        invoiceId: this.invoiceId
    };
    this.invoiceSendStep = 3;
    this.http.post(`${this.apiURL}project/send-invoice`, formData).subscribe(
      data => {
        this.invoiceSendStep = 4;
        setTimeout(()=>{  
          this.closeModal();
          this.reloadProject.emit('yes');
          this.reloadInvoiceList.emit({projectId: this.projectId});
        }, 2 * 1000);
      }
    );
  }

  getClientAddress() {
    this.invoiceForm.patchValue({
      address: '',
      clientTaxID: '',
    });

    let formData = {
      clientId: this.clientId,
      token: this.token.get()
    };
    this.http.post(`${this.apiURL}client/invoice-address`, formData).subscribe(
      data => {
        this.invoiceForm.patchValue({
          address: data['address'],
          clientTaxID: data['tax_vat_id'],
        });
        this.langCode = data['pdf_language'];
      },
      error => this.handleError(error)
    );
  }

  loading() {
    return this.httpReq && this.httpReq.isStopped == false
  }

  handleError(error) {
    this.Jwtwis.handleError(error);
  }

  onChangeSecondCurrency(){
    this.showCurrencyDropdown = !this.showCurrencyDropdown;
  }
}

<div class="row justify-content-md-center new-redesign-style">
  <div class="col-md-11 mt-3 mb-3" *ngIf="users">
    <div class="tab-content row justify-content-md-center">
      <div class="col-md-11 mb-3">
        <div class="header mt-4 mb-4 d-flex align-items-center">
          <h2>{{lang.getText('user_management')}}</h2>

          <div class="client-actions ps-2 ms-2">
            <button class="btn btn-circle" routerLink="/users-list/create" title="{{lang.getText('create_user')}}">
              <img src="/assets/svg/create-client.svg">
            </button>
          </div>

          <!-- Push this span to the right using ms-auto -->
          <span class="ms-auto">
            {{lang.getText('number_of_users')}}:
            <button class="btn btn-circle btn-success" routerLink="/users-list/create"
              title="{{lang.getText('create_user')}}">
              <strong>{{totalUsers}}</strong>
            </button>
          </span>
        </div>

        <div class="">
          <div class="row row-secondary mt-2 mb-2 p-2">
            <div class="col-md-4 col-lg-4 col-sm-4 text-start">{{lang.getText('name')}}</div>
            <div class="col-md-4 col-lg-4 col-sm-4 text-start">{{lang.getText('email')}}</div>
            <div class="col-md-2 col-lg-2 col-sm-2 text-start">{{lang.getText('actions')}}</div>
            <div class="col-lg-2 col-md-2 col-sm-2 text-start">{{lang.getText('role')}}</div>
          </div>
        </div>
        <div class="row content row-primary mb-2 p-1" *ngFor="let user of users; let i = index">
          <div class="col-md-4 col-lg-4 col-sm-12 text-start">
            <div class="hide_mobile">{{lang.getText('name')}}</div>
            {{user.name}}
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 text-start">
            <div class="hide_mobile">{{lang.getText('email')}}</div>
            {{user.email}}
          </div>
          <div class="col-lg-2 col-md-2 col-sm-12 text-start ps-0">
            <div class="hide_mobile">{{lang.getText('actions')}}</div>
            <a class="btn btn-circle-sm btn-circle btn-circle-icon" routerLink="/users-list/edit/{{user.id}}"
              title="{{lang.getText('edit')}}">
              <i class="fa fa-pencil color-primary" aria-hidden="true"></i>
            </a>
            <button (click)="delete(user.id)" class="btn btn-circle-sm btn-circle btn-circle-icon"
              title="{{lang.getText('delete')}}">
              <i class="fa fa-trash color-secondary" aria-hidden="true"></i>
            </button>
            <!-- <a class="btn border border-secondary me-2" routerLink="/users-list/edit/{{user.id}}">{{lang.getText('edit')}} <i class="fa fa-pencil" aria-hidden="true"></i></a>
            <button (click)="delete(user.id)" class="btn border border-secondary">{{lang.getText('delete')}} <i class="fa fa-trash" aria-hidden="true"></i></button> -->
          </div>
          <div class="col-lg-2 col-md-2 col-sm-12 text-start">
            <div class="hide_mobile">{{lang.getText('role')}}</div>
            <span *ngFor="let role of user.user_role">
              <span [class]="role.role.code"
                (click)="openModalManageClientsOf(manageClientsOfModal, user.id, role.role.id)">
                {{role.role.name}}
              </span><br />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #manageClientsOfModal>
  <div class="modal-header mt-2">
    <h4 class="modal-title pull-left">
      {{lang.getText('clients_managed_by_account_manager')}}
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div [formGroup]="clientsListForm" class="modal-body">
    <div class="row align-items-center mb-3">
      <div class="col-md-8">
        <div class="form-group">
          <select class="form-control" id="client" (change)="selectedClient = $event.target.value;">
            <option [value]="''">{{lang.getText('add')}} {{lang.getText('client')}}</option>
            <option *ngFor="let client of clients" [value]="client.id">{{client.name}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-4 text-end">
        <a (click)="addClientToUser()" class="btn btn-primary">{{lang.getText('add')}}</a>
      </div>
    </div>

    <div class="row row-secondary mt-2 mb-2 p-2">
      <div class="col-md-7 text-start"> {{lang.getText('client_list')}}</div>
      <div class="col-md-5 text-end">{{lang.getText('action')}}</div>
    </div>
    <div class="row content row-primary mb-2 p-1"
      *ngFor="let selCli of clientsListForm.get('selectedClients').controls; let coui = index;">
      <div class="col-md-7 text-start">
        {{clientsOfUser[coui].name}}
      </div>
      <div class="col-md-5 text-end" formArrayName="selectedClients">
        <input [formControlName]="coui" type="checkbox" [value]="clientsOfUser[coui].id" hidden />        
        <div class="align-items-center btn btn-md btn-rounded d-flex rounded-pill"
             [ngClass]="{ 'btn-selected': selCli.value, 'btn-unselected': !selCli.value }">
          <div>
            <span class="btn btn-circle btn-primary float-end p-2">
              <em class="fa fa-check fa-sm" *ngIf="!selCli.value"></em>
              <em class="fa fa-arrow-right fa-sm" *ngIf="selCli.value"></em>
            </span>
          </div>
          <span class="f-w-500 ps-5">
            <ng-container *ngIf="selCli.value; else unselected">
              {{lang.getText('selected')}}
            </ng-container>
            <ng-template #unselected>
              {{lang.getText('select')}}
            </ng-template>
          </span>
         
        </div>
      </div>
      
    </div>
    <div class="text-right">
      <button class="btn btn-danger btn-lg">{{lang.getText('delete_selection')}}</button>
    </div>
  </div>
</ng-template>



<!-- <div class="row justify-content-md-center">
  <div class="col-md-10 col-lg-10 col-sm-10" *ngIf="users">
    <h2 class="title row">{{lang.getText('user_management')}}</h2>
    <div class="d-flex ps-0 pe-0" style="margin-left: -10px;">
      <button class="btn btn-primary float-end" routerLink="/users-list/create">{{lang.getText('create_user')}}</button>
      <span class="btn total-users">{{lang.getText('number_of_users')}}: <strong>{{totalUsers}}</strong></span>
    </div>

    <br>
    <div class="users-list-layout">
      <div class="row header header_main for_web_only">
        <div class="col-3  name_info_head  ps-0 pe-0 font-weight-bold">{{lang.getText('name')}}</div>
        <div class="col-4 email_actions_head  ps-0 pe-0 font-weight-bold">{{lang.getText('email')}}</div>
        <div class="col-3 action_head  ps-0 pe-0 font-weight-bold">{{lang.getText('actions')}}</div>
        <div class="col-2 role_head  ps-0 pe-0 font-weight-bold">{{lang.getText('role')}}</div>
      </div>
    </div>
    <div class="row content user_single" *ngFor="let user of users; let i = index">
      <div class="col-md-3 col-lg-3 col-sm-12 name">
        <div class="hide_mobile">{{lang.getText('name')}}</div>
        {{user.name}}
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 email">
        <div class="hide_mobile">{{lang.getText('email')}}</div>
        {{user.email}}
      </div>
      <div class="col-lg-3 col-md-3 col-sm-12 action">
        <div class="hide_mobile">{{lang.getText('actions')}}</div>
        <a class="btn border border-secondary me-2" routerLink="/users-list/edit/{{user.id}}">{{lang.getText('edit')}} <i class="fa fa-pencil" aria-hidden="true"></i></a>
        <button (click)="delete(user.id)" class="btn border border-secondary">{{lang.getText('delete')}} <i class="fa fa-trash" aria-hidden="true"></i></button>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-12 role">
        <div class="hide_mobile">{{lang.getText('role')}}</div>
        <span *ngFor="let role of user.user_role">
          <span
            [class]="role.role.code"
            (click)="openModalManageClientsOf(manageClientsOfModal, user.id, role.role.id)">
            {{role.role.name}}
          </span><br />
        </span>
      </div>
    </div>
  </div>
</div> -->

<!-- <ng-template #manageClientsOfModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
        {{lang.getText('clients_managed_by_account_manager')}}
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div [formGroup]="clientsListForm" class="modal-body">
    <div class="row">
      <div class="col-md-7">
        <div class="form-group">
          <label for="email">{{lang.getText('select_client')}} </label>
          <select (change)="selectedClient = $event.target.value;">
            <option></option>
            <option *ngFor="let client of clients" value="{{client.id}}">{{client.name}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-5">
        <a (click)="addClientToUser()" class="btn btn-primary">{{lang.getText('add')}}</a>
      </div>
    </div>
    <div class="row">
      <div class="col-md-7">
        {{lang.getText('client_list')}}
      </div>
      <div class="col-md-5">
        <a (click)="removeClientFormUser()" class="btn btn-primary">{{lang.getText('delete_selection')}}</a>
      </div>
    </div>
    <div *ngFor="let selCli of clientsListForm.get('selectedClients').controls; let coui = index;" class="row">
      <div class="col-md-7">
        {{clientsOfUser[coui].name}}
      </div>
      <div formArrayName="selectedClients" class="col-md-5">
        <input [formControlName]="coui" type="checkbox" [value]="clientsOfUser[coui].id"/>
      </div>
    </div>
  </div>
</ng-template> -->
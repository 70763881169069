import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProjectsRoutingModule } from './projects-routing.module';
import { DetailsPopupComponent } from './components/details-popup/details-popup.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [
    DetailsPopupComponent
  ],
  imports: [
    CommonModule,
    ProjectsRoutingModule,
    SharedModule
  ],
  exports: [
    DetailsPopupComponent
  ]
  })
export class ProjectsModule { }

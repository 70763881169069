<div class="innerproject_details">
  <form *ngIf="vendorForm" [formGroup]='vendorForm'>
    <div class="row action-layout">
      <div class="col-xl-12">
        <div class="border-layout filter_main text-center txt_cnt vendor-banner">
          <p>
            <u *ngIf="!isVendor()"> {{lang.getText('long_text15')}}</u>
            <button *ngIf="!disableEdit()" (click)="updateMyDetails()" class="btn btn-primary save-changes">{{lang.getText('save_changes2')}}</button>
          </p>
        </div>
      </div>

    </div>
    <br />
    <div class="row action-layout">
      <div class="col-xl-8">
        <div class="contacts-section" [ngClass]="{'full-height': hideDisplayLabel('vendor_language_combinations_section')}">
          <ng-container formArrayName="contacts" *ngFor="let contact of contactsFa['controls']; let ci = index">
            <div [formGroupName]="ci" class="border-layout filter_main contact-section">
              <span class="text">
                {{lang.getText('contact')}} {{ci+1}}
                <em
                  *ngIf="ci > 0"
                  (click)="contact.enabled?contact.disable():contact.enable()"
                  [ngClass]="contact.disabled?'fa-eye-slash':'fa-eye'"
                  class="fa"></em>
              </span>
              <div *ngIf="validErrors" class="col-12">
                <div *ngIf="validErrors['contacts.'+ci+'.email']" class="form-errors">
                  <p *ngFor="let err of validErrors['contacts.'+ci+'.email']" class="error">{{err}}</p>
                </div>
              </div>
              <div class="row" [hidden]="contact.disabled">
                <div class="col-xl-6 col-lg-6 col-md-12">
                  <div class="row">
                    <div class="col-xl-3">
                      <label> {{lang.getText('name2')}}</label>
                    </div>
                    <div class="col-xl-9 ">
                      <input formControlName='name' class="form-control smallin_txt" type="text">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-3">
                      <label> {{lang.getText('email_address')}}</label>
                    </div>
                    <div class="col-xl-9">
                      <input formControlName='email' class="form-control smallin_txt" type="text">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-3">
                      <label>{{lang.getText('street_address')}}</label>
                    </div>
                    <div class="col-xl-9">
                      <div class="form-group">
                        <textarea formControlName='address' class="form-control textarea_txt" rows="4"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12">
                  <div class="row">
                    <div class="col-xl-3">
                      <label>{{lang.getText('phone')}}</label>
                    </div>
                    <div class="col-xl-9">
                      <input formControlName="phone" class="form-control smallin_txt" type="text">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-3">
                      <label style="padding-bottom: 10px;padding-top: 10px;">{{lang.getText('im_account')}} </label>
                    </div>
                    <div class="col-xl-9">
                      <input formControlName="im_type" class="smallin_txt form-control " type="text" />
                    </div>
                    <div class="col-xl-3">
                      <label>{{lang.getText('tax_vat_id')}}</label>
                    </div>
                    <div class="col-xl-9">
                      <input formControlName="im" class="smallin_txt form-control " type="text" />
                    </div>
                  </div>
                  <div class="row contact_comment">
                    <div class="col-xl-3">
                      <label>{{lang.getText('role_comments')}}</label>
                    </div>
                    <div class="col-xl-9">
                      <div class="form-group">
                        <textarea formControlName="comment" class="form-control textarea_txt" rows="2"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </ng-container>
        </div>
        <div #langCombiTemp class="border-layout filter_main lang-combi-section" [hidden]="hideDisplayLabel('vendor_language_combinations_section')" >
          <span class="text"> {{lang.getText('language_combinations')}} </span>
          <div class="form-row filter-sec-i">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <span class="handle-all-cb noGrayedOut">
                      <button (click)="addLangCombi()" [disabled]=vendorForm.value.cb_handling_all_major_lang
                        class="btn btn-xs btn-primary" type="button">{{lang.getText('add_combination')}}</button>
                      &nbsp; {{lang.getText('lsp_handling_all_major_languages')}}
                      <input (click)="transServicesFa.disable ? transServicesFa.enable() : transServicesFa.disable();"
                        class="check-box-head" formControlName="cb_handling_all_major_lang" type="checkbox" id="l1"
                        name="c1">
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <a class="cur-pointer" (click)="fileLangCombi.click()">
                      <u *ngIf="!disableEdit()">{{lang.getText('upload_list')}}</u>
                    </a>
                    <input (change)="updateLangCombi($event)" type="file" #fileLangCombi style="display: none;"
                      [accept]=acceptExcel />
                    <span class="btn upload-fn"
                      title="{{getFileName(vendorForm.value.lsp_lang_combi_path)}}">{{getFileName(vendorForm.value.lsp_lang_combi_path)}}</span>
                    <a (click)="exportLangCombi()" class="cur-pointer"> <u>{{lang.getText('download_list')}}</u></a>
                  </div>
                </div>
              </div><br>
              <div [ngClass]="{grayedOut: vendorForm.value.cb_handling_all_major_lang}">
                <div class="row">
                  <div class="col-md-3">
                    {{lang.getText('source2')}}
                  </div>
                  <div class="col-md-3">
                    {{lang.getText('target2')}}
                  </div>
                  <div class="col-md-2">
                    {{lang.getText('target_native_speaker')}}
                  </div>
                  <div class="col-md-3">
                    {{lang.getText('specialization')}}
                  </div>
                  <div class="col-md-1 no-padding">
                    {{lang.getText('trash')}}
                  </div>
                </div>
                <div formArrayName="transServices"
                  style="overflow-x: hidden; min-height: 175px; max-height: 476px; overflow-y: auto; margin-top: 10px;">
                  <div *ngFor="let trans of vendorForm.get('transServices')['controls']; let tsi = index;"
                    [formGroupName]="tsi" class="row">
                    <div class="col-md-3">
                      <select formControlName="source_lang_id" class="form-control">
                        <option *ngFor="let language of languages; let li = index" [value]="language.id">
                          {{language.name}}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-3">
                      <select formControlName="target_lang_id" class="form-control">
                        <option *ngFor="let language of languages; let li = index" [value]="language.id">
                          {{language.name}}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-2">
                      <input formControlName="native_speaker" type="checkbox" id="c1" name="c1">
                    </div>
                    <div class="col-md-3">
                      <select formControlName="specialization_code_id" class="form-control">
                        <option *ngFor="let code of specCodes; let li = index" [value]="code.id">
                          {{code.name}}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-1">
                      <span (click)="deleteLangCombi(tsi)" class="fa fa-trash fa-lg"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br>
        <div class="row" [hidden]="hideDisplayLabel('vendor_cat_tool_expertise_section')">
          <div class="col-md-12 two-box">
            <div class="border-layout filter_main">
              <span class="text"> {{lang.getText('cat_tool_expertise')}}</span>
              <div class="form-row filter-sec-i">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-6 align-items-center d-flex">
                          <button (click)="addToolExp()" [disabled]=vendorForm.value.cb_handling_all_major_tools
                            class="btn btn-primary">
                            {{lang.getText('add_tool')}}
                          </button>
                          <span class="handle-all-cb noGrayedOut">
                            &nbsp; {{lang.getText('lsp_handling_all_major_tools')}}
                          </span>
                          <input
                            (click)="toolsExpertiseFa.disable ? toolsExpertiseFa.enable() : toolsExpertiseFa.disable();"
                            class="" style="margin-top:8px!important;" formControlName="cb_handling_all_major_tools"
                            type="checkbox" id="l1" name="c1">
                        </div>
                        <div class="col-md-6">
                          <a *ngIf="!disableEdit()" class="cur-pointer" (click)="fileHandTool.click()">
                            <u>{{lang.getText('upload_list')}}</u>
                          </a>
                          <span class="btn upload-fn"
                            title="{{getFileName(vendorForm.value.lsp_cat_tool_exp_path)}}">{{getFileName(vendorForm.value.lsp_cat_tool_exp_path)}}</span>
                          <input (change)="updateHandTool($event)" type="file" #fileHandTool style="display: none;"
                            [accept]=acceptExcel />
                          <a class="cur-pointer" (click)="exportHandTool()"> <u>{{lang.getText('download_list')}}</u></a>
                        </div>
                      </div>
                    </div>
                  </div><br>
                  <div [ngClass]="{grayedOut: vendorForm.value.cb_handling_all_major_tools}" class="col-md-12">
                    <div class="row">
                      <div class="col-md-2 tool_name" style="padding-left: 20px;">
                        {{lang.getText('tool_name')}}
                      </div>
                      <div class="col-md-4">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-5" style="padding-left: 20px;">
                                {{lang.getText('version')}}
                              </div>
                              <div class="col-md-7">
                                {{lang.getText('level_of_expertise')}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-5">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-4 tool_name">
                                {{lang.getText('license_owned')}}
                              </div>
                              <div class="col-md-8 ">
                                {{lang.getText('comments')}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-1 no-padding"> {{lang.getText('trash')}} </div>
                    </div>
                  </div>
                  <div [ngClass]="{grayedOut: vendorForm.value.cb_handling_all_major_tools}" class="col-md-12"
                    style="overflow-x: hidden; min-height: 128px; max-height: 434px; overflow-y: auto; margin-top: 10px;"
                    formArrayName="toolsExpertise">
                    <div *ngFor="let tool of toolsExpertiseFa['controls']; let tei = index;" [formGroupName]="tei"
                      class="row">
                      <input formControlName="level" type="hidden">
                      <div class="col-md-2">
                        <input formControlName="name" class="form-control smallin" type="text">
                      </div>
                      <div class="col-md-4">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-5">
                                <input formControlName="version" class="form-control smallin" type="text">
                              </div>
                              <div class="col-md-7">
                                <em *ngFor="let rate of ratings; let ri = index;" class="fa fa-star icon-c rating"
                                  [ngClass]="{orange: (tool.value.level > ri)}"
                                  (click)="tool.patchValue({level: (ri+1)})"></em>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-5">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-4">
                                <input formControlName="license" type="checkbox" id="l1">
                              </div>
                              <div class="col-md-8 cat_comments">
                                <input formControlName="comments" class="form-control smallin" type="text">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-1">
                        <span (click)="deleteToolExp(tei)" class="fa fa-trash fa-lg"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br [hidden]="hideDisplayLabel('vendor_cat_tool_expertise_section')">
        <div class="row rates-layout">
          <div class="col-md-12 two-box">
            <div class="border-layout filter_main">
              <span class="text">{{lang.getText('rates')}}</span>
              <div class="form-row filter-sec-i">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-6 align-items-center d-flex">
                          <button (click)="addRate()" [disabled]=vendorForm.value.cb_with_rate_agreement
                            class="btn btn-primary" type="button">{{lang.getText('add_rate')}} </button>
                          <span class="handle-all-cb">
                            &nbsp; {{lang.getText('lsp_with_rate_agreement')}}
                          </span>
                          <input class="" style="margin-top:8px!important;" formControlName="cb_with_rate_agreement"
                            type="checkbox" id="l1" name="c1">
                        </div>
                        <div class="col-md-6">
                          <a *ngIf="!disableEdit()" (click)="fileRateAgree.click()" class="cur-pointer"> <u>{{lang.getText('upload_list')}}</u></a>
                          <input (change)="updateRateAgree($event)" type="file" #fileRateAgree style="display: none;"
                            [accept]=acceptExcel />
                          <span class="btn upload-fn"
                            title="{{getFileName(vendorForm.value.lsp_rate_agree_path)}}">{{getFileName(vendorForm.value.lsp_rate_agree_path)}}</span>
                          <a (click)="exportRateAgree()" class="cur-pointer"> <u>{{lang.getText('download_list')}}</u></a>
                        </div>
                      </div>
                    </div>
                  </div><br>
                  <div [ngClass]="{grayedOut: vendorForm.value.cb_with_rate_agreement}">
                    <div class="row" style="margin-left: -6px;">
                      <div class="col-md-2 col-lg-2 col-xl-2">
                        {{lang.getText('rate_unit_description')}}
                      </div>
                      <div class="col-md-2 col-lg-2 col-xl-2">
                        {{lang.getText('currency2')}}
                      </div>
                      <div class="col-md-2 col-lg-2 col-xl-2">
                        {{lang.getText('rate_per_unit')}}
                      </div>
                      <div class="col-md-6 col-lg-6 col-xl-6">
                        <span style="margin-left: 206px;">{{lang.getText('status2')}}</span>
                      </div>
                    </div>
                    <div formArrayName="rates"
                      style="overflow-x: hidden; min-height: 130px; max-height: 434px; overflow-y: auto; margin-top: 10px;">
                      <div *ngFor="let rate of ratesFa['controls']; let ri = index;" [formGroupName]="ri" class="row">
                        <div class="col-md-2 col-lg-2 col-xl-2">
                          <input formControlName="description" class="form-control smallin" type="text"
                            [title]="rate.value.description">
                        </div>
                        <div class="col-md-2 col-lg-2 col-xl-2">
                          <select formControlName="currency_id" class="form-control currency">
                            <option *ngFor="let curr of currencies;" [value]="curr.id">
                              {{curr.name}}
                            </option>
                          </select>
                        </div>
                        <div class="col-md-2 col-lg-2 col-xl-2">
                          <input formControlName="rate_per_unit" min="0" class="form-control smallin" type="number">
                        </div>
                        <div class="col-md-6 col-lg-6 col-xl-6 no-padding row">
                          <div class="col-md-10">
                            <span *ngIf="disableEdit() == false">
                              <input [disabled]="disableSubmitRate(rate)" (click)="submitRate(rate)"
                                class="btn btn-sm btn-primary rate-text" type="button" value="{{lang.getText('submit')}}">
                              <input [disabled]="disableChangeRate(rate)" (click)="rate.enable()"
                                class="btn btn-sm btn-primary rate-text" type="button" value="{{lang.getText('change')}}">
                              <input [disabled]="disableApproveRate(rate)" (click)="approveRate(rate)"
                                class="btn btn-sm btn-primary rate-text" type="button" value="{{lang.getText('approve')}}">
                              <input [disabled]="disableRejectRate(rate)" (click)="rejectRate(rate)"
                                class="btn btn-sm btn-primary rate-text" type="button" value="{{lang.getText('reject')}}">
                            </span>
                            <span style="margin-left: 10px; display: inline-block;">
                              <p *ngIf="rate.value.status === 'approved' " style="color: rgb(8, 131, 8);">
                                {{rate.value.status | titlecase}}</p>
                              <p *ngIf="rate.value.status === lang.getText('rate-proposed')" style="color: rgb(41, 28, 111);">
                                {{rate.value.status | titlecase}}</p>
                              <p *ngIf="rate.value.status === 'rejected' " style="color: rgb(240, 54, 40);">
                                {{rate.value.status | titlecase}}</p>
                            </span>
                          </div>
                          <div class="col-md-2">
                            <span *ngIf="rate.value.status != 'approved'" (click)="deleteRate(ri)"
                              class="fa fa-trash fa-lg"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-errors">
                    <p *ngFor="let error of rateErrors" class="error">{{error}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-12 col-md-6 col-sm-12 col-xs-12  size1024 ">
        <input (change)="updateISODoc($event)" type="file" #fileISODoc style="display: none;" accept=".pdf" />
        <div class="border-layout iso-docs-section">
          <div class="actoins_outer col-auto col-form-label ">
            <span class="text"> {{lang.getText('iso_documentation')}} </span><br />
            <div class="col-xl-12 col-lg-6 col-md-12" style="font-size: 11px;">
              <div *ngFor="let doc of vendorDetail.isoDocs" class="row single-iso">
                <div class="col-xl-4 col-lg-4 col-form-label-sm no-padding my-auto">
                  <label>{{doc.iso_doc.title}}</label>
                </div>
                <div class="col-xl-1 col-lg-1 my-auto">

                  <a *ngIf="doc.path !==null" style="color:#8dbe83;">{{lang.getText('yes')}}</a>
                  <a *ngIf="doc.path ==null" style="color:red">{{lang.getText('no')}}</a>
                </div>
                <div class="col-xl-3 col-lg-3 no-padding my-auto">
                  <span [title]=getFileName(doc.path) class="btn upload-fn"
                    style="max-width: 100%;margin-left: 5px;line-height: 0;">
                    {{getFileName(doc.path)}}
                  </span>
                </div>
                <div class="col-xl-4 col-lg-4 my-auto fa-icons">
                  <a class="cur-pointer">
                    <u *ngIf="!disableEdit()" class="fa fa-2x fa-upload" title="{{lang.getText('upload')}}"
                      (click)="uploadDocId=doc.vendor_iso_document_id; fileISODoc.click()">
                    </u>
                  </a>&nbsp;
                  <a [ngClass]="{'cur-pointer': doc.path !== null}" (click)="downISODoc(doc.vendor_iso_document_id)">
                    <u class="fa fa-2x fa-download" title="{{lang.getText('download')}}"></u>
                  </a>&nbsp;
                  <a [ngClass]="{'cur-pointer': doc.path !== null}">
                    <u *ngIf="!disableEdit()" class="fa fa-2x fa-trash" title="{{lang.getText('delete')}}" (click)="deleteISODoc(doc)">
                    </u>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="border-layout pitch-section" [style.height.px]="[pitchHeight]">
          <div class="actoins_outer col-auto col-form-label " style="height:100%">
            <span class="text"> {{lang.getText('pitch')}} </span><br />
            <div class="col-xl-12 col-lg-12 col-md-12" style="height:100%">
              <div class="row" style="position: relative;height: 100%;">
                <div class="col-xl-12 col-form-label-sm pitch">
                  <u *ngIf="!disableEdit()" class="cur-pointer" (click)="logoInput.click()">{{lang.getText('upload_a_picture_or_logo')}}</u>
                  <u *ngIf="disableEdit()">{{lang.getText('upload_a_picture_or_logo')}}</u>
                  <input (change)="uploadLogo($event)" accept="image/png, image/jpeg" type="file" #logoInput
                    style="display: none;">
                  <br>
                  <img *ngIf="logoData" [src]="'data:image/png;base64,'+logoData.file" width="200" heigh="200"
                    alt="Angular Logo" style="height: 175px; width: auto; max-width: 100%;">
                </div>
                <br>
                <div class="col-xl-12 tell-us-layout">
                  <div class="row">
                    <u class="pitch">{{lang.getText('tell_us_who_you_are')}}</u>
                  </div>
                  <div class="row">
                    <div class="form-group" style="width: 95%;">
                      <textarea style="min-height: 180px;" formControlName="tell_us_who_you_are" class="form-control"
                        rows="4" cols="23"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="border-layout">
          <div class="actoins_outer col-auto col-form-label " style="height:100%">
            <span class="text"> {{lang.getText('availability')}} </span><br />
            <div class="col-xl-12 col-lg-12 col-md-12" style="height:100%">
              <div class="row">
                <div class="col-xl-12">
                  <p style="font-size: 14px;text-align: justify;">
                    {{lang.getText('please_click_on_a_date_repeatedly_to_toggle_the_color')}}
                  </p>
                </div>
                <div class="col-xl-12 col-form-label-sm no-padding">
                  <calendar-avail vendorId="{{vendorId}}"></calendar-avail>
                </div>
                <div class="col-xl-12 col-form-label-sm pad-lft2 no-padding ">
                  <div class="row m-auto">
                    <div class="col-4">
                      <div class="input-color div-title-label" data-tip="No click for Available status.">
                        <div class="color-box" style="background-color: #00800080;"></div>   <label class="label-input cal1">{{lang.getText('available')}} </label>
                       

                      </div>
                    </div>
                    <div class="col-4">
                      <div class="input-color div-title-label" data-tip="Single click for Booked status.">
                        <div class="color-box" style="background-color:#ff6860c9;"></div>   <label class="label-input cal2">{{lang.getText('booked')}} </label>
                      </div>
                    </div>
                    <div class="col-4">

                      <div class="input-color  div-title-label" data-tip="Double click for Partially Available status.">
                        <div class="color-box" style="background-color:#ffa500b8;"></div>   <label class="label-input cal3">{{lang.getText('partially_available')}}</label>
                      </div>

                    </div>
                  </div>

                </div>


                <div class="col">
                  <div class="row">
                    <u class="availability">{{lang.getText('comments')}}</u>
                  </div>
                  <div class="row">
                    <div class="form-group" style="width: 96%;">
                      <br>
                      <textarea formControlName="availability_comments" class="form-control" rows="4" cols="18"
                        style="height: 180px;"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div *ngIf="!isVendor()">
      <div class="row action-layout">
        <div class="col-xl-12">
          <div class="border-layout filter_main text-center txt_cnt">
            <p><u> {{lang.getText('long_text16')}} </u></p>
          </div>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-12">
          <div class="border-layout filter_main">
            <span class="text"> {{lang.getText('vendor_review_summary')}}</span>
            <div class="form-row filter-sec-i">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6">
                        <input (click)="addReviewItem()" class="btn btn-primary" type="button" value="{{lang.getText('add_item')}}">
                        <input (click)="reviewItemsFa.enable()" class="btn btn-primary" type="button"
                          value="{{lang.getText('change_item')}}">
                      </div>
                      <div class="col-md-6">
                        <input (change)="updateReviewDoc($event)" type="file" #fileReviewDoc style="display: none;"
                          accept=".pdf" />
                        <a *ngIf="!disableEdit()" (click)="fileReviewDoc.click()" class="cur-pointer"> <u>{{lang.getText('upload_review_document')}}</u></a>
                        <span class="btn upload-fn"
                          title="{{getFileName(vendorForm.value.su_review_document_path)}}">{{getFileName(vendorForm.value.su_review_document_path)}}</span>
                        <a (click)="downReviewDoc()" class="cur-pointer"> <u>{{lang.getText('download_review_document')}}</u></a>
                      </div>
                    </div>
                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-md-7">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-6">
                          </div>
                          <div class="col-md-6">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5">

                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="row">
                          <div formArrayName="reviewItems" class="col-md-8">
                            <div *ngFor="let item of vendorForm.get('reviewItems')['controls']; let rii = index;"
                              [formGroupName]="rii" class="row">
                              <div class="col-md-6">
                                <p *ngIf="item.disabled" class="summary-section">{{item.value.title}}:</p>
                                <input *ngIf="item.enabled" formControlName="title" type="text"
                                  class="form-control form-control-sm" />
                              </div>
                              <div class="col-md-6">
                                <span>
                                  <em *ngFor="let rate of ratings; let ri = index;" class="fa fa-star icon-c rating"
                                    [ngClass]="{orange: (item.value.review > ri)}" (click)="starClicked(ri, item)"></em>
                                </span>
                                <em (click)=deleteReviewItem(rii) *ngIf="item.enabled"
                                  class="fa fa-trash fa-lg cur-pointer review-delete"></em>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <strong>{{lang.getText('overal_review_score')}}</strong> <br>
                            <span class="score">
                              <div class="score-wrap">
                                <span class="stars-active" [style.width.%]="overalReviewScore()">
                                  <i *ngFor="let rate of ratings; let ri = index;"
                                    class="fa fa-star icon-c rating orange" aria-hidden="true"></i>
                                </span>
                                <span class="stars-inactive">
                                  <i *ngFor="let rate of ratings; let ri = index;" class="fa fa-star icon-c rating"
                                    aria-hidden="true"></i>
                                </span>
                              </div>
                            </span>

                            <strong>{{lang.getText('last_updated')}}</strong>
                            <br>
                            {{vendorForm.value.su_updated_by_name}}
                            <br>
                            {{vendorForm.value.su_updated_at + ' GMT' | date:'d-MMM-y HH:mm':'local'}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 vend_review_comment">
                    <div class="form-group">
                      {{lang.getText('comments')}}
                      <textarea formControlName="su_comment" [readonly]="disableEdit()"
                        class="form-control textarea_txt " rows="4" cols="20"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="backdrop" [ngStyle]="{'display':display}"></div>
<div class="modal " tabindex="-1" role="dialog" [ngStyle]="{'display':display}">
  <div class="modal-dialog" role="document">
    <div class="modal-content modal-color">
      <div class="modal-header text-center">
        <em class="fa fa-check" style="padding-top: 4px;"></em> &nbsp;
        {{ this.actionStatus }}
        <button type="button" class="close" aria-label="Close" (click)="closeModalDialog()"><span
            aria-hidden="true">&times;</span></button>
      </div>
    </div>
  </div>
</div>

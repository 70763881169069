export class AppConfig {
    public dateTimeFormate:string = 'dd-MM-yyyy / HH:mm';
    public dateFormate:string = 'dd-MM-yyyy';
    public apiDateFormate:string = 'dd/MM/yyyy';
    public pdfDateFormate:string = 'yyyy-MM-dd';
    public releaseNoteVersion: Number = 4.0;
    public appLanguages = [
      {'code' : 'de', 'name' : 'Deutsch (German)'},
      {'code' : 'en', 'name' : 'English'},
      {'code' : 'es', 'name' : 'Español (Spanish)'},
      {'code' : 'fr', 'name' : 'Français (French)'},
      {'code' : 'it', 'name' : 'Italiano (Italian)'},
      {'code' : 'nl', 'name' : 'Nederlands (Dutch)'},
      {'code' : 'pt', 'name' : 'Português (Portuguese)'},
    ];
}
